<template>
  <v-btn
    icon
    :class="{
      'ml-1': $vuetify.breakpoint.mdAndUp
    }"
    @click="$emit('change', layout == 'details' ? 'thumbnail' : 'details')"
  >
    <v-icon>{{ layout == 'details' ? 'mdi-view-grid' : 'mdi-format-list-text' }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    layout: {
      type: String,
      default: null
    }
  }
}
</script>