import ActivityDetailsPage from '@/modules/shared/pages/ActivityDetailsPage/ActivityDetailsPage.vue';
import ActivityFilesPage from '@/modules/shared/pages/ActivityFilesPage/ActivityFilesPage.vue';
import EpisodeDetailsPage from '@/modules/shared/pages/EpisodeDetailsPage/EpisodeDetailsPage.vue';
import EpisodeFilesPage from '@/modules/shared/pages/EpisodeFilesPage/EpisodeFilesPage.vue';
import EpisodeMapPage from '@/modules/shared/pages/EpisodeMapPage/EpisodeMapPage.vue';

export default [
  {
    path: '/shared/:episodeId',
    name: 'shared-episode',
    component: EpisodeDetailsPage,
    meta: { shared: true }
  },
  {
    path: '/shared/:episodeId/activity/:activityId',
    name: 'shared-activity-details',
    component: ActivityDetailsPage,
    meta: { shared: true }
  },
  {
    path: '/shared/:episodeId/files',
    name: 'shared-episode-files',
    component: EpisodeFilesPage,
    meta: { shared: true }
  },
  {
    path: '/shared/:episodeId/activity/:activityId/files',
    name: 'shared-activity-files',
    component: ActivityFilesPage,
    meta: { shared: true }
  },
  {
    path: '/shared/:episodeId/map',
    name: 'shared-episode-map',
    component: EpisodeMapPage,
    meta: { shared: true }
  },
]
