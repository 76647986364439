<template>
  <div class="app-menu">
    
    <v-container class="app-menu__button-container">
      <user-button
        v-if="!open"
        class="app-menu__button"
        @click.native="onClick"
      />
    </v-container>

    <div
      class="app-menu__overlay" 
      :class="open ? 'app-menu__overlay--open' : ''" 
      @click="open = false"
    ></div>

    <menu-items :open.sync="open" />

  </div>
</template>

<script>
import { eventBus } from '@/eventBus.js';
import { mapActions } from 'vuex';
import MenuItems from './MenuItems';
import UserButton from './UserButton';

export default {
  components: {
    MenuItems,
    UserButton
  },
  data() {
    return {
      open: false,
    }
  },
  created() {
    eventBus.$on('openMenu', () => {
      this.open = true;
    });
  },
  methods: {
    ...mapActions({
      updateDeviceInformation: 'user/updateDeviceInformation',
    }),
    close() {
      this.open = false;
    },
    onClick() {
      this.open = true;
      this.updateDeviceInformation();
    }
  }
}
</script>

<style scoped>
/* mobile */
.app-menu {
  position: absolute;
  z-index: 7;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
}
.app-menu__button-container {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}
.app-menu__button {
  position: absolute;
  top: 6px;
  left: 16px;
}
.app-menu__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.7);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.35s, opacity 0.35s ease-out;
  cursor: pointer;
}
.app-menu__overlay--open {
  visibility: visible;
  opacity: 1;
  transition-delay:0s;
}

/* desktop */
@media (min-width: 1025px) {
  .app-menu__button {
    top: 27px;
    left: 38px;
  }
  .app-menu__logo {
    top: 20px;
    font-size: 28px;
    margin-left: 38px;
  }
}
</style>
