<template>
  <div class="l-vertical__scroll l-vertical">
    <v-container class="container--narrow">

      <v-form
        ref="form"
        v-model="item.tab3Valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >
        
        <form-fields-3
          :item="item"
        />

      </v-form>
    </v-container>

    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn  
              outlined
              block
              @click="$emit('back')"
            >{{ $t('actions.back') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :disabled="!item.tab3Valid"
              :loading="saving"
              @click="submit"
            >{{ $t('actions.save') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    
  </div>
</template>

<script>
import FormFields3 from '../../components/FormFields/FormFields3.vue';

export default { 
  components: {
    FormFields3,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    saving: {
      type: Boolean,
      default: false
    }
  }, 
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.$emit('next');
    }
  }
}
</script>