<template>
  <div 
    class="trash-item"
  >
    <div class="trash-item__thumb">
      <img 
        v-lazy="imageUrl"
        class="trash-item__img"
        alt=""
      >
    </div>
    <div class="trash-item__details">
      <div class="body-2 height-lines-1">{{ item.title }}</div>
      <div class="overline small">{{ $moment(item.date).format($t('locale.formatDate')) }}</div>
    </div>
    <v-checkbox
      v-model="item.selected" 
      class="trash-item__checkbox"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageUrl() {
      return (this.item && this.item.urlImageThumbnails) 
        ? this.item.urlImageThumbnails.small_ + '?bearer=' + this.apiToken 
        : 'img/placeholder/episode-list.png';
    }
  },
};
</script>

<style scoped>
.trash-item__img {
  width: 76px;
  height: 34px;
  object-fit: cover;
  object-position: center;
}
</style>