import axios from 'axios';

export default {
  namespaced: true,
  state: {
    selectedFiles: null,
    uploadData: null,
    uploadFiles: null
  },
  getters: {
    getSelectedFiles: state => {
      return state.selectedFiles;
    },
    getUploadData: state => {
      return state.uploadData;
    },
    getUploadFiles: state => {
      return state.uploadFiles;
    }
  },
  mutations: {
    setSelectedFiles: (state, files) => {
      state.selectedFiles = files;
    },
    setUploadData: (state, uploadData) => {
      state.uploadData = uploadData;
    },
    setUploadFiles: (state, uploadFiles) => {
      state.uploadFiles = uploadFiles;
    }
  },
  actions: {
    uploadFile(context, { activityId, data }) {
      let formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }

      return axios.post('/api/activity/' + activityId + '/file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    },
    updateFile(context, { activityId, fileId, data }) {
      let formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }

      return axios.post('/api/activity/' + activityId + '/file/' + fileId, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    },
    updateFiles(context, { filesIds, data }) {
      return axios.post('api/multiple-files', { files: filesIds, data });
    },
    deleteFile(context, { activityId, fileId}) {
      return axios.delete('/api/activity/' + activityId + '/file/' + fileId);
    },
    deleteFiles(context, ids) {
      return axios.delete('/api/multiple-files', { data: { files: ids } });
    },
    getFile(context, {activityId, fileId}) {
      return axios.get('/api/activity/' + activityId + '/file/' + fileId);
    },
    downloadFiles(context, { filesIds, onDownloadProgress }) {
      let data = { files: filesIds };
      let config = { onDownloadProgress };
      return axios({
        url: '/api/download-files',
        method: 'POST',
        data: data,
        config: config,        
        responseType: 'blob', // important
      })
    },
    downloadFile(context, url) {
      return axios({ url: url, method: 'get', baseURL: null });
    },
    assignEpisodeImage(context, { episodeId, fileId }) {
      return axios.post('api/episode/' + episodeId + '/assign-image/' + fileId);
    },
    assignActivityImage(context, { episodeId, activityId, fileId }) {
      return axios.post('api/episode/' + episodeId + '/activity/' + activityId + '/assign-image/' + fileId);
    },
    getFavoriteFiles() {
      return axios.get('/api/favorite-activity-files');
    },
    setFavoriteFile(context, { activityId, fileId }) {
      return axios.post('/api/activity/' + activityId + '/file/' + fileId + '/favorite');
    },
  }
};
