<template>
  <div class="l-vertical__grow l-vertical">
    <page-actions>
      <sort-menu 
        class="mr-1"
        @change="sortBy = $event" 
      />
      <help-menu>{{ $t('shared.texts.help.episodeDetails') }}</help-menu>
    </page-actions>

    <episode-tabs
      :episode="episode"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <episode-header 
        :episode="episode" 
      />

      <v-container 
        :class="{ 'px-3': $vuetify.breakpoint.smAndDown }"
        class="container--narrow"
      >

        <loading v-if="!episode" />

        <collapse-text
          v-if="episode && episode.description"
          center-button
        >
          {{ episode.description }}
        </collapse-text>

      </v-container>

      <v-container 
        v-if="episode"
        :class="{ 'px-3': $vuetify.breakpoint.smAndDown }"
      >

        <loading v-if="loadingActivities" />

        <v-row 
          no-gutters
          class="activities-list"
        >
          <v-col
            v-for="activity in activitiesSorted"
            :key="activity.id"
            cols="6"
            sm="4"
            md="3"
            class="pr-3"
          >
            <activity-list-item
              :item="activity"
              show-date
              class="activities-list__item"
              shared
              :to="{ 
                name: 'shared-activity-details', 
                params: { 
                  episodeId: $route.params.episodeId, 
                  activityId: activity.id
                },
                query: { hash: $route.query.hash } 
              }"
            />
          </v-col>
        </v-row>

      </v-container>
    
    </div>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ActivityListItem from '@/modules/activities/components/ActivityListItem/ActivityListItem.vue';
import CollapseText from '@/components/util/CollapseText';
import EpisodeHeader from './EpisodeHeader';
import EpisodeTabs from '@/modules/shared/components/EpisodeTabs';
import HelpMenu from '@/modules/shared/components/HelpMenu.vue';
import Loading from '@/components/util/Loading';
import PageActions from '@/modules/shared/components/PageActions.vue';
import statusTap from '@/mixins/statusTap.js';
import SortMenu from './SortMenu';

export default {
  components: {
    ActivityListItem,
    CollapseText,
    EpisodeHeader,
    EpisodeTabs,
    HelpMenu,
    Loading,
    PageActions,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      activities: null,
      loading: false,
      loadingActivities: false,
      sortBy: 'recent'
    }
  },
  computed: {
    ...mapGetters({
      episode: 'shared/cachedEpisode',
    }),
    activitiesSorted() {
      if (!this.activities) {
        return [];
      }
      let activities = [...this.activities];
      switch (this.sortBy) {
        case 'recent':
          activities.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'date':
          activities.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? -1 : 1);       
          break;       
        case 'name':
          activities.sort((a, b) => (a.name || '').localeCompare(b.name || ''));        
          break;
      }
      return activities;
    },
  },
  created() {
    // load episode
    this.loading = true;
    this.loadEpisodeToCache({
      episodeId: this.$route.params.episodeId,
      hash: this.$route.query.hash
    })
    .finally(() => {
      this.loading = false;
    });

    // load activities
    this.loadingActivities = true;
    this.getActivities({
      episodeId: this.$route.params.episodeId,
      hash: this.$route.query.hash
    })
    .then(response => {
      this.activities = response.data.activities;
      this.loadingActivities = false;
    });
  },
  methods: {
    ...mapActions({
      loadEpisodeToCache: 'shared/loadEpisodeToCache',
      getActivities: 'shared/getActivities',
    })
  }
}
</script>

<style scoped>
.activities-list {
  margin-right: -12px;
}
.activities-list__item {
  margin: 0 0 12px 0;
}
</style>
