<template>
  <div class="welcome-message l-vertical__grow l-vertical">

    <v-carousel
      ref="carousel"
      light
      :show-arrows="false"
      :continuous="false"
      hide-delimiter-background
      class="l-vertical__grow l-vertical"
      height="100%"
    >

      <v-carousel-item class="l-vertical__grow l-vertical">
        <step-1 />
      </v-carousel-item>
      
      <v-carousel-item class="l-vertical__grow l-vertical">
        <step-2 />
      </v-carousel-item>
      
      <v-carousel-item class="l-vertical__grow l-vertical">
        <step-3 />
      </v-carousel-item>
      
      <v-carousel-item class="l-vertical__grow l-vertical">
        <step-4 />
      </v-carousel-item>
      
      <v-carousel-item class="l-vertical__grow l-vertical">
        <step-5 @close="$emit('close')" />
      </v-carousel-item>

    </v-carousel>

  </div>
</template>

<script>
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';
import Step5 from './Step5.vue';

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
  },  
  mounted() {
    window.addEventListener('keyup', this.onKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyPress);
  },
  methods: {
    onKeyPress({ key }) {
      switch (key) {
        case 'ArrowRight':
          this.$refs.carousel.next();
          break;
        case 'ArrowLeft':
          this.$refs.carousel.prev();
          break;
      }
    }
  }
}
</script>

<style scoped>
.welcome-message /deep/ .v-carousel__controls__item .v-icon {
  border: 2px solid var(--v-accent-base);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  opacity: 1;
}
.welcome-message /deep/ .v-carousel__controls__item.v-item--active .v-icon {
  background-color: var(--v-accent-base);
}
.welcome-message /deep/ .v-carousel__controls__item .v-icon::before {
  display: none;
}
.welcome-message /deep/ .v-carousel__controls__item .v-icon::after {
  display: none;
}
</style>