<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list' }">
      {{ $t('episodes.titles.createEpisode') }}
    </page-title>

    <page-tabs>
      <v-tab>
        {{ $t('episodes.titles.episode') }}
      </v-tab> 
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">
      <v-container class="container--narrow mb-3">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >
          <form-fields
            :item="episode"
          />

          <gps-field
            :value="useGps"
            @change="useGps = $event"
          />

        </v-form>
      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'episodes-list'}"
                exact
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid || loadingCountries || loadingProvinces"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.add') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      
    </div>  

    <geocode-service
      ref="geocodeService"
      @geocoded="onGeocoded"
    />

    <reverse-geocode-service
      ref="reverseGeocodeService"
      @reverseGeocoded="onReverseGeocoded" 
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy';
import FormFields from '@/modules/episodes/components/FormFields.vue';
import GeocodeService from '@/modules/activities/components/GeocodeService.vue';
import GpsField from './GpsField.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ReverseGeocodeService from '@/modules/activities/components/ReverseGeocodeService.vue';

export default {
  components: {
    FormFields,
    GeocodeService,
    GpsField,
    Loading,
    PageTabs,
    PageTitle,
    ReverseGeocodeService
  },
  data() {
    return {
      activityGroupId: null,
      activityTypeId: null,
      address: null,
      city: null,
      countries: null,
      country: null,
      episode: { 
        date: this.$moment().format('YYYY-MM-DD')
      },
      latitude: null,
      longitude: null,
      loadingCountries: false,
      loadingProvinces: false,
      provinceId: null,
      provinces: null,
      saving: false,
      useGps: true,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    })
  },
  created() {
    // get default activity group and type
    // load groups
    this.getActivityGroups()
    .then(response => {
      // find default group
      let group = response.data.find(item => item.slug == 'none');
      this.activityGroupId = group.id;

      // load types
      this.getActivityTypes()
      .then(response => {
        let types = response.data.find(item => item.id == this.activityGroupId);
        let type = types.activityTypes[0];
        this.activityTypeId = type.id;
      });
    });


    // load countries
    this.loadingCountries = true;
    this.getCountries()
    .then(response => {
      this.countries = response.data;
      this.loadingCountries = false;
    });

    // load provinces
    if (this.user.idCountry) {
      this.loadingProvinces = true;
      this.getProvinces(this.user.idCountry)
      .then(response => {
        this.provinces = response.data;
        this.loadingProvinces = false;    
      });
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      assignActivityImage: 'files/assignActivityImage',
      assignEpisodeImage: 'files/assignEpisodeImage',
      createActivity: 'activities/createActivity',
      createEpisode: 'episodes/createEpisode',
      getActivityGroups: 'activities/getActivityGroups',
      getActivityTypes: 'activities/getActivityTypes',
      getCountries: 'user/getCountries',
      getProvinces: 'user/getProvinces',
      uploadFile: 'files/uploadFile'
    }),
    getEpisode() {
      return this.espisode;
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      // if use GPS: get coordinates and convert them to address
      if (this.useGps) {
        this.$refs.reverseGeocodeService.start();
      }
      // if no GPS: use default values and convert them to coordinates
      else {
        this.country = this.countries.find(item => item.id == this.user.idCountry);
        this.provinceId = this.user.idProvince;
        let province = this.provinces.find(item => item.id == this.provinceId);
        this.city = this.user.city;

        this.$refs.geocodeService.geocode(
          null, 
          this.city ? this.city : null, 
          province ? province.name : null,
          this.country ? this.country.name : null
        );
      }
    },
    onGeocoded({ latitude, longitude }) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.save();
    },
    onReverseGeocoded(result) {
      console.log('onReverseGeocoded');

      this.latitude = result.latitude;
      this.longitude = result.longitude;
      this.address = result.address;
      this.city = result.city;

      // find country by name
      this.country = this.countries.find(item => item.name == result.country);

      this.save();
    },
    async save() {
      try {
        // create episode
        console.log('create episode');
        const episodeData = {
          title: this.episode.title,
          date: this.episode.date,
          description: this.episode.description,
        }

        let response = await this.createEpisode(episodeData);
        const episodeId = response.data.episode.id;
          
 
        // create activity
        console.log('create activity');
        const activityData = {
          name: this.episode.title,
          activityGroup: this.activityGroupId,
          activityType: this.activityTypeId,
          date: this.episode.date,
          time: this.$moment().format('kk:mm'),
          cost: 0,
          currency: this.user.currency,
          description: this.episode.description,
          country: this.country ? this.country.id : null,
          province: this.provinceId ? this.provinceId : null,
          city: this.city ? this.city : null,
          establishment: '',
          address: this.address ? this.address : '',
          privacy: ActivityPrivacy.VISIBLE,
          latitude: this.latitude,
          longitude: this.longitude
        }

        response = await this.createActivity({ 
          episodeId: episodeId,
          data: activityData
        });
        const activity = response.data.activity;

        // upload episode image to gallery
        if (this.episode.imageFile) {
          console.log('upload image');
          response = await this.uploadFile({
            activityId: activity.id,
            data: {
              privacy: ActivityPrivacy.VISIBLE,
              date: this.episode.date,
              time: this.$helperFunctions.fixApiTime(activity.time),
              description: this.episode.title,
              activity: activity.id,
              filenameFile: this.episode.imageFile,
              type: 'photo'
            }
          });
          const fileId = response.data.activity_file.id;

          console.log('assign episode/activity image');
          await Promise.all([
            // set episode image
            this.assignEpisodeImage({
              episodeId: episodeId,
              fileId: fileId
            }),
            // set activity image
            this.assignActivityImage({
              episodeId: episodeId,
              activityId: activity.id,
              fileId: fileId
            })
          ]);
        }

        this.addFlash({
          text: this.$t("episodes.messages.success.episodeCreated"),
          type: 'success'
        });
        console.log('finished! episode created!');
        
        // redirect to activities list 
        this.$router.push({ name: 'episode-details', params: { episodeId: episodeId } });
      }
      catch(error) {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      };

      this.saving = false;
    },
  }
}
</script>