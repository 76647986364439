<template>
  <div class="collapse-text">
    <div  
      ref="slotWrapper"
      class="collapse-text__text"
      :class="{
        'collapse-text__text--show-button': showButton,
        'collapse-text__text--open': open,
        'collapse-text__text--collapse-all': showButton && !open && collapseAll
      }"
    >
      <slot></slot>
    </div>
    <div 
      v-if="showButton"
      :class="{ 
        'text-center': centerButton,
        'text-right': !centerButton,
        'mb-n7': overflowButton
      }"
    >
      <v-btn 
        x-small 
        text
        :class="buttonClass"
        @click="open = !open"
      >{{ $t(open ? 'actions.close' : 'actions.keepReading') }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    forceCollapse: {
      type: Boolean,
      default: false
    },
    centerButton: {
      type: Boolean,
      default: false
    },
    overflowButton: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: ''
    },
    collapseAll: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      html: '',
      open: false
    }
  },
  computed: {
    // number of characters in one line of text (calculated for font-size: 16px)
    charactersInLine() {
      return this.html
        ? Math.round(167 / 1200 * this.$el.offsetWidth) - 4
        : null;
    },
    charCount() {
      return this.$helperFunctions.stripTags(this.html).length;
    },
    showButton() {
      return this.forceCollapse || (this.charCount > (2 * this.charactersInLine));
    }
  },
  mounted() {
    this.html = this.$refs.slotWrapper.innerHTML;
  }
}
</script>

<style scoped>
.collapse-text__text {
  white-space: pre-wrap;
}
.collapse-text__text--show-button {
  max-height: 48px;
  transition: all .3s ease-out;
  overflow: hidden;
}
.collapse-text__text--open {
  max-height: 90vh;
}
.collapse-text__text--collapse-all {
  max-height: 0;
}
</style>