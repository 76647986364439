<template>
  <div>
    <v-list-item 
      class="success--text"
      @click="onButtonClick"
    >
      <v-icon class="mr-3">mdi-check</v-icon>
      {{ $t('transfers.actions.accept') }}
    </v-list-item>

    <v-dialog
      v-model="open"
      width="450"
    >
      <v-container class="py-3 px-6">
        <div class="subtitle-1 text-center mt-2 mb-6">{{ $t('transfers.dialogs.accept.title') }}</div>
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="primary"
              :loading="saving"
              block
              small
              @click="submit"
            >{{ $t('transfers.actions.accept') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SpaceLimit from '@/modules/user/model/SpaceLimit';

export default {
  props: {
    transfer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      open: false,
      saving: false,
      statistics: {},
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    freeSpace() {
      let totalSpace = SpaceLimit * 1000 * 1000 * 1000; // convert from GB to B
      return totalSpace 
        - this.statistics.space_pictures 
        - this.statistics.space_videos
        - this.statistics.space_soft_deleted;
    },
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      acceptTransfer: 'transfers/acceptTransfer',
      getStatistics: 'user/getStatistics',
    }),
    submit() {
      this.saving = true;
      
      // load available space
      this.getStatistics(this.user.id)
      .then(response => {
        this.statistics = response.data.statistics;
        
        // verify if user has enough space to accept the files
        if (this.freeSpace < this.transfer.totalSizeInBytes) {
          this.addFlash({
            text: this.$t('files.messages.error.upload.notEnoughSpace'),
            type: 'error'
          });
          this.saving = false;
          return;
        }

        this.acceptTransfer(this.transfer.id)
        .then(() => {
          this.addFlash({
            text: this.$t("transfers.messages.success.accepted"),
            type: 'success'
          });
          this.$emit('saved');
        })
        .catch(error => {
          this.addFlash({
            text: this.$helperFunctions.errorToString(error),
            type: 'error'
          });
        })
        .finally(() => {
          this.saving = false;
          this.open = false;
        });

      });
    },
    onButtonClick() {
      setTimeout(() => {
        this.open = true;
      }, 0);
    }
  }
};
</script>
