<template>
  <div>
    <v-btn
      icon
      :small="!big"
      :x-large="big"
      :loading="loading"
      :color="big ? '' : 'primary'"
      @click="toggle"
    >
      <v-icon
        :title="$t('notes.actions.play')"
        :class="big ? '' : 'primary--text'"
        :size="big ? 50 : 28"
      >{{ isPlaying ? 'mdi-stop' : 'mdi-play' }}</v-icon>
    </v-btn>
    <audio 
      ref="player"
      :src="src + (isBlob ? '' : ('?bearer=' + apiToken))"
      preload="none"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { eventBus } from '@/eventBus.js';

export default {
  props: {
    src: {
      type: String,
      default: null
    },
    big: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPlaying: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    isBlob() {
      return this.src ? this.src.substr(0, 4) == 'blob' : false;
    }
  },
  created() {
    // Listen to event
    eventBus.$on('audioPlayerPlay', uid => {
      if (uid != this._uid) {
        this.pause();
      }
    });
  },
  mounted() {
    this.$refs.player.addEventListener("playing", this.onPlaying);
  },
  methods: {
    toggle() {
      if (!this.isPlaying) {
        this.loading = true;
        this.$refs.player.play();
        this.isPlaying = true;
        // Emit event
        eventBus.$emit('audioPlayerPlay', this._uid);
      }
      else {
        this.pause();
      }
    },
    pause() {
      if (this.$refs.player && this.$refs.player.pause) {
        this.$refs.player.pause();
      }
      this.isPlaying = false;
    },
    onPlaying() {
      this.loading = false;
    }
  }
}
</script>