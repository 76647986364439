<template>
  <div>
    <v-btn
      block
      class="error"
      @click="open = true"
    >{{ $t('user.actions.deleteAccount') }}</v-btn>


    <v-dialog 
      v-model="open" 
      max-width="450"
    >  
      <template v-if="!success">   

        <v-container class="px-6">
          <div class="subtitle-1  mt-1 mb-3">{{ $t('user.titles.enterPassword') }}</div>

          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
            @keyup.native.enter="submit"
          >
            <v-text-field
              v-model="password"
              :label="$t('user.form.label.password')"
              :rules="[$validationRules.required]"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              class="mb-1 v-input--darker"
              autocomplete="on"
              outlined
              dense
              :error="passwordIncorrect"
              :error-messages="passwordIncorrect ? [$t('user.messages.error.incorrectPassword')] : []"
              @click:append="showPassword = !showPassword"
              @input="passwordIncorrect = false"
            />
          </v-form> 
        </v-container>

        <hr>

        <v-container>
          <v-row>
            <v-col cols="6">
              <v-btn
                outlined
                block
                small
                @click="open = false"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                color="error"
                :loading="sending"
                block
                small
                @click="submit"
              >{{ $t('actions.continue') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>

      </template>

      <template v-else>

        <v-container class="px-6 py-8">
          <div class="body-2 error--text">{{ $t('user.dialogs.deleteAccount.linkSent') }}</div>
        </v-container>

        <hr>

        <v-container class="px-6">
          <v-row>
            <v-col 
              cols="6" 
              offset="3"
            >
              <v-btn
                outlined
                block
                small
                @click="open = false"
              >{{ $t('actions.close') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>

      </template>

      
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      open: false,
      password: null,
      passwordIncorrect: false,
      sending: false,
      showPassword: false,
      success: false,
      valid: false
    };
  },
  watch: {
    open() {
      this.success = false;
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      sendDeleteLink: 'user/sendDeleteLink'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.sending = true;
      this.passwordIncorrect = false;

      this.sendDeleteLink(this.password)
      .then(response => {
        this.success = true;
      })
      .catch(error => {
        if (error.response.data && error.response.data.exception == 'Invalid password') {
          this.passwordIncorrect = true;
        }
        else {
          this.addFlash({
            text: this.$helperFunctions.errorToString(error),
            type: 'error'
          });
        }
      })
      .finally(() => {
        this.sending = false;
      });
    }
  }
};
</script>
