<template>
  <div class="l-vertical__scroll l-vertical"> 
    <v-container class="container--narrow">
      <div 
        class="mt-2 mb-6"
        v-html="$t('user.texts.deleteAccountDescription')"
      ></div>
    </v-container>

    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <delete-dialog />
      </v-container>
    </div>
  </div>
</template>

<script>
import DeleteDialog from './DeleteDialog';

export default { 
  components: {
    DeleteDialog
  },
}
</script>