<template>
  <div>
    <v-container class="py-3 px-6">
      <div class="subtitle-1 text-center mt-2 mb-6 error--text">
        {{ $t('activities.dialogs.delete.activity') }}
      </div>
    </v-container>

    <hr>


    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="$emit('close')"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            color="error"
            :loading="saving"
            block
            small
            @click="submit"
          >{{ $t('actions.delete') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      open: false,
      saving: false
    };
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      deleteActivity: 'activities/deleteActivity'
    }),
    submit() {
      this.saving = true;

      this.deleteActivity({
      episodeId: this.$route.params.episodeId,
      activityId: this.$route.params.activityId
    })
      .then(() => {
        this.addFlash({
          text: this.$t("activities.messages.success.deleted"),
          type: 'success'
        });
        this.$router.push({ name: 'episode-details', params: { 
          episodeId: this.$route.params.episodeId
        } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>
