<template>
  <page-tabs v-model="selectedTab">
    <v-tab 
      @click="$emit('change', 1)"
    >{{ $t('user.titles.profileTab1') }}</v-tab>
    <v-tab 
      :disabled="!userData.step1Valid"
      @click="$emit('change', 2)"
    >{{ $t('user.titles.profileTab2') }}</v-tab> 
  </page-tabs>
</template>

<script>
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';

export default {
  components: {
    PageTabs
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    userData: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      selectedTab: 0
    }
  },
  watch: {
    step: function(value) {
      this.selectedTab = value - 1;
    }
  }
}
</script>