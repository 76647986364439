import axios from 'axios';

export default {
  namespaced: true,
  state: {
    nested: null, 
    folders: null,
    rootName: null,
    alerts: [],
  },
  getters: {
    nested(state) {
      return state.nested;
    },
    folders(state) {
      return state.folders;
    },
    rootName(state) {
      return state.rootName;
    },
    alerts(state) {
      return state.alerts;
    }
  },
  mutations: {
    setNested(state, nested) {
      state.nested = nested;
    },
    setFolders(state, folders) {
      state.folders = folders;
    },
    setRootName(state, rootName) {
      state.rootName = rootName;
    },
    addAlert(state, { text, type }) {
      state.alerts.push({ text, type });
    },
    clearAlerts(state) {
      state.alerts = [];
    }
  },
  actions: {
    getAveragePhotoSize() {
      return axios.get('/api/total-space/avg');
    },
  },
}
