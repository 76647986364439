<template>
  <v-list-item
    v-if="file.type == FileTypes.PHOTO"
    @click.native.stop.prevent
    @click="submit"
  >
    <div 
      v-if="saving"
      class="text-center flex-grow-1"
    >
      <v-progress-circular
        indeterminate
        :size="24"
        :width="3"
      />
    </div>
    <span 
      v-else
      style="white-space: nowrap"
    >
      <v-icon class="mr-3 primary--text">mdi-folder-multiple-plus-outline</v-icon>  
      {{ $t('files.actions.assignAsActivityImage') }}
    </span>
  </v-list-item> 
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FileTypes from '@/modules/files/model/FileTypes.js';

export default {
  props: {
    file: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      FileTypes: FileTypes,
      saving: false
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      assignActivityImage: 'files/assignActivityImage',
    }),
    submit() {
      this.saving = true;

      this.assignActivityImage({
        episodeId: this.$route.params.episodeId,
        activityId: this.$route.params.activityId,
        fileId: this.file.id
      })
      .then(() => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'          
        });

        this.$emit('saved');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
    }
  }
}
</script>