<template>
  <v-expansion-panel 
    class="expansion-panel"
    :class="{ 'expansion-panel--selected': filters.country || filters.city || filters.establishment || filters.address }"
  >
    <v-expansion-panel-header class="body-2 ">
      {{ $t('search.titles.filtersGroup4') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content eager>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="py-0"
        >
          <country-field 
            :filters="filters" 
            class="mb-3"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="py-0"
        >
          <province-field 
            :filters="filters"  
            class="mb-3"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="py-0"
        >
          <v-text-field
            v-model="filters.city"
            :label="$t('user.form.label.city')"
            hide-details
            class="mb-3"
            clearable
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="py-0"
        >
          <v-text-field
            v-model="filters.establishment"
            :label="$t('search.form.establishment')"
            hide-details
            class="mb-3"
            clearable
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="py-0"
        >
          <v-text-field
            v-model="filters.address"
            :label="$t('activities.form.label.address')"
            hide-details
            clearable
            outlined
            dense
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CountryField from './formFields/CountryField.vue';
import ProvinceField from './formFields/ProvinceField.vue';

export default {
  components: {
    CountryField,
    ProvinceField
  },
  props: {
    filters: {
      type: Object,
      default: null
    }
  }
}
</script>