<template>
  <v-container
    fluid 
    class="episodes-list"
  >
    <v-row no-gutters>
      <v-col
        v-for="item in items"
        :key="item.id"
        :item="item"
        cols="12"
        sm="6"
        md="4"
      >
        <episode-item 
          :item="item"
          :favorite-button="favoriteButton"
          @modified="$emit('modified')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EpisodeItem from './EpisodeItem';

export default {
  components: {
    EpisodeItem
  },
  props: {
    items: {
      type: Array,
      default: null
    },
    favoriteButton: {
      type: Boolean,
      default: null
    }
  }
}
</script>

<style scoped>
.episodes-list {
  padding: 0 0 1px 1px;
}
</style>