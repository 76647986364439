<template>
  <div class="page-tabs">
    <div class="page-tabs__border"></div>

    <v-container 
      class="py-0"
      :class="{ 'px-0' : $vuetify.breakpoint.smAndDown }"
    >

      <div 
        ref="tabsWrap"
        class="page-tabs__wrap"
        @scroll="onScroll"
      >
        <div 
          ref="tabs"
          class="page-tabs__tabs"
        >
          <slot></slot>
        </div>
      </div>

    </v-container>
  </div>
</template>

<script>
import dragscroll from "dragscroll/dragscroll.js";
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    keyboardNavigation: {
      type: Boolean,
      default: true
    },
    scrollKey: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      pageTabsScroll: 'pageTabs/pageTabsScroll',
    })
  },
  watch: {
    value() {
      this.setActiveTab();
    }
  },
  mounted() {
    this.setActiveTab();

    // get last scroll position
    if (this.pageTabsScroll.position && this.pageTabsScroll.key && (this.scrollKey == this.pageTabsScroll.key)) {
      this.$refs.tabsWrap.scrollLeft = this.pageTabsScroll.position;
    }
    else {
      this.setPageTabsScroll({
        key: null,
        position: 0 
      });
      // scroll to active tab
      let activeTab = this.$refs.tabsWrap.querySelector('.v-tab--active');
      if (activeTab) {
        activeTab.scrollIntoView({ behavior: 'instant', block: 'nearest' });
      }
    }

    // enable horizontal drag with mouse cursor on desktop
    if (!this.$helperFunctions.isCordova()) {
      let slider = this.$refs.tabsWrap;
      slider.classList.add('dragscroll');
      dragscroll.reset();
    }

    // enable keyboard navigation on desktop
    if (this.keyboardNavigation && !this.$helperFunctions.isCordova()) {
      window.addEventListener('keyup', this.onKeyPress);
    }
  },
  beforeDestroy() {
    if (this.keyboardNavigation && !this.$helperFunctions.isCordova()) {
      window.removeEventListener('keyup', this.onKeyPress);
    }
  },
  methods: {
    ...mapMutations({
      setPageTabsScroll: 'pageTabs/setPageTabsScroll',
    }),
    onKeyPress({ key }) {
      if (!this.keyboardNavigation) {
        return;
      }

      let items = this.$refs.tabsWrap.querySelectorAll('.v-tab');
      let activeTab = this.$refs.tabsWrap.querySelector('.v-tab--active');
      let activeIndex = Array.from(items).findIndex(item => item === activeTab);

      switch (key) {
        case 'ArrowRight':
          let nextItem = items[activeIndex + 1];
          if (nextItem) {
            let url = nextItem.getAttribute('href');
            if (url) {
              this.$router.push(url);
            }
          }
          break;
        case 'ArrowLeft':
          let prevItem = items[activeIndex - 1];
          if (prevItem) {
            let url = prevItem.getAttribute('href');
            if (url) {
              this.$router.push(url);
            }
          }
          break;
      }
    },
    onScroll(event) {
      if (this.scrollKey) {
        this.setPageTabsScroll({
          key: this.scrollKey,
          position: event.currentTarget.scrollLeft 
        });
      }
    },
    setActiveTab() {
      if (this.value === null) {
        return;
      }
      let items = this.$refs.tabsWrap.querySelectorAll('.v-tab');
      items.forEach(item => item.classList.remove('v-tab--active'));
      let activeTab = items[this.value];
      if (activeTab) {
        activeTab.classList.add('v-tab--active');
      }
    }
  }

}
</script>

<style scoped>
.page-tabs {
  position: relative;
  margin-bottom: 2px;
}
.page-tabs__wrap {
  position: relative;
  display: flex;
  overflow-x: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}
.page-tabs__wrap::-webkit-scrollbar {
  display: none;
}
.page-tabs__tabs {
  display: flex;
}
.page-tabs__border {
  position: absolute;
  width: 100%;
  height: 0;
  bottom: -2px;
  border-bottom: 2px solid var(--v-primary-base);
}
/deep/ .v-tab {
  font-size: 18px;
  font-weight: 600;
  border-radius: 6px 6px 0 0 !important;
  overflow: hidden;
  color: var(--color-text--dark) !important;
  background-color: var(--color-grey-76);
  padding-left: 24px;
  padding-right: 24px;
  margin-right: 1px;
  white-space: nowrap;
  height: 48px;
}
/deep/ .v-slide-group__prev--disabled {
  margin-left: -35px;
	transition: all 0.15s ease-out;
}
/deep/ .v-slide-group__next, 
/deep/ .v-slide-group__prev {
  min-width: 35px;
  flex-basis : 35px;
}

/* mobile */
@media (max-width: 1024px) {
  /deep/ .v-tab {
    font-size: 13px;
    padding-left: 16px;
    padding-right: 16px;
    height: 37px;
  }
}
</style>