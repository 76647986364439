<template>
  <v-dialog 
    v-model="isOpen" 
    width="450"
  >
    <v-container class="py-3 px-6">
      <div class="subtitle-1 text-center mt-2 mb-6 error--text">
        {{ $t('files.dialogs.tooManyFiles.text', { max: UploadParameters.MAX_FILES_NUMBER }) }}
      </div>
    </v-container>

    <hr>


    <v-container>
      <v-btn
        outlined
        block
        small
        @click="close"
      >{{ $t('actions.close') }}</v-btn>
    </v-container>

  </v-dialog>
</template>

<script>
import UploadParameters from '@/modules/files/model/UploadParameters.js';

export default {
  data() {
    return {
      isOpen: false,
      UploadParameters: UploadParameters,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit('close');
    }
  }
};
</script>
