<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('dataUpload.titles.dataUpload') }}
    </page-title>

    <template v-if="isDesktop">

      <data-upload-tabs />

      <div 
        ref="statusTap"
        class="l-vertical__scroll"
      >

        <v-container>
        
          <div class="well mb-4">
            <div>{{ $t('dataUpload.titles.chooseFolderStructure') }}:</div>

            <v-radio-group 
              v-model="nestedValue"
            >

              <v-radio
                :label="$t('dataUpload.model.folderStructure.nested.title')"
                :value="true"
              />
              <div
                class="small step-1__radio-description mb-4"
                v-html="$t('dataUpload.model.folderStructure.nested.description')"
              ></div>

              <v-radio
                :label="$t('dataUpload.model.folderStructure.flat.title')"
                :value="false"
              />
              <div
                class="small step-1__radio-description"
                v-html="$t('dataUpload.model.folderStructure.flat.description')"
              ></div>

            </v-radio-group>
          </div>
          
          <v-row>
            <v-col
              cols="3"
            >
              <v-btn 
                class="primary"
                block
                @click="submit"
              >{{ $t('actions.next') }}</v-btn>
            </v-col>
          </v-row>
          
        </v-container>

      </div>

    </template>

    <v-container v-else>
      <div class="well">
        <v-alert type="info">
          <div v-html="$t('dataUpload.messages.info.useDesktop')"></div>
        </v-alert>
      </div>
    </v-container>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import DataUploadTabs from '@/modules/dataUpload/components/DataUploadTabs';
import PageTitle from "@/components/layout/PageTitle.vue";
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    DataUploadTabs,
    PageTitle,
  },
  mixins: [statusTap],
  data() {
    return {
      nestedValue: true,
    }
  },
  computed: {
    ...mapGetters({
      'nested': 'dataUpload/nested',
    }),
    isDesktop() {
      return !this.$helperFunctions.isIos() && !this.$helperFunctions.isAndroid();
    },
  },
  created() {
    if (this.nested !== null) {
      this.nestedValue = this.nested;
    }
    this.clearAlerts();
  },
  methods: {
    ...mapMutations({
      clearAlerts: 'dataUpload/clearAlerts',
      setNested: 'dataUpload/setNested',
    }),
    submit() {
      this.setNested(this.nestedValue);
      this.$router.push({ name: 'data-upload-step-2' });
    }
  }
}
</script>

<style scoped>
.step-1__radio-description {
  padding-left: 32px;
}
</style>
