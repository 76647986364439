import ChatPage from '../pages/ChatPage/ChatPage.vue';

export default [
  {
    path: '/episode/:episodeId/chat',
    name: 'chat',
    component: ChatPage,
    meta: { requiresAuth: true }
  }
]
