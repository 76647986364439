<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :title="$t('actions.filter')"
        :class="{
          'ml-1': $vuetify.breakpoint.mdAndUp
        }"
        v-on="on"
      >
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">


      <!-- filter by lang ------------------------------- -->


      <v-list-item 
        dense
        @click.stop="selectAllLangs"
      >
        <v-icon class="mr-2 primary--text">
          {{ langsAll ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
        </v-icon> 
        {{ $t('texts.filter.all') }}
      </v-list-item>

      <v-list-item  
        v-for="(value, lang) in langs"
        :key="lang"
        dense
        @click.stop="onLangClick(lang)"
      >
        <v-icon class="ml-6 mr-2 primary--text">
          {{ langs[lang] ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
        </v-icon> 
        {{ $t('languages.' + lang) }}
      </v-list-item>
        

      <!-- filter by platform ------------------------------- -->

      <v-list-item 
        dense
        @click.stop="selectAllPlatforms"
      >
        <v-icon class="mr-2 primary--text">
          {{ platformsAll ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
        </v-icon> 
        {{ $t('texts.filter.all') }}
      </v-list-item>

      <v-list-item  
        v-for="platform in NoticePlatforms"
        :key="platform"
        dense
        @click.stop="onPlatformClick(platform)"
      >
        <v-icon class="ml-6 mr-2 primary--text">
          {{ platforms[platform] ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
        </v-icon> 
        {{ $t('notices.model.platform.' + platform) }}
      </v-list-item>


      <!-- Buttons --------------------------------------- -->


      <v-row class="mx-0 my-2">
        <v-col 
          cols="6" 
          class="pr-2"
        >
          <v-btn
            outlined
            block
            small
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>
        <v-col 
          cols="6" 
          class="pl-2"
        >
          <v-btn 
            block
            small
            color="primary"
            @click="$emit('change', { langs: {...langs} , langsAll, platforms: {...platforms} , platformsAll })"
          >{{ $t('actions.apply') }}</v-btn>
        </v-col>
      </v-row>

    </v-list>
  </v-menu>
</template>

<script>
import NoticePlatforms from '@/modules/notices/model/NoticePlatforms.js';

export default {
  data() {
    return {
      langs: {},
      langsAll: true,
      NoticePlatforms: NoticePlatforms,
      platforms: {},
      platformsAll: true,
    }
  },
  created() {
    // load langs
    for (let lang of this.$i18n.availableLocales) {
      this.$set(this.langs, lang, false);
    }

    // load platforms
    for (let platform of NoticePlatforms) {
      this.$set(this.platforms, platform, false);
    }
  },
  methods: {
    selectAllLangs() {
      this.langsAll = true;
      for (let lang in this.langs) {
        this.langs[lang] = false;
      }
    },
    onLangClick(lang) {
      this.langs[lang] = !this.langs[lang]; 
      this.langsAll = false;
    },
    selectAllPlatforms() {
      this.platformsAll = true;
      for (let platform in this.platforms) {
        this.platforms[platform] = false;
      }
    },
    onPlatformClick(platform) {
      this.platforms[platform] = !this.platforms[platform]; 
      this.platformsAll = false;
    },
  }
}
</script>