var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-tabs',_vm._b({},'page-tabs',_vm.$attrs,false),[_c('v-tab',{attrs:{"to":{ 
      name: 'shared-episode', 
      params: { episodeId: _vm.$route.params.episodeId },
      query: { hash: _vm.$route.query.hash } 
    },"exact":""}},[_vm._v(_vm._s(_vm.$t('actions.back')))]),_c('v-tab',{attrs:{"to":{ 
      name: 'shared-activity-details', 
      params: { episodeId: _vm.$route.params.episodeId, activityId: _vm.$route.params.activityId },
      query: { hash: _vm.$route.query.hash } 
    },"exact":""}},[_vm._v(_vm._s(_vm.$t('activities.titles.nav.description')))]),_c('v-tab',{attrs:{"to":{ 
      name: 'shared-activity-files', 
      params: { episodeId: _vm.$route.params.episodeId, activityId: _vm.$route.params.activityId },
      query: { hash: _vm.$route.query.hash } 
    }}},[_vm._v(_vm._s(_vm.$t('activities.titles.nav.gallery')))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }