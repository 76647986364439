<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ $t('user.titles.statistics') }}
    </page-title>

    <statistics-tabs />
    

    <loading v-if="loading" />

    <div 
      v-else
      class="l-vertical__scroll"
    > 
      <v-container class="container--narrow">

        <v-row class="subtitle-1 mb-1">
          <v-col cols="7">Total</v-col>
          <v-col 
            cols="5"
            class="text-right"
          >{{ $helperFunctions.numberWithDots($helperFunctions.formatFileSize(summary.total, 1)) }}</v-col>
        </v-row>

        <v-row class="small mb-2">
          <v-col cols="7">Promedio por usuario</v-col>
          <v-col 
            cols="5"
            class="text-right"
          >{{ totalUsersCount ? $helperFunctions.numberWithDots($helperFunctions.formatFileSize(summary.total / totalUsersCount, 1)) : '' }}</v-col>
        </v-row>
                
        <v-row>
          <v-col cols="7">- Año en curso</v-col>
          <v-col 
            cols="5"
            class="text-right"
          >{{ $helperFunctions.numberWithDots($helperFunctions.formatFileSize(summary['current-year'], 1)) }}</v-col>
        </v-row>
                
        <v-row>
          <v-col cols="7">- Mes en curso</v-col>
          <v-col 
            cols="5"
            class="text-right"
          >{{ $helperFunctions.numberWithDots($helperFunctions.formatFileSize(summary['current-month'], 1)) }}</v-col>
        </v-row>
                
        <v-row>
          <v-col cols="7">- Día anterior</v-col>
          <v-col 
            cols="5"
            class="text-right"
          >{{ $helperFunctions.numberWithDots($helperFunctions.formatFileSize(summary['yesterday'], 1)) }}</v-col>
        </v-row>

        
        <div class="subtitle-1 mb-2 mt-4">Evolución</div>

        <v-row class="mb-5">
          <v-col cols="3">
            <year-select
              v-model="selectedYear"
            />
          </v-col>
        </v-row>

        <months-graph
          :value="selectedMonth"
          :months-data="monthsData"
          :loading="loadingMonths"
          class="mb-5"
          format-file-size
          @input="selectedMonth = $event"
        />

        <days-graph
          :value="selectedDay"
          :days-data="daysData"
          :loading="loadingDays"
          class="mb-2"
          @input="selectedDay = $event"
        />

        <div 
          v-if="daysData && selectedDay"
          class="text-center"
        >
          <small>En el día:</small> &nbsp;
          {{ $helperFunctions.numberWithDots($helperFunctions.formatFileSize(daysData[selectedDay], 1)) }}
        </div>

      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '@/components/util/Loading';
import DaysGraph from '@/modules/adminStatistics/components/DaysGraph/DaysGraph.vue';
import MonthsGraph from '@/modules/adminStatistics/components/MonthsGraph/MonthsGraph.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import StatisticsTabs from '@/modules/adminStatistics/components/StatisticsTabs.vue';
import YearSelect from '@/modules/adminStatistics/components/YearSelect.vue';

export default {
  components: {
    Loading,
    DaysGraph,
    MonthsGraph,
    PageTitle,
    StatisticsTabs,
    YearSelect
  },
  data () {
    return {
      loading: false,
      loadingMonths: false,
      loadingDays: false,
      daysData: null,
      monthsData: null,
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      summary: {}
    }
  },
  computed: {
    ...mapGetters({
      totalUsersCount: 'adminStatistics/totalUsersCount'
    }),
  },
  watch: {
    selectedYear() {
      this.loadMonthsData();
      // select current month
      if (this.selectedYear == Number(this.$moment().format('YYYY'))) {
        this.selectedMonth = Number(this.$moment().format('MM'));
      }
    },
    selectedMonth() {
      this.loadDaysData();
    },
  },
  created() {
    this.loading = true;
    this.getSpaceSummary()
    .then(response => {
      this.summary = response.data.count;
      this.loading = false;
    });

    // select current year
    this.selectedYear = Number(this.$moment().format('YYYY'));
  },
  methods: {
    ...mapActions({
      getSpaceSummary: 'adminStatistics/getSpaceSummary',
      getSpaceByMonth: 'adminStatistics/getSpaceByMonth',
      getSpaceByDay: 'adminStatistics/getSpaceByDay',
    }),
    loadMonthsData() {
      if (!this.selectedYear) {
        return;
      }
      this.loadingMonths = true;
      this.getSpaceByMonth(this.selectedYear)
      .then(response => {
        this.monthsData = response.data.count;
        this.loadingMonths = false;
      });
      this.selectedMonth = null;
      this.daysData = null;
    },
    loadDaysData() {
      if (!this.selectedYear || !this.selectedMonth) {
        return;
      }
      this.loadingDays = true;
      this.getSpaceByDay({
        year: this.selectedYear,
        month: this.selectedMonth
      })
      .then(response => {
        this.daysData = response.data.count;
        this.loadingDays = false;
      });
      this.selectedDay = null;
    }
  }
}
</script>
