<template>
  <v-snackbar
    v-model="show" 
    v-on-click-outside="{
      exclude: [],
      handler: 'close'
    }"
    top 
    multi-line 
    :timeout="timeoutComputed"
    :color="color"
    class="flash-bag"
    :class="'flash-bag--' + type"
    role="alert"
    @click.native="close"
  >
    <v-icon 
      v-if="icon" 
      class="flash-bag__icon pr-2"
    >{{ icon }}</v-icon>
    <div class="flash-bag__text">{{ text }}</div>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      show: false,
      text: null,
      type: null,
      timeout: null,
    };
  },
  computed: {
    ...mapGetters(['flashBag']),
    color() {
      switch (this.type) {
        case 'success': 
        case 'error': 
        case 'warning': {
          return this.type;
        }
        default: {
          return 'white';
        }
      }
    },
    icon() {
      switch (this.type) {
        case 'success': {
          return 'mdi-check-circle';
        }
        case 'error': {
          return 'mdi-alert-circle';
        }
        case 'warning': {
          return 'mdi-alert';
        }
        case 'info': {
          return 'mdi-information-outline';
        }
        default: {
          return null;
        }
      }
    },
    timeoutComputed() {
      if (this.timeout) {
        return this.timeout;
      }
      switch (this.type) {
        case 'success': {
          return 5000;
        }
      }
      return -1;
    }
  },
  watch: {
    flashBag(value) {
      if (this.flashBag) {
        this.showFlashMessage();
      }
    },
  },
  methods: {
    ...mapMutations(['flushFlashBag']),
    showFlashMessage() {
      if (this.flashBag) {
        this.show = true;
        this.text = this.flashBag.text;
        this.type = this.flashBag.type;
        this.timeout = this.flashBag.timeout;
      }
      setTimeout(() => {
        this.flushFlashBag();
      }, 100);
    },
    close() {
      if (!this.flashBag) {
        this.show = false;
      }
    }
  }
};
</script>

<style scoped>
.flash-bag {
  top: 213px;
  color: var(--v-info-base);
}
.flash-bag /deep/ .v-snack__content {
  display: flex;
}
.flash-bag__text {
  font-weight: 600;
}
.flash-bag--info /deep/ .v-snack__wrapper.theme--dark,
.flash-bag--info /deep/ .theme--dark.v-icon {
  color: inherit !important;
}

/* mobile & tablet */
@media (max-width: 1024px) {
  .flash-bag {
    top: 136px;
  }
}
</style>
