<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'collaborators-list', params: { episodeId: $route.params.episodeId } }">
      {{ $t('collaborators.titles.collaborators') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('collaborators.titles.addCollaborator') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">

      <v-container class="container--narrow pt-5 pb-0">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent
          @keyup.native.enter="onSubmit"
        >
          <user-field
            :item="item"
          />
          <role-field
            :item="item"
          />
        </v-form>
      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn
                outlined
                block
                :to="{ name: 'collaborators-list', params: { episodeId: $route.params.episodeId } }"
                exact
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                class="primary"
                :disabled="!valid"
                :loading="saving"
                block
                @click="submit"
              >{{ $t('actions.add') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import RoleField from '../../components/formFields/RoleField';
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import UserField from './UserField';

export default {
  components: {
    PageTabs,
    PageTitle,
    RoleField,
    UserField
  },
  data() {
    return {
      item: {
        rol: roles.EDITOR
      },
      saving: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode'
    })
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      createCollaborator: 'collaborators/createCollaborator',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        email: this.item.email,
        role: this.item.rol
      }

      this.createCollaborator({ 
        episodeId: this.episode.id,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("collaborators.messages.success.collaboratorCreated", { 
            user: this.item.email,
            role: this.$t('collaborators.model.role.' + this.item.rol),
            episode: this.episode.title
          }),
          type: 'success'
        });
        this.$router.push({ name: 'collaborators-list', params: { 
          episodeId: this.episode.id
        } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>