<template>
  <v-container
    class="py-0"
    :class="{ 'px-0' : $vuetify.breakpoint.smAndDown }"
  >
    <header
      class="episode-header"
      :style="'background-image:url(' + imageBackground + ')'"
    >
      <router-link  
        class="image-overlay"
        :to="{ name: 'episode-files', params: { episodeId: $route.params.episodeId } }"
      />

      <div 
        v-if="episode"
        class="header__top"
      >
        <router-link  
          class="header__title white--text"
          :to="{ name: 'episode-files', params: { episodeId: $route.params.episodeId } }"
        >
          <h2 class="subtitle-1">
            {{ episode ? episode.title : '' }}
          </h2>
        </router-link>
        
        <v-spacer />

        <div class="header__icons">

          <v-icon 
            v-if="episode.hasCost"
            class="pa-2"
          >mdi-cash-multiple</v-icon>

          <v-btn
            icon
            dark
            class="primary--text "
            :title="episode.isFavorite ? $t('actions.removeFavorite') : $t('actions.addFavorite')"
            :loading="savingFavorite"
            @click="onFavoriteClick"
          >
            <v-icon>{{ episode.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
          </v-btn>

          <v-btn
            icon
            dark
            class="primary--text "
            :title="episode.isWish ? $t('episodes.actions.removeWishList') : $t('episodes.actions.addWishList')"
            :loading="savingWishList"
            @click="onWishListClick"
          >
            <v-icon>{{ episode.isWish ? 'mdi-briefcase-clock' : 'mdi-briefcase-clock-outline' }}</v-icon>
          </v-btn>

          <actions-menu 
            :episode="episode"
            @modified="loadEpisodeToCache($route.params.episodeId)" 
          />

        </div>

      </div><!-- /.header__top -->

      <div class="header__bottom">
        <div class="overline small">{{ episode && episode.rol ? $t('collaborators.model.role.' + episode.rol) : '' }}</div>
        <v-spacer />
        <div class="overline small">{{ episode && $moment($helperFunctions.fixApiDate(episode.date)).format($t('locale.formatDate')) }}</div>
      </div><!-- /.header__bottom -->

    </header>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';

export default {
  components: {
    ActionsMenu
  },
  props: {
    episode: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      favorite: null,
      savingFavorite: false,
      savingWishList: false
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageBackground() {
      return this.episode
        ? this.episode.urlImageThumbnails
          ? this.episode.urlImageThumbnails.large_ + '?bearer=' + this.apiToken
          : 'img/placeholder/episode-details.png'
        : null;
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      setFavoriteEpisode: 'episodes/setFavoriteEpisode',
      setWishList: 'episodes/setWishList',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
    }),
    onFavoriteClick() {
      this.savingFavorite = true;
      this.setFavoriteEpisode(this.$route.params.episodeId)
      .then(response => {
        this.favorite = response.data.episode.isFavorite;
        this.addFlash({
          text: this.$t(this.favorite
            ? "episodes.messages.success.favoriteAdded"
            : "episodes.messages.success.favoriteRemoved"),
          type: 'success'
        });
      })
      .finally(() => {
        this.loadEpisodeToCache(this.$route.params.episodeId)
        .then(() => {
          this.savingFavorite = false;
        });
      })
    },
    onWishListClick() {
      this.savingWishList = true;
      this.setWishList(this.$route.params.episodeId)
      .then(response => {
        this.wishList = response.data.episode.isWish;
        this.addFlash({
          text: this.$t(this.wishList
            ? "episodes.messages.success.wishListAdded"
            : "episodes.messages.success.wishListRemoved"),
          type: 'success'
        });
      })
      .finally(() => {
        this.loadEpisodeToCache(this.$route.params.episodeId)
        .then(() => {
          this.savingWishList = false;
        });
      })
    }
  }
}
</script>

<style scoped>
.episode-header {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 273px;
  background-size: cover;
  background-position: center;
  padding: 11px 18px 14px 18px;
}
.episode-header > * {
  position: relative;
  z-index: 1;
  flex: 0 1 auto;
}
.header__title {
  margin-top: 3px;
}
.header__top {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.header__icons {
  display: flex;
  margin-top: -6px;
  margin-right: -10px;
}
.header__bottom {
  display: flex;
  width: 100%;
  align-self: flex-end;
}


/* mobile */
@media (max-width: 1024px) {
  .episode-header {
    height: 154px;
    padding: 11px 16px 14px 16px;
  }
}
</style>
