<template>
  <file-upload
    ref="uploadWidget"
    class="upload-button"
    :class="{ 
      'upload-button--menu-bottom': menuBottom,
      'upload-button--android': $helperFunctions.isAndroid(),
    }"
    :accept="acceptedMimeTypes"
    :multiple="true"
    :size="maxSize"
    :drop="false"
    ripple
    input-id="upload-button-input"
    @input="onFilesSelected"
    @input-filter="inputFilter"
    @click.native.stop="onOpen"
  >
    <slot
      name="button"
      :loading="loading"
    ></slot>
  </file-upload>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FileUpload from 'vue-upload-component';
import UploadParameters from '@/modules/files/model/UploadParameters.js';

export default {
  components: {
    FileUpload
  },
  props: {
    menuBottom: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      acceptedMimeTypes: UploadParameters.ACCEPTED_MIME_TYPES.join(','),
      loading: false,
      maxSize: UploadParameters.MAX_FILE_SIZE * 1024
    };
  },
  computed: {
    ...mapGetters({
      uploadFiles: 'files/getUploadFiles',
    }),
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      setUploadFiles: 'files/setUploadFiles',
    }),
    inputFilter(file, oldFile, prevent) {
      this.loading = true;

      // validate file size
      if (this.$helperFunctions.bytesToMegaBytes(file.size) > UploadParameters.MAX_FILE_SIZE) {
        this.addFlash({
          text: this.$t('files.messages.error.upload.maxFileSizeExceeded', {
            max: UploadParameters.MAX_FILE_SIZE,
            file: file.name
          }),
          type: 'error'
        });
        return prevent();
      }

      // validate max video duration
      if (file.type.indexOf('video') !== -1) {
        var video = document.createElement('video');
        video.preload = 'metadata';
        video.ondurationchange = () => {
          file.duration = Math.round(video.duration);
          if (video.duration > UploadParameters.MAX_VIDEO_DURATION) {
            this.addFlash({
              text: this.$t('files.messages.error.upload.videoTooLong', {
                max: UploadParameters.MAX_VIDEO_DURATION,
                file: file.name
              }),
              type: 'error'
            });
            this.removeFile(file);
          }
        };
        video.src = URL.createObjectURL(file.file);
      }

      file.uploading = false;
      file.uploaded = false;
    },
    onFilesSelected(files) {
      this.loading = true;
      if (!files.length) {
        return;
      }
      this.setUploadFiles(files);
      this.$refs.uploadWidget.clear();
      this.$router.push({ 
        name: 'file-upload', 
        params: { 
          episodeId: this.$route.params.episodeId, 
          activityId: this.$route.params.activityId 
        } 
      });
    },
    removeFile(file) {
      let files = this.uploadFiles;
      let index = files.findIndex(item => item.id == file.id);
      files.splice(index, 1);
      this.setUploadFiles(files);
    },
    onOpen() {
      this.setUploadFiles([]);
      this.loading = true;
      document.body.onfocus = this.onReturn; 
    },
    onReturn() { 
      document.body.onfocus = null; 

      // fix statusBar on iOS after returning from the camera
      this.$statusBarHelper.hide();
      this.$statusBarHelper.show();

      setTimeout(() => {
        if (!this.uploadFiles || !this.uploadFiles.length) {
          this.loading = false;
        }
      }, 500);
    }     
  }

};
</script>

<style scoped>
.upload-button {
  min-width: 100%;
  white-space: nowrap;
  padding: 12px 15px;
  margin: -12px -15px;
  display: flex;
}
.upload-button--menu-bottom {
  padding: 0;
  margin: 0;
  min-width: 0;
}
.upload-button--menu-bottom.upload-button--android {
  margin-left: 0;
  margin-right: 0;
}
.upload-button /deep/ label {
  cursor: pointer;
}
</style>
