<template>
  <v-autocomplete
    v-model="filters.province"
    :label="$t('form.label.province')"
    :items="provinces"
    item-text="name"
    item-value="id"
    return-object
    :loading="loadingProvinces"
    clearable
    outlined
    hide-details
    dense
    :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      provinces: [],
      loadingProvinces: false
    }
  },
  watch: {
    'filters.country': function(country) {
      this.filters.province = null;
      this.provinces = [];
      if (country && country.id) {
        // load provinces
        this.loadingProvinces = true;
        this.getProvinces(country.id)
        .then(response => {
          this.provinces = response.data;
          this.loadingProvinces = false;
        });
      }
    }
  },
  methods: {
    ...mapActions({
      getProvinces: 'user/getProvinces',
    }),
  }
}
</script>