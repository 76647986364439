<template>
  <div v-if="item && episodes">
    
    <v-combobox
      v-model="item.episode"
      :label="$t('activities.form.label.episode') + '*'"
      :items="episodes"
      item-text="title"
      item-value="id"
      :loading="loadingEpisodes"
      :rules="[$validationRules.required]"
      auto-select-first
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
    >
      <template v-slot:item="data">
        <episodes-dropdown-item :item="data.item" />
      </template>
      <template v-slot:selection="data">
        <episodes-dropdown-item 
          :key="data.item.id"
          :item="data.item" 
        />
      </template>
    </v-combobox>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EpisodesDropdownItem from '@/modules/episodes/components/EpisodesDropdownItem';

export default {
  components: {
    EpisodesDropdownItem
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      episodes: null,
      loadingEpisodes: false,
    }
  },
  created() {
    // load episodes
    this.loadingEpisodes = true;
    this.getEpisodes()
    .then(response => {
      this.episodes = response.data.episodes;
      this.loadingEpisodes = false;
      this.item.episode = this.$route.params.episodeId ? this.episodes.find(item => item.id == this.$route.params.episodeId) : this.episodes[0];
    });
  },  
  methods: {
    ...mapActions({
      getEpisodes: 'episodes/getEpisodes'
    }),
  }
}
</script>