<template>
  <div 
    v-if="item"
    class="py-2"
  >
    <v-row>
      <v-col class="flex-grow-0 d-flex align-center">
        <v-icon>mdi-music-note</v-icon>
      </v-col>

      <v-col class="pa-0 d-flex flex-column align-start justify-center">
        <div class="height-lines-1">{{ item.name }}</div>
        <div class="height-lines-1">{{ item.author }}</div>
        {{ item.type }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  }
};
</script>