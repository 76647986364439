<template>
  <v-container
    fluid
    class="file-list__container pa-0"
  >
    <v-row 
      class="file-list__row"
      :class="'file-list__row--' + layout"
    >
      <slot></slot>
    </v-row>

    <no-permission-dialog />
  </v-container>
</template>

<script>
import NoPermissionDialog from './NoPermissionDialog.vue';

export default {
  components: { 
    NoPermissionDialog 
  },
  props: {
    layout: {
      type: String,
      default: 'thumbnail'
    }
  },
}
</script>

<style scoped>
.file-list__container {
  overflow-x: hidden;
}
.file-list__row--thumbnail {
  margin-left: -1px;
  margin-right: -1px;
}
.file-list__row--details {
  margin-left: 0px;
  margin-right: -12px;
}
</style>