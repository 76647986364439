<template>
  <div class="generic-list-item">
    <div class="generic-list-item__main d-flex">
      {{ item.alias ? item.alias : item.email }}
    </div>

    <div class="generic-list-item__buttons">
      <actions-menu 
        v-if="!selectionEnabled"
        @edit="$emit('edit')"
        @delete="$emit('delete')"
        @resend="$emit('resend')"
      />
          
      <v-simple-checkbox
        v-if="selectionEnabled"
        v-model="item.selected" 
        v-ripple
        color="primary"
        class="mb-1"
      />

    </div>

  </div>
</template>

<script>
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';

export default {
  components:{
    ActionsMenu
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    selectionEnabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.list-item {
  border-radius: 5px;
  background-color: var(--color-background--light);
  color: var(--color-text--dark);
}
</style>
