export default {
  MAX_FILE_SIZE: 500, // MB
  MAX_FILES_NUMBER: 75,
  MAX_VIDEO_DURATION: 300, // seconds
  MAX_WIDTH: 2048, // scale images to max width (episode / activity / profile image)
  MAX_HEIGHT: 2048, // scale images to max height (episode / activity / profile image)
  JPEG_QUALITY: 85,
  ACCEPTED_MIME_TYPES: [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/webp',
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/quicktime',
  ],
  MIME_TYPES_EXTENSIONS: {
    'image/jpeg': 'jpg',
    'image/gif': 'gif',
    'image/png': 'png',
    'image/webp': 'webp',
    'video/mp4': 'mp4',
    'video/webm': 'webm',
    'video/ogg': 'ogv',
    'video/quicktime': 'mov',
  },
};