import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    createExpense(context, { episodeId, data }) {
      return axios.post('api/episode/' + episodeId + '/expense', data);
    },
    updateExpense(context, { episodeId, expenseId, data }) {
      return axios.post('api/episode/' + episodeId + '/expense/' + expenseId, data);
    },
    getExpense(context, {episodeId, expenseId}){
      return axios.get('api/episode/' + episodeId + '/expense/' + expenseId);
    },
    getEpisodeExpenses(context, episodeId) {
      return axios.get('/api/episode/' + episodeId + '/expenses');
    },
    deleteExpense(context, {episodeId, expenseId}){
      return axios.delete('api/episode/' + episodeId + '/expense/' + expenseId);
    },
  }
}
