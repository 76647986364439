<template>
  <div class="generic-list-item">
    <div class="generic-list-item__main d-flex">

      <img
        v-lazy="imageUrl"
        class="item__image flex-grow-0 mr-2"
        alt=""
      >

      <div class="flex-grow-1 d-flex flex-column justify-space-between">
        <div>{{ item.title }}</div>
        <div class="overline small">
          {{ $moment(item.date).format($t('locale.formatDate')) }} {{ $helperFunctions.fixApiTime(item.time) }}
        </div>
      </div>
      
    </div>
    <div class="generic-list-item__buttons">
      
      <template v-if="!selectionEnabled">

        <v-btn
          icon
          small
          dark
          color="primary"
          class="mb-1"
          :to="{ name: 'episode-accesses', params: { episodeId: item.id }}"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>

      </template>
      <template v-else>

        <v-simple-checkbox
          v-model="item.selected" 
          v-ripple
          color="primary"
          class="mb-1 mr-n1"
        />

      </template>

    </div>    
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    selectionEnabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageUrl() {
      return (this.item && this.item.urlImageThumbnails) 
        ? this.item.urlImageThumbnails.small_ + '?bearer=' + this.apiToken 
        : 'img/placeholder/episode-list.png';
    }
  },
}
</script>

<style scoped>
.item__image {
  display: block;
  width: 64px;
  height: 64px;
  object-fit: scale-down;
}
.item__image[lazy=loaded] {
  object-fit: cover;
}
</style>
