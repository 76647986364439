<template>
  <div 
    class="remote-item"
    :class="{ 'remote-item--nested': nested }"
  >
    <div 
      class="remote-item__title"
      :class="{ 'remote-item__title--nested': nested }"
    >
      <div class="remote-item__episode">
        <div class="overline small">{{ $t('dataUpload.titles.episode') }}</div>
        {{ item.episodeName }}
      </div>
      <div class="remote-item__activity">
        <div class="overline small">{{ $t('dataUpload.titles.activity') }}</div>
        {{ item.activityName }}
      </div>
    </div>
    <div class="remote-item__size small">
      <template v-if="photosCount">
        {{ photosCount }} {{ $t('dataUpload.titles.photos') }},
      </template>
      <template v-if="videosCount">
        {{ videosCount }} {{ $t('dataUpload.titles.videos') }},
      </template>
      {{ $helperFunctions.formatFileSize(item.estimatedSize) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    photosCount() {
      if (!this.item.files) {
        return 0;
      }
      else {
        return this.item.files.filter(file => file.type === 'photo').length;
      }
    },
    videosCount() {
      if (!this.item.files) {
        return 0;
      }
      else {
        return this.item.files.filter(file => file.type === 'video').length;
      }
    },
  },
}
</script>

<style scoped>
.remote-item {
  width: calc(100% - 64px);
  display: flex;
  align-items: flex-start;
  padding-left: 8px;
}
.remote-item--nested {
  width: calc(50% - 32px);
}
.remote-item__icon {
  margin-top: 4px;
  margin-right: 6px;
}
.remote-item__title {
  width: 100%;
  display: flex;
}
.remote-item__title--nested {
  width: 400px;
}
.remote-item__episode {
  margin-right: 8px;
  width: 140px;
  word-wrap: break-word;
}
.remote-item__activity {
  width: calc(100% - 140px);
  word-wrap: break-word;
}
.remote-item__title .overline {
  color: var(--color-text--darker);
}
.remote-item__size {
  text-align: right;
  white-space: nowrap;
  width: 92px;
}
</style>
