<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ episode ? episode.title : '' }}
      <template slot="buttons">
        <selection-toolbar
          v-if="episode && episode.canEditCollaborators"
          v-show="collaborators && (collaborators.length > 1)"
          ref="itemSelectionToolbar"
          :items="collaboratorsFiltered"
          @success="loadCollaborators"
        />

        <filter-menu 
          v-if="collaborators && (collaborators.length > 1)"
          @change="filters = $event" 
        /> 

        <exit-dialog :episode-id="$route.params.episodeId" />

        <app-fab 
          v-if="episode && episode.canEditCollaborators && (!$refs.itemSelectionToolbar || !$refs.itemSelectionToolbar.selectionEnabled)"
        >
          <template slot="list">

            <router-link 
              class="app-fab__menu-item"
              :to="{ name: 'collaborator-add', params: { episodeId: $route.params.episodeId } }"
            >
              <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
                {{ $t('collaborators.actions.addCollaborator') }}
              </v-btn>
              <v-btn
                fab
                small
                class="primary"
              >
                <v-icon>mdi-account-plus-outline</v-icon>
              </v-btn>
            </router-link>


            <router-link 
              class="app-fab__menu-item"
              :to="{ name: 'add-collaborator-group', params: { episodeId: $route.params.episodeId } }"
            >
              <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
                {{ $t('collaborators.actions.addGroup') }}
              </v-btn>
              <v-btn
                fab
                small
                class="primary"
              >
                <v-icon>mdi-account-multiple-plus-outline</v-icon>
              </v-btn>
            </router-link>

          </template>  
        </app-fab>
      </template>
    </page-title>

    <episode-tabs
      :episode="episode"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <v-container>

        <loading v-if="loading" />

        <template v-if="episode && collaboratorsFiltered.length">

          <div class="overline">{{ $t('collaborators.texts.countProtagonists') }}: {{ editorsCount }}</div>
          <div class="overline mb-4">{{ $t('collaborators.texts.countSpectators') }}: {{ commentatorsCount }}</div>

          <v-row>
            <v-col 
              v-for="item in collaboratorsFiltered"
              :key="item.id"
              cols="12" 
              sm="6"
              md="4"
              class="mb-4"
            >
              <collaborator-list-item
                :item="item"
                :can-edit="episode.canEditCollaborators && (item.id != user.id) && (item.rol != roles.CREATOR)"
                :selection-enabled="$refs.itemSelectionToolbar && $refs.itemSelectionToolbar.selectionEnabled"
              />
            </v-col>
          </v-row>
        </template>
          
        <v-alert 
          v-if="collaborators && !collaboratorsFiltered.length"
          type="info"
        >
          {{ $t('texts.noElementsMatching') }}
        </v-alert>

      </v-container>

    </div>

    <menu-bottom />    
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import CollaboratorListItem from './CollaboratorListItem';
import EpisodeRoles from '@/modules/episodes/model/EpisodeRoles.js';
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs.vue';
import ExitDialog from './ExitDialog';
import FilterMenu from './FilterMenu';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import SelectionToolbar from './SelectionToolbar/SelectionToolbar.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    AppFab,
    CollaboratorListItem,
    EpisodeTabs,
    ExitDialog,
    FilterMenu,
    Loading,
    MenuBottom,
    PageTitle,
    SelectionToolbar
  },
  mixins: [statusTap],
  data() {
    return {
      collaborators: null,
      filters: 'all',
      loading: false,
      roles: roles
    }
  },  
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
      user: 'user/user'
    }),
    collaboratorsFiltered() {
      if (!this.collaborators) {
        return [];
      }
      return this.collaborators.filter(item => {
        // filter by role
        switch (this.filters) {
          case 'editor':
            return (item.rol == EpisodeRoles.CREATOR) || (item.rol == EpisodeRoles.EDITOR);
          case 'commentator':
            return item.rol == EpisodeRoles.COMMENTATOR;
        }

        return true;
      });
    },
    editorsCount() {
      if (!this.collaborators) {
        return null;
      }
      return this.collaborators.filter(item => (item.rol == EpisodeRoles.CREATOR) || (item.rol == EpisodeRoles.EDITOR)).length;
    },
    commentatorsCount() {
      if (!this.collaborators) {
        return null;
      }
      return this.collaborators.filter(item => item.rol == EpisodeRoles.COMMENTATOR).length;
    }
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    this.loadCollaborators();
  },
  methods: {
    ...mapActions({
      getCollaborators: 'collaborators/getCollaborators',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache'
    }),
    loadCollaborators() {
      this.loading = true;
      this.getCollaborators(this.$route.params.episodeId)
      .then(response => {
        // add reactive field "selected"
        for (let i in response.data.collaborators) {
          response.data.collaborators[i].selected = false;
        }
        this.collaborators = response.data.collaborators;
        this.collaborators.sort((a, b) => (a.alias || '').localeCompare(b.alias || ''));
        this.loading = false;
      })
    }
  }
}
</script>