<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <router-link 
        :to="{}" 
        class="link--no-color"
        @click.native="on.click"
      >{{ $t('user.actions.selectLanguage') }}</router-link> 
    </template>
    <v-list class="py-0">
      <v-list-item
        v-for="lang in $i18n.availableLocales"
        :key="lang"
        :class="lang == $i18n.locale ? 'v-list-item--active' : ''"
        color="primary"
        @click="onClick(lang)"
      >
        <v-list-item-title>{{ $t('languages.' + lang) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  methods: {
    onClick(lang) {
      this.$i18n.locale = lang;
      localStorage.lang = lang;
      this.$vuetify.lang.current = lang;
      this.$moment.locale(lang);
    }
  }
}
</script>