var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-tabs',_vm._b({},'page-tabs',_vm.$attrs,false),[_c('v-tab',{attrs:{"to":{ 
      name: 'shared-episode', 
      params: { episodeId: _vm.$route.params.episodeId },
      query: { hash: _vm.$route.query.hash } 
    },"exact":""}},[_vm._v(_vm._s(_vm.$t('shared.titles.episodeNav.episode')))]),_c('v-tab',{attrs:{"to":{ 
      name: 'shared-episode-files', 
      params: { episodeId: _vm.$route.params.episodeId },
      query: { hash: _vm.$route.query.hash } 
    }}},[_vm._v(" "+_vm._s(_vm.$t('episodes.titles.episodeNav.gallery'))+" ")]),_c('v-tab',{attrs:{"to":{ 
      name: 'shared-episode-map', 
      params: { episodeId: _vm.$route.params.episodeId } ,
      query: { hash: _vm.$route.query.hash } 
    }}},[_vm._v(_vm._s(_vm.$t('episodes.titles.episodeNav.map')))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }