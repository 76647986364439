<template>
  <page-tabs v-bind="$attrs">

    <v-tab 
      :to="{ name: 'activity-details', params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId } }"
      exact
    >{{ $t('activities.titles.nav.description') }}</v-tab>    
    
    <v-tab 
      :to="{ name: 'activity-files', params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId } }"
    >{{ $t('activities.titles.nav.gallery') }}</v-tab>

    <v-tab 
      :to="{ name: 'activity-notes', params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId}}"
    >{{ $t('activities.titles.nav.notes') }}</v-tab>

  </page-tabs>
</template>

<script>
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';

export default {
  components: {
    PageTabs
  }
}
</script>
