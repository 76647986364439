<template>
  <loading 
    v-if="loading" 
    height="140"
  />
  <div v-else>
    <div 
      v-if="monthsData"
      class="months-graph"
    >
      <month-item
        v-for="i in 12"
        :key="i"
        :selected="value == i"
        :label="labels[i - 1]"
        :month-data="monthsData[i]"
        :format-file-size="formatFileSize"
        :percents="monthsData[i] / maxData * 100"
        @click.native="$emit('input', i)"
      />
    </div>
  </div>
</template>

<script>
import Loading from '@/components/util/Loading';
import MonthItem from './MonthItem.vue'

export default {
  components: {
    Loading,
    MonthItem
  },
  props: {
    value: {
      type: Number,
      default: null
    }, 
    monthsData: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    formatFileSize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labels: ['E', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
    }
  },
  computed: {
    maxData() {
      if (!this.monthsData) {
        return 1;
      }
      let max = 1;
      for (let i in this.monthsData) {
        let val = Number(this.monthsData[i]);
        if (val > max) {
          max = val;
        }
      }
      return max;
    }
  }
};
</script>

<style scoped>
.months-graph {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 140px;
}
</style>