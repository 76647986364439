<template>
  <div
    v-if="item.count > 0"
    class="generic-list-item"
  >
    <div class="generic-list-item__main">
      <user-item
        :item="item"
        class="mb-2"
        show-name
      />  
      <div class="small">
        {{ $tc('contacts.texts.collaboratesInEpisodes', item.count, { episodesCount: item.count }) }}
      </div>
      
      
    </div>

    <div class="generic-list-item__buttons">
      <v-btn 
        v-if="!selectionEnabled"
        icon
        small
        color="primary"
        :to="{ name: 'contact-details', params: {
          id: item.id
        } }"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>

      <v-simple-checkbox
        v-else
        v-model="item.selected" 
        v-ripple
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    UserItem
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    selectionEnabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>