<template>
  <v-btn 
    v-if="$helperFunctions.isAndroid()"
    icon
    outlined
    :title="$t('actions.openCamera')"
    @click="takePicture"
  >
    <v-icon>mdi-camera-outline</v-icon>
  </v-btn>
</template>

<script>

export default {
  props: {
    value: {
      type: File,
      default: null
    },
    maxWidth: {
      type: Number,
      default: 2048
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    quality: {
      type: Number,
      default: 85
    }
  },
  methods: {
    takePicture() {
      let options = { 
        mediaType: Camera.MediaType.PICTURE,
        targetWidth: this.maxWidth, 
        targetHeight: this.maxHeight, 
        quality: this.quality,
        correctOrientation: true,
        allowEdit: true
      };
      
      MediaPicker.takePhoto(item => {
        this.$emit('input', {});
        
        let newFile = {
          file: null,
          name: item.name,
          type: item.mediaType,
          id: item.name,
          url: item.uri
        };

        // create File object
        MediaPicker.fileToBlob(item.path, result => {
          let blob = new Blob([result], { type: newFile.type });
          newFile.file = new File([blob], item.name, {
            type: newFile.type
          });

          this.$imageFunctions.scale(newFile.file, this.maxWidth, this.maxHeight, this.quality)
          .then(file => {
            newFile.file = file;
            this.$emit('input', newFile);
          });
        }, error => { console.log(error) });
      }, function(error) { console.log(error) }, options);
    }
  }
};
</script>

<style scoped>
</style>
