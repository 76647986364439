import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const languages = ['es', 'en', 'fr', 'it', 'pt'];
const translations = {};

// load translations
for (let lang of languages) {
  translations[lang] = {
    ...require(`@/translations/${lang}.json`),
    
    // modules
    activities: require(`@/translations/activities/${lang}.json`),
    chat: require(`@/translations/chat/${lang}.json`),
    collaborators: require(`@/translations/collaborators/${lang}.json`),
    contacts: require(`@/translations/contacts/${lang}.json`),
    dataPolicy: require(`@/translations/dataPolicy/${lang}.json`),
    dataUpload: require(`@/translations/dataUpload/${lang}.json`),
    episodeAccesses: require(`@/translations/episodeAccesses/${lang}.json`),
    episodes: require(`@/translations/episodes/${lang}.json`),
    expenses: require(`@/translations/expenses/${lang}.json`),
    favorites: require(`@/translations/favorites/${lang}.json`),
    files: require(`@/translations/files/${lang}.json`),
    melodies: require(`@/translations/melodies/${lang}.json`),
    notes: require(`@/translations/notes/${lang}.json`),
    notices: require(`@/translations/notices/${lang}.json`),
    notifications: require(`@/translations/notifications/${lang}.json`),
    procedures: require(`@/translations/procedures/${lang}.json`),
    search: require(`@/translations/search/${lang}.json`),
    shared: require(`@/translations/shared/${lang}.json`),
    subscription: require(`@/translations/subscription/${lang}.json`),
    timeline: require(`@/translations/timeline/${lang}.json`),
    transfers: require(`@/translations/transfers/${lang}.json`),
    trash: require(`@/translations/trash/${lang}.json`),
    user: require(`@/translations/user/${lang}.json`),
    worldmap: require(`@/translations/worldmap/${lang}.json`),

    // HTML files
    dataPolicyLegal: require(`@/translations/dataPolicy/legal/${lang}.html`),
    dataPolicyCookies: require(`@/translations/dataPolicy/cookies/${lang}.html`),
    dataPolicyPolicies: require(`@/translations/dataPolicy/policies/${lang}.html`),
    dataPolicyPrivacy: require(`@/translations/dataPolicy/privacy/${lang}.html`),
    faqCapabilities: require(`@/translations/faq/capabilities/${lang}.html`),
    faqFunctioning: require(`@/translations/faq/functioning/${lang}.html`),
    faqPrivacy: require(`@/translations/faq/privacy/${lang}.html`),
  };
}

const i18n = new VueI18n({
  locale: localStorage.lang ? localStorage.lang : 'es',
  messages: translations
});

export default i18n;
