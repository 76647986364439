<template>
  <div>
    <v-list-item @click="onButtonClick">
      <v-icon class="mr-3 primary--text">mdi-folder-move-outline</v-icon> 
      {{ $t('actions.move') }}
    </v-list-item>

    <v-dialog
      v-model="open"
      width="450"
    >
      <v-container class="px-6">
        <div class="mb-4"><strong>{{ $t('files.dialogs.moveFiles.title') }}</strong></div>

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >
          <form-fields-2
            :item="item"
          />
        </v-form> 
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn 
              class="primary"
              :disabled="!valid"
              block
              small
              :loading="saving"
              @click="submit"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields2 from '@/modules/files/components/FormFields2.vue';

export default {
  components: {
    FormFields2,
  },
  props: {  
    file: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      item: {},
      loading: false,
      open: false,
      saving: false,
      valid: false
    };
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash'
    }),
    ...mapActions({
      updateFiles: 'files/updateFiles'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        activity: this.item.activity.id,
      }

      this.updateFiles({
        filesIds: [this.file.id],
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.$emit('success');
        this.open = false;
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
    },
    onButtonClick() {
      setTimeout(() => {
        this.open = true;
      }, 0);
    }
  }
};
</script>
