<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'contact-groups-list' }">
      {{ $t('contacts.titles.contacts') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('contacts.actions.addGroup') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">

      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <group-form-fields
            :item="item"
          />
        </v-form>

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'contact-groups-list' }"
                exact
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.add') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import GroupFormFields from '@/modules/contacts/components/GroupFormFields.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    GroupFormFields,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {
        text: null
      },
      saving: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      selectedItems: 'contacts/getSelectedContacts'
    }),
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapMutations({
      setSelectedItems: 'contacts/setSelectedContacts'
    }),
    ...mapActions({
      addContactsToGroup: 'contacts/addContactsToGroup',
      createGroup: 'contacts/createGroup'
    }),
    async submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        title: this.item.title,
        description: this.item.description,
        imageFile: this.item.imageFile || undefined,
      }

      try {
        let response = await this.createGroup(data);

        // add selected contacts
        if (this.selectedItems && this.selectedItems.length) {
          await this.addContactsToGroup({
            groupId: response.data.group.id,
            contacts: this.selectedItems.map(item => item.id)
          });
        }

        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.setSelectedItems(null);
        this.$router.push({ name: 'contact-groups-list' });

      }
      catch(error) {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      }

      this.saving = false;
    }
  }
}
</script>
