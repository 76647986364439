<template>
  <div>

    <v-combobox
      v-model="item.country"
      :label="$t('form.label.country')"
      :items="countries"
      item-text="name"
      item-value="id"
      return-object
      :loading="loadingCountries"
      clearable
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
      @change="item.province = null; loadProvinces(); clearCoordinates()"
    />

    <v-combobox
      :key="item.country ? item.country.id : ''"
      v-model="item.province"
      :label="$t('form.label.province')"
      :items="provinces"
      item-text="name"
      item-value="id"
      return-object
      :loading="loadingProvinces"
      clearable
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
      @change="clearCoordinates()"
    />

    <v-text-field
      v-model="item.city"
      :label="$t('user.form.label.city')"
      type="text"
      maxlength="64"
      @change="clearCoordinates()"
    />

    <v-text-field
      v-model="item.establishment"
      :label="$t('activities.form.label.place')"
      type="text"
      maxlength="128"
    />

    <v-text-field
      v-model="item.address"
      :label="$t('activities.form.label.address')"
      type="text"
      maxlength="128"
      @change="clearCoordinates()"
    />

    <location-button
      class="mb-2"
      @click="clearCoordinates(); clearAddress()"
      @gotCoordinates="onGotCoordinates"
      @reverseGeocoded="onReverseGeocoded" 
    />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LocationButton from '@/modules/activities/components/LocationButton.vue'

export default {
  components: {
    LocationButton
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      countries: [],
      loadingCountries: false,
      loadingProvinces: false,
      provinces: [],
      geocodedProvince: null
    }
  },
  created() {
    // load countries
    this.loadingCountries = true;
    this.getCountries()
    .then(response => {
      this.countries = response.data;
      // fix default country
      if (this.item.country && this.item.country.id && !this.item.country.name) {
        this.item.country = this.countries.find(item => item.id == this.item.country.id);
      }
      this.loadingCountries = false;
    });
    this.loadProvinces();
  },
  methods: {
    ...mapActions({
      getCountries: 'user/getCountries',
      getProvinces: 'user/getProvinces'
    }),
    loadProvinces() {
      if (this.item.country && this.item.country.id) {
        this.loadingProvinces = true;
        this.getProvinces(this.item.country.id)
        .then(response => {
          this.provinces = response.data;
          // fix default province
          if (this.item.province && this.item.province.id && !this.item.province.name) {
            this.item.province = this.provinces.find(item => item.id == this.item.province.id);
          }
          this.loadingProvinces = false;      
          if (this.geocodedProvince) {
            this.item.province = this.provinces.find(province => province.name == this.geocodedProvince);
            this.geocodedProvince = null;
          }
        });
      }
      else {
        this.provinces = [];
      }
    },
    clearCoordinates() {
      this.item.latitude = '';
      this.item.longitude = '';
    },
    clearAddress() {
      this.item.country = null;
      this.item.province = null;
      this.item.city = null;
      this.item.address = null;
    },
    onGotCoordinates(result) {
      if (result.latitude) {
        this.item.latitude = result.latitude;
      }
      if (result.longitude) {
        this.item.longitude = result.longitude;
      }
    },
    onReverseGeocoded(result) {
      if (result.country) {
        this.item.country = this.countries.find(country => country.name == result.country);
      }
      if (result.province) {
        this.geocodedProvince = result.province;
        this.loadProvinces();
      }
      if (result.city) {
        this.item.city = result.city;
      }
      if (result.address) {
        this.item.address = result.address;
      } 
    },
  }
}
</script>