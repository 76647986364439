<template>
  <loading 
    v-if="loading" 
    height="120"
  />
  <div v-else>
    <div 
      v-if="daysData"
      class="days-graph"
    >
      <template 
        v-for="i in 31"
      > 
        <day-item
          v-if="daysData[i] !== undefined"
          :key="i"
          :percents="daysData[i] / maxData * 100"
          :day="i"
          :selected="value == i"
          @click.native="$emit('input', i)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/util/Loading';
import DayItem from './DayItem.vue'

export default {
  components: {
    Loading,
    DayItem
  },
  props: {
    value: {
      type: Number,
      default: null
    }, 
    daysData: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    maxData() {
      if (!this.daysData) {
        return 1;
      }
      let max = 1;
      for (let i in this.daysData) {
        let val = Number(this.daysData[i]);
        if (val > max) {
          max = val;
        }
      }
      return max;
    }
  }
};
</script>

<style scoped>
.days-graph {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 120px;
}
</style>