<template>
  <div class="actions-menu">
    <v-menu 
      v-model="open"
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          color="primary"
          small
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">

        <v-list-item
          :to="{ name: 'contact-group-edit', params: { id: id } }"
        >
          <v-icon class="mr-3 primary--text">mdi-pencil-outline</v-icon> 
          {{ $t('actions.edit') }}
        </v-list-item> 

        <delete-dialog
          :id="id"
          @success="$emit('deleted')"
        /> 

      </v-list>
    </v-menu>
  </div>
</template>

<script>
import DeleteDialog from './DeleteDialog';

export default {
  components: {
    DeleteDialog
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      open: false
    }
  }
}
</script>