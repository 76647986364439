<template>
  <div>
    <v-btn
      icon
      :label="$t('actions.select')"
      @click="selectionEnabled = true"
    >
      <v-icon>mdi-checkbox-blank-outline</v-icon>
    </v-btn>

    <div 
      v-if="selectionEnabled"
      class="selection-toolbar"
    >
      <v-container class="selection-toolbar__container">
        <div class="selection-toolbar__inner">

          <v-btn
            icon
            :title="$t('actions.close')"
            class="mr-2"
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <div class="selection-toolbar__count">
            <div class="selection-toolbar__count-text">{{ $t('texts.selected') }}</div>
            {{ selectedItems.length }} {{ $t('texts.selectedOf') }} {{ items ? items.length : 0 }}
          </div>

          <v-btn
            v-if="selectAllFlag"
            icon
            :title="$t('actions.selectAll')"
            class="mr-2"
            @click="selectAll(); selectAllFlag = !selectAllFlag"
          >
            <v-icon>mdi-checkbox-multiple-marked-outline</v-icon>
          </v-btn>

          <v-btn
            v-else
            icon
            :title="$t('actions.clearSelection')"
            class="mr-2"
            @click="clearSelection(); selectAllFlag = !selectAllFlag"
          >
            <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
          </v-btn>

          <actions-menu 
            :selected-items="selectedItems"
            @close="close"
            @success="onSuccess"
          />        

        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';

export default {
  components: {
    ActionsMenu
  },
  props: {  
    items: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      selectAllFlag: true,
      selectionEnabled: false
    }
  },
  computed: {
    selectedItems() {
      return this.items
        ? this.items.filter(item => item.selected)
        : [];
    }
  },
  watch: {
    selectionEnabled() {
      this.clearSelection();
    }
  },
  methods: {
    clearSelection() {
      for (let i in this.items) {
        this.items[i].selected = false;
      }
    },
    selectAll() {
      for (let i in this.items) {
        this.items[i].selected = true;
      }
    },
    onSuccess() {
      this.selectionEnabled = false;
      this.clearSelection();
      this.$emit('success');
    },
    close() {
      this.selectionEnabled = false;
    }
  }
}
</script>

