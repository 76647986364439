<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('melodies.titles.melodies') }}
      <template slot="buttons">
        <selection-toolbar
          ref="itemSelectionToolbar"
          :items="items"
          @success="loadMelodies"
        />

        <sort-menu @change="sortBy = $event" />

        <app-fab 
          v-if="!$refs.itemSelectionToolbar || !$refs.itemSelectionToolbar.selectionEnabled"
        >
          <template slot="list">

            <router-link 
              class="app-fab__menu-item"
              :to="{ name: 'add-melody' }"
            >
              <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
                {{ $t('melodies.actions.addMelody') }}
              </v-btn>
              <v-btn
                fab
                small
                class="primary"
              >
                <v-icon>mdi-music-note-plus</v-icon>
              </v-btn>
            </router-link>

          </template>  
        </app-fab>

      </template>
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('melodies.titles.myMelodies') }}
      </v-tab>
    </page-tabs>

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <loading v-if="loading" />

      <v-container
        v-else
        class="container--narrow"
      >
        
        <v-alert 
          v-if="items && !items.length"
          type="info"
        >
          {{ $t('texts.noElements') }}
        </v-alert>

        <template v-if="itemsSorted">
          <melody-list-item
            v-for="item in itemsSorted"
            :key="item.id"
            :item="item"
            :selection-enabled="$refs.itemSelectionToolbar && $refs.itemSelectionToolbar.selectionEnabled"
            @delete="deleteId = item.id"
            @assign="assignId = item.id"
          />
        </template>

      </v-container>

    </div>
    
    <delete-dialog
      :item-id="deleteId"
      @close="deleteId = null"
      @success="loadMelodies"
    />
    
    <assign-melody-dialog
      :item-id="assignId"
      @close="assignId = null"
    />

    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import AssignMelodyDialog from './AssignMelodyDialog/AssignMelodyDialog.vue';
import DeleteDialog from './DeleteDialog';
import Loading from '@/components/util/Loading';
import MelodyListItem from '@/modules/melodies/pages/MelodyListPage/MelodyListItem/MelodyListItem.vue';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import SelectionToolbar from './SelectionToolbar/SelectionToolbar.vue';
import SortMenu from './SortMenu.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    AppFab,
    AssignMelodyDialog,
    DeleteDialog,
    Loading,
    MelodyListItem,
    MenuBottom,
    PageTabs,
    PageTitle,
    SelectionToolbar,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      assignId: null,
      deleteId: null,
      items: null,
      loading: false,
      sortBy: 'title'
    }
  },
  computed: {
    itemsSorted() {
      if (!this.items) {
        return [];
      }
      let items = this.items;
      switch (this.sortBy) {      
        case 'title':
          items.sort((a, b) => (a.name || '').localeCompare(b.name || ''));      
          break;
        case 'author':
          items.sort((a, b) => (a.author || '').localeCompare(b.author || ''));      
          break;
        case 'type':
          items.sort((a, b) => (a.type || '').localeCompare(b.type || ''));      
          break;
      }
      return items;
    }
  },
  created() {
    this.loadMelodies();
  },
  methods: {
    ...mapActions({
      getMelodies: 'melodies/getMelodies'
    }),
    loadMelodies() {
      this.loading = true;
      this.getMelodies()
      .then(response => {
        // add reactive field "selected"
        let i;
        for (i in response.data.melodies) {
          response.data.melodies[i].selected = false;
        }
        this.items = response.data.melodies;
        this.loading = false;
      });
    }
  }
}
</script>
