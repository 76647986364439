<template>
  <div>

    <date-input
      v-model="item.date"
      :label="$t('form.label.date')"
      required
    />

    <v-select
      v-model="item.activityGroup"
      :label="$t('activities.form.label.group')"
      :items="groups"
      item-text="name"
      item-value="id"
      return-object
      :loading="loadingGroups"
      :rules="[$validationRules.required]"
      :menu-props="{ 'content-class': 'expenses-activity-group-select-menu' }"
    />

    <v-text-field
      v-model="item.title"
      :label="$t('expenses.form.label.title')"
      :rules="[$validationRules.required]"
      type="text"
      maxlength="255"
    />    
    
    <user-field
      :item="item"
      :episode-id="$route.params.episodeId"
      :rules="[$validationRules.required]"
    />
    
    <v-row>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model="item.amount"
          type="number"
          :label="$t('expenses.form.label.amount')"
          :rules="[$validationRules.required]" 
        />
      </v-col>

      <v-col
        cols="6"
      >
        <currency-select
          v-model="item.currency"
          :rules="[$validationRules.required]"
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DateInput from '@/components/form/DateInput';
import CurrencySelect from '@/components/form/CurrencySelect';
import UserField from './UserField.vue';

export default {
  components: {
    DateInput,
    CurrencySelect,
    UserField,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      groups: [],
      loadingGroups: true,
    }
  },
  created() {
    this.getActivityGroups()
    .then(response => {
      let groups = response.data;

      // filter out default group
      let defaultGroup = groups.find(item => item.slug == 'none');
      groups = groups.filter(item => item.id != defaultGroup.id);
      // sort groups by name
      groups.sort((a, b) => (a.name || '').toString().localeCompare(b.name || '')); 
      this.groups = groups;

      this.loadingGroups = false;
    })
  },
  methods: {
    ...mapActions({
      getActivityGroups: 'activities/getActivityGroups',
    }),
  }
}
</script>

<style>
.expenses-activity-group-select-menu {
  max-height: 375px !important;
}
</style>
