import ActivitiesResultsPage from '@/modules/search/pages/ActivitiesResultsPage/ActivitiesResultsPage.vue';
import AdvancedSearchPage from '@/modules/search/pages/AdvancedSearchPage/AdvancedSearchPage.vue';
import EpisodesResultsPage from '@/modules/search/pages/EpisodesResultsPage/EpisodesResultsPage.vue';
import FilesResultsPage from '@/modules/search/pages/FilesResultsPage/FilesResultsPage.vue';
import NotesResultsPage from '@/modules/search/pages/NotesResultsPage/NotesResultsPage.vue';
import SearchPage from '@/modules/search/pages/SearchPage/SearchPage.vue';
import SharedEpisodesResultsPage from '@/modules/search/pages/SharedEpisodesResultsPage/SharedEpisodesResultsPage.vue';

export default [
  {
    path: '/search',
    name: 'search',
    component: SearchPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/advanced-search',
    name: 'advanced-search',
    component: AdvancedSearchPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/search-episodes-results',
    name: 'search-episodes-results',
    component: EpisodesResultsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/search-activities-results',
    name: 'search-activities-results',
    component: ActivitiesResultsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/search-files-results',
    name: 'search-files-results',
    component: FilesResultsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/search-notes-results',
    name: 'search-notes-results',
    component: NotesResultsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/search-shared-episodes-results',
    name: 'search-shared-episodes-results',
    component: SharedEpisodesResultsPage,
    meta: { requiresAuth: true }
  },
];
