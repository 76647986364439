<template>
  <div class="l-vertical__scroll l-vertical"> 
    <v-container class="container--narrow">

      <v-form
        ref="form"
        v-model="userData.step1Valid"
        class="mt-3"
        @submit.prevent
        @keyup.native.enter="onSubmit"
      >

        <upload-image 
          v-model="userData.avatarFile"
          class="mb-4"
          :circle="true"
          :text="$t('user.form.label.avatarFile')"
          icon="mdi-account-outline"
          :max-width="1024"
          :max-height="1024"
        />

        <v-text-field
          v-model="userData.name"
          :label="$t('user.form.label.name') + '*'"
          type="text"
          :rules="[$validationRules.required]"
          maxlength="64"
        />
        <v-text-field
          v-model="userData.lastname"
          :label="$t('user.form.label.lastname') + '*'"
          type="text"
          :rules="[$validationRules.required]"
          maxlength="64"
        />
        <v-text-field
          v-model="userData.alias"
          :label="$t('user.form.label.alias') + '*'"
          type="text"
          :rules="[$validationRules.required]"
          maxlength="10"
        />
      </v-form>
    </v-container>

    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :disabled="!userData.step1Valid"
              @click="onSubmit"
            >
              {{ $t('actions.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    
  </div>
</template>

<script>
import UploadImage from "@/components/form/UploadImage/UploadImage.vue";
import DateInput from '@/components/form/DateInput';

export default { 
  components: {
    DateInput,
    UploadImage
  },
  props: {
    userData: {
      type: Object,
      default: null
    }
  }, 
  data() {
    return {
      newFile: null
    };
  },
  methods: {
    onSubmit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.$emit('go-to-step', 2);
    }
  }
}
</script>