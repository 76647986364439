<template>
  <v-app>
    <version-number />
    <flash-bag />
    <div class="l-vertical">
      <app-header-shared v-if="$route.meta.shared" />
      <app-header v-else />

      <keep-alive 
        v-if="showRouterView"
        max="3"
        include="EpisodesListPage,FavoritesPage,WishListPage,TimelinePage,SearchPage,EpisodesResultsPage,ActivitiesResultsPage,NotesResultsPage,SharedEpisodesResultsPage,ContactsListPage"
      >
        <router-view :key="$route.fullPath" />
      </keep-alive>
    </div>
  </v-app>
</template>

<script>
import { eventBus } from '@/eventBus.js';
import AppHeader from '@/components/layout/AppHeader/AppHeader';
import AppHeaderShared from '@/modules/shared/components/AppHeaderShared/AppHeaderShared.vue';
import FirebaseCloudMessagingMixin from '@/modules/notifications/mixins/FirebaseCloudMessagingMixin.js';
import FlashBag from '@/components/util/FlashBag/FlashBag';
import VersionNumber from '@/components/VersionNumber';

export default {
  components: {
    AppHeader,
    AppHeaderShared,
    FlashBag,
    VersionNumber
  },
  mixins: [ FirebaseCloudMessagingMixin ],
  data() {
    return {
      showRouterView: true
    }
  },
  created() {
    // Listen to event
    eventBus.$on('clearAlivePages', () => {
      this.showRouterView = false;
      this.$nextTick(() => {
        this.showRouterView = true;
      });
    });
  },
};
</script>