<template>
  <div class="l-vertical__grow l-vertical">

    <progress-linear :loading="loading" />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
    
      <file-list>
        <file-list-col
          v-for="file in filesSorted"
          :key="file.id" 
        >
          <file-list-item
            :file="file"
            favorite-button
            @click="openFile = file"
            @modified="loadFiles"
          />
        </file-list-col>
      </file-list>

      <v-container
        v-if="!files.length && !loading" 
      >
        <v-alert
          text
          type="info"
        >
          {{ $t('favorites.texts.noData') }}
          <v-icon class="primary--text mt-n1">mdi-heart</v-icon>
          {{ $t('favorites.texts.noData2') }}
        </v-alert>
      </v-container>

    </div>

    <file-details-modal
      :open="!!(openFile && openFile.id)"
      :file="openFile"
      :files="filesSorted"
      :melody-url="melodyUrl"
      @close="openFile = null"
      @deleted="openFile = null; loadFiles()"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { eventBus } from '@/eventBus.js';
import FileDetailsModal from '@/modules/files/components/FileDetailsModal/FileDetailsModal.vue';
import FileList from '@/modules/files/components/FileList/FileList.vue';
import FileListCol from '@/modules/files/components/FileListCol.vue';
import FileListItem from '@/modules/files/components/FileListItem/FileListItem.vue';
import ProgressLinear from '@/components/util/ProgressLinear';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    FileDetailsModal,
    FileList,
    FileListCol,
    FileListItem,
    ProgressLinear,
  },
  mixins: [statusTap],
  props: {   
    sortBy: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      files: [],
      loading: false,
      melodyUrl: null,
      openFile: null,
    }
  },  
  computed: {
    filesSorted() {
      if (!this.files) {
        return [];
      }
      let files = [...this.files];
      switch (this.sortBy) {
        case 'recent':
          files.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'dateAsc':
          files.sort((a, b) => (a.date + a.time + a.id) < (b.date + b.time + b.id) ? -1 : 1);      
          break;       
        case 'dateDesc':
          files.sort((a, b) => (a.date + a.time + a.id) < (b.date + b.time + b.id) ? 1 : -1);     
          break;       
        case 'name':
          files.sort((a, b) => (a.description || '').toString().localeCompare(b.description || ''));      
          break;
      }
      return files;
    }
  },
  created() {
    this.loadFiles();
    this.loadMelodyUrl();

    // Listen to event
    eventBus.$on('favoriteFilesMelodyChanged', () => {
      this.loadMelodyUrl();
    });
  },
  methods: {
    ...mapActions({
      getFavoriteFiles: 'files/getFavoriteFiles',
      getFavoriteFilesMelody: 'favorites/getFavoriteFilesMelody',
    }),
    loadFiles() {
      this.loading = true;

      this.getFavoriteFiles()
      .then(response => {
        if (response) {
          this.files = response.data.activity_files;
          this.loading = false;
        }
      })
    },
    loadMelodyUrl() {
      this.getFavoriteFilesMelody()
      .then(response => {
        let melody = response.data.melody;
        this.melodyUrl = (melody && melody.urlAudioFile) ? melody.urlAudioFile : null;
      })
    },
  }
}
</script>