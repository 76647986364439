<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('notices.titles.notices') }}
      <template 
        slot="buttons"
      >
        <filter-menu 
          v-if="tab == 1"
          @change="filters = $event" 
        />

        <sort-menu  
          v-if="tab == 1"
          @change="sortBy = $event" 
        />

        <app-fab>
          <template slot="list">
            <router-link 
              class="app-fab__menu-item"
              :to="{ name: 'add-notice' }"
            >
              <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
                {{ $t('notices.actions.addNotice') }}
              </v-btn>
              <v-btn
                fab
                small
                class="primary"
              >
                <v-icon>mdi-text-box-plus-outline</v-icon>
              </v-btn>
            </router-link>

          </template>  
        </app-fab>

      </template>
    </page-title>

    <page-tabs v-model="tab">
      <v-tab @click="tab = 0">{{ $t('notices.titles.tabCurrent') }}</v-tab>
      <v-tab @click="tab = 1">{{ $t('notices.titles.tabHistory') }}</v-tab>
    </page-tabs>

    <progress-linear :loading="loading" />

    <div class="l-vertical__scroll"> 
      <v-container class="container--narrow pt-5">

        <v-tabs-items 
          v-model="tab"
          class="l-vertical__grow l-vertical"
        >
          <v-tab-item 
            eager
            class="l-vertical__grow l-vertical"
          >
            <tab-current
              :notices="notices"
              @success="loadNotices"
            />
          </v-tab-item>
          <v-tab-item 
            eager
            class="l-vertical__grow l-vertical"
          >
            <tab-history
              :notices="notices"
              :sort-by="sortBy"
              :filters="filters"
            />
          </v-tab-item>
        </v-tabs-items>

      </v-container>

    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import FilterMenu from './FilterMenu';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ProgressLinear from '@/components/util/ProgressLinear';
import SortMenu from './SortMenu.vue';
import TabCurrent from './TabCurrent/TabCurrent.vue';
import TabHistory from './TabHistory/TabHistory.vue';

export default {
  components: {
    AppFab,
    FilterMenu,
    PageTabs,
    PageTitle,
    ProgressLinear,
    SortMenu,
    TabCurrent,
    TabHistory
  },
  data() {
    return {
      filters: { 
        langs: {},
        langsAll: true,
        platforms: {},
        platformsAll: true
      },
      loading: false,
      notices: null,
      sortBy: 'date',
      tab: 0
    }
  },  
  created() {
    this.loadNotices();
  },
  methods: {
    ...mapActions({
      getNotices: 'notices/getNotices'
    }),
    loadNotices() {
      this.loading = true;
      this.getNotices()
      .then(response => {
        this.notices = response.data.notices;
        this.loading = false;
      });
    }
  }
}
</script>
