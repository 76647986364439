<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'search' }">
      {{ $t('search.titles.search') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('search.titles.searchFilters') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">
      <v-container class="container--800 pt-5 pb-0">

        <panel1
          :filters="filters"
          :search-for.sync="searchFor"
          class="mb-4"
        />

        <panel3
          :filters="filters"
          class="mb-4"
        />


        <v-expansion-panels 
          flat 
          accordion
          @keyup.native.enter="submit"
        >


          <panel2
            :filters="filters"
            class="mb-4"
          />
          <panel4
            :filters="filters"
            class="mb-4"
          />

          <panel5
            :filters="filters"
            :search-for="searchFor"
          />
        
        </v-expansion-panels>
      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--800">
          <v-row>
            <v-col 
              cols="6"
            >
              <v-btn
                block
                outlined
                :disabled="!hasFilters"
                @click="clear"
              >{{ $t('episodes.actions.clearFilters') }}</v-btn>
            </v-col>    

            <v-col 
              cols="6"
            >
              <v-btn
                block
                color="primary secondary--text"
                @click="submit"
              >{{ $t('episodes.actions.applyFilters') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle";
import Panel1 from "./Panel1.vue";
import Panel2 from "./Panel2.vue";
import Panel3 from "./Panel3.vue";
import Panel4 from "./Panel4.vue";
import Panel5 from "./Panel5.vue";

export default {
  components: {
    PageTabs,
    PageTitle,
    Panel1,
    Panel2,
    Panel3,
    Panel4,
    Panel5
  },
  data() {
    return {
      filters: {
        title: null,
        activityGroup: null,
        activityType: null,
        dateFrom: this.$moment().subtract(1, 'months').format('YYYY-MM-DD'),
        dateTo: this.$moment().format('YYYY-MM-DD'),
        country: null,
        province: null,
        city: null,
        establishment: null,
        address: null,
        user: null,
      },
      searchFor: 'activities'
    }
  },
  computed: {
    ...mapGetters({
      getFilters: 'search/getFilters',
      getSearchFor: 'search/getSearchFor',
    }),
    hasFilters() {
      return Boolean(this.filters.activityGroup 
        || this.filters.country 
        || this.filters.city 
        || this.filters.establishment 
        || this.filters.user
        || this.filters.address);
    },
  },
  created() {
    if (this.$route.query.clear) {
      this.clear();
    }
    else {
      if (this.getFilters) {
        this.filters = this.getFilters;
      }
      if (this.getSearchFor) {
        this.searchFor = this.getSearchFor;
      }
    }
  },
  methods: {
    ...mapMutations({
      setFilters: 'search/setFilters',
      setSearchFor: 'search/setSearchFor',
      setSearchCached: 'search/setSearchCached',
    }),
    clear() {
      this.filters = {
        title: null,
        activityGroup: null,
        activityType: null,
        dateFrom: this.$moment().subtract(1, 'months').format('YYYY-MM-DD'),
        dateTo: this.$moment().format('YYYY-MM-DD'),
        country: null,
        province: null,
        city: null,
        establishment: null,
        address: null,
        user: null,
      };
      this.setFilters(this.filters);
    },
    submit() {
      this.setSearchFor(this.searchFor);
      this.setFilters(this.filters);
      this.setSearchCached(false);

      switch (this.searchFor) {
        case 'episodes':
          if (this.filters.sharedEpisodes) {
            this.$router.push({ name: 'search-shared-episodes-results' });
          }
          else {
            this.$router.push({ name: 'search-episodes-results' });
          }
          break;        
        case 'activities':
          this.$router.push({ name: 'search-activities-results' });
          break;        
        case 'photos':
        case 'videos':
          this.$router.push({ name: 'search-files-results' });
          break;
        case 'notes':
          this.$router.push({ name: 'search-notes-results' });
          break;
      }
    }
  }
}
</script>

<style scoped>
/deep/ .search-panel {
  border-radius: 4px !important;
  background-color: var(--color-background--darker) !important;
  padding: 12px;
}
/deep/ .search-panel__header {
  padding-bottom: 12px;
}


/deep/ .expansion-panel {
  border-radius: 4px !important;
  background-color: var(--color-background--darker) !important;
}
/deep/ .expansion-panel--selected {
  border: 1px solid var(--v-primary-base);
}
/deep/ .v-expansion-panel-header {
  padding: 12px;
  min-height: 0 !important;
}
/deep/ .v-expansion-panel-content__wrap {
  padding: 0 12px 12px;
}
/deep/ .v-expansion-panel-header__icon .v-icon {
  color: var(--color-text) !important;
  font-size: 34px;
}
/deep/ .v-text-field--outlined fieldset {
  background-color: var(--color-background);
  border-color: var(--color-background);
}
/deep/ .v-text-field--outlined.v-input--is-focused fieldset {
  border-color: var(--v-primary-base);
}
/deep/ .theme--dark.v-select.v-text-field .v-label {
  color: var(--v-primary-base);
}
</style>