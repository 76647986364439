<template>
  <v-menu 
    offset-y
    left
    eager
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :disabled="disabled"
        :class="disabled ? 'actions-menu__button-disabled' : 'primary'"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">        

      <v-list-item :to="{ name: 'multiple-notes-edit'}">
        <v-icon class="mr-3 primary--text">mdi-pencil-outline</v-icon> 
        {{ $t('actions.edit') }}
      </v-list-item>

      <v-list-item :to="{ name: 'multiple-notes-move'}">
        <v-icon class="mr-3 primary--text">mdi-folder-move-outline</v-icon> 
        {{ $t('actions.move') }}
      </v-list-item>  

      <v-list-item @click="$emit('play')">
        <v-icon class="mr-3 primary--text">mdi-volume-high</v-icon> 
        {{ $t('actions.play') }}
      </v-list-item>  

      <download-item 
        @close="$emit('close')"
      />

      <delete-dialog @success="$emit('success')" />

    </v-list>
  </v-menu>
</template>

<script>
import DeleteDialog from './DeleteDialog';
import DownloadItem from './DownloadItem.vue';

export default {
  components: {
    DeleteDialog,
    DownloadItem
  },
  props: {  
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.actions-menu__button-disabled {
  visibility: hidden;
}
</style>