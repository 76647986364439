<template>
  <div>

    <v-text-field
      v-model="item.title"
      :label="$t('form.label.title')"
      :rules="[$validationRules.required]"
      type="text"
      maxlength="255"
    />

    <v-textarea
      v-model="item.description"
      :label="$t('form.label.description')"
      :rules="[$validationRules.required]"
      rows="3"
      :auto-grow="true"
    />

    <v-checkbox 
      v-model="item.ios"
      hide-details
      class="mt-2"
      :label="$t('notices.model.platform.ios')"
      :disabled="context=='edit'"
    />

    <v-checkbox 
      v-model="item.android"
      hide-details
      :label="$t('notices.model.platform.android')"
      :disabled="context=='edit'"
    />

    <v-checkbox 
      v-model="item.web"
      hide-details
      class="mb-6"
      :label="$t('notices.model.platform.web')"
      :disabled="context=='edit'"
    />

    <language-select
      v-model="item.lang"
      class="mb-4"
      :disabled="context=='edit'"
    />

  </div>
</template>

<script>
import LanguageSelect from '@/components/form/LanguageSelect';

export default {
  components: {
    LanguageSelect,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    context: { // add | edit
      type: String,
      default: 'add'
    }
  }
}
</script>
