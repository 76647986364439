<template>
  <div>
    <v-list-item 
      class="error--text"
      @click="onButtonClick"
    >
      <v-icon class="mr-3">mdi-trash-can-outline</v-icon> 
      {{ $t('actions.delete') }}
    </v-list-item>

    <v-dialog
      v-model="open"
      width="450"
    >
      <v-container class="py-3 px-6">
        <div class="subtitle-1 text-center mt-2 mb-6 error--text">
          {{ $t('trash.dialogs.delete.text') }}
        </div>
        <p class="text-center mb-8">{{ $t('trash.dialogs.delete.text2') }}</p>
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="error"
              :loading="saving"
              block
              small
              @click="submit"
            >{{ $t('actions.delete') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    selectedItems: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      open: false,
      saving: false,
    };
  },
  computed: {
    selectedEpisodesIds() {
      return this.selectedItems.filter(item => item.itemType == 'episode').map(item => item.id);
    },
    selectedActivitiesIds() {
      return this.selectedItems.filter(item => item.itemType == 'activity').map(item => item.id);
    },
    selectedFilesIds() {
      return this.selectedItems.filter(item => item.itemType == 'file').map(item => item.id);
    },
    selectedNotesIds() {
      return this.selectedItems.filter(item => item.itemType == 'note').map(item => item.id);
    },
    selectedMelodiesIds() {
      return this.selectedItems.filter(item => item.itemType == 'melody').map(item => item.id);
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      deleteActivities: 'trash/deleteActivities',
      deleteEpisodes: 'trash/deleteEpisodes',
      deleteFiles: 'trash/deleteFiles',
      deleteNotes: 'trash/deleteNotes',
      deleteMelodies: 'trash/deleteMelodies',
    }),
    submit() {
      this.saving = true;

      const promises = [];

      // delete episodes
      if (this.selectedEpisodesIds.length) {
        promises.push(this.deleteEpisodes(this.selectedEpisodesIds));
      }

      // delete activities
      if (this.selectedActivitiesIds.length) {
        promises.push(this.deleteActivities(this.selectedActivitiesIds));
      }

      // delete files
      if (this.selectedFilesIds.length) {
        promises.push(this.deleteFiles(this.selectedFilesIds));
      }

      // delete notes
      if (this.selectedNotesIds.length) {
        promises.push(this.deleteNotes(this.selectedNotesIds));
      }

      // delete melodies
      if (this.selectedMelodiesIds.length) {
        promises.push(this.deleteMelodies(this.selectedMelodiesIds));
      }

      Promise.all(promises)
      .then(() => {
        this.addFlash({
          text: this.$t("trash.messages.deleted"),
          type: 'success'
        });
        this.$emit('success');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
        this.open = false;
      })      
    },
    onButtonClick() {
      setTimeout(() => {
        this.open = true;
      }, 0);
    }
  }
};
</script>
