<template>
  <div>
    <page-title :back-to="{ name: 'episodes-list'}">
      UI Explorer
    </page-title>

    <v-container
      grid-list-xl
      pb-5
    >
      <v-tabs>
        <v-tab ripple>
          Tipografía
        </v-tab>
        <v-tab-item class="pt-5">
          <tab-typography />
        </v-tab-item>

        <v-tab ripple>
          Colores
        </v-tab>
        <v-tab-item class="pt-5">
          <tab-colors />
        </v-tab-item>

        <v-tab ripple>
          Botones
        </v-tab>
        <v-tab-item class="pt-5">
          <tab-buttons />
        </v-tab-item>

        <v-tab ripple>
          Iconos
        </v-tab>
        <v-tab-item class="pt-5">
          <tab-icons />
        </v-tab-item>

        <v-tab ripple>
          Well
        </v-tab>
        <v-tab-item class="pt-5">
          <tab-well />
        </v-tab-item>

        <v-tab ripple>
          Alertas
        </v-tab>
        <v-tab-item class="pt-5">
          <tab-alerts />
        </v-tab-item>

        <v-tab ripple>
          Elementos interactivos
        </v-tab>
        <v-tab-item>
          <tab-interactive-states />
        </v-tab-item>

        <v-tab ripple>
          Formularios
        </v-tab>
        <v-tab-item class="pt-5">
          <tab-forms />
        </v-tab-item>

        <v-tab ripple>
          Chips
        </v-tab>
        <v-tab-item class="pt-5">
          <tab-chips />
        </v-tab-item>


      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "@/components/layout/PageTitle.vue";
import TabAlerts from './TabAlerts';
import TabButtons from './TabButtons';
import TabChips from './TabChips';
import TabColors from './TabColors';
import TabForms from './TabForms';
import TabIcons from './TabIcons';
import TabInteractiveStates from './TabInteractiveStates';
import TabTypography from './TabTypography';
import TabWell from './TabWell';

export default {
  components: {
    PageTitle,
    TabAlerts,
    TabButtons,
    TabChips,
    TabColors,
    TabForms,
    TabIcons,
    TabInteractiveStates,
    TabTypography,
    TabWell,
  }
};
</script>
