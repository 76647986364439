import Vue from "vue";

const videoFunctions = {

  getFileDuration(file) {
    return new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          resolve(this.duration);
        }

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        }

        video.src = window.URL.createObjectURL(file)
      } catch (e) {
        reject(e)
      }
    });
  }
};

const plugin = {
  install() {
    Vue.videoFunctions = videoFunctions;
    Vue.prototype.$videoFunctions = videoFunctions;
  }
};

Vue.use(plugin);

export default videoFunctions;
