<template>
  <div>
    <v-btn
      icon
      :title="$t('favorites.dialogs.melody.title')"
      @click="open = true"
    >
      <v-icon>mdi-music-note</v-icon>
    </v-btn>

    <v-dialog
      v-model="open"
      width="450"
    >
      <v-container
        class="px-6"
      >
        <div class="mb-4"><strong>{{ $t('favorites.dialogs.melody.title') }}</strong></div>

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >
          <melody-combobox
            v-if="open"
            :item="item"
            :loading-item="loading"
          />
        </v-form> 
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn 
              class="primary"
              :disabled="!valid || loading"
              block
              small
              :loading="saving"
              @click="submit"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { eventBus } from '@/eventBus.js';
import Loading from '@/components/util/Loading.vue';
import MelodyCombobox from '@/modules/melodies/components/MelodyCombobox/MelodyCombobox.vue';

export default {
  components: {
    Loading,
    MelodyCombobox
  },
  data() {
    return {
      item: {
        melody: {
          id: null,
          urlAudioFile: null
        }
      },
      loading: false,
      open: false,
      saving: false,
      valid: false
    };
  },
  watch: {
    // on modal open
    open(open) {
      if (open) {
        this.loadFavoriteFilesMelody();
      }
    }
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash'
    }),
    ...mapActions({
      getFavoriteFilesMelody: 'favorites/getFavoriteFilesMelody',
      setFavoriteFilesMelody: 'favorites/setFavoriteFilesMelody',
    }),
    loadFavoriteFilesMelody() {
      this.loading = true;
      this.getFavoriteFilesMelody()
      .then(response => {
        this.item.melody = response.data.melody;
        this.loading = false;
      })
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      this.setFavoriteFilesMelody(this.item.melody ? this.item.melody.id : 0)
      .then(response => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.$emit('success');
        eventBus.$emit('favoriteFilesMelodyChanged');
        this.open = false;
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
    }
  }
};
</script>
