<template>
  <v-radio-group
    v-model="item.rol"
    :rules="[$validationRules.required]"
    class="mt-0 mb-3"
  >
    <v-radio
      :value="roles.EDITOR"
      :label="$t('collaborators.model.role.EDITOR')"
    />
    <div class="small pl-8 mb-2">
      <div v-html="$t('collaborators.form.description.role.EDITOR')"></div>
    </div>

    <v-radio
      :value="roles.COMMENTATOR"
      :label="$t('collaborators.model.role.COMMENTATOR')"
    />
    <div class="small pl-8">
      <div v-html="$t('collaborators.form.description.role.COMMENTATOR')"></div>
    </div>

  </v-radio-group>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      roles: roles
    }
  }
}
</script>
