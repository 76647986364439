<template>
  <div class="melody-item">
    <div 
      v-ripple
      class="melody-item__thumbnail"
      @click="onClick"
    >
      <v-icon large>mdi-music-note</v-icon>
    </div>

    <div class="melody-item__details">
      <div class="body-2 height-lines-1">{{ item.name }}</div>
      <div class="height-lines-1">{{ item.author }}</div>
      <div class="small height-lines-1">{{ item.type }}</div>
    </div>

    <v-checkbox
      v-model="item.selected" 
      color="primary"
      class="melody-item__checkbox"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    onClick() {
      this.$emit('play', this.item.urlAudioFile);
    }
  }
};
</script>

<style scoped>
.melody-item {
  display: flex;
  align-items: center;
  position: relative;
}
.melody-item__thumbnail {
  position: relative;
  width: 76px;
  height: 76px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: var(--color-background--light);
  color: var(--color-text--dark);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.melody-item__details {
  flex-grow: 1;
  padding: 0 4px 0 16px;
  text-align: left;
}
</style>