<template>
  <v-dialog
    v-model="isOpen"
    width="450"
  >
    
    <step-1
      v-if="step == 1"
      @cancel="isOpen = false"
      @choose-existing="$emit('choose-existing'); isOpen = false"
      @choose-new="step = 2"
    />

    <step-2
      v-if="step == 2"
      :add-activity="addActivity"
      @cancel="step = 1"
      @episode-created="onEpisodeCreated($event)"
    />  

  </v-dialog>
</template>

<script>
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';

export default {
  components: {
    Step1,
    Step2
  },
  props: {
    addActivity: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      isOpen: false,
      step: 1
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    onEpisodeCreated($event) {
      this.$emit('episode-created', $event);
      this.isOpen = false;
    }
  }
};
</script>
