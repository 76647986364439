import MelodyAddPage from '@/modules/melodies/pages/MelodyAddPage.vue';
import MelodyEditPage from '@/modules/melodies/pages/MelodyEditPage.vue';
import MelodyListPage from '@/modules/melodies/pages/MelodyListPage/MelodyListPage.vue';

export default [
   {
    path: '/melodies',
    name: 'melodies-list',
    component: MelodyListPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-melody',
    name: 'add-melody',
    component: MelodyAddPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/melody/:id',
    name: 'edit-melody',
    component: MelodyEditPage,
    meta: { requiresAuth: true }
  },
]
