/**
 * This file contains helper functions for plugin cordova-plugin-statusbar
 * https://cordova.apache.org/docs/en/10.x/reference/cordova-plugin-statusbar
 */

import Vue from 'vue';
import helperFunctions from './helperFunctions';

const statusBarHelper = {

  onDeviceready() {
    StatusBar.show();
    StatusBar.overlaysWebView(false);
    // set background color
    StatusBar.backgroundColorByHexString('#242424');
    // set text color
    StatusBar.styleLightContent();
  },

  // change status bar color to detault
  setColorDefault() {
    if (helperFunctions.isCordova()) {
      // set background color
      StatusBar.backgroundColorByHexString('#242424');
      // set text color
      StatusBar.styleLightContent();
    }
  },

  // change status bar color to black
  setColorBlack() {
    if (helperFunctions.isCordova()) {
      // set background color
      StatusBar.backgroundColorByHexString('#000000');
      // set text color
      StatusBar.styleLightContent();
    }
  },

  // hide status bar
  hide() {
    if (helperFunctions.isCordova()) {
      StatusBar.hide();
    }
  },

  // show status bar
  show() {
    if (helperFunctions.isCordova()) {
      StatusBar.show();
    }
  }

}

document.addEventListener("deviceready", statusBarHelper.onDeviceready);

const plugin = {
  install () {
    Vue.statusBarHelper = statusBarHelper
    Vue.prototype.$statusBarHelper = statusBarHelper
  }
}
Vue.use(plugin);

export default statusBarHelper;