<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'advanced-search' }">
      {{ $t('search.titles.search') }}
      <template slot="buttons">
        <note-selection-toolbar
          ref="itemSelectionToolbar"
          :notes="notes"
          @success="loadNotes"
        />

        <filter-menu @change="filters = $event" />

        <sort-menu @change="sortBy = $event" />
      </template>
    </page-title>
    
    <page-tabs class="mb-2">
      <v-tab>
        {{ $t('search.titles.searchResults') }}
      </v-tab>
    </page-tabs>

    <filters-chips 
      search-for="notes" 
      @change="loadNotes"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <loading v-if="loading" />

      <v-container 
        v-else
        class="container--800 pt-0"
      >
        
        <v-alert 
          v-if="notes && !notes.length"
          type="info"
        >
          {{ $t('notes.texts.noElements') }}
        </v-alert>

        <v-alert 
          v-if="notes && notes.length && !notesFilteredSorted.length"
          type="info"
        >
          {{ $t('texts.noElementsMatching') }}
        </v-alert>

        <template v-if="notesFilteredSorted">
          <note-list-item
            v-for="note in notesFilteredSorted"
            :key="note.id"
            :item="note"
            :selection-enabled="$refs.itemSelectionToolbar && $refs.itemSelectionToolbar.selectionEnabled"
            show-activity-link
          />
        </template>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FilterMenu from '@/modules/notes/components/FilterMenu';
import FiltersChips from '@/modules/search/components/FiltersChips.vue';
import Loading from '@/components/util/Loading';
import NoteListItem from '@/modules/notes/components/NoteListItem/NoteListItem.vue';
import NotePrivacy from '@/modules/notes/model/NotePrivacy.js';
import NoteSelectionToolbar from '@/modules/notes/components/NoteSelectionToolbar/NoteSelectionToolbar.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import SortMenu from '@/modules/notes/components/SortMenu.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  name: 'NotesResultsPage',
  components: {
    FilterMenu,
    FiltersChips,
    Loading,
    NoteListItem,
    NoteSelectionToolbar,
    PageTabs,
    PageTitle,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      filters: { 
        favorites: false,
        type: 'all',
        privacy: 'all'
      },
      notes: null,
      loading: false,
      sortBy: 'recent'
    }
  },
  computed: {
    ...mapGetters({
      searchFilters: 'search/getFilters',
      searchCached: 'search/getSearchCached',
      user: 'user/user'
    }),
    notesFiltered() {
      if (!this.notes) {
        return [];
      }
      return this.notes.filter(item => {
        // filter by type
        switch (this.filters.type) {
          case 'all':
            break;
          case 'text':
            if (item.urlAudioFile) {
              return false;
            }
            break;
          case 'audio':
            if (!item.urlAudioFile) {
              return false;
            }
            break;
        }

        // filter by privacy
        switch (this.filters.privacy) {
          case 'all':
            break;
          case 'mine':
            if (item.user.id != this.user.id) {
              return false;
            }
            break;
          case 'minePublic':
            if ((item.user.id != this.user.id) || (item.privacy != NotePrivacy.VISIBLE)) {
              return false;
            }
            break;
          case 'minePrivate':
            if ((item.user.id != this.user.id) || (item.privacy != NotePrivacy.PRIVATE)) {
              return false;
            }
            break;
          case 'others':
            if (item.user.id == this.user.id) {
              return false;
            }
            break;
        }

        // filter by favorites
        switch (this.filters.favorites) {
          case true:
            if (!item.isFavorite) {
              return false;
            }
            break;
        }

        return true;
      });
    },
    notesFilteredSorted() {
      if (!this.notesFiltered) {
        return [];
      }
      let notes = [...this.notesFiltered];
      switch (this.sortBy) {
        case 'recent':
          notes.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'date':
          notes.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? -1 : 1);       
          break;       
        case 'text':
          notes.sort((a, b) => (a.text || '').localeCompare(b.text || ''));      
          break;
      }
      return notes;
    }
  },
  activated() {
    if (!this.searchCached) {
      this.loadNotes();
    }
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      setSearchCached: 'search/setSearchCached',
    }),
    ...mapActions({
      searchNotes: 'search/searchNotes'
    }),
    loadNotes() {
      this.notes = null;
      this.loading = true;

      let params = {
        note_title: this.searchFilters.title,

        activity_group: this.searchFilters.activityGroup ? this.searchFilters.activityGroup.id : null,
        activity_type: this.searchFilters.activityType ? this.searchFilters.activityType.id : null,
        address: this.searchFilters.address,
        city: this.searchFilters.city,
        country: this.searchFilters.country ? this.searchFilters.country.id : null,
        date_from: this.searchFilters.dateFrom,
        date_to: this.searchFilters.dateTo,
        establishment: this.searchFilters.establishment,
        province: this.searchFilters.province ? this.searchFilters.province.id : null,
        user: this.searchFilters.user ? this.searchFilters.user.id : null
      };

      this.searchNotes(params)
      .then(response => {
        // add reactive field "selected"
        let i;
        for (i in response.data.notes) {
          response.data.notes[i].selected = false;
        }
        this.notes = response.data.notes;
        this.loading = false;
        this.setSearchCached(true);
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      });
    }
  }
}
</script>
