<template>
  <div class="l-vertical__grow l-vertical">
    
    <page-title>
      {{ $t('user.actions.deleteAccount') }}
    </page-title>

    <v-container class="container--narrow">

      <loading v-if="loading" />

      <v-alert 
        v-if="tokenInvalid"
        type="error" 
        class="mb-6"
      >{{ $t('user.messages.error.incorrectLink') }}</v-alert>
      
      <template v-if="!loading && !tokenInvalid && !success && !cancelled">
        
        <div class="body-2 mb-6">{{ $t('user.texts.deleteAccountFinalize1') }}</div>  
        <div 
          class="body-2 error--text mb-6"
          v-html="$t('user.texts.deleteAccountFinalize2')"
        ></div>  

        <div class="body-2  mt-1 mb-3">{{ $t('user.titles.enterPassword') }}</div>

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >
          <v-text-field
            v-model="password"
            :label="$t('user.form.label.password')"
            :rules="[$validationRules.required]"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            class="mb-1 v-input--darker"
            autocomplete="on"
            outlined
            dense
            :error="passwordIncorrect"
            :error-messages="passwordIncorrect ? [$t('user.messages.error.incorrectPassword')] : []"
            @click:append="showPassword = !showPassword"
            @input="passwordIncorrect = false"
          />
        </v-form> 

        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="cancelled = true"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="error"
              :loading="deleting"
              block
              small
              @click="submit"
            >{{ $t('actions.delete') }}</v-btn>
          </v-col>
        </v-row>

      </template>

      <div 
        v-if="success"
        class="mt-6 mb-6"
        v-html="$t('user.texts.deleteAccountSuccess')"
      ></div>

      <template
        v-if="cancelled"
        class="mt-6 mb-6"
      >
        <div v-html="$t('user.texts.deleteAccountCancelled1')"></div><br>
        <div v-html="$t('user.texts.deleteAccountCancelled2')"></div><br>
        <div v-html="$t('user.texts.deleteAccountCancelled3')"></div>
      </template>

    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Loading from '@/components/util/Loading';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    Loading,
    PageTitle,
  },
  data() {
    return {
      deleting: false,
      cancelled: false,
      loading: true,
      password: null,
      passwordIncorrect: false,
      showPassword: false,
      success: false,
      tokenInvalid: false,
      valid: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
  },
  created() {
    // validate token
    this.validateDeleteToken({ 
      userId: this.$route.params.userId,
      token: this.$route.params.token
    })
    .then(response => {
      if (!this.user) {
        this.mutateUser(response.data.user);
      }
    })
    .catch(error => {
      this.tokenInvalid = true;
    })
    .finally(() => {
      this.loading = false;
    });

  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      logout: 'user/logout',
      mutateUser: 'user/mutateUser'
    }),
    ...mapActions({
      deleteAccount: 'user/deleteAccount',
      validateDeleteToken: 'user/validateDeleteToken'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.deleting = true;
      this.success = null;

      this.deleteAccount({ 
        userId: this.$route.params.userId,
        token: this.$route.params.token,
        password: this.password
      })
      .then(response => {
        this.success = true;
        this.logout();
      })
      .catch(error => {
        if (error.response.data && error.response.data.exception == 'Invalid  password') {
          this.passwordIncorrect = true;
        }
        else {
          this.addFlash({
            text: this.$helperFunctions.errorToString(error),
            type: 'error'
          });
        }
      })
      .finally(() => {
        this.deleting = false;
      })
    }
  }
}
</script>
