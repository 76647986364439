<template>
  <v-select
    v-model="filters.activityType"
    :label="$t('activities.form.label.type')"
    :items="typesOptions"
    item-text="name"
    item-value="id"
    return-object
    :loading="loadingTypes"
    clearable
    hide-details
    outlined
    dense
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loadingTypes: false,
      types: []
    }
  },
  computed: {
    typesOptions() {
      let group;
      if (this.filters && this.filters.activityGroup && this.types) {
        group = this.types.find(group => group.id == this.filters.activityGroup.id)
      }
      if (group) {
        return group.activityTypes;
      }
      return [];
    }
  },
  created() {
    // load types
    this.loadingTypes = true;
    this.getActivityTypes()
    .then(response => {
      this.types = response.data;
      this.loadingTypes = false;
    });
  },
  methods: {
    ...mapActions({
      getActivityTypes: 'activities/getActivityTypes',
    })
  }
}
</script>