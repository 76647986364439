<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        small
        :title="$t('actions.filter')"
        :class="{
          'ml-1': $vuetify.breakpoint.mdAndUp
        }"
        v-on="on"
      >
        {{ $t('episodes.filter.' + filter) }}
      </v-btn>
    </template>
    <v-list class="py-0">

      <v-list-item 
        dense
        @click="filter = 'all'; $emit('change', 'all')"
      >
        <v-checkbox
          v-model="filter"
          value="all"
          :label="$t('episodes.filter.all')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click="filter = 'editor'; $emit('change', 'editor')"
      >
        <v-checkbox
          v-model="filter"
          value="editor"
          :label="$t('episodes.filter.editor')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click="filter = 'commentator'; $emit('change', 'commentator')"
      >
        <v-checkbox
          v-model="filter"
          value="commentator"
          :label="$t('episodes.filter.commentator')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      filter: 'editor'
    }
  }
}
</script>