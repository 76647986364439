<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'notice-list' }">
      {{ $t('notices.titles.notices') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('actions.edit') }}
      </v-tab>
    </page-tabs>

    <loading v-if="loading" />

    <div
      v-else 
      class="l-vertical__scroll l-vertical"
    >

      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <form-fields
            :item="item"
            context="edit"
          />

        </v-form>

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'notice-list' }"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from '../components/FormFields.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    Loading,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {},
      loading: false,
      saving: false,
      valid: false
    }
  },
  created() {
    // load notice
    this.loading = true;
    this.getNotice(this.$route.params.noticeId)
    .then(response => {
      this.item = response.data.notice;
      this.loading = false;
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getNotice: 'notices/getNotice',
      updateNotice: 'notices/updateNotice'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        title: this.item.title,
        description: this.item.description
      }

      this.updateNotice({
        noticeId: this.item.id,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("notices.messages.success.updated"),
          type: 'success'
        });
        this.$router.push({ name: 'notice-list' });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>