<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'procedure-list', params: { episodeId: $route.params.episodeId } }">
      {{ $t('procedures.titles.procedures') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('procedures.titles.create') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">

      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <form-fields
            :item="item"
            context="add"
          />
        </v-form>

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'procedure-list', params: { episodeId: $route.params.episodeId }}"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.add') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from '../components/FormFields.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {},
      saving: false,
      valid: false,
      fileRules: [
        value => { 
          return !value || value.size < 1000000 || 'Avatar size should be less than 2 MB!'
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
      user: 'user/user'
    })
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    // default values
    this.item.currency = this.user.currency;
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      createProcedure: 'procedures/createProcedure',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        type: this.item.type,
        text: this.item.text,
        cost: this.item.cost ? this.item.cost : '',
        currency: this.item.currency,
      }
      // upload file
      if (this.item.file) {
        data.filenameFile = this.item.file;
      }

      this.createProcedure({
        episodeId: this.episode.id,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("procedures.messages.success.added"),
          type: 'success'
        });
        this.$router.push({ name: 'procedure-list', params: { episodeId: this.$route.params.episodeId } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
