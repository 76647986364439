<template>
  <div 
    class="alerts-panel__wrap"
    :class="{ 'alerts-panel__wrap--open': isOpen }"
  >

    <div class="alerts-panel__toggle">
      <v-btn
        small
        @click="toggle"
      >
        {{ $t('dataUpload.titles.alerts') }} ({{ count }})
        <v-icon 
          size="16"
          class="ml-1"
        >{{ isOpen ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
      </v-btn>
    </div>

    <div 
      ref="alertsPanel"
      class="alerts-panel"
      :class="{ 'alerts-panel--open': isOpen }"
    >
      <alert-item
        v-for="(item, index) in alerts"
        :key="index"
        :item="item"
      >
      </alert-item>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import AlertItem from './AlertItem.vue';

export default {
  components: {
    AlertItem,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      alerts: 'dataUpload/alerts',
    }),
    count() {
      return this.alerts ? this.alerts.length : 0;
    },
  },
  watch: {
    alerts() {
      if (this.alerts.length && !this.isOpen) {
        this.isOpen = true;
      }
      this.scrollToBottom();
    },
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
        this.scrollToBottom();
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.alertsPanel.scrollTop = this.$refs.alertsPanel.scrollHeight;
      });
    },
  },
}
</script>

<style scoped>
.alerts-panel__wrap {
  position: absolute;
  z-index: 1;
  top: 207px;
  width: 1000px;
  max-width: calc(100% - 100px);
  left: 50%;
  translate: -50% 0;
  overflow-y: hidden;
  transition: all 0.15s ease-out;
  max-height: 34px;
}
.alerts-panel__wrap--open {
  max-height: 100%;
}
.alerts-panel__toggle {
  position: absolute;
  z-index: 1;
  right: 20px;
}
.alerts-panel {
  position: absolute;
  max-height: calc(100vh - 284px);
  position: relative;
  translate: 0 -100%;
  transition: all 0.15s ease-out;
  overflow-y: auto;
  background-color: #3f3f3f;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%);
  border-radius: 0 0 12px 12px;
  padding: 12px 12px 0 12px;
  margin: 0 20px 20px 20px;
}
.alerts-panel--open {
  translate: 0 0;
}
</style>