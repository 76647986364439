<template>
  <div class="l-vertical__scroll l-vertical">
    <v-container class="container--narrow">

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
        @keyup.native.enter="onSubmit"
      >
        <v-text-field
          v-model="userData.email"
          :label="$t('user.form.label.email')"
          :rules="[$validationRules.required, $validationRules.email]"
          type="email"
          maxlength="128"
          validate-on-blur
          @copy="$event.preventDefault()"
        />
        <v-text-field
          v-model="userData.emailRepeat"
          :label="$t('user.form.label.emailRepeat')"
          :rules="rulesEmailRepeat"
          type="email"
          maxlength="128"
          validate-on-blur
          autocomplete="off"
        />
        <v-text-field
          v-model="userData.password"
          :label="$t('user.form.label.password')"
          :rules="rulesOne"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          maxlength="128"
          :hint="$t('messages.error.password')"
          validate-on-blur
          autocomplete="off"
          @click:append="showPassword = !showPassword"
        />
        <v-text-field
          v-model="userData.passwordRepeat"
          :label="$t('user.form.label.passwordRepeat')"
          :rules="rulesTwo"
          :type="showPassword2 ? 'text' : 'password'"
          :append-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
          maxlength="128"
          autocomplete="off"
          @click:append="showPassword2 = !showPassword2"
        />

        <v-checkbox
          v-model="userData.acceptance"
          :label="$t('user.form.label.acceptance')"
          :rules="[$validationRules.required]"
        />

        <div>
          {{ $t('user.form.label.acceptanceHint') }} <data-policy-dialog />.
        </div>

      </v-form>
    </v-container>



    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn  
              outlined
              block
              @click="$emit('go-to-step', 1)"
            >{{ $t('actions.back') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :disabled="!valid"
              :loading="saving"
              @click="onSubmit"
            >
              {{ $t('actions.finish') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DataPolicyDialog from '@/components/dialogs/DataPolicyDialog';

// fix strange error: 'Unknown custom element: <app-header>'
import Vue from 'vue';
import AppHeader from '@/components/layout/AppHeader/AppHeader';
Vue.component('app-header', AppHeader);

export default {
  components: {
    DataPolicyDialog,
  },
  props: {
    userData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      saving: false,
      showPassword: false,
      showPassword2: false,
      valid: false,
      rulesOne: [],
      rulesTwo: [],
      rulesEmailRepeat: [],
    };
  },
  computed: {
    emailConfirmationRule() {
      return () => (this.userData.email === this.userData.emailRepeat) || this.$t('messages.error.emailMatch')
    },
    passwordConfirmationRule() {
      return () => (this.userData.password === this.userData.passwordRepeat) || this.$t('messages.error.passwordMatch')
    },
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      registerUser: 'user/registerUser',
    }),
    onSubmit() {
      this.errorEmail = null;
      this.rulesOne = [this.$validationRules.required, this.$validationRules.password];
      this.rulesTwo = [this.$validationRules.required, this.passwordConfirmationRule];
      this.rulesEmailRepeat = [this.$validationRules.required, this.emailConfirmationRule];

      this.$nextTick(function () {
        if (!this.$refs.form.validate()) {
          return false;
        }

        this.saving = true;

        const data = {
          acceptance: this.userData.acceptance,
          alias: this.userData.alias,
          birth: this.$moment().format('YYYY-MM-DD'),
          currency: 'EUR',
          email: this.userData.email,
          lastname: this.userData.lastname,
          locale: this.$i18n.locale,
          name: this.userData.name,
          plainPassword: this.userData.password
        }
        if (this.userData.avatarFile) {
          data.avatarFile = this.userData.avatarFile;
        }

        this.registerUser(data)
        .then(response => {
          if (response.data && response.data.user) {
            this.$emit('go-to-step', 3);
          }
        })
        .catch(error => {
          this.addFlash({
            text: this.$helperFunctions.errorToString(error),
            type: 'error'
          });
        })
        .finally(() => {
          this.saving = false;
        });
      })
        
    }
  }
}
</script>
