<template>
  <div class="l-vertical__grow l-vertical">

    <welcome-message 
      v-if="showWelcomeMessage"
      prominent 
      dismissible
      @close="closeWelcomeMessage"
    />

    <template v-else>
      <page-title>
        {{ $t('episodes.titles.home') }}
        <template slot="buttons">
          <filter-menu @change="filters = $event" />

          <sort-menu @change="sortBy = $event" />
          
          <app-fab 
            add-episode
            add-activity
            add-files
            add-notes
          />
        </template>
      </page-title>

      <episodes-tabs />
        
      <progress-linear :loading="loading" />

      <div 
        ref="statusTap"
        class="l-vertical__scroll"
      >

        <episodes-list 
          v-if="episodesFilteredSorted.length"
          :items="episodesFilteredSorted"
        />

        <v-container v-if="!loading && !episodes.length && !showWelcomeMessage">
          <v-alert 
            type="info"
            class="mt-2"
          >{{ $t('episodes.texts.episodesNoData') }}</v-alert>
        </v-container>

        
        <v-container v-if="episodes && episodes.length && !episodesFilteredSorted.length">
          <v-alert type="info">
            {{ $t('texts.noElementsMatching') }}
          </v-alert>
        </v-container>

      </div>

      <menu-bottom />

    </template>

    <notice-dialog />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import EpisodesList from '@/modules/episodes/components/EpisodesList/EpisodesList';
import EpisodesTabs from '@/modules/episodes/components/EpisodesTabs';
import EpisodeRoles from '@/modules/episodes/model/EpisodeRoles.js';
import FilterMenu from './FilterMenu';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import NoticeDialog from '@/modules/notices/components/NoticeDialog.vue';
import PageTitle from "@/components/layout/PageTitle";
import ProgressLinear from '@/components/util/ProgressLinear';
import SortMenu from '@/modules/episodes/components/EpisodesList/SortMenu';
import statusTap from '@/mixins/statusTap.js';
import WelcomeMessage from "./WelcomeMessage/WelcomeMessage.vue";

export default {
  name: 'EpisodesListPage',
  components: {
    AppFab,
    EpisodesList,
    EpisodesTabs,
    FilterMenu,
    MenuBottom,
    NoticeDialog,
    PageTitle,
    ProgressLinear,
    SortMenu,
    WelcomeMessage
  },
  mixins: [statusTap],
  data() {
    return {
      episodes: [],
      filters: 'editor',
      loading: false,
      showWelcomeMessage: false,
      sortBy: 'dateDesc',
    }
  },  
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    episodesFiltered() {
      if (!this.episodes) {
        return [];
      }
      return this.episodes.filter(item => {
        // filter by role
        switch (this.filters) {
          case 'editor':
            return (item.rol == EpisodeRoles.CREATOR) || (item.rol == EpisodeRoles.EDITOR);
          case 'commentator':
            return item.rol == EpisodeRoles.COMMENTATOR;
        }

        return true;
      });
    },
    episodesFilteredSorted() {
      if (!this.episodesFiltered) {
        return [];
      }
      let episodes = [...this.episodesFiltered];
      switch (this.sortBy) {
        case 'recent':
          episodes.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;   
        case 'dateAsc':
          episodes.sort((a, b) => a.date < b.date ? -1 : 1);      
          break;       
        case 'dateDesc':
          episodes.sort((a, b) => a.date < b.date ? 1 : -1);      
          break;        
        case 'title':
          episodes.sort((a, b) => (a.title || '').localeCompare(b.title || ''));      
          break;
      }
      return episodes;
    }
  },
  created() {
    this.loadEpisodes();

    // fix for iOS CORS problem
    setTimeout(() => {
      if (!this.episodes.length) {
        this.loadEpisodes();
      }
    }, 1000);
  },
  activated() {
    this.loadEpisodes();
  },
  methods: {
    ...mapActions({
      getEpisodes: 'episodes/getEpisodes'
    }),
    closeWelcomeMessage() {
      this.showWelcomeMessage = false;
      localStorage.hideWelcomeMessage = this.user.id;
    },
    loadEpisodes() {
      this.loading = true;

      this.getEpisodes()
      .then(response => {
        // show welcome message
        if (!response.data.episodes.length && (!localStorage.hideWelcomeMessage || this.user.id != localStorage.hideWelcomeMessage)) {
          this.showWelcomeMessage = true;
        }

        this.episodes = response.data.episodes.filter(item => !item.isWish);
        this.loading = false;
      })
    }
  }
}
</script>