export default {
  data() {
    return {
      scrollTop: null, // scroll position
    }
  },
  mounted() {
    if (this.$refs.statusTap) {
      window.addEventListener('statusTap', this.onStatusTap);
      this.$refs.statusTap.addEventListener("scroll", this.onScroll, { passive: true });
    }
  },
  activated() {
    // restore scroll position
    this.$refs.statusTap.scrollTo({ top: this.scrollTop });
  },
  beforeDestroy() {
    window.removeEventListener('statusTap', this.onStatusTap);
    window.removeEventListener('statusTap', this.onScroll);
  },
  methods: {
    onStatusTap() {
      this.$refs.statusTap.scrollTo({top: 0, behavior: 'smooth'});
    },
    onScroll(event) {
      // save scroll position
      this.scrollTop = event.target.scrollTop;
    }
  }
}