<template>
  <div v-if="isOwner">
    <v-list-item @click="onButtonClick">
      <v-icon class="mr-3 primary--text">mdi-send</v-icon> 
      {{ $t('actions.transfer') }}
    </v-list-item>

    <v-dialog
      v-model="open"
      width="450"
    >

      <v-container class="px-6">

        <loading 
          v-if="loading && !errorMessage" 
          height="100"
        />

        <template v-if="errorMessage">

          <v-alert
            type="info"
            class="mt-3 mb-5"
          >
            {{ errorMessage }}
          </v-alert>

          <v-btn
            class="primary mb-2"
            block
            small
            @click="close"
          >{{ $t('actions.ok') }}</v-btn>

        </template>


        <template v-if="!loading && !errorMessage">
          <div class="mb-1"><strong>{{ $t('activities.dialogs.transfer.title') }}</strong></div>

          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
            @keyup.native.enter="submit"
          >
            <user-field
              :item="item"
              :collaborators="collaborators"
              :rules="[$validationRules.required]"
            />
          </v-form> 

          <hr class="mb-4">

          <v-row>
            <v-col cols="6">
              <v-btn
                outlined
                block
                small
                @click="open = false"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn 
                class="primary"
                :disabled="!valid"
                block
                small
                :loading="saving"
                @click="submit"
              >
                {{ $t('actions.save') }}
              </v-btn>
            </v-col>
          </v-row>

        </template>

      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy';
import Loading from '@/components/util/Loading';
import UserField from './UserField.vue';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    Loading,
    UserField,
  },
  props: {
    activity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      collaborators: null,
      errorMessage: null,
      item: {},
      loading: false,
      open: false,
      saving: false,
      valid: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    isOwner() {
      return this.activity && this.activity.user && (this.activity.user.id == this.user.id);
    },
    isPrivate() {
      return this.activity && this.activity.privacy == ActivityPrivacy.PRIVATE
    },
  },
  watch: {
    activity() {
      this.onAcitivityLoaded();
    }
  },
  created() {
    this.onAcitivityLoaded();
  }, 
  methods: {
    ...mapMutations({
      addFlash: 'addFlash'
    }),
    ...mapActions({
      getCollaborators: 'collaborators/getCollaborators',
      transferActivity: 'activities/transferActivity',
    }),
    onAcitivityLoaded() {
      if (this.isPrivate) {
        this.errorMessage = this.$t('activities.messages.info.noTransferPrivate');
      }
    },
    onButtonClick() {
      setTimeout(() => {
        this.open = true;
        if (!this.errorMessage) {
          this.loadCollaborators();
        }
      }, 0);
    },
    loadCollaborators() {
      this.loading = true;
      this.getCollaborators(this.activity.episode.id)
      .then(response => {
        this.collaborators = response.data.collaborators.filter(item => {
          return (item.id != this.user.id) && (item.rol != roles.COMMENTATOR);
        });
        if (this.collaborators.length == 0) {
          this.errorMessage = this.$t('episodes.messages.info.noCollaborators');
        }
        this.loading = false;
      });
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      this.transferActivity({
        toUserId: this.item.user.id,
        activityId: this.activity.id
      })
      .then(response => {
        this.addFlash({
          text: this.$t("activities.messages.success.transferred"),
          type: 'success'
        });
        this.$emit('success');
        this.open = false;
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
    },
    close() {
      this.open = false;
    },
  }
};
</script>
