<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'advanced-search' }">
      {{ $t('search.titles.search') }}
      <template slot="buttons">
        <file-selection-toolbar
          ref="itemSelectionToolbar"
          :files="files"
          @success="submit"
        />

        <list-layout-button 
          :layout="layout" 
          @change="layout = $event" 
        />

        <filter-menu @change="filters = $event" />

        <sort-menu @change="sortBy = $event" />
      </template>
    </page-title>
    
    <page-tabs class="mb-2">
      <v-tab>
        {{ $t('search.titles.searchResults') }}
      </v-tab>
    </page-tabs>

    <filters-chips 
      :search-for="searchFor" 
      @change="submit"
    />
    
    <progress-linear :loading="loading" />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
        
      <v-container v-if="!loading && files && !files.length">
        <v-alert type="info">
          {{ $t('texts.noElementsMatching') }}
        </v-alert>
      </v-container>

      <v-container v-if="!loading && files && files.length && !filesFilteredSorted.length">
        <v-alert type="info">
          {{ $t('texts.noElementsMatching') }}
        </v-alert>
      </v-container>

      <file-list :layout="layout">
        <file-list-col
          v-for="file in filesFilteredSorted"
          :key="file.id"
          :layout="layout" 
        >
          <file-list-item
            :file="file"
            :selection-enabled="$refs.itemSelectionToolbar && $refs.itemSelectionToolbar.selectionEnabled"
            :layout="layout" 
            show-activity-link
            favorite-button
            @click="openFile = file"
          />
        </file-list-col>
      </file-list>

    </div>

    <file-details-modal
      :files="files"
      :file="openFile"
      :open="!!(openFile && openFile.id)"
      @close="openFile = null"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FileDetailsModal from '@/modules/files/components/FileDetailsModal/FileDetailsModal.vue';
import FileList from '@/modules/files/components/FileList/FileList.vue';
import FileListCol from '@/modules/files/components/FileListCol.vue';
import FileListItem from '@/modules/files/components/FileListItem/FileListItem.vue';
import FilePrivacy from '@/modules/files/model/FilePrivacy.js';
import FilterMenu from './FilterMenu';
import FiltersChips from '@/modules/search/components/FiltersChips.vue';
import FileSelectionToolbar from '@/modules/files/components/FileSelectionToolbar/FileSelectionToolbar.vue';
import ListLayoutButton from '@/modules/files/components/ListLayoutButton.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ProgressLinear from '@/components/util/ProgressLinear';
import SortMenu from '@/modules/files/components/SortMenu.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    FileDetailsModal,
    FileList,
    FileListCol,
    FileListItem,
    FilterMenu,
    FileSelectionToolbar,
    FiltersChips,
    ListLayoutButton,
    PageTabs,
    PageTitle,
    ProgressLinear,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      files: null,
      filters: { 
        favorites: false,
        privacy: 'all'
      },
      layout: 'thumbnail',
      loading: false,
      openFile: null,
      sortBy: 'recent'
    }
  },
  computed: {
    ...mapGetters({
      searchFilters: 'search/getFilters',
      searchFor: 'search/getSearchFor',
      user: 'user/user'
    }),  
    filesFiltered() {
      if (!this.files) {
        return [];
      }
      return this.files.filter(item => {

        // filter by privacy
        switch (this.filters.privacy) {
          case 'all':
            break;
          case 'mine':
            if (item.user.id != this.user.id) {
              return false;
            }
            break;
          case 'minePublic':
            if ((item.user.id != this.user.id) || (item.privacy != FilePrivacy.VISIBLE)) {
              return false;
            }
            break;
          case 'minePrivate':
            if ((item.user.id != this.user.id) || (item.privacy != FilePrivacy.PRIVATE)) {
              return false;
            }
            break;
          case 'others':
            if (item.user.id == this.user.id) {
              return false;
            }
            break;
        }

        // filter by favorites
        switch (this.filters.favorites) {
          case true:
            if (!item.isFavorite) {
              return false;
            }
            break;
        }

        return true;
      });
    },
    filesFilteredSorted() {
      if (!this.filesFiltered) {
        return [];
      }
      let files = [...this.filesFiltered];
      switch (this.sortBy) {
        case 'recent':
          files.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'date':
          files.sort((a, b) => (a.date + a.time + a.id) < (b.date + b.time + b.id) ? -1 : 1);       
          break;       
        case 'title':
          files.sort((a, b) => (a.description || '').localeCompare(b.description || ''));      
          break;
        case 'owner':
          files.sort((a, b) => (a.user.alias || '').toString().localeCompare(b.user.alias || ''));      
          break;
      }
      return files;
    }
  },
  created() {
    this.submit();
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
    }),
    ...mapActions({
      searchFiles: 'search/searchFiles'
    }),
    submit() {
      this.loading = true;

      let params = {
        file_description: this.searchFilters.title,

        activity_group: this.searchFilters.activityGroup ? this.searchFilters.activityGroup.id : null,
        activity_type: this.searchFilters.activityType ? this.searchFilters.activityType.id : null,
        address: this.searchFilters.address,
        city: this.searchFilters.city,
        country: this.searchFilters.country ? this.searchFilters.country.id : null,
        date_from: this.searchFilters.dateFrom,
        date_to: this.searchFilters.dateTo,
        establishment: this.searchFilters.establishment,
        province: this.searchFilters.province ? this.searchFilters.province.id : null,
        user: this.searchFilters.user ? this.searchFilters.user.id : null
      };

      this.searchFiles(params)
      .then(response => {
        // add reactive field "selected"
        let i;
        for (i in response.data.files) {
          response.data.files[i].selected = false;
        }
        // filter by type
        this.files = response.data.files.filter(item => item.type == (this.searchFor == 'videos' ? 'video' : 'photo'));
        this.loading = false;
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      });
    }
  }
}
</script>