<template>
  <v-list-item
    v-if="menuBottom"
    class="sort-menu__item primary--text"
    @click.stop="takePicture"
  >
    {{ $t('menu-fab.camera') }}
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      size="20"
      width="2"
    />
    <v-icon 
      v-else
      class="ml-2"
    >mdi-camera-plus-outline</v-icon>
  </v-list-item>
  <div 
    v-else
    class="app-fab__menu-item"
    @click.stop="takePicture"
  >
    <v-btn 
      class="app-fab__item-label primary v-btn--raised mr-2"
      :loading="loading"
    >
      {{ $t('menu-fab.camera') }}
    </v-btn>
    <v-btn
      fab
      small
      class="primary"
    >
      <v-icon>mdi-camera-plus-outline</v-icon>
    </v-btn>
  </div> 
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FileTypes from '@/modules/files/model/FileTypes.js';
import UploadParameters from '@/modules/files/model/UploadParameters.js';

export default {
  props: {
    menuBottom: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapGetters({
      getUploadFiles: 'files/getUploadFiles',
    }),
    ...mapMutations({
      addFlash: 'addFlash',
      setUploadFiles: 'files/setUploadFiles',
    }),
    takePicture() {
      this.loading = true;
      this.setUploadFiles([]);

      let cameraOptions = {
        quality: UploadParameters.JPEG_QUALITY, // Some common settings are 20, 50, and 100
        destinationType: Camera.DestinationType.DATA_URL,
        sourceType: Camera.PictureSourceType.CAMERA,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        allowEdit: false,
        correctOrientation: true
      }

      navigator.camera.getPicture(imageUri => {
        let file = {
          file: null,
          name: "image.jpg",
          type: "image/jpeg",
          id: 1,
          uploading: false,
          uploaded: false,
          camera: true,
        };

        let blob = this.getBlob(imageUri);
        blob.lastModifiedDate = new Date();
        blob.name = "image.jpg";
        file.file = blob;

        this.onFilesSelected([file]);
      }, error => {
        console.log(error);
        this.loading = false; 
      }, cameraOptions);
    },
    /**
     * Turn base 64 image into a blob, so we can send it using multipart/form-data posts
     * @param b64Data
     * @param contentType
     * @param sliceSize
     * @return {Blob}
     */
    getBlob(b64Data, contentType = "image/jpeg", sliceSize = 512) {
      let byteCharacters = atob(b64Data);
      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize);

          let byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }

          let byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
      }

      let blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    onFilesSelected(files) {
      this.setUploadFiles(files);
      if (files.length) {
        this.$router.push({ 
          name: 'file-upload', 
          params: { 
            episodeId: this.$route.params.episodeId, 
            activityId: this.$route.params.activityId 
          } 
        });
      }
      this.loading = false;
    },
  }
};
</script>