<template>
  <v-expansion-panel 
    class="expansion-panel"
    :class="{ 'expansion-panel--selected': filters.user }"
  >
    <v-expansion-panel-header class="body-2 ">
      {{ $t('search.titles.filtersGroup5') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content eager>
      <user-field 
        :filters="filters" 
      />
      <shared-episodes-field 
        v-if="searchFor == 'episodes'"
        :filters="filters"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import SharedEpisodesField from './formFields/SharedEpisodesField.vue';
import UserField from './formFields/UserField.vue';

export default {
  components: {
    SharedEpisodesField,
    UserField
  },
  props: {
    filters: {
      type: Object,
      default: null
    },
    searchFor: {
      type: String,
      default: null
    },
  }
}
</script>