<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'melodies-list' }">
      {{ $t('melodies.titles.melodies') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('melodies.actions.addMelody') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">

      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <form-fields
            :item="item"
          />

          <v-file-input
            v-model="item.audioFile"
            :accept="acceptedMimeTypes"
            :label="$t('melodies.form.placeholder.file')"
            :rules="[$validationRules.required]"
          ></v-file-input>

        </v-form>

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'melodies-list' }"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.add') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from '../components/FormFields.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import UploadParameters from '@/modules/melodies/model/UploadParameters.js';

export default {
  components: {
    FormFields,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      acceptedMimeTypes: UploadParameters.ACCEPTED_MIME_TYPES.join(','),
      item: {},
      saving: false,
      valid: false
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      createMelody: 'melodies/createMelody',
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        name: this.item.name,
        type: this.item.type,
        author: this.item.author,
        audioFile: this.item.audioFile
      }

      this.createMelody(data)
      .then(response => {
        this.addFlash({
          text: this.$t("melodies.messages.success.added"),
          type: 'success'
        });
        this.$router.push({ name: 'melodies-list' });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
