<template>
  <div class="l-vertical__scroll l-vertical">
    <v-container class="container--narrow">

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >
        <form-fields
          :item="item"
        />
      </v-form>

    </v-container>

    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn  
              outlined
              block
              @click="$router.back()"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :disabled="!valid"
              :loading="saving"
              @click="submit"
            >{{ $t('actions.add') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <episode-add-modal 
      ref="episodeAddModal"
      @choose-existing="$emit('go-to-step-2')"
      @episode-created="onEpisodeCreated($event)"
    />
  </div>
</template>

<script>
import EpisodeAddModal from '@/modules/episodes/components/EpisodeAddModal/EpisodeAddModal.vue';
import FormFields from '../../components/FormFields.vue';

export default {
  components: {
    EpisodeAddModal,
    FormFields,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false
    }
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.$refs.episodeAddModal.open();
    },
    onEpisodeCreated($event) {
      this.item.episode = { id: $event.episodeId };
      this.item.activity = { id: $event.activityId };
      this.$emit('submit');
    }
  }
}
</script>