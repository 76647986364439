<template>
  <div>
    <v-btn
      icon
      :title="$t('collaborators.actions.deleteCollaborator')"
      class="error--text"
      @click="open = true"
    >
      <v-icon>mdi-trash-can-outline</v-icon>
    </v-btn>

    <v-dialog 
      v-model="open" 
      width="450"
    >
      
      <v-container class="py-4 px-6">
        <div
          v-if="collaborator"
          class="subtitle-1 text-center mt-2 mb-6 error--text"
        >
          {{ $t('collaborators.dialogs.delete.collaborator', { user: collaborator.alias }) }}
        </div>
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="error"
              :loading="saving"
              block
              small
              @click="submit"
            >{{ $t('actions.remove') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  props: {
    collaboratorId: {
      type: [Number, String],
      default: null
    },
    episodeId: {
      type: [Number, String],
      default: null
    },
    collaborator: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      open: false,
      saving: false
    };
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      deleteCollaborator: 'collaborators/deleteCollaborator'
    }),
    submit() {
      this.saving = true;

      this.deleteCollaborator({
        episodeId: this.episodeId,
        userId: this.collaboratorId
      })
      .then(() => {
        this.addFlash({
          text: this.$t('collaborators.messages.success.collaboratorRemoved', { 
            user: this.collaborator.alias 
          }),
          type: 'success'
        });
        this.$router.push({ name: 'collaborators-list', params: { 
          episodeId: this.episodeId
        } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>
