<template>
  <v-list-item 
    :disabled="loading"
    class="download-item"
    @click.stop.prevent="onClick"
  >
    <v-progress-linear
      v-if="loading"
      :value="percentCompleted"
      color="primary"
      stream
      buffer-value="0"
    />
    <template v-else>
      <v-icon class="mr-3 primary--text">mdi-cloud-download-outline</v-icon> 
      {{ $t('actions.download') }}
    </template>
  </v-list-item>  
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { eventBus } from '@/eventBus.js';
import downloadAndroidFunctions from '@/modules/files/functions/downloadAndroidFunctions.js';
import downloadBrowserFunctions from '@/modules/files/functions/downloadBrowserFunctions.js';
import downloadIosFunctions from '@/modules/files/functions/downloadIosFunctions.js';

export default {
  props: {  
    file: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      loading: false,
      percentCompleted: 0
    };
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    canUseImage() {
      return this.file.canEdit || this.file.user.allowOthersUseMyPhotos;
    },
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      downloadFiles: 'files/downloadFiles'
    }),
    onClick() {
      if (this.canUseImage) {
        if (this.$helperFunctions.isIos()) {
          this.downloadIos();
        } else {
          this.download();
        }
      }
      else {
        eventBus.$emit('no-permisison-dialog-open');
      }
    },
    download() {
      this.loading = true;
      this.percentCompleted = 0;

      let url = this.file.urlFilename + '?bearer=' + this.apiToken;
      console.log('url', url);

      let onDownloadProgress = (progressEvent) => {
        this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      }

      this.downloadFiles({ 
        filesIds: [this.file.id], 
        onDownloadProgress 
      })
      .then((response) => {
        let filename = response.headers['content-disposition'].split('filename=')[1];
        if (this.$helperFunctions.isAndroid()) {
          downloadAndroidFunctions.downloadBlob(response.data, filename);
        } 
        else {
          downloadBrowserFunctions.downloadBlob(response.data, filename);
        }
        this.addFlash({
          text: this.$t('files.messages.success.files-downloaded'),
          type: 'success'
        });
        this.$emit('close');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.loading = false;
      }); 
    },
    async downloadIos() {
      // File is downloaded from URL (urlImageThumbnails.original_) and saved to iOS 'Photos'
      this.loading = true;

      try {
        if (this.file.type == 'video') {
          let url = this.file.urlFilename + '?bearer=' + this.apiToken;
          await downloadIosFunctions.downloadVideo(url);
        }
        else {
          let url = this.file.urlImageThumbnails.original_ + '?bearer=' + this.apiToken;
          await downloadIosFunctions.downloadImage(url);
        }
      } catch(error) {
        this.loading = false;
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
        return;
      }

      this.loading = false;
      this.addFlash({
        text: this.$t('files.messages.success.files-downloaded'),
        type: 'success'
      });
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.download-item {
  min-width: 140px;
}
</style>