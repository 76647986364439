<template>
  <div>

    <v-autocomplete
      ref="idCountry"
      v-model="selectedCountryId"
      :label="$t('user.form.label.country')"
      :items="countries"
      item-text="name"
      item-value="id"
      :loading="loadingCountries"
      autocomplete="off"
      clearable
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
    />

    <v-autocomplete
      ref="idProvince"
      v-model="selectedProvinceId"
      :label="$t('user.form.label.province')"
      :items="provinces"
      item-text="name"
      item-value="id"
      :loading="loadingProvinces"
      :rules="[validationProvince]"
      autocomplete="off"
      validate-on-blur
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
    />

    <v-text-field
      v-model="userData.city"
      :label="$t('user.form.label.city')"
      type="text"
      maxlength="64"
    />

    <date-input
      v-model="userData.birth"
      :label="$t('user.form.label.birthday')"
    />

    <currency-select
      v-model="userData.currency"
      required
    />

    <language-select
      v-model="userData.locale"
      class="mb-5"
    />

    <v-row>
      <v-col cols="10">
        {{ $t('user.form.label.allowOthersUseMyPhotos') }}
      </v-col>
      <v-col cols="2">
        <v-switch
          v-model="userData.allowOthersUseMyPhotos"
          hide-details
          class="mt-1"
        />
      </v-col>
    </v-row>

    <div class="text-right small mb-2">
      {{ $t('form.switch.currentState') }} {{ $t(userData.allowOthersUseMyPhotos ? 'form.switch.on' : 'form.switch.off') }}
    </div>

    <div 
      class="small mb-3"
      v-html="$t('user.form.hint.allowOthersUseMyPhotos')"
    ></div>


  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CurrencySelect from '@/components/form/CurrencySelect';
import DateInput from '@/components/form/DateInput';
import LanguageSelect from '@/components/form/LanguageSelect';

export default {
  components: {
    CurrencySelect,
    DateInput,
    LanguageSelect,
  },
  props: {
    userData: {
      type: Object,
      default: null
    },
  },  
  data() {
    return {
      countries: [],
      loadingCountries: false,
      loadingProvinces: false,
      provinces: [],
      selectedCountryId: null,
      selectedProvinceId: null,
    };
  },
  computed: {
    validationProvince(val){
      if (this.selectedCountryId && this.provinces && this.provinces.length && !this.selectedProvinceId){
        return this.$t('messages.error.empty');
      }
      return true;
    }
  },
  watch: {
    'selectedCountryId': function(value) {
      if (value) {
        // load provinces
        this.loadingProvinces = true;
        this.getProvinces(this.selectedCountryId)
        .then(response => {
          this.provinces = response.data;
          if (this.selectedCountryId === this.userData.idCountry) {
            this.selectedProvinceId = this.userData.idProvince;
          } else {
            this.selectedProvinceId = null;
          }
          this.loadingProvinces = false;
        });
      }
      else {
        this.provinces = [];
        this.selectedProvinceId = null;
      }
      this.userData.idCountry = this.selectedCountryId;
    },    
    'selectedProvinceId': function(value) {
      this.userData.idProvince = this.selectedProvinceId;
    }
  },
  created() {
    // load countries
    this.loadingCountries = true;
    this.getCountries()
    .then(response => {
      this.countries = response.data;
      this.loadingCountries = false;
      this.selectedCountryId = this.userData.idCountry;
    })
  },
  methods: {    
    ...mapActions({
      getCountries: 'user/getCountries',
      getProvinces: 'user/getProvinces',
    }),
    filter(item, search) {
      return this.$helperFunctions.normalizeString(item.name)
        .indexOf(this.$helperFunctions.normalizeString(search)) > -1;
    }
  }
};
</script>