var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collapse-text"},[_c('div',{ref:"slotWrapper",staticClass:"collapse-text__text",class:{
      'collapse-text__text--show-button': _vm.showButton,
      'collapse-text__text--open': _vm.open,
      'collapse-text__text--collapse-all': _vm.showButton && !_vm.open && _vm.collapseAll
    }},[_vm._t("default")],2),(_vm.showButton)?_c('div',{class:{ 
      'text-center': _vm.centerButton,
      'text-right': !_vm.centerButton,
      'mb-n7': _vm.overflowButton
    }},[_c('v-btn',{class:_vm.buttonClass,attrs:{"x-small":"","text":""},on:{"click":function($event){_vm.open = !_vm.open}}},[_vm._v(_vm._s(_vm.$t(_vm.open ? 'actions.close' : 'actions.keepReading')))])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }