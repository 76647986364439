<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'advanced-search' }">
      {{ $t('search.titles.search') }}
      <template slot="buttons">
        <selection-toolbar
          ref="selectionToolbar"
          :items="episodes"
          @success="submit"
        />

        <sort-menu 
          v-if="episodes && episodes.length"
          @change="sortBy = $event" 
        />
      </template>
    </page-title>
    
    <page-tabs class="mb-2">
      <v-tab>
        {{ $t('search.titles.searchResults') }}
      </v-tab>
    </page-tabs>

    <filters-chips 
      search-for="episodes" 
      @change="submit"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
    
      <loading v-if="loading" />

      <v-container 
        v-if="episodesFilteredSorted.length"
        class="container--800 pt-0"
      >
        <episode-list-item
          v-for="episode in episodesFilteredSorted"
          :key="episode.id"
          :item="episode"
          :selection-enabled="$refs.selectionToolbar && $refs.selectionToolbar.selectionEnabled"
          show-activity-link
        />
      </v-container>
    
      <v-container 
        v-if="!loading && episodes && !episodesFilteredSorted.length"
        class="container--800"
      >
        <v-alert type="info">{{ $t('messages.results.notFound') }}</v-alert>
      </v-container>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EpisodeListItem from './EpisodeListItem/EpisodeListItem';
import FiltersChips from '@/modules/search/components/FiltersChips.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle";
import SelectionToolbar from './SelectionToolbar/SelectionToolbar';
import SortMenu from '@/modules/episodes/components/EpisodesList/SortMenu';
import statusTap from '@/mixins/statusTap.js';

export default {
  name: 'SharedEpisodesResultsPage',
  components: {
    EpisodeListItem,
    FiltersChips,
    Loading,
    PageTabs,
    PageTitle,
    SelectionToolbar,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      episodes: null,
      filterTop: 'all',
      loading: false,
      sortBy: 'recent'
    }
  },
  computed: { 
    ...mapGetters({
      filters: 'search/getFilters',
      searchCached: 'search/getSearchCached',
    }),  
    episodesFiltered() {
      if (!this.episodes) {
        return [];
      }
      return this.episodes;
    },
    episodesFilteredSorted() {
      if (!this.episodes) {
        return [];
      }
      let episodes = [...this.episodesFiltered];
      switch (this.sortBy) {
        case 'recent':
          episodes.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'dateAsc':
          episodes.sort((a, b) => a.date < b.date ? -1 : 1);      
          break;       
        case 'dateDesc':
          episodes.sort((a, b) => a.date < b.date ? 1 : -1);      
          break;        
        case 'title':
          episodes.sort((a, b) => (a.title || '').localeCompare(b.title || ''));      
          break;
      }
      return episodes;
    }
  },
  activated() {
    if (!this.searchCached) {
      this.submit();
    }
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      setSearchCached: 'search/setSearchCached',
    }),
    ...mapActions({
      searchEpisodes: 'search/searchEpisodes'
    }),
    submit() {
      this.episodes = null;
      
      this.loading = true;

      let params = {
        search: '',
        episode_title: this.filters.title,
        activity_group: this.filters.activityGroup ? this.filters.activityGroup.id : null,
        activity_type: this.filters.activityType ? this.filters.activityType.id : null,
        address: this.filters.address,
        city: this.filters.city,
        country: this.filters.country ? this.filters.country.id : null,
        date_from: this.filters.dateFrom,
        date_to: this.filters.dateTo,
        establishment: this.filters.establishment,
        province: this.filters.province ? this.filters.province.id : null,
        haveGuests: 1
      };

      this.searchEpisodes(params)
      .then(response => {
        if (response) {
          // add reactive field "selected"
          let i;
          for (i in response.data.episodes) {
            response.data.episodes[i].selected = false;
          }
          this.episodes = response.data.episodes;

          this.loading = false;
          this.setSearchCached(true);
        }
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      });
    }
  }
}
</script>