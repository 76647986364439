<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="$route.params.episodeId 
        ? { name: 'episode-details', params: { episodeId: $route.params.episodeId } }
        : { name: 'episodes-list' }"
    >
      {{ $t('activities.titles.create') }}
    </page-title>

    <page-tabs v-model="tab">

      <v-tab 
        @click="tab = 0"
      >
        {{ $t('activities.form.tabs.details') }}
      </v-tab>

      <v-tab 
        :disabled="!item.tab1Valid"
        @click="tab = 1"
      >
        {{ $t('activities.form.tabs.location') }}
      </v-tab>  

      <v-tab 
        :disabled="!item.tab1Valid || !item.tab2Valid"
        @click="tab = 2"
      >
        {{ $t('activities.actions.addTo') }}
      </v-tab>   

    </page-tabs>


    <v-tabs-items 
      v-model="tab"
      class="l-vertical__grow l-vertical"
    >
      <v-tab-item
        class="l-vertical__grow l-vertical"
        eager
      >
        <tab-1
          :item="item"
          @next="tab = 1"
        />
      </v-tab-item>

      <v-tab-item
        class="l-vertical__grow l-vertical"
        eager
      >
        <tab-2
          :item="item"
          :saving="saving"
          @back="tab = 0"
          @next="tab = 2"
          @submit="submit"
        />
      </v-tab-item>

      <v-tab-item
        class="l-vertical__grow l-vertical"
        eager
      >
        <tab-3
          :item="item"
          :saving="saving"
          @back="tab = 1"
          @next="submit"
        />
      </v-tab-item>

    </v-tabs-items>

    <geocode-service
      ref="geocodeService"
      @geocoded="onGeocoded"
    />

    <reverse-geocode-service
      ref="reverseGeocodeService"
      @reverseGeocoded="onReverseGeocoded" 
    />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy';
import GeocodeService from '@/modules/activities/components/GeocodeService.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ReverseGeocodeService from '@/modules/activities/components/ReverseGeocodeService.vue';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';

export default {
  components: {
    GeocodeService,
    PageTabs,
    PageTitle,
    ReverseGeocodeService, 
    Tab1,
    Tab2,
    Tab3,
  },
  data() {
    return {
      item: {
        episode: null
      },
      saving: false,
      tab: 0,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    })
  },
  watch: {
    'item.imageFile': function(value) {
      // if user has uploaded an image then get coordinates from GPS
      if (value) {
        this.$refs.reverseGeocodeService.start();
      }
    }
  },
  created() {
    // default activity values
    this.item = {
      city: this.user.city,
      country: this.user.idCountry ? { id: this.user.idCountry } : null,
      date: this.$moment().format('YYYY-MM-DD'),
      privacy: ActivityPrivacy.VISIBLE,
      province: this.user.idProvince ? { id: this.user.idProvince } : null,
      time: this.$moment().format('kk:mm')
    };

    // load episode
    if (this.$route.params.episodeId) {
      this.getEpisode(this.$route.params.episodeId)
      .then(response => {
        this.episode = response.data.episode;
        this.item.date = this.$helperFunctions.fixApiDate(this.episode.date);
      });
    }

    // load countries
    this.getCountries()
    .then(response => {
      this.countries = response.data;
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      createActivity: 'activities/createActivity',
      getCountries: 'user/getCountries',
      getEpisode: 'episodes/getEpisode',
      uploadFile: 'files/uploadFile'
    }),
    submit() {
      if (!this.item.tab1Valid || !this.item.tab2Valid || !this.item.tab3Valid) {
        return false;
      }

      this.saving = true;

      if (!this.item.latitude || !this.item.longitude) {
        return this.$refs.geocodeService.geocode(
          this.item.address, 
          this.item.city, 
          this.item.province ? this.item.province.name : '', 
          this.item.country ? this.item.country.name : ''
        );
      }
      this.save();
    },
    onGeocoded({ latitude, longitude }) {
      this.item.latitude = latitude;
      this.item.longitude = longitude;
      this.save();
    },
    onReverseGeocoded(result) {
      console.log('onReverseGeocoded', result);
      this.item.latitude = result.latitude;
      this.item.longitude = result.longitude;
      this.item.address = result.address;
      this.item.city = result.city;
      this.item.province = null;

      // find country by name
      this.item.country = null;
      if (this.countries) {
        this.item.country = this.countries.find(item => item.name == result.country);
      }
    },
    async save() {
      try {
        const episodeId = this.item.episode.id;

        const data = {
          name: this.item.name,
          establishment: this.item.establishment,
          address: this.item.address,
          country: this.item.country ? this.item.country.id : null,
          description: this.item.description,
          province: this.item.province ? this.item.province.id : null,
          city: this.item.city,
          date: this.item.date,
          time: this.item.time,
          privacy: this.item.privacy,
          activityGroup: this.item.activityGroup.id,
          activityType: this.item.activityType.id,
          imageFile: this.item.imageFile || undefined,
          latitude: this.item.latitude,
          longitude: this.item.longitude
        }

        let response = await this.createActivity({ 
          episodeId: episodeId,
          data: data
        });
        const activity = response.data.activity;

        // upload activity image to gallery
        if (this.item.imageFile) {
          await this.uploadFile({
            activityId: activity.id,
            data: {
              privacy: activity.privacy,
              date: this.$helperFunctions.fixApiDate(activity.date),
              time: this.$helperFunctions.fixApiTime(activity.time),
              description: activity.name,
              activity: activity.id,
              filenameFile: this.item.imageFile,
              type: 'photo'
            }
          });
        }

        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        // redirect to activity details
        this.$router.push({ name: 'activity-details', params: { 
          episodeId: episodeId,
          activityId: response.data.activity.id 
        }});
      }
      catch(error) {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      };
        
      this.saving = false;
    }
  }
}
</script>


