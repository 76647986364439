<template>
  <div>
    <div class="d-flex">
      <div class="flex-shrink-1 mr-2">
        <strong>{{ $t('user.statistics.spaceTitle') }}</strong>
      </div>
      <div 
        v-show="!loading && !loadingSubscription"
        class="flex-grow-1 text-right"
      >
        {{ $t('user.statistics.available') }} 
        <span class="primary--text">{{ $helperFunctions.formatFileSize(availableSpace, 2) }}</span>
        {{ $t('user.statistics.of') }} {{ $helperFunctions.formatFileSize(totalSpace) }}
      </div>
    </div>

    <div class="my-2">
      <v-progress-linear
        class="progress-linear--border"
        rounded
        height="10"
        background-color="transparent"
        color="primary"
        :value="percentageUsed"
        :indeterminate="loading || loadingSubscription"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SpaceLimit from '@/modules/user/model/SpaceLimit';
import SubscritpionModel from '@/modules/subscription/model/SubscriptionModel.js';

export default {
  data () {
    return {
      loading: false,
      loadingSubscription: false,
      statistics: {},
      subscription: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    availableSpace() {
      return (this.totalSpace < this.usedSpaceTotal) ? 0 : (this.totalSpace - this.usedSpaceTotal);
    },
    percentageUsed() {
      return (this.usedSpaceTotal / this.totalSpace) * 100
    },
    subscriptionIsValid() {
      return SubscritpionModel.subscriptionIsValid(this.subscription);
    },
    totalSpace() {
      // convert from GB to B
      return this.$helperFunctions.gigaBytesToBytes(this.subscriptionIsValid ? SpaceLimit.subscribed : SpaceLimit.notSubscribed);
    },
    usedSpaceTotal() {
      return this.statistics.space_pictures + this.statistics.space_videos + this.statistics.space_soft_deleted;
    }
  },
  created() {
    this.loadStatistics();
    this.loadSubscription();
  },
  methods: {
    ...mapActions({
      getStatistics: 'user/getStatistics',
      getSubscription: 'subscription/getSubscription',
    }),
    loadStatistics() {
      this.loading = true;
      this.getStatistics(this.user.id)
      .then(response => {
        this.statistics = response.data.statistics;
        this.loading = false;
      });
    },
    loadSubscription() {
      this.loadingSubscription = true;
      this.getSubscription()
      .then(response => {
        this.subscription = response.data.subscription;
        this.loadingSubscription = false;
      });
    },
  }
}
</script>
<style scoped>
.progress-linear--border{
  border:1px solid var(--v-primary-base) !important;
}
</style>

