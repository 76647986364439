<template>
  <v-form
    ref="form"
    class="new-message"
    @keypress.native.enter.exact="send"
  >
    <v-textarea
      v-model="message"
      auto-grow
      filled
      rounded
      rows="1"
      background-color="transparent"
      dark
      dense
      :placeholder="$t('chat.form.label.message')"
      hide-details
      :readonly="saving"
      maxlength="255"
    ></v-textarea>
    <v-btn 
      icon 
      color="primary"
      :loading="saving"
      title="Enviar"
      @click="send"
    >
      <v-icon>mdi-send</v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      message: '',
      saving: false
    }
  },
  methods: {    
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      createMessage: 'chat/createMessage'
    }),
    send() {
      this.message = this.message.trim();
      if (!this.message) {
        return;
      }

      this.saving = true;

      const data = {
        text: this.message,
      }

      this.createMessage({ 
        episodeId: this.$route.params.episodeId, 
        data 
      })
      .then(response => {
        this.message = '';
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>

<style scoped>
.new-message {
  display: flex;
  align-items: flex-end;
  background-color: rgb(232, 232, 232);
  color: var(--color-text--dark);
  border-radius: 21px;
}
/deep/ .theme--dark.v-input.v-textarea textarea {
  margin-top: 5px !important;
  color: var(--color-text--dark) !important;
}
/deep/ .theme--dark.v-input.v-textarea textarea::placeholder {
  color: var(--color-text--dark) !important;
}
</style>