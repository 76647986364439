<template>
  <v-row v-if="item">
    <v-col class="flex-grow-0 pb-1">
      <router-link
        :to="{ name: 'contact-details', params: { id: item.data.id } }"
      >
        <user-avatar 
          :src="item.data.urlAvatar"
          :alt="item.data.alias"
          class="user-item__avatar"
        />
      </router-link>
    </v-col>
    <v-col class="flex-grow-1 pb-1 pl-1 pt-1 d-flex flex-column justify-center">
      <div
        v-if="!item.viewed" 
        class="overline small error--text mb-1"
      >{{ $t('notifications.texts.new') }}</div>
      <div class="overline small">{{ $moment(item.createdAt).format($t('locale.formatDateTime')) }}</div>
    </v-col>
  </v-row>
</template>

<script>
import UserAvatar from '@/modules/user/components/UserAvatar.vue';

export default {
  components: {
    UserAvatar
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
}
</script>