<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ episode ? episode.title : '' }}
      <template
        v-if="episode"
        slot="buttons"
      >
        <file-selection-toolbar
          v-if="episode.rol != roles.COMMENTATOR"
          ref="itemSelectionToolbar"
          :files="files"
          @success="loadFiles"
        />

        <list-layout-button 
          :layout="layout" 
          @change="layout = $event" 
        />

        <filter-menu @change="filters = $event" />

        <sort-menu @change="sortBy = $event" />
        
        <app-fab 
          v-if="(episode.rol != roles.COMMENTATOR) && (!$refs.itemSelectionToolbar || !$refs.itemSelectionToolbar.selectionEnabled)"
          add-activity
          add-files
          add-notes
        />
      </template>
    </page-title>

    <episode-tabs
      :episode="episode"
      :keyboard-navigation="!(openFile && openFile.id)"
    />

    <progress-linear :loading="loading" />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
        
      <v-container v-if="!loading && files && !files.length">
        <v-alert type="info">
          {{ $t('files.texts.noElements') }}
        </v-alert>
      </v-container>

      <v-container v-if="!loading && files && files.length && !filesFilteredSorted.length">
        <v-alert type="info">
          {{ $t('texts.noElementsMatching') }}
        </v-alert>
      </v-container>

      <file-list :layout="layout">
        <file-list-col
          v-for="file in filesFilteredSorted"
          :key="file.id"
          :layout="layout" 
        >
          <file-list-item
            :file="file"
            :selection-enabled="$refs.itemSelectionToolbar && $refs.itemSelectionToolbar.selectionEnabled"
            :layout="layout" 
            :episode-title="episode ? episode.title : null"
            :episode-role="episode ? episode.rol : null"
            favorite-button
            @click="openFile = file"
          />
        </file-list-col>
      </file-list>

    </div>

    <file-details-modal
      :files="filesFilteredSorted"
      :file="openFile"
      :open="!!(openFile && openFile.id)"
      show-assign-as-episode-image
      :episode-role="episode ? episode.rol : null"
      @close="openFile = null"
      @deleted="openFile = null; loadFiles()"
    />

    <menu-bottom />    

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs.vue';
import FileDetailsModal from '@/modules/files/components/FileDetailsModal/FileDetailsModal.vue';
import FileList from '@/modules/files/components/FileList/FileList.vue';
import FileListCol from '@/modules/files/components/FileListCol.vue';
import FileListItem from '@/modules/files/components/FileListItem/FileListItem.vue';
import FilePrivacy from '@/modules/files/model/FilePrivacy.js';
import FileTypes from '@/modules/files/model/FileTypes.js';
import FilterMenu from '@/modules/files/components/FilterMenu';
import FileSelectionToolbar from '@/modules/files/components/FileSelectionToolbar/FileSelectionToolbar.vue';
import ListLayoutButton from '@/modules/files/components/ListLayoutButton.vue';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ProgressLinear from '@/components/util/ProgressLinear';
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import SortMenu from '@/modules/files/components/SortMenu.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    AppFab,
    EpisodeTabs,
    FileDetailsModal,
    FileList,
    FileListCol,
    FileListItem,
    FilterMenu,
    FileSelectionToolbar,
    ListLayoutButton,
    MenuBottom,
    PageTitle,
    ProgressLinear,
    SortMenu 
  },
  mixins: [statusTap],
  data() {
    return {
      files: null,
      filters: { 
        favorites: false,
        type: 'all',
        privacy: 'all'
      },
      layout: 'thumbnail',
      loading: false,
      openFile: null,
      sortBy: 'recent',
      roles: roles
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
      user: 'user/user'
    }),
    filesFiltered() {
      if (!this.files) {
        return [];
      }
      return this.files.filter(item => {
        // filter by type
        switch (this.filters.type) {
          case 'all':
            break;
          case 'photo':
            if (item.type != FileTypes.PHOTO) {
              return false;
            }
            break;
          case 'video':
            if (item.type != FileTypes.VIDEO) {
              return false;
            }
            break;
        }

        // filter by privacy
        switch (this.filters.privacy) {
          case 'all':
            break;
          case 'mine':
            if (item.user.id != this.user.id) {
              return false;
            }
            break;
          case 'minePublic':
            if ((item.user.id != this.user.id) || (item.privacy != FilePrivacy.VISIBLE)) {
              return false;
            }
            break;
          case 'minePrivate':
            if ((item.user.id != this.user.id) || (item.privacy != FilePrivacy.PRIVATE)) {
              return false;
            }
            break;
          case 'others':
            if (item.user.id == this.user.id) {
              return false;
            }
            break;
        }

        // filter by favorites
        switch (this.filters.favorites) {
          case true:
            if (!item.isFavorite) {
              return false;
            }
            break;
        }

        return true;
      });
    },
    filesFilteredSorted() {
      if (!this.filesFiltered) {
        return [];
      }
      let files = [...this.filesFiltered];
      switch (this.sortBy) {
        case 'recent':
          files.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'date':
          files.sort((a, b) => (a.date + a.time + a.id) < (b.date + b.time + b.id) ? -1 : 1);       
          break;       
        case 'title':
          files.sort((a, b) => (a.description || '').localeCompare(b.description || ''));       
          break;
        case 'owner':
          files.sort((a, b) => (a.user.alias || '').toString().localeCompare(b.user.alias || ''));      
          break;
      }
      return files;
    }
  },
  created() {
    this.updateAccessFilesEpisode(this.$route.params.episodeId);

    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    this.loadFiles();
  },
  methods: {
    ...mapActions({
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
      getFiles: 'episodes/getFiles',
      updateAccessFilesEpisode: 'episodes/updateAccessFilesEpisode'
    }),
    loadFiles() {
      this.loading = true;
      this.getFiles({ episodeId: this.$route.params.episodeId })
      .then(response => {
        // add reactive field "selected"
        let i;
        for (i in response.data.activity_files) {
          response.data.activity_files[i].selected = false;
        }
        this.files = response.data.activity_files;
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
.episode-files-page {
  position: relative;
}
</style>

