<template>
  <div>
    <v-menu 
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          small
          class="primary"
          v-on="on"
          @click.stop.prevent
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">

        <accept-dialog
          :transfer="transfer"
          @saved="$emit('saved')"
        /> 

        <reject-dialog
          :transfer="transfer"
          @saved="$emit('saved')"
        /> 

      </v-list>
    </v-menu>
  </div>
</template>

<script>
import AcceptDialog from './AcceptDialog.vue';
import RejectDialog from './RejectDialog.vue';

export default {
  components: {
    AcceptDialog,
    RejectDialog
  },
  props: {
    transfer: {
      type: Object,
      default: null
    }
  }
}
</script>