<template>
  <div>

    <div v-if="notices && noticesFilteredSorted.length">
      <notice-list-item
        v-for="notice in noticesFilteredSorted"
        :key="notice.id"
        :item="notice"
      />
    </div>

    <v-alert 
      v-if="notices && !noticesFinished.length"
      type="info"
    >
      {{ $t('notices.texts.noFinishedNotices') }}
    </v-alert>

    <v-alert 
      v-if="notices && noticesFinished.length && !noticesFilteredSorted.length"
      type="info"
    >
      {{ $t('texts.noElementsMatching') }}
    </v-alert>

  </div>
</template>

<script>
import NoticeListItem from '@/modules/notices/pages/NoticeListPage/NoticeListItem/NoticeListItem.vue';

export default {
  components: {
    NoticeListItem
  },
  props: {
    notices: {
      type: Array,
      default: null
    },
    sortBy: {
      type: String,
      default: null
    },
    filters: {
      type: Object,
      default: null
    },
  },
  computed: {
    noticesFinished() {
      if (!this.notices) {
        return [];
      }
      return this.notices.filter(item => {
        return item.finished;
      });
    },
    noticesFiltered() {
      if (!this.noticesFinished) {
        return [];
      }
      return this.noticesFinished.filter(item => {

        // filter by lang
        if (!this.filters.langsAll) {
          if (!this.filters.langs[item.lang]) {
            return false;
          }
        }

        // filter by platform
        if (!this.filters.platformsAll) {
          let match = false;
          for (let platform in this.filters.platforms) {
            if (this.filters.platforms[platform] && item[platform]) {
              match = true;
              break;
            }
          }
          if (!match) {
            return false;
          }
        }

        return true;
      });
    },
    noticesFilteredSorted() {
      if (!this.noticesFiltered) {
        return [];
      }
      let notices = [...this.noticesFiltered];
      switch (this.sortBy) {      
        case 'date':
          notices.sort((a, b) => a.publishDate < b.publishDate ? 1 : -1);        
          break;       
        case 'title':
          notices.sort((a, b) => (a.title || '').toString().localeCompare(b.title || ''));      
          break;
      }
      return notices;
    },
  },
}
</script>
