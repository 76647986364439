<template>
  <div>
    <v-menu 
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          small
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">

        <v-list-item
          :to="{ name: 'expense-edit', params: {
            episodeId: $route.params.episodeId,
            expenseId: item.id
          } }"
        >
          <v-icon class="mr-3 primary--text">mdi-pencil-outline</v-icon> 
          {{ $t('actions.edit') }}
        </v-list-item> 

        <v-list-item 
          class="error--text"
          @click="$emit('delete')"
        >
          <v-icon class="mr-3">mdi-trash-can-outline</v-icon> 
          {{ $t('actions.delete') }}
        </v-list-item>

      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  }
}
</script>