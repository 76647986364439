import store from '@/store/index.js';
import router from '@/router/index.js';
import helperFunctions from '@/plugins/helperFunctions.js';

if (helperFunctions.isIos()) {
  document.addEventListener('deviceready', setupOpenwith, false);
}

function setupOpenwith() {

  // Increase verbosity if you need more logs
  // cordova.openwith.setVerbosity(cordova.openwith.DEBUG);

  // Initialize the plugin
  cordova.openwith.init(initSuccess, initError);

  function initSuccess()  { 
    //console.log('init success!'); 
  }
  function initError(err) { console.log('init failed: ' + err); }

  // Define your file handler
  cordova.openwith.addHandler(myHandler);

  async function myHandler(intent) {
    console.log('intent received');

    let uploadFiles = [];

    for (let item of intent.items) {
      // console.log(item);
      // console.log('  uti: ', item.uti);    // UTI. possible values: public.url, public.text or public.image
      // console.log('  type: ', item.type);   // Mime type. For example: "image/jpeg"
      // console.log('  data: ', item.data);   // shared data. For text, it is the shared text string. For files, the file's URL on the device file system. You can read it from the webview with cordova-plugin-file or window.Ionic.WebView.convertFileSrc.
      // console.log('  text: ', item.text);   // text to share alongside the item. as we don't allow user to enter text in native UI, in most cases this will be empty. However for sharing pages from Safari this might contain the title of the shared page.
      // console.log('  name: ', item.name);   // suggested name of the image. For instance: "IMG_0404.JPG"
      // console.log('  utis: ', item.utis);   // some optional additional info

      let file = await itemToFile(item);
      // console.log('file: ', file);

      let fileObject = {
        file: file,
        fileObject: true,
        name: item.name,
        type: 'image/jpeg',
        size: file.size,
        uploaded: false,
        uploading: false
      }
      uploadFiles.push(fileObject);
    }

    store.commit('files/setUploadFiles' , uploadFiles);
    router.push({ name: 'file-upload' });
  }
}

function itemToFile(item) {
  return new Promise((resolve, reject) => {
    let path = item.data;
    window.resolveLocalFileSystemURL(path, (fileEntry) => {
      console.log(fileEntry);

      fileEntry.file(file => {
        var reader = new FileReader();
 
        reader.onloadend = evt => {
          // Create a blob based on the FileReader "result", which we asked to be retrieved as an ArrayBuffer
          let file = new Blob([new Uint8Array(evt.target.result)], { type: 'image/jpeg' });
          file.name = item.name;
          file.lastModifiedDate = new Date();
          resolve(file);
        };
    
        // Read the file as an ArrayBuffer
        reader.readAsArrayBuffer(file);
      });
    }, (err) => {
      console.log('error: ', err);
      reject(err);
    });
  });
}


