<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('titles.faq') }}
    </page-title>
    
    <page-tabs v-model="tab">
      <v-tab @click="tab = 0">
        {{ $t('titles.faqPrivacy') }}
      </v-tab>
      <v-tab @click="tab = 1">
        {{ $t('titles.faqCapabilities') }}
      </v-tab>
      <v-tab @click="tab = 2">
        {{ $t('titles.faqFunctioning') }}
      </v-tab>
    </page-tabs>

    <v-tabs-items 
      v-model="tab"
      class="l-vertical__grow l-vertical"
    >
      <v-tab-item class="l-vertical__grow l-vertical">
        <div class="l-vertical__scroll">
          <v-container v-html="$t('faqPrivacy')"></v-container>
        </div>
      </v-tab-item>
      <v-tab-item class="l-vertical__grow l-vertical">
        <div class="l-vertical__scroll">
          <v-container v-html="$t('faqCapabilities')"></v-container>
        </div>
      </v-tab-item>
      <v-tab-item class="l-vertical__grow l-vertical">
        <div class="l-vertical__scroll">
          <v-container v-html="$t('faqFunctioning')"></v-container>
        </div>
      </v-tab-item>
    </v-tabs-items>
      
    <menu-bottom />
  </div>
</template>

<script>
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    MenuBottom,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      tab: 0
    }
  },
};
</script>
