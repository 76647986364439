<template>
  <div 
    class="m-img"
    :class="inline ? 'm-img--inline' : ''"
    :style="{ 
      width: width ? width + 'px' : 'auto',
      height: height ? height + 'px' : 'auto',
      maxHeight: maxHeight ? maxHeight + 'px' : 'none',
      minHeight: minHeight + 'px',
      minWidth: minWidth + 'px',
      backgroundColor,
      backgroundImage: cover ? 'url(' + src + ')' : '',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    }"
  >
    <template
      v-if="aspectRatio && !height"
    >
      <img
        v-if="!cover"
        v-lazy="src"
        :alt="alt"
        class="m-img__img--aspect-ratio"
        :style="{ 
          maxHeight: maxHeight ? maxHeight + 'px' : '100%'
        }"
      >
      <div 
        class="m-img__spacer"
        :style="'padding-bottom: ' + 1 / aspectRatio * 100 + '%'"
      ></div>
    </template>

    <img
      v-else-if="!cover"
      v-lazy="src"
      :alt="alt"
      class="m-img__img"
      :style="{ 
        maxHeight: maxHeight ? maxHeight + 'px' : '100%'
      }"
    >
  </div>
</template>

<script>
export default {
  name: 'MImg',
  props: {
    src: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: ''
    },
    aspectRatio: {
      type: [Number, String],
      default: null
    },
    width: {
      type: [Number, String],
      default: null
    },
    height: {
      type: [Number, String],
      default: null
    },
    maxHeight: {
      type: [Number, String],
      default: null
    },
    minHeight: {
      type: [Number, String],
      default: 50
    },
    minWidth: {
      type: [Number, String],
      default: 50
    },
    backgroundColor: {
      type: String,
      default: '#EDEAE9'
    },
    cover: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style scoped>
.m-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.m-img--inline {
  display: inline-flex;
}
.m-img__img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}
.m-img__img--aspect-ratio {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
}
.m-img__spacer {
  height: 0;
}
</style>

