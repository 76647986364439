<template>
  <div 
    v-if="$route.name != 'user-login'"
    class="header" 
  >

    <app-menu v-if="showUser" />

    <v-container class="header__container">

      <router-link 
        :to="{ name: 'episodes-list' }"
        class="logo__link"
        @click.native="clearAlivePages"
      >
        Timlapp
      </router-link>

      <search-field 
        v-if="$vuetify.breakpoint.mdAndUp && showSearch"
        class="header__search-input" 
      />

      <v-btn
        v-if="$vuetify.breakpoint.smAndDown && showSearch"
        icon
        class="header__search-button" 
        :title="$t('actions.search')"
        :to="{ name: 'search' }"
        height="30"
        width="30"
        @click.native="clearAlivePages"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      
      <notifications-button 
        v-if="showSearch"
        class="header__notifications" 
      />
      
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { eventBus } from '@/eventBus.js';
import AppMenu from '../AppMenu/AppMenu';
import NotificationsButton from '@/modules/notifications/components/NotificationsButton';
import SearchField from './SearchField';

export default {
  components: {
    AppMenu,
    NotificationsButton,
    SearchField
  },
  props: {
    onlyLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    showUser() {
      return !!this.user
        && !this.onlyLogo
        && (this.$route.name != 'user-register')
        && (this.$route.name != 'user-reset-password') 
        && (this.$route.name != 'user-new-password');
    },
    showSearch() {
      return !!this.user
        && !this.onlyLogo
        && (this.$route.name != 'user-register')
        && (this.$route.name != 'user-reset-password') 
        && (this.$route.name != 'user-new-password')
        && (this.$route.name != 'delete-account');
    }
  },
  methods: {
    clearAlivePages() {
      eventBus.$emit('clearAlivePages');
    },
  },
}
</script>

<style scoped>
.header {
  height: 100px;
  transition: all 0.35s ease-out;
}
.header__container {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  z-index: 5;
}
.logo__link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1;
}
.header__search-input {
  position: absolute;
  top: 18px;
  right: 90px;
}
.header__notifications {
  position: absolute;
  z-index: 99;
  top: 28px;
  right: 38px;
}

/* mobile */
@media (max-width: 1024px) {
  .header {
    height: 42px;
  }
  .logo__link {
    top: 0%;
    transform: translate(-50%, 0%);
    font-size: 21px;
  }  
  .header__notifications {
    top: 6px;
    right: 16px;
  }
  .header__search-button {
    position: absolute;
    top: 6px;
    right: 51px;
  }
}
</style>
