<template>
  <div class="generic-list-item">
    <div class="generic-list-item__main d-flex pb-2">
      <div class="flex-grow-0 mr-2">
        <img
          v-lazy="imageUrl"
          class="item__image"
          alt=""
        >
      </div>
      <div class="flex-grow-1">
        <div class="body-2 mb-1">{{ item.title }}</div>
        <div class="overline small mb-1">{{ $t('collaborators.model.role.' + item.rol) }}</div>
        <div class="overline small">{{ $moment(item.date).format($t('locale.formatDate')) }}</div>
      </div>
    </div> 
        
    <div class="generic-list-item__buttons">
      <v-btn 
        icon
        small
        color="primary"
        :to="{ name: 'episode-details', params: {
          episodeId: item.id
        } }"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageUrl() {
      return (this.item && this.item.urlImageThumbnails) 
        ? this.item.urlImageThumbnails.medium_ + '?bearer=' + this.apiToken 
        : 'img/placeholder/episode-list.png';
    }
  },
}
</script>

<style scoped>
.item__image {
  display: block;
  width: 70px;
  height: 70px;
  object-fit: scale-down;
}
.item__image[lazy=loaded] {
  object-fit: cover;
}
</style>