<template>
  <span></span>
</template>

<script>
/**
 * GEOCODE
 * convert address to coordinates
 */ 
import { mapActions, mapGetters, mapMutations } from 'vuex';
import GoogleMapsApiLoader from "google-maps-api-loader";

export default { 
  methods: {
    ...mapActions({
      incrementGoogleApiCounter: 'user/incrementGoogleApiCounter'
    }),
    async geocode(address, city, province, country) {
      address = address ? address + ', ' : '';
      if (city) {
        address += city + ', ';
      }
      if (province) {
        address += province + ', ';
      }
      if (country) {
        address += country;
      } 

      // load Google Maps API
      const google = await GoogleMapsApiLoader({ 
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        language: this.$i18n.locale
      });

      // Geocoding: address >> coordinates
      const geocoder = new google.maps.Geocoder();
      const params = {
        address: address ? address : '',
        language: this.$i18n.locale
      };
      geocoder.geocode(params, (results, status) => {
        if (status === "OK") {
          let result = {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          };
          this.$emit('geocoded', result);
        } 
        else {
          console.log("Geocoder failed due to: " + status);
          this.$emit('geocoded', {});
        }
      });

      this.incrementGoogleApiCounter();
    }
  }
}
</script>