<template>
  <div>
    <v-menu
      v-model="expanded"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          ref="textField"
          :value="value ? $moment(value).format($t('locale.formatDate')) : ''"
          return-masked-value
          :append-icon="prepend ? null : 'mdi-calendar'"
          :prepend-icon="prepend ? 'mdi-calendar' : null"
          :rules="rules ? rules : (required ? [$validationRules.required] : [])"
          :disabled="disabled"
          readonly
          v-bind="$attrs"
          v-on="on"
          @change="$emit('change', $event ? $moment($event, $t('locale.formatDate')).format('YYYY-MM-DD') : null)"
          @click="expanded = true"
          @click:append="expanded = true"
          @click:prepend="expanded = true"
          @click:clear="$emit('change', null)"
        />
      </template>
      <v-date-picker
        first-day-of-week="1"
        :value="value ? $moment(value).format('YYYY-MM-DD') : null"
        no-title
        scrollable
        :locale="$i18n.locale"
        :disabled="disabled"
        :min="min"
        :max="max"
        @input="$emit('change', $event); expanded = false"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: null
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    }, 
    prepend: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    valid() {
      return this.$refs.textField.valid;
    }
  },
  created() {
    /* fix date format */
    if (this.value) {
      this.$emit('change', this.$helperFunctions.fixApiDate(this.value));
    }
  }
};
</script>