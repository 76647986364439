import NoticeAddPage from '@/modules/notices/pages/NoticeAddPage.vue';
import NoticeEditPage from '@/modules/notices/pages/NoticeEditPage.vue';
import NoticeListPage from '@/modules/notices/pages/NoticeListPage/NoticeListPage.vue';

export default [
  {
    path: '/notices',
    name: 'notice-list',
    component: NoticeListPage,
    meta: { requiresAdmin: true }
  },
  {
    path: '/add-notice',
    name: 'add-notice',
    component: NoticeAddPage,
    meta: { requiresAdmin: true }
  },
  {
    path: '/notice/:noticeId/edit',
    name: 'edit-notice',
    component: NoticeEditPage,
    meta: { requiresAdmin: true }
  }
]
