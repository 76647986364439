<template>
  <v-dialog
    v-model="isOpen"
    width="450"
  >
    <v-container class="px-6 py-5">
      {{ $t('files.dialogs.noPermission') }}
    </v-container>

    <hr>

    <v-container class="py-4 px-6">
      <v-btn
        outlined
        block
        small
        @click="close"
      >{{ $t('actions.close') }}</v-btn>
    </v-container>

  </v-dialog>
</template>

<script>
import { eventBus } from '@/eventBus.js';

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    // Listen to global event
    eventBus.$on('no-permisison-dialog-open', this.open);
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    }
  }
};
</script>
