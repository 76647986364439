import axios from 'axios';

export default {
  namespaced: true,
  state: {
    cachedActivity: null
  },
  getters: {
    cachedActivity(state) {
      return state.cachedActivity;
    },
  },
  mutations: {
    setCachedActivity: (state, activity) => {
      state.cachedActivity = activity;
    }
  },
  actions: {
    getActivities(context, episodeId) {
      return axios.get('/api/episode/' + episodeId + '/activities');
    },
    getActivity(context, { episodeId, activityId }) {
      return axios.get('/api/episode/' + episodeId + '/activity/' + activityId);
    },
    loadActivityToCache({ state, dispatch }, { episodeId, activityId }) {
      if (state.cachedActivity && state.cachedActivity.id != activityId) {
        state.cachedActivity = null;
      }
      // return promise and save data to cache
      return new Promise((resolve, reject) => {
        dispatch('getActivity', { episodeId, activityId })
        .then(response => {
          state.cachedActivity = response.data.activity;
          resolve(response);
        })
        .catch(error => {
          state.cachedActivity = null;
          reject(error);
        });
      });
    },
    createActivity(context, { episodeId, data }) {
      let formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
      
      return axios.post('/api/episode/' + episodeId + '/activity', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    },
    updateActivity(context, { episodeId, activityId, data }) {
      let formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }

      return axios.post('/api/episode/' + episodeId + '/activity/' + activityId, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    },
    deleteActivity(context, { episodeId, activityId }) {
      return axios.delete('/api/episode/' + episodeId + '/activity/' + activityId);
    }, 
    getActivityGroups() {
      return axios.get('/api/activity-groups');
    },
    getActivityTypes() {
      return axios.get('/api/activity-types');
    },
    getFiles(context, { activityId }) {
      return axios.get('/api/activity/' + activityId + '/files');
    },
    getAllActivities() {
      return axios.get('/api/activities');
    },
    downloadZip(context, { episodeId, activityId }) {
      return axios.get('/api/episode/' + episodeId + '/activity/' + activityId + '/download');
    },
    setNotAttended(context, { episodeId, activityId, notAttended }) {
      return axios.post('/api/episode/' + episodeId + '/activity/' + activityId + '/not-attended', { notAttended });
    },
    getFavoriteActivities() {
      return axios.get('/api/favorite-activities');
    },
    setFavoriteActivity(context, id) {
      return axios.post('/api/activity/' + id + '/favorite');
    },
    transferActivity(context, { toUserId, activityId }) {
      return axios.post('/api/transfer-activity', { toUserId, activityId });
    },
  }
};
