<template>
  <div>

    <upload-image
      v-model="item.imageFile"
      class="mt-2 mb-3"
      :url-image="item.urlFilename ? item.urlFilename + '?bearer=' + apiToken : ''"
      circle
      :text="$t('contacts.form.group.image')"
      @error="onFileError"
    />

    <v-text-field
      v-model="item.title"
      :label="$t('contacts.form.group.title') + '*'"
      :rules="[$validationRules.required]"
      type="text"
      maxlength="25"
    />

    <v-textarea
      v-model="item.description"
      :label="$t('contacts.form.group.description')"
      maxlength="250"
      auto-grow
      @keyup.stop
    />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UploadImage from "@/components/form/UploadImage/UploadImage.vue";

export default {
  components: {
    UploadImage
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken',
    })
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    onFileError(error) {
      this.addFlash({
        text: error,
        type: 'error'
      });
    }
  }
}
</script>