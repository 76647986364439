<template>
  <div class="l-vertical__grow l-vertical">

    <progress-linear :loading="loading" />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
      <v-container 
        v-if="notes.length"
        class="container--narrow"
      >   
        <note-list-item
          v-for="note in notesSorted"
          :key="note.id"
          :item="note"
          show-activity-link
          @modified="loadNotes"
        />
      </v-container>

      <v-container
        v-if="!notes.length && !loading" 
      >
        <v-alert
          text
          type="info"
        >
          {{ $t('favorites.texts.noData') }}
          <v-icon class="primary--text mt-n1">mdi-heart</v-icon>
          {{ $t('favorites.texts.noData2') }}
        </v-alert>
      </v-container>


    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NoteListItem from '@/modules/notes/components/NoteListItem/NoteListItem.vue';
import ProgressLinear from '@/components/util/ProgressLinear';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    NoteListItem,
    ProgressLinear,
  },
  mixins: [statusTap],
  props: {   
    sortBy: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      notes: [],
      loading: false,
    }
  },  
  computed: {
    notesSorted() {
      if (!this.notes) {
        return [];
      }
      let notes = [...this.notes];
      switch (this.sortBy) {
        case 'recent':
          notes.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'dateAsc':
          notes.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? -1 : 1);       
          break;        
        case 'dateDesc':
          notes.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? 1 : -1);       
          break;       
        case 'name':
          notes.sort((a, b) => (a.title || '').localeCompare(b.title || ''));      
          break;
      }
      return notes;
    }
  },
  created() {
    this.loadNotes();
  },
  methods: {
    ...mapActions({
      getFavoriteNotes: 'notes/getFavoriteNotes'
    }),
    loadNotes() {
      this.loading = true;

      this.getFavoriteNotes()
      .then(response => {
        if (response) {
          this.notes = response.data.notes;
          this.loading = false;
        }
      })
    }
  }
}
</script>
