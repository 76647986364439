<template>
  <page-tabs 
    v-bind="$attrs"
    :scroll-key="'episode-' + $route.params.episodeId"
  >
    
    <v-tab 
      :to="{ name: 'episode-details', params: { episodeId: $route.params.episodeId } }"
      exact
    >{{ $t('episodes.titles.episodeNav.activities') }}</v-tab>

    <v-tab 
      :to="{ name: 'episode-files', params: { episodeId: $route.params.episodeId } }"
    >
      <v-badge
        :value="newFilesCount > 0"
        :content="newFilesCount"
      >{{ $t('episodes.titles.episodeNav.gallery') }}</v-badge>
    </v-tab>

    <v-tab 
      :disabled="!episode || !episode.rol"
      :to="{ name: 'chat', params: { episodeId: $route.params.episodeId } }"
    >
      <v-badge
        :value="newMessagesCount > 0"
        :content="newMessagesCount"
      >{{ $t('episodes.titles.episodeNav.chat') }}</v-badge>
    </v-tab>  

    <v-tab 
      :disabled="!episode || !episode.rol"
      :to="{ name: 'collaborators-list', params: { episodeId: $route.params.episodeId } }"
    >{{ $t('episodes.titles.episodeNav.collaborators') }}</v-tab>  

    <v-tab 
      :to="{ name: 'episode-map', params: { episodeId: $route.params.episodeId } }"
    >{{ $t('episodes.titles.episodeNav.map') }}</v-tab>  

    <v-tab 
      :to="{ name: 'episode-notes', params: { episodeId: $route.params.episodeId }}"
    >{{ $t('activities.titles.nav.notes') }}</v-tab>

    <v-tab 
      :to="{ name: 'procedure-list', params: { episodeId: $route.params.episodeId } }"
    >{{ $t('episodes.titles.episodeNav.procedures') }}</v-tab>  

    <v-tab 
      :to="{ name: 'expenses-list', params: { episodeId: $route.params.episodeId } }"
    >{{ $t('episodes.titles.episodeNav.expenses') }}</v-tab>   

    <v-tab 
      :to="{ name: 'episode-statistics', params: { episodeId: $route.params.episodeId } }"
    >{{ $t('episodes.titles.episodeNav.statistics') }}</v-tab>  

    <v-tab 
      v-if="(episode && (episode.rol != roles.COMMENTATOR)) || $route.name == 'episode-accesses'"
      :to="{ name: 'episode-accesses', params: { episodeId: $route.params.episodeId } }"
      exact
    >{{ $t('episodes.titles.episodeNav.access') }}</v-tab>  

  </page-tabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    PageTabs
  },
  props: {
    episode: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      roles: roles
    }
  },
  computed: {
    ...mapGetters({
      newFilesCount: 'episodes/newFilesCount',
      newMessagesCount: 'episodes/newMessagesCount',
    })
  }
}
</script>
