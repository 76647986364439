<template>
  <v-menu
    v-model="expanded"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="textField"
        :value="value"
        :label="label"
        append-icon="mdi-clock-time-four-outline"
        :rules="rules ? rules : (required ? [$validationRules.required] : [])"
        readonly
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      :value="value"
      full-width
      format="24hr"
      @input="$emit('change', $event)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    valid() {
      return this.$refs.textField.valid;
    }
  }
};
</script>