<template>
  <div class="form-fields-2">

    <progress-linear :loading="loadingEpisodes" />

    <v-combobox
      v-if="!loadingEpisodes && episodes"
      v-model="item.episode"
      :label="$t('files.form.label.episode')"
      :items="episodes"
      item-text="title"
      item-value="id"
      :rules="[$validationRules.required]"
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
      @change="loadActivities"
    >
      <template v-slot:item="data">
        <episodes-dropdown-item :item="data.item" />
      </template>
      <template v-slot:selection="data">
        <episodes-dropdown-item 
          :key="data.item.id"
          :item="data.item" 
        />
      </template>
    </v-combobox>

    <progress-linear :loading="loadingActivities" />

    <v-combobox
      v-if="!loadingActivities && !loadingEpisodes && episodes && activities"
      v-model="item.activity"
      :label="$t('files.form.label.activity')"
      :items="activities"
      item-text="name"
      item-value="id"
      :rules="[$validationRules.required]"
      :validate-on-blur="false"
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
    >
      <template v-slot:item="data">
        <activities-dropdown-item :item="data.item" />
      </template>
      <template v-slot:selection="data">
        <activities-dropdown-item 
          :key="data.item.id"
          :item="data.item" 
        />
      </template>
    </v-combobox>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ActivitiesDropdownItem from '@/modules/activities/components/ActivitiesDropdownItem';
import EpisodesDropdownItem from '@/modules/episodes/components/EpisodesDropdownItem';
import ProgressLinear from '@/components/util/ProgressLinear';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    ActivitiesDropdownItem,
    EpisodesDropdownItem,
    ProgressLinear
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activities: null,
      episodes: null,
      loadingActivities: false,
      loadingEpisodes: false,
    }
  },
  created() {
    // load episodes
    this.loadingEpisodes = true;
    this.getEpisodes()
    .then(response => {
      this.episodes = response.data.episodes.filter(item => {
        return item.rol != roles.COMMENTATOR
      });
      this.loadingEpisodes = false;
      if (this.episodes) {
        if (this.$route.params.episodeId) {
          this.item.episode = this.episodes.find(item => item.id == this.$route.params.episodeId);
        }
        else {
          this.item.episode = this.episodes[0];
        }
        this.loadActivities();
      }
    });
  },  
  methods: {
    ...mapActions({
      getActivities: 'activities/getActivities',
      getEpisodes: 'episodes/getEpisodes'
    }),
    loadActivities() {
      this.activities = null;
      this.item.activity = null; 

      if (this.item.episode) {
        this.loadingActivities = true;
        this.getActivities(this.item.episode.id)
        .then(response => {
          this.activities = response.data.activities;
          this.loadingActivities = false;
          if (this.activities) {
            if (this.$route.params.activityId) {
              this.item.activity = this.activities.find(item => item.id == this.$route.params.activityId);
            }
            else {
              this.item.activity = this.activities[0];
            }
          }
        });
      }
    },
  }
}
</script>

<style scoped>
.form-fields-2 {
  min-height: 190px;
}
</style>