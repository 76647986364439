<template>  
  <div class="l-vertical__grow l-vertical">
    <page-title history-back>
      {{ $t('titles.modifyData') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('files.titles.information') }} 
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">
      <loading v-if="loading" />

      <v-container
        v-if="!loading"
        class="container--narrow"
      >
        <m-img
          v-if="item"
          class="file-thumb mb-4"
          :src="item.urlImageThumbnails ? item.urlImageThumbnails.medium_ + '?bearer=' + apiToken : ''"
          :aspect-ratio="item.dimensions && item.dimensions.length && Number(item.dimensions[0]) ? item.dimensions[0]/item.dimensions[1] : null"
        />

        <div v-if="item">
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
            @keyup.native.enter="onSubmit"
          >
            <form-fields
              :item="item"
            />
          </v-form>
        </div>

      </v-container>

      <div 
        v-if="!loading"
        class="l-vertical__sticky-footer--mobile"
      >
        <v-container class="container--narrow">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                @click="$router.back()"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                :disabled="!valid"
                class="primary"
                :loading="saving"
                @click="submit"
              >
                {{ $t('actions.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActivityTabs from '@/modules/activities/components/ActivityTabs';
import Loading from '@/components/util/Loading';
import FormFields from '@/modules/files/components/FormFields.vue';
import MImg from '@/components/util/MImg';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    ActivityTabs,
    FormFields,
    Loading,
    MImg,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: null,
      loading: true,
      saving: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    })
  },
  created() {
    // load file
    this.getFile({
      activityId: this.$route.params.activityId,
      fileId: this.$route.params.fileId
    })
    .then(response => {
      this.item = response.data.activity_file;

      // fix time format from API
      this.item.time = this.$helperFunctions.fixApiTime(this.item.time);
      this.loading = false;
    })
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getFile: 'files/getFile',
      updateFile: 'files/updateFile',
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        activity: this.$route.params.activityId,
        privacy: this.item.privacy,
        date: this.item.date,
        time: this.item.time,
        description: this.item.description
      }

      this.updateFile({
        activityId: this.$route.params.activityId,
        fileId: this.$route.params.fileId,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.$router.back();
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
      
    }
  }
}
</script>

<style scoped>
.file-thumb {
  height: 350px;
  background: var(--color-image-background);
}

/* mobile & tablet */
@media (max-width: 1024px) {
  .file-thumb {
    margin-left: -16px;
    margin-right: -16px;
  }
}
</style>
