<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ episode ? episode.title : '' }}
      <template 
        slot="buttons"
      >
        <filter-menu @change="filters = $event" />

        <sort-menu @change="sortBy = $event" />

        <app-fab 
          v-if="canEdit"
        >
          <template slot="list">
            <router-link 
              class="app-fab__menu-item"
              :to="{ name: 'procedure-add', params: { episodeId: $route.params.episodeId } }"
            >
              <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
                {{ $t('procedures.actions.addProcedure') }}
              </v-btn>
              <v-btn
                fab
                small
                class="primary"
              >
                <v-icon>mdi-text-box-plus-outline</v-icon>
              </v-btn>
            </router-link>

          </template>  
        </app-fab>

      </template>
    </page-title>

    <episode-tabs
      :episode="episode"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <loading v-if="loading" />

      <v-container 
        v-else
        class="container--narrow"
      >
        
        <v-alert 
          v-if="procedures && !procedures.length"
          type="info"
        >
          {{ $t('procedures.texts.noElements') }}
        </v-alert>

        <v-alert 
          v-if="procedures && procedures.length && !proceduresFilteredSorted.length"
          type="info"
        >
          {{ $t('texts.noElementsMatching') }}
        </v-alert>

        <procedure-list-item
          v-for="item in proceduresFilteredSorted"
          :key="item.id"
          :item="item"
          :show-menu="canEdit"
          @delete="deleteId = item.id"
        />
      </v-container>

    </div>
    
    <delete-dialog
      :item-id="deleteId"
      :episode-id="$route.params.episodeId"
      @close="deleteId = null"
      @success="loadProcedures()"
    />
    
    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import DeleteDialog from './DeleteDialog';
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs.vue';
import FilterMenu from './FilterMenu';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import ProcedureListItem from '@/modules/procedures/pages/ProcedureListPage/ProcedureListItem/ProcedureListItem.vue';
import ProcedureTypes from '@/modules/procedures/model/ProcedureTypes.js';
import PageTitle from "@/components/layout/PageTitle.vue";
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import SortMenu from './SortMenu.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    AppFab,
    DeleteDialog,
    EpisodeTabs,
    FilterMenu,
    Loading,
    MenuBottom,
    ProcedureListItem,
    PageTitle,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      deleteId: null,
      filters: { 
        cost: 'all',
        types: {},
        typesAll: true
      },
      procedures: null,
      loading: false,
      roles: roles,
      sortBy: 'recent'
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode'
    }),
    canEdit() {
      return this.episode && ((this.episode.rol == roles.CREATOR) || (this.episode.rol == roles.EDITOR));
    },
    proceduresFiltered() {
      if (!this.procedures) {
        return [];
      }
      return this.procedures.filter(item => {

        // filter by cost
        switch (this.filters.cost) {
          case 'all':
            break;
          case 'withCost':
            if (!item.cost) {
              return false;
            }
            break;
          case 'withoutCost':
            if (item.cost) {
              return false;
            }
            break;
        }

        // filter by type
        if (!this.filters.typesAll) {
          if (!this.filters.types[item.type]) {
            return false;
          }
        }

        return true;
      });
    },
    proceduresFilteredSorted() {
      if (!this.procedures) {
        return [];
      }
      let procedures = [...this.proceduresFiltered];
      switch (this.sortBy) {
        case 'recent':
          procedures.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'type':
          procedures.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);
          procedures.sort((a, b) => ProcedureTypes[a.type].sort < ProcedureTypes[b.type].sort ? -1 : 1);       
          break;       
      }
      return procedures;
    },
  },
  created() {
    // pre-select filter
    if (this.$route.query.type) {
      this.filters.typesAll = false;
      this.filters.types[this.$route.query.type] = true;
    }

    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    this.loadProcedures();
  },
  methods: {
    ...mapActions({
      getProcedures: 'procedures/getProcedures',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache'
    }),
    loadProcedures() {
      this.loading = true;
      this.getProcedures(this.$route.params.episodeId)
      .then(response => {
        this.procedures = response.data.procedures;
        this.loading = false;
      });
    }
  }
}
</script>
