<template>
  <div>
    <v-list-item 
      class="error--text"
      @click="onButtonClick"
    >
      <v-icon class="mr-3">mdi-trash-can-outline</v-icon> 
      {{ $t('actions.delete') }}
    </v-list-item>

    <v-dialog
      v-model="open"
      width="450"
    >
      <v-container class="py-3 px-6">
        <div class="subtitle-1 text-center mt-2 mb-6 error--text">
          {{ $t('files.dialogs.deleteFiles.title') }}
        </div>
        <div class="text-center mb-8">{{ $t('files.dialogs.deleteFiles.text') }}</div>
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="error"
              :loading="saving"
              block
              small
              @click="submit"
            >{{ $t('actions.delete') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      open: false,
      saving: false
    };
  },
  computed: {
    ...mapGetters({
      files: 'files/getSelectedFiles'
    }),
    filesIds() {
      return this.files.map(file => file.id);
    },
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      setSelectedFiles: 'files/setSelectedFiles'
    }),
    ...mapActions({
      deleteFiles: 'files/deleteFiles'
    }),
    submit() {
      this.saving = true;

      this.deleteFiles(this.filesIds)
      .then(() => {
        this.addFlash({
          text: this.$t("files.messages.success.files-deleted"),
          type: 'success'
        });
        this.setSelectedFiles(null);
        this.$emit('success');
        this.open = false;
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    },
    onButtonClick() {
      setTimeout(() => {
        this.open = true;
      }, 0);
    }
  }
};
</script>
