<template>
  <div class="transfer-list-item mb-4">
    <router-link 
      class="transfer-list-item__header px-3"
      :style="'background-image: url(' + imageUrl + ')'"
      :to="{ name: 'transfer-received-details', params: { transferId: item.id } }"
    >
      <div class="image-overlay"></div>

      <div class="text-right mb-5">
        <v-icon class="mt-3">mdi-arrow-right</v-icon>
      </div>
      <div class="d-flex align-center">
        <div class="flex-grow-1 subtitle-1 height-lines-1">{{ item.episode.title }}</div>
        <div class="flex-shrink-0 small">{{ $t('actions.seeMore') }}</div>
      </div>
    </router-link>

    <div class="d-flex pt-3 px-3">
      <div class="small flex-grow-1">
        {{ $t('transfers.texts.transferRequest') }}
        <template v-if="item.photosCount">
          {{ $tc('transfers.texts.transferRequestImages', item.photosCount, { number: item.photosCount }) }}
        </template>
        <template v-if="item.photosCount && item.moviesCount">{{ $t('and') }}</template>
        <template v-if="item.moviesCount">
          {{ $tc('transfers.texts.transferRequestVideos', item.moviesCount, { number: item.moviesCount }) }}
        </template>
      </div>
      <div class="flex-shrink-0">
        <strong>{{ $helperFunctions.formatFileSize(item.totalSizeInBytes, 1) }}</strong>
      </div>
    </div>

    <div class="d-flex pt-2 pb-3 px-3 align-end">
      <user-item
        :item="item.fromUser"
        small-text
        :height="36"
        class="flex-grow-1 small pt-0"
      />
      <actions-menu 
        class="flex-shrink-0 pl-3"
        :transfer="item"
        @saved="$emit('saved')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ActionsMenu from './ActionsMenu.vue';
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    ActionsMenu,
    UserItem
  },
  props: {
    item: {
      type: Object,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageUrl() {
      return (this.item && this.item.episode.urlImageThumbnails) 
        ? this.item.episode.urlImageThumbnails.small_ + '?bearer=' + this.apiToken 
        : 'img/placeholder/episode-list.png';
    },
  },
}
</script>

<style scoped>
.transfer-list-item {
  border-radius: 5px;
  background-color: var(--color-background--light);
  color: var(--color-text--dark);
  overflow: hidden;
}
.transfer-list-item__header {
  display: block;
  position: relative;
  background-color: var(--color-image-background);
  background-position: center;
  background-size: cover;
  height: 91px;
  color: white;
}
.transfer-list-item__header > * {
  position: relative;
  z-index: 1;
}
</style>