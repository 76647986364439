<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episode-details', params: { episodeId: $route.params.episodeId }}"
    >
      {{ $t('episodes.titles.episode') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('actions.edit') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">
      <v-container class="container--narrow">

        <loading v-if="loading" />

        <v-form
          v-if="!loading"
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >
          <form-fields
            :item="item"
          />
          
          <melody-combobox
            :item="item"
          />
        </v-form>
      </v-container>

      <div 
        v-if="!loading"
        class="l-vertical__sticky-footer--mobile"
      >
        <v-container class="container--narrow">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'episode-details', params: { episodeId: $route.params.episodeId }}"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from '../../components/FormFields.vue';
import Loading from '@/components/util/Loading';
import MelodyCombobox from '@/modules/melodies/components/MelodyCombobox/MelodyCombobox.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    Loading,
    MelodyCombobox,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {},
      loading: false,
      saving: false,
      valid: false
    }
  },
  created() {
    this.loading = true;
    this.getEpisode(this.$route.params.episodeId)
    .then(response => {
      this.item = response.data.episode;
      this.loading = false;
    })
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getActivities: 'activities/getActivities',
      getEpisode: 'episodes/getEpisode',
      updateEpisode: 'episodes/updateEpisode',
      uploadFile: 'files/uploadFile'
    }),
    async submit() {
      if (!this.$refs.form.validate() || !this.item.id) {
        return false;
      }

      this.saving = true;

      try {
        const data = {
          title: this.item.title,
          date: this.item.date,
          description: this.item.description,
          imageFile: this.item.imageFile || undefined,
          melody: this.item.melody ? this.item.melody.id : undefined
        };

        let response = await this.updateEpisode({
          id: this.item.id,
          data
        });

        // upload episode image to gallery
        if (this.item.imageFile) {
          // get latest activity
          response = await this.getActivities(this.item.id)
          const activities = response.data.activities;
          activities.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);
          const activity = activities[0];

          await this.uploadFile({
            activityId: activity.id,
            data: {
              privacy: activity.privacy,
              date: this.item.date,
              time: this.$helperFunctions.fixApiTime(activity.time),
              description: this.item.title,
              activity: activity.id,
              filenameFile: this.item.imageFile,
              type: 'photo'
            }
          });
        }

        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.$router.push({ name: 'episode-details', params: this.item.id });
      }
      catch(error) {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      }
        
      this.saving = false;
    }
  }
}
</script>
