import axios from 'axios';

export default {
  namespaced: true,
  state: {
    totalUsersCount: null,
  },
  getters: {
    totalUsersCount: state => {
      return state.totalUsersCount;
    }
  },
  mutations: {
    setTotalUsersCount: (state, count) => {
      state.totalUsersCount = count;
    },
  },
  actions: {
    getUsersCountSummary({ commit }) {
      return axios.get('/api/users-count/summary')        
        .then((response) => {
          commit('setTotalUsersCount', response.data.count.total);
          return response;
        });
    },
    getUsersCountByMonth(context, year){
      return axios.get('/api/users-count/' + year);
    },
    getUsersCountByDay(context, {year, month}){
      return axios.get('/api/users-count/' + year + '/' + month);
    },

    getGoogleApiCallsSummary(){
      return axios.get('/api/total-calls/summary');
    },
    getGoogleApiCallsByMonth(context, year){
      return axios.get('/api/total-calls/' + year);
    },
    getGoogleApiCallsByDay(context, {year, month}){
      return axios.get('/api/total-calls/' + year + '/' + month);
    },

    getSpaceSummary(){
      return axios.get('/api/total-space/summary');
    },
    getSpaceByMonth(context, year){
      return axios.get('/api/total-space/' + year);
    },
    getSpaceByDay(context, {year, month}){
      return axios.get('/api/total-space/' + year + '/' + month);
    },

    getSubscriptionsSummary(){
      return axios.get('/api/subscriptions-count/summary');
    },
    getSubscriptionsByMonth(context, year){
      return axios.get('/api/subscriptions-count/' + year);
    },
    getSubscriptionsByDay(context, {year, month}){
      return axios.get('/api/subscriptions-count/' + year + '/' + month);
    },
  


  }
}
