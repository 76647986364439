<template>
  <div class="items-total">
    <div>
      <span class="overline">{{ $t('dataUpload.titles.totalFiles') }}:</span> 
      <strong>
        {{ total }} 
        <span
          :class="{ 'error--text': !enoughSpace }"
        >
          ({{ $helperFunctions.formatFileSize(totalSize) }})
          <v-icon
            v-if="!enoughSpace"
            size="20"
          >mdi-alert-circle</v-icon> 
        </span>
      </strong>
    </div>
    <div>
      <span class="overline">{{ $t('dataUpload.titles.freeSpaceInTimlapp') }}:</span>&nbsp;
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="18"
      />
      <strong v-else>{{ $helperFunctions.formatFileSize(availableSpace, 0) }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    availableSpace: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    totalSize: {
      type: Number,
      default: 0,
    },
    enoughSpace: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    activities: {
      type: Array,
      default: null,
    },
  },
}
</script>

<style scoped>
.items-total {
  flex: 0 0 auto;
}
</style>
