<template>
  <div class="l-vertical__grow l-vertical">
    <page-title>
      {{ title }}
      <template slot="buttons">
        <melody-dialog 
          v-if="filter=='files'"
        />

        <filter-menu @change="filter = $event" />

        <sort-menu @change="sortBy = $event" />
        
        <app-fab 
          add-files
          add-episode
          add-activity
          add-notes
        />
      </template>
    </page-title>

    <episodes-tabs />

    
    <favorite-episodes-list 
      v-if="filter=='episodes'"
      :sort-by="sortBy"
    />
    
    <favorite-activities-list 
      v-if="filter=='activities'"
      :sort-by="sortBy"
    />
    
    <favorite-files-list 
      v-if="filter=='files'"
      :sort-by="sortBy"
    />
    
    <favorite-notes-list 
      v-if="filter=='notes'"
      :sort-by="sortBy"
    />

    <menu-bottom />
    
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import EpisodesTabs from '@/modules/episodes/components/EpisodesTabs';
import FavoriteActivitiesList from './FavoriteActivitiesList';
import FavoriteEpisodesList from './FavoriteEpisodesList';
import FavoriteFilesList from './FavoriteFilesList';
import FavoriteNotesList from './FavoriteNotesList';
import FilterMenu from './FilterMenu';
import MelodyDialog from './MelodyDialog';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle";
import SortMenu from '@/modules/episodes/components/EpisodesList/SortMenu';

export default {
  name: 'FavoritesPage',
  components: {
    AppFab,
    EpisodesTabs,
    FavoriteActivitiesList,
    FavoriteEpisodesList,
    FavoriteFilesList,
    FavoriteNotesList,
    FilterMenu,
    MelodyDialog,
    MenuBottom,
    SortMenu,
    PageTitle
  },
  data() {
    return {
      filter: 'episodes',
      sortBy: 'dateDesc',
    }
  },
  computed: {
    title() {
      switch (this.filter) {
        case 'episodes':
          return this.$t('episodes.titles.home');
        case 'activities':
          return this.$t('activities.titles.activities');
        case 'files':
          return this.$t('files.titles.gallery');
        case 'notes':
          return this.$t('notes.titles.blog');
      }
      return null;
    }
  }
}
</script>