<template>
  <v-select
    v-model="item.type"
    :label="$t('procedures.form.label.type')"
    :items="typeOptions"
    item-value="id"
    :placeholder="$t('procedures.form.placeholder.type')"
    required
    :rules="[$validationRules.required]"
  >
    <template v-slot:item="data">
      <v-icon class="mr-2">{{ data.item.icon }}</v-icon> {{ $t('procedures.model.types.' + data.item.id) }}
    </template>
    <template v-slot:selection="data">
      <v-icon class="my-2 mr-2">{{ data.item.icon }}</v-icon> {{ $t('procedures.model.types.' + data.item.id) }}
    </template>
  </v-select>
</template>

<script>
import ProcedureTypes from '@/modules/procedures/model/ProcedureTypes.js';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      typeOptions: Object.values(ProcedureTypes)
    }
  }
}
</script>
