<template>
  <div 
    v-if="item"
    class="episode-item"
  >
    <img 
      v-lazy="imageUrl"
      class="episode-item__img"
      alt=""
    >
    <div class="episode-item__name">
      {{ item.title }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageUrl() {
      return (this.item && this.item.urlImageThumbnails) 
        ? this.item.urlImageThumbnails.small_ + '?bearer=' + this.apiToken 
        : 'img/placeholder/episode-list.png';
    }
  },
};
</script>

<style scoped>
.episode-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.episode-item__img {
  width: 60px;
  height: 33px;
  margin: 8px 12px 9px 0; 
  object-fit: cover;
  object-position: center;
  background-color: var(--color-image-background);
}
.episode-item__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
}
</style>