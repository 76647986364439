<template>
  <div 
    class="search-panel"
    :class="{ 'search-panel--selected': filters.title }"
  >
    <div class="body-2 search-panel__header">
      {{ $t('search.titles.filtersGroup1') }}
    </div>
    <div>      
      <search-for-field 
        :value="searchFor"
        @input="$emit('update:searchFor', $event)"
      />
    </div>
  </div>
</template>

<script>
import SearchForField from './formFields/SearchForField';
import TitleField from './formFields/TitleField';

export default {
  components: {
    SearchForField,
    TitleField
  },
  props: {
    filters: {
      type: Object,
      default: null
    },    
    searchFor: {
      type: String,
      default: null
    },
  }
}
</script>