<template>
  <div class="user-field">
    <v-select
      v-model="item.user"
      :items="collaborators"
      item-text="title"
      item-value="id"
      return-object
      :rules="rules"
    >
      <template v-slot:item="data">
        <user-item 
          :item="data.item" 
          class="my-1"
          disable-modal
        />
      </template>
      <template v-slot:selection="data">
        <user-item 
          :key="data.item.id"
          :item="data.item"  
          class="my-2"
          disable-modal
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    UserItem
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    rules: {
      type: Array,
      default: null
    },
    collaborators: {
      type: Array,
      default: null
    },
  },
} 
</script>

<style scoped>
.user-field {
  min-height: 70px;
}
</style>