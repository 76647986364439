<template>
  <v-list-item
    v-if="$helperFunctions.isMobile()"
    class="share-item"
    @click="onClick"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      class="mr-3"
      size="28"
    />
    <v-icon 
      v-else
      class="mr-3 primary--text"
    >mdi-share-variant-outline</v-icon> 
    {{ $t('actions.share') }}
  </v-list-item>  
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { eventBus } from '@/eventBus.js';
import fileFunctions from '@/modules/files/functions/fileFunctions.js';

export default {
  props: {  
    file: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    canUseImage() {
      return this.file.canEdit || this.file.user.allowOthersUseMyPhotos;
    },
    tokenParameter() {
      return 'bearer=' + this.apiToken;
    },
  },
  activated() {
    console.log('ShareItem activated');
  },
  mounted() {
    console.log('ShareItem mounted');
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    onClick() {
      if (this.canUseImage) {
        this.loading = true;
        fileFunctions.shareFile(this.file, this.tokenParameter);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
      else {
        eventBus.$emit('no-permisison-dialog-open');
      }
    },
  }
}
</script>


<style scoped>
.share-item {
  min-width: 140px;
}
</style>