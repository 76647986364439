<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :title="$t('actions.sort')"
        class="mr-2"
        v-on="on"
      >
        <v-icon>mdi-swap-vertical</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">

      <v-list-item
        class="sort-menu__item"
        :class="sortBy == 'name' ? 'v-list-item--active' : ''"
        @click="$emit('update:sortBy', 'name'); $emit('update:sortDirection', 1)"
      >
        {{ $t('titles.sortBy.title') }}
        <v-icon 
          small 
          class="ml-2"
          :title="$t('titles.sortBy.ascending')"
        >mdi-arrow-up</v-icon>
      </v-list-item>

      <v-list-item
        class="sort-menu__item"
        :class="sortBy == 'itemType' ? 'v-list-item--active' : ''"
        @click="$emit('update:sortBy', 'itemType'); $emit('update:sortDirection', 1)"
      >
        {{ $t('titles.sortBy.type') }}
        <v-icon 
          small 
          class="ml-2"
          :title="$t('titles.sortBy.ascending')"
        >mdi-arrow-up</v-icon>
      </v-list-item>

      <v-list-item
        class="sort-menu__item"
        :class="sortBy == 'date' ? 'v-list-item--active' : ''"
        @click="$emit('update:sortBy', 'date'); $emit('update:sortDirection', 1)"
      >
        {{ $t('titles.sortBy.date') }}
        <v-icon 
          small 
          class="ml-2"
          :title="$t('titles.sortBy.ascending')"
        >mdi-arrow-up</v-icon>
      </v-list-item>

    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    sortBy: {
      type: String,
      default: null
    },
    sortDirection: {
      type: Number,
      default: 1
    }
  }
}
</script>