<template>
  <div 
    class="progress-linear" 
  >
    <v-progress-linear 
      v-if="loading"
      :indeterminate="true" 
      :color="grey ? 'grey' : 'primary'" 
      :height="grey ? 2 : 4" 
    />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    grey: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.progress-linear {
  position: relative;
  height: 0;
  overflow: visible;
}
.v-progress-linear {
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
</style>