<template>
  <div 
    class="chat-message"
    :class="{
      'chat-message--right': right,
      'chat-message--hide-user': hideUser,
      'chat-message--last-message': lastMessage,
    }"
  >        
    <user-avatar 
      v-if="!hideUser"
      :src="item.userDeleted ? null : item.user.urlAvatar" 
      class="message__avatar" 
      :size="right ? 16 : 35"
    />
    <div class="message__body">
      <div 
        v-if="!hideUser"
        class="message__data"
      >
        <strong class="message__name">
          {{ item.userDeleted ? $t('user.titles.userDeleted') : item.user.alias }}
        </strong>
      </div>
      <div 
        class="message__text dense--text"
        v-html="message"
      />
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/modules/user/components/UserAvatar.vue";
import linkifyHtml from 'linkify-html';

export default {
  components: {
    UserAvatar
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    right: {
      type: Boolean,
      default: false
    },
    hideUser: {
      type: Boolean,
      default: false
    },
    lastMessage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    message() {
      let message = this.item.text;
      message = this.$helperFunctions.stripTags(message);
      message = linkifyHtml(message, {
        defaultProtocol: 'https',
        target: "_blank"
      });
      // add hour
      message += ' <span class="chat-message__hour">' + this.$moment(this.item.createdAt).format('kk:mm') + '</span>';
      return message;
    }
  }
}
</script>

<style scoped>
.chat-message {
  font-size: 15px;
  margin-bottom: 6px;
  padding-left: 45px;
  position: relative;
}
.chat-message--hide-user {
  margin-top: 0;
}
.chat-message--right {
  padding-left: 0;
  padding-right: 23px;
  text-align: right;
}
.message__avatar {
  left: 0;
  top: 21px;
  position: absolute;
  z-index: 1;
}
.chat-message--right .message__avatar {
  left: auto;
  right: 0;
}
.message__data {
  justify-content: space-between;
  font-size: 10px;
  color: var(--color-text--darker);
  margin-bottom: 6px;
}
.message__name {
  margin-right: 6px;
  margin-left: 12px;
  text-align: left;
}
.message__text {
  display: inline-block;
  background-color: rgb(127, 127, 127);
  color: white;
  padding: 9px 12px;
  border-radius: 18px 18px 18px 4px;
  white-space: pre-wrap;
}
.chat-message--right .message__text {
  border-radius: 18px 18px 4px 18px;
  text-align: left;
}
.chat-message--hide-user .message__text {
  border-top-left-radius: 4px;
}
.chat-message--right.chat-message--hide-user .message__text {
  border-top-left-radius: 18px;
  border-top-right-radius: 4px;
}
.chat-message--last-message .message__text {
  border-bottom-left-radius: 18px;
}
.chat-message--right.chat-message--last-message .message__text {
  border-bottom-right-radius: 18px;
}
.message__text /deep/ a {
  color: blue !important;
}
.chat-message--right .message__text {
  background-color: rgb(232, 232, 232);
  color: var(--color-text--dark);
}
/deep/ .chat-message__hour {
  font-size: 10px;
  margin-left: 4px;
  float: right;
  margin-top: 6px;
}

/* mobile */
@media (max-width: 340px) {

  .message__data {
    font-size: 9px;
  }
  .message__name {
    margin-left: 0;
    text-align: left;
  }

}
</style>