<template>
  <v-list-item 
    :disabled="loading"
    class="download-item"
    @click.stop.prevent="onClick"
  >
    <div 
      v-if="loading"
      class="text-center flex-grow-1"
    >
      <v-progress-circular
        indeterminate
        :size="24"
        :width="3"
      />
    </div>
    <span v-else>
      <v-icon class="mr-3 primary--text">mdi-cloud-download-outline</v-icon> 
      {{ $t('actions.download') }}
    </span>
  </v-list-item>  
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {  
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    id() {
      return this.item ? this.item.id : [];
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      downloadMelodies: 'melodies/downloadMelodies'
    }),
    onClick() {
      this.loading = true;

      this.downloadMelodies([this.id])
      .then((response) => {
        this.$helperFunctions.downloadFile(response.data.note.link);
        this.$emit('close');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.loading = false;
      }); 
    }
  }
}
</script>

<style scoped>
.download-item {
  min-width: 140px;
}
</style>