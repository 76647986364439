import axios from 'axios';
import i18n from '@/plugins/i18n.js';
import helperFunctions from '@/plugins/helperFunctions.js';
import UAParser from 'ua-parser-js/dist/ua-parser.min.js';
import SpaceLimit from '@/modules/user/model/SpaceLimit';
import SubscritpionModel from '@/modules/subscription/model/SubscriptionModel.js';

const parser = new UAParser();
const userAgentResult = parser.getResult();

export default {
  namespaced: true,
  state: {
    apiToken: null,
    refreshToken: null,
    user: null
  },
  getters: {
    apiToken: state => {
      return state.apiToken;
    },
    user: state => {
      return state.user;
    }
  },
  mutations: {
    login: (state, { apiToken, refreshToken, user }) => {
      state.apiToken = apiToken;
      state.refreshToken = refreshToken;
      state.user = user;
    },
    refreshToken: (state, payload) => {
      state.apiToken = payload.apiToken;
      state.refreshToken = payload.refreshToken;
    },
    logout: state => {
      state.apiToken = null;
      state.refreshToken = null;
      state.user = null;
    },
    mutateUser: (state, user) => {
      state.user = user;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      const data = {
        '_username': email,
        '_password': password
      };

      try {
        let response = await axios.post('/api/login_check', data);

        if (response.data && response.data.token && response.data.user && response.data.user.id) {
          commit('login', {
            apiToken: response.data.token,
            refreshToken: response.data.refresh_token,
            user: response.data.user
          });
          i18n.locale = response.data.user.locale;
          localStorage.lang = response.data.user.locale;
          return response;
        }
        else {
          return Promise.reject();
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    refreshToken({ commit, state }) {
      return axios.post('/api/token/refresh', { refresh_token: state.refreshToken })
        .then(response => {
          if (response.status === 200) {
            commit('refreshToken', {
              apiToken: response.data.token,
              refreshToken: response.data.refresh_token
            });

            return response;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getCountries() {
      return axios.get('/api/countries');
    },
    getProvinces(context, countryId) {
      return axios.get('/api/provinces/' + countryId);
    },
    getUser(context, userId) {
      return axios.get('/api/user/' + userId);
    },
    registerUser({ commit }, data) {
      let formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      return axios.post(
        '/api/user-register', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
    },
    completeRegistration(context, token) {
      return axios.post('/api/user-complete-register/' + token);
    },
    updateUser({ commit }, data) {
      let formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      return axios.post(
        '/api/user-profile', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
    },
    updateConfiguration(context, data) {
      return axios.post('/api/user-configuration', data);
    },
    resetPassword(context, email) {
      return axios.post('/api/resetting/reset-password', { username: email });
    },
    changePassword(context, { token, password, passwordRepeat }) {
      let params = new URLSearchParams();
      params.append("fos_user_resetting_form[plainPassword][first]", password);
      params.append("fos_user_resetting_form[plainPassword][second]", passwordRepeat);
      return axios.post('/api/resetting/change-password/' + token, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    },
    changeLanguage(context, locale) {
      return axios.put('/api/user-locale', { locale: locale });
    },
    getStatistics(context, userId) {
      return axios.get('/api/statistics/' + userId);
    },    
    // all users statistics
    getTotalUsedSpace() {
      return axios.get('/api/total-space');
    },
    getUsersCount() {
      return axios.get('/api/users-count');
    },
    updateMessagingToken(context, messagingToken) {
      return axios.put('/api/user-messaging-token', { messagingToken: messagingToken });
    },
    incrementGoogleApiCounter() {
      return axios.post('/api/calls-to-google');
    },
    getTotalGoogleApiCalls() {
      return axios.get('/api/total-calls');
    },
    validatePassword(context, password) {
      return axios.post('/api/user-profile/validate-password', { password: password });
    },
    sendDeleteLink(context, password) {
      return axios.post('/api/user-delete/request', { password: password });
    },
    validateDeleteToken(context, { userId, token }) {
      return axios.get('/api/user-delete/validate/' + userId + '/' + token);
    },
    deleteAccount(context, { userId, token, password }) {
      return axios.post('/api/user-delete/confirm/' + userId + '/' + token, { password: password });
    },
    updateDeviceInformation() {
      let data = {
        platform: helperFunctions.platform(),
        OSVersion: userAgentResult.os.name + '/' + userAgentResult.os.version,
        deviceVendor: userAgentResult.device.vendor,
        deviceModel: userAgentResult.device.model,
        appVersion: process.env.VUE_APP_BUILD,
      };
      return axios.post('/api/user-device', data);
    },   
    getAvailableSpace(store) {
      return new Promise((resolve, reject) => {
        let user = store.rootGetters['user/user'];

        Promise.all([
          store.dispatch('user/getStatistics', user.id, { root: true }),
          store.dispatch('subscription/getSubscription', null, { root: true }),
        ])
        .then(response => {
          let statistics = response[0].data.statistics;
          let subscription = response[1].data.subscription;

          let usedSpace = statistics.space_pictures 
            + statistics.space_videos 
            + statistics.space_melodies
            + statistics.space_notes
            + statistics.space_soft_deleted;

          let subscriptionIsValid = SubscritpionModel.subscriptionIsValid(subscription);
          let totalSpace = helperFunctions.gigaBytesToBytes(subscriptionIsValid ? SpaceLimit.subscribed : SpaceLimit.notSubscribed);


          let availableSpace = (totalSpace < usedSpace) ? 0 : (totalSpace - usedSpace);

          resolve(availableSpace);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
  }
}
