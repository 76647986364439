<template>
  <div class="l-vertical__grow l-vertical">
    
    <page-title :back-to="{ name: 'user-login'}">
      {{ $t('user.titles.newPassword') }}
    </page-title>

    <v-container class="container--narrow">

      <v-alert 
        v-if="error" 
        type="error"
        class="mt-6 mb-6"
      >{{ error }}</v-alert> 
      
      <v-form
        v-if="!success"
        ref="form"
        v-model="valid"
        class="mt-12"
        @submit.prevent
        @keyup.native.enter="submitForm"
      >
        <v-text-field
          v-model="password"
          :label="$t('user.form.label.passwordNew')"
          :rules="[$validationRules.required, $validationRules.password]"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          maxlength="128"
          :hint="$t('messages.error.password')"
          validate-on-blur
          @click:append="showPassword = !showPassword"
        />       
        <v-text-field
          v-model="passwordRepeat"
          :label="$t('user.form.label.passwordRepeat')"
          :rules="[$validationRules.required, passwordConfirmationRule]"
          :type="showPassword2 ? 'text' : 'password'"
          :append-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
          maxlength="128"
          class="mb-4"
          validate-on-blur
          @click:append="showPassword2 = !showPassword2"
        />

        <v-btn
          block
          large
          class="primary secondary--text mb-6"
          :loading="loading"
          @click="submitForm"
        >{{ $t('actions.save') }}</v-btn>
                  
        <v-btn
          block
          large
          outlined
          class="mb-6"
          :to="{ name: 'user-login' }" 
        >{{ $t('actions.back') }}</v-btn> 

      </v-form>

      <template v-if="success">
        <v-alert 
          type="success" 
          class="mt-6 mb-6"
        >{{ $t('user.texts.newPasswordSuccess') }}</v-alert>

        <v-btn 
          block 
          large
          class="primary secondary--text mb-6"
          :to="{ name: 'user-login' }"
        >
          {{ $t('actions.enter') }}
        </v-btn>
      </template>   

    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      error: null,
      loading: false,
      password: null,
      passwordRepeat: null,
      showPassword: false,
      showPassword2: false,
      success: false,
      valid: false
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.passwordRepeat) || this.$t('messages.error.passwordMatch')
    },
  },
  methods: {
    ...mapActions({
      changePassword: 'user/changePassword'
    }),
    submitForm() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.loading = true;
      this.success = null;
      this.error = null;

      const data = {
        password: this.password,
        passwordRepeat: this.passwordRepeat,
        token: this.$route.params.token
      }

      this.changePassword(data)
      .then(response => {
        this.success = true;
      })
      .catch(error => {
        this.error = this.$helperFunctions.errorToString(error);
      })
      .finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
