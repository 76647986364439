<template>
  <div class="to-user-field">
    <progress-linear :loading="loading" />

    <v-select
      v-if="!loading"
      v-model="item.toUser"
      :label="$t('transfers.form.label.toUser')"
      :placeholder="$t('transfers.form.placeholder.toUser')"
      :items="collaborators"
      item-text="title"
      item-value="id"
      :rules="[$validationRules.required]"
      :disabled="noData"
    >
      <template v-slot:item="data">
        <user-item 
          :item="data.item" 
          class="my-1"
          disable-modal
        />
      </template>
      <template v-slot:selection="data">
        <user-item 
          :key="data.item.id"
          :item="data.item"  
          class="my-2"
          disable-modal
        />
      </template>
      <template v-slot:no-data>No options message</template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserItem from '@/modules/user/components/UserItem';
import ProgressLinear from '@/components/util/ProgressLinear';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    ProgressLinear,
    UserItem
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    episodeId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      collaborators: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    noData() {
      return !this.loading && !this.collaborators;
    }
  },
  created() {
    // load collaborators
    this.loading = true;
    this.getCollaborators(this.episodeId)
    .then(response => {
      this.collaborators = response.data.collaborators.filter(item => {
        return (item.id != this.user.id) && (item.rol != roles.COMMENTATOR);
      });
      this.loading = false;
    });
  },  
  methods: {
    ...mapActions({
      getCollaborators: 'collaborators/getCollaborators'
    })
  }
}
</script>


<style scoped>
.to-user-field {
  min-height: 70px;
}
</style>