<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'activity-notes', params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId } }">
      {{ $t('notes.titles.blog') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ isAudio ? $t('notes.titles.editAudio') : $t('notes.titles.edit') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">

      <v-container class="container--narrow">

        <loading v-if="loading" />

        <v-form
          v-if="item"
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <form-fields
            :item="item"
            :hide-text="isAudio"
          >
            <div 
              v-if="isAudio"
              class="text-center my-2"
            >
              <audio-player
                :src="item.urlAudioFile"
                big
              />
            </div>
          </form-fields>
        </v-form>

      </v-container>

      <div 
        v-if="item"
        class="l-vertical__sticky-footer--mobile"
      >
        <v-container class="container--narrow">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                @click="$router.back()"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AudioPlayer from '@/components/AudioPlayer.vue';
import FormFields from '@/modules/notes/components/FormFields.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    AudioPlayer,
    FormFields,
    Loading,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: null,
      loading: false,
      saving: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      activity: 'activities/cachedActivity',
      episode: 'episodes/cachedEpisode'
    }),
    isAudio() {
      return this.item && !!this.item.urlAudioFile;
    }
  },
  created() {
    this.loading = true;

    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    // load activity
    this.loadActivityToCache({
      episodeId: this.$route.params.episodeId,
      activityId: this.$route.params.activityId
    });

    // load note
    this.getNote({
      activityId: this.$route.params.activityId,
      noteId: this.$route.params.noteId
    })
    .then(response => {
      this.item = response.data.note;

      // fix time format from API
      this.item.date = this.$helperFunctions.fixApiDate(this.item.date);
      this.item.time = this.$helperFunctions.fixApiTime(this.item.time);

      this.loading = false;
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getNote: 'notes/getNote',
      loadActivityToCache: 'activities/loadActivityToCache',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
      updateNote: 'notes/updateNote'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        title: this.item.title,
        text: this.item.text,
        date: this.item.date,
        time: this.item.time,
        privacy: this.item.privacy
      }

      this.updateNote({
        noteId: this.$route.params.noteId,
        activityId: this.$route.params.activityId,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("notes.messages.success.updated"),
          type: 'success'
        });
        this.$router.back();
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
