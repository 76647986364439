<template>
  <v-dialog 
    v-model="open" 
    width="450"
  >
    <v-container class="py-3 px-6">
      <div class="subtitle-1 text-center mt-2 mb-6 error--text">
        {{ $t('notices.dialogs.delete.title') }}
      </div>
    </v-container>

    <hr>


    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="$emit('close')"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            color="error"
            :loading="saving"
            block
            small
            @click="submit"
          >{{ $t('actions.delete') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    itemId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      open: false,
      saving: false
    };
  },
  watch: {
    itemId(itemId) {
      this.open = !!itemId;
    },
    open(open) {
      if (!open) {
        this.$emit('close');
      }
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      deleteNotice: 'notices/deleteNotice'
    }),
    submit() {
      this.saving = true;

      this.deleteNotice(this.itemId)
      .then(() => {
        this.addFlash({
          text: this.$t("notices.messages.success.deleted"),
          type: 'success'
        });
        this.$emit('success');
        this.$emit('close');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>
