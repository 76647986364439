<template>
  <div 
    v-if="!isEmpty"
    class="app-fab"
  >

    <v-btn
      ref="fab"
      class="app-fab__button primary ml-3"
      fab
      :to="to"
      @click="buttonClicked"
    >
      <slot></slot> 
      <v-icon 
        v-if="!$slots.default"
        large
        class="app-fab__icon"
        :class="open ? 'app-fab__icon--open' : ''"
      >mdi-plus</v-icon>
    </v-btn>

    <div 
      v-on-click-outside="{
        exclude: ['fab'],
        handler: 'onOutsideClick'
      }"
      class="app-fab__menu"
      :class="{ 'app-fab__menu--open' : open }"
      @click="open = false"
    >

      <router-link 
        v-if="addEpisode && $vuetify.breakpoint.mdAndUp"
        class="app-fab__menu-item"
        :to="{ name: 'episode-add' }"
      >
        <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
          {{ $t('menu-fab.episode') }}
        </v-btn>
        <v-btn
          fab
          small
          class="primary"
        >
          <v-icon>mdi-folder-plus-outline</v-icon>
        </v-btn>
      </router-link>

      <router-link 
        v-if="addActivity && $vuetify.breakpoint.mdAndUp"
        class="app-fab__menu-item"
        :to="{ name: 'activity-add', params: { episodeId: $route.params.episodeId } }"
      >
        <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
          {{ $t('menu-fab.activity') }}
        </v-btn>
        <v-btn
          fab
          small
          class="primary"
        >
          <v-icon>mdi-folder-multiple-plus-outline</v-icon>
        </v-btn>
      </router-link>

      <template v-if="addFiles && $vuetify.breakpoint.mdAndUp">
        <camera-button-android v-if="$helperFunctions.isAndroid()" />
        <upload-button>
          <template v-slot:button="{ loading }">
            <v-btn 
              class="app-fab__item-label primary v-btn--raised mr-2"
              :loading="loading"
            >
              {{ $t('menu-fab.upload') }}
            </v-btn>
            <v-btn
              fab
              small
              class="primary"
            >
              <v-icon>mdi-camera-plus</v-icon>
            </v-btn>
          </template>
        </upload-button>
      </template>


      <slot name="list"></slot> 

      <fab-audio-menu 
        v-if="addNotes && $vuetify.breakpoint.mdAndUp"
        @open-recorder="$refs.audioRecorder.open()"
      />            
      
      <router-link 
        v-if="addNotes && $vuetify.breakpoint.mdAndUp"
        class="app-fab__menu-item"
        :to="{ name: 'add-note', params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId } }"
      >
        <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
          {{ $t('menu-fab.note') }}
        </v-btn>
        <v-btn
          fab
          small
          class="primary"
        >
          <v-icon>mdi-format-annotation-plus</v-icon>
        </v-btn>
      </router-link>

    </div>
        
    <audio-recorder-cordova
      v-if="addNotes && $vuetify.breakpoint.mdAndUp && $helperFunctions.isCordova()"
      ref="audioRecorder"
    />
  </div>
</template>

<script>
import AudioRecorderCordova from '@/modules/notes/components/AudioRecorder/AudioRecorderCordova.vue';
import FabAudioMenu from '@/modules/notes/components/FabAudioMenu/FabAudioMenu.vue';
import CameraButtonAndroid from '@/modules/files/components/UploadButton/CameraButtonAndroid.vue';
import UploadButton from '@/modules/files/components/UploadButton/UploadButton.vue';

export default {
  components: {
    AudioRecorderCordova,
    CameraButtonAndroid,
    FabAudioMenu,
    UploadButton,
  },
  props: {
    addEpisode: {
      type: Boolean,
      default: false
    },
    addActivity: {
      type: Boolean,
      default: false
    },
    addFiles: {
      type: Boolean,
      default: false
    },
    addNotes: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    isEmpty() {
      return this.$vuetify.breakpoint.smAndDown && !this.$slots.list;
    }
  },
  methods: {
    buttonClicked() {
      if (!this.to) {
        this.open = !this.open;
      }
      this.$emit('click');
    },
    onOutsideClick() {
      this.open = false;
    }
  }
}
</script>

<style scoped>
.app-fab {
  position: relative;
  z-index: 6;
}
.app-fab__menu {
  position: absolute;
  z-index: 50;
  top: 100%;
  right: -7px;
  padding: 0 15px;
  max-height: 0px;
  overflow: hidden;
  /* transition: max-height 0.15s ease-out; */
}
.app-fab__menu--open {
  max-height: 400px;
}
.app-fab__icon {
  transition: transform 0.15s ease-out;
}
.app-fab__icon--open {
  transform: rotate(45deg);
}
.app-fab__menu > *:first-child {
  margin-top: 10px;
}
/deep/ .app-fab__menu-item {
  display: flex;
  white-space: nowrap;
  margin-bottom: 12px;
  margin-top: 12px;
  text-align: right;
}
/deep/ .app-fab__item-label {
  flex-grow: 1;
  border-radius: 6px !important; 
  text-transform: none;
  font-weight: normal;
  justify-content: left;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

/* mobile & tablet */
@media (max-width: 1024px) {
  .app-fab {
    position: absolute;
    bottom: 76px;
    right: 16px;
  }
  .app-fab__menu {
    top: auto;
    bottom: 100%;
  }
}
</style>  