<template>
  <div 
    class="day-item"
    :class="{
      'day-item--selected': selected
    }"
  >
    <div 
      class="day-item__bar"
      :style="'height: ' + (percents / 100 * maxHeight) + 'px'"
    ></div>
    <div class="day-item__label">
      {{ (day % 2) ? day : '' }}
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false
    }, 
    day: {
      type: Number,
      default: null
    },
    percents: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      maxHeight: 100
    }
  }
};
</script>

<style scoped>
.day-item {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 8px;
  margin: 0 1px;
  padding-bottom: 15px;
}
.day-item__bar {
  width: 100%;
  height: 20px;
  background-color: var(--v-success-base);
}
.day-item__label {
  position: absolute;
  bottom: 0;
}
.day-item--selected .day-item__bar,
.day-item:hover .day-item__bar  {
  background-color: var(--v-primary-base);
}
</style>