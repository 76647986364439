<template>
  <div class="bar-chart">
    
    <div class="chart mb-4">
      <template v-for="(value, i) in values">
        <div 
          v-if="value"
          :key="i"
          class="chart__bar"
          :class="'chart__bar--color-' + (i + 1)"
          :style="'width: calc(' + Math.round(value / total * 100) + '% + 7px)'"
        ></div>
      </template>

    </div>

    <div class="legend mb-4">
      <div 
        v-for="(label, i) in labels"
        :key="i"
        class="legend__label mb-3"
        :class="'legend__label--color-' + (i + 1)"
      >
        {{ label }}
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    statistics: {
      type: Object,
      default: null
    },
    labels: {
      type: Array,
      default: null
    }
  },
  computed: {
    values() {
      return [
        this.minePublicCount,
        this.othersPublicCount,
        this.minePrivateCount
      ];
    },
    minePublicCount() {
      return Number(this.statistics.photo.public_my.count)
        + Number(this.statistics.video.public_my.count)
        + Number(this.statistics.note.public_my.count);
    },
    othersPublicCount() {
      return Number(this.statistics.photo.public_people.count)
        + Number(this.statistics.video.public_people.count)
        + Number(this.statistics.note.public_people.count);
    },
    minePrivateCount() {
      return Number(this.statistics.photo.private.count)
        + Number(this.statistics.video.private.count)
        + Number(this.statistics.note.private.count);
    },
    total() {
      let total = 0;
      for (let i in this.values) {
        total += this.values[i];
      }
      return total;
    },
  }
}
</script>

<style scoped>
.bar-chart {
  --color-1: #bef019;
  --color-2: #48B3AE;
  --color-3: #92DCBD;
}
.chart {
  display: flex;
  background-color: var(--color-background--darker);
  border-radius: 7px;
  overflow: hidden;
}
.chart__bar {
  position: relative;
  background-color: var(--color-background--darker);
  height: 14px;
  border-radius: 0 7px 7px 0;
  margin-left: -7px;
}
.chart__bar--color-1 {
  background-color: var(--color-1);
  border-radius: 7px;
  z-index: 10;
}
.chart__bar--color-2 {
  background-color: var(--color-2);
  z-index: 9;
}
.chart__bar--color-3 {
  background-color: var(--color-3);
  z-index: 8;
}

.legend__label::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: var(--color-background--darker);
  margin-right: 8px;
  margin-bottom: -2px;
}
.legend__label--color-1::before {
  background-color: var(--color-1);
}
.legend__label--color-2::before {
  background-color: var(--color-2);
}
.legend__label--color-3::before {
  background-color: var(--color-3);
}
</style>