<template>
  <div class="actions-menu">
    <v-menu 
      v-model="open"
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-show="episode && episode.canEdit"
          icon
          dark
          class="primary--text"
          :title="$t('episodes.actions.editEpisode')"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">

        <v-list-item
          :to="{ name: 'episode-edit', params: { episodeId: $route.params.episodeId } }"
        >
          <v-icon class="mr-3 primary--text">mdi-pencil-outline</v-icon> 
          {{ $t('actions.edit') }}
        </v-list-item>

        <download-item 
          :episode-id="$route.params.episodeId"
          @close="open = false"
        />  

        <transfer-item 
          :episode="episode"
          @success="open = false; $emit('modified')"
        /> 

        <v-list-item 
          class="error--text"
          @click="deleteDialogOpen = true"
        >
          <v-icon class="mr-3">mdi-trash-can-outline</v-icon> 
          {{ $t('actions.delete') }}
        </v-list-item>

      </v-list>
    </v-menu>

    <v-dialog
      v-model="deleteDialogOpen"
      width="450"
    >
      <delete-dialog 
        :item="episode"
        @close="deleteDialogOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import DeleteDialog from './DeleteDialog';
import DownloadItem from './DownloadItem.vue';
import TransferItem from './TransferItem/TransferItem.vue';

export default {
  components: {
    DeleteDialog,
    DownloadItem,
    TransferItem,
  },
  props: {
    episode: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      deleteDialogOpen: false,
      open: false
    }
  }
}
</script>

<style scoped>
.actions-menu {
  width: 42px;
  height: 42px;
}
</style>