<template>
  <div class="mb-8">
    <v-row>
      <v-col class="flex-grow-0">
        <router-link
          :to="{ name: 'contact-details', params: { id: item.data.id } }"
        >
          <user-avatar 
            v-if="item"
            :src="item.data.urlAvatar"
            :alt="item.data.alias"
            class="user-item__avatar"
          />
        </router-link>
      </v-col>
      <v-col class="flex-grow-1 pl-1 pt-1 d-flex flex-column justify-center">
        <div
          v-if="!item.viewed" 
          class="overline small error--text mb-1"
        >Nuevo</div>
        <div class="overline small">{{ $moment(item.createdAt).format($t('locale.formatDateTime')) }}</div>
      </v-col>
    </v-row>
    <div>
      {{ item.data.alias }} {{ $t('notifications.types.user_exit_episode') }}
      <router-link
        :to="{ name: 'episode-details', params: { episodeId: item.data.episode.id } }"
      >{{ item.data.episode.title }}</router-link>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/modules/user/components/UserAvatar.vue';

export default {
  components: {
    UserAvatar
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
}
</script>