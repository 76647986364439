<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      :back-to-routes-names="['search-shared-episodes-results']"
    >
      {{ episode ? episode.title : '' }}
      <template slot="buttons">

        <selection-toolbar
          ref="selectionToolbar"
          :items="items"
          @success="loadItems"
        />

        <sort-menu @change="sortBy = $event" />

        <app-fab v-if="!$refs.selectionToolbar || !$refs.selectionToolbar.selectionEnabled">
          <template slot="list">
            <div 
              class="app-fab__menu-item"
              @click="$refs.addDialog.open()"
            >
              <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
                {{ $t('episodeAccesses.actions.addAccess') }}
              </v-btn>
              <v-btn
                fab
                small
                class="primary"
              >
                <v-icon>mdi-account-plus-outline</v-icon>
              </v-btn>
            </div>

          </template>  
        </app-fab>
      </template>
    </page-title>

    <episode-tabs
      :episode="episode"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <loading v-if="loading" />

      <v-container
        v-else
        class="container--narrow"
      >
        
        <v-alert 
          v-if="items && !items.length"
          type="info"
        >
          {{ $t('texts.noElements') }}
        </v-alert>

        <template v-if="itemsSorted">
          <list-item
            v-for="item in itemsSorted"
            :key="item.id"
            :item="item"
            :selection-enabled="$refs.selectionToolbar && $refs.selectionToolbar.selectionEnabled"
            @edit="editItem = item"
            @delete="deleteId = item.id"
            @resend="resendId = item.id"
          />
        </template>

      </v-container>

    </div>

    <add-dialog 
      ref="addDialog" 
      @saved="loadItems"
    />
    
    <edit-dialog
      :item="editItem"
      @close="editItem = null"
      @success="loadItems()"
    />
    
    <delete-dialog
      :item-id="deleteId"
      @close="deleteId = null"
      @success="loadItems()"
    />
    
    <resend-dialog
      :item-id="resendId"
      @close="resendId = null"
    />
    
    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddDialog from './AddDialog.vue';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import DeleteDialog from './DeleteDialog';
import EditDialog from './EditDialog';
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs.vue';
import ListItem from './ListItem/ListItem.vue';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ResendDialog from './ResendDialog';
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import SelectionToolbar from './SelectionToolbar/SelectionToolbar.vue';
import SortMenu from './SortMenu.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    AddDialog,
    AppFab,
    DeleteDialog,
    EditDialog,
    EpisodeTabs,
    ListItem,
    Loading,
    MenuBottom,
    PageTitle,
    ResendDialog,
    SelectionToolbar,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      deleteId: null,
      editItem: null,
      items: null,
      loading: false,
      resendId: null,
      roles: roles,
      sortBy: 'alias'
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
    }),
    itemsSorted() {
      if (!this.items) {
        return [];
      }
      let items = [...this.items];
      switch (this.sortBy) {       
        case 'alias':
          items.sort((a, b) => (a.alias || '').localeCompare(b.alias || ''));      
          break;
        case 'email':
          items.sort((a, b) => (a.email || '').localeCompare(b.email || ''));      
          break;
      }
      return items;
    }
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    this.loadItems();
  },
  methods: {
    ...mapActions({
      getAccesses: 'episodeAccesses/getAccesses',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
    }),
    loadItems() {
      this.loading = true;
      this.getAccesses(this.$route.params.episodeId)
      .then(response => {
        // add reactive field "selected"
        for (let i in response.data.accesses) {
          response.data.accesses[i].selected = false;
        }
        this.items = response.data.accesses;
        // fix: convert object to array
        if (typeof this.items === 'object' && this.items !== null) {
          this.items = Object.values(this.items);
        }
        this.loading = false;
      });
    }
  }
}
</script>
