<template>
  <loading
    v-if="loading || loadingItem"
    height="50"
    color="grey"
  />
  <v-row v-else>
    <v-col class="flex-grow-1">
      <v-combobox
        v-model="item.melody"
        :label="$t('melodies.form.label.melody')"
        :items="melodies"
        item-text="name"
        item-value="id"
        :loading="loading"
        :validate-on-blur="false"
        clearable
        :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
      >
        <template v-slot:item="data">
          <melody-dropdown-item :item="data.item" />
        </template>
        <template v-slot:selection="data">
          <melody-dropdown-item 
            :key="data.item.id"
            :item="data.item" 
          />
        </template>
      </v-combobox>
    </v-col>
    <v-col 
      v-if="item.melody && item.melody.urlAudioFile"
      class="flex-grow-0 pl-0 pt-4"
    >
      <audio-player
        :src="item.melody.urlAudioFile"
      />      
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import AudioPlayer from '@/components/AudioPlayer.vue';
import Loading from '@/components/util/Loading.vue';
import MelodyDropdownItem from './MelodyDropdownItem';

export default {
  components: {
    AudioPlayer,
    MelodyDropdownItem,
    Loading
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    loadingItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      melodies: null,
      loading: false
    }
  },
  created() {
    // load melodies      
    this.loading = true;
    this.getMelodies()
    .then(response => {
      this.melodies = response.data.melodies;
      this.melodies.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
      this.loading = false;
    });
  },  
  methods: {
    ...mapActions({
      getMelodies: 'melodies/getMelodies'
    }),
  }
}
</script>