<template>
  <section class="well well--with-border well--dense">

    <div class="text-center"><strong>{{ $t('episodes.titles.statistics.notes') }}</strong></div>

    <v-row>
      <v-col class="pt-0">
        <strong>{{ $t('episodes.titles.statistics.notesPublic') }}:</strong>
      </v-col>
      <v-col class="pt-0 text-right">
        <strong>{{ publicCount }}</strong>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0">
        - {{ $t('episodes.titles.statistics.notesMinePublic') }}
      </v-col>
      <v-col class="pt-0 text-right">
        {{ minePublicCount }}
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col class="pt-0">
        - {{ $t('episodes.titles.statistics.notesOthersPublic') }}
      </v-col>
      <v-col class="pt-0 text-right">
        {{ othersPublicCount }}
      </v-col>
    </v-row>


    <v-row>
      <v-col class="pt-0">
        <strong>{{ $t('episodes.titles.statistics.notesPrivate') }}:</strong>
      </v-col>
      <v-col class="pt-0 text-right">
        <strong>{{ minePrivateCount }}</strong>
      </v-col>
    </v-row>

  </section>
</template>

<script>

export default {
  props: {
    statistics: {
      type: Object,
      default: null
    }
  },
  computed: {
    minePublicCount() {
      return this.statistics.public_my.count;
    },
    othersPublicCount() {
      return this.statistics.public_people.count;
    },
    publicCount() {
      return Number(this.statistics.public_my.count) + Number(this.statistics.public_people.count);
    },
    minePrivateCount() {
      return this.statistics.private.count;
    }
  }
}
</script>