<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ episode ? episode.title : '' }}
      <template slot="buttons">
        <filter-menu 
          :activity-groups="activityGroups"
          :disabled="!!$route.query.activity"
          @change="filters = $event; showMarkers()" 
        />

        <map-legend :activity-groups="activityGroups" />
      </template>
    </page-title>

    <episode-tabs
      :episode="episode"
    />

    <div class="l-vertical__grow l-vertical">
      <loading v-if="loading" />

      <div 
        ref="googleMap" 
        class="google-map"
        :class="loading ? 'google-map--loading' : ''"
      ></div>
    </div>

    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs';
import FilterMenu from './FilterMenu';
import GoogleMapsApiLoader from "google-maps-api-loader";
import Loading from '@/components/util/Loading';
import MapLegend from "@/modules/worldmap/components/MapLegend.vue";
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    EpisodeTabs,
    FilterMenu,
    Loading,
    MapLegend,
    MenuBottom,
    PageTitle
  },
  data() {
    return {
      activities: null,
      activityGroups: null,
      bounds: null,
      filters: { 
        attended: 'all',
        groups: {},
        groupsAll: true,
      },
      loading: true,
      map: null,
      mapConfig: {
        center: { lat: 0, lng: 0 },
        zoom: 2,
        disableDefaultUI: true,
      },
      markers: [],
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode'
    }),
    activitiesFiltered() {
      if (!this.activities) {
        return [];
      }

      // filter activity selected from activity details page
      if (this.$route.query.activity) {
        return this.activities.filter(item => item.id == this.$route.query.activity);
      }

      return this.activities.filter(item => {

        // filter by group
        if (!this.filters.groupsAll) {
          if (!this.filters.groups[item.activityGroup.id]) {
            return false;
          }
        }

        // filter by attended
        switch (this.filters.attended) {
          case 'all':
            break;
          case 'attended':
            if (item.notAttended) {
              return false;
            }
            break;
          case 'notAttended':
            if (!item.notAttended) {
              return false;
            }
            break;
        }

        return true;
      });
    }
  },
  watch: {
    '$route.query.activity': function (id) {
      this.showMarkers();
    }
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId)

    // load activities
    this.loading = true;
    this.getActivities(this.$route.params.episodeId)
    .then(response => {
      this.activities = response.data.activities;
      this.loading = false;
      this.showMarkers();
    });

    // load activity groups
    this.getActivityGroups()
    .then(response => {
      let groups = response.data;
      groups.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
      let firstItemIndex = groups.findIndex(item => item.slug == 'none');
      let firstItem = groups.splice(firstItemIndex, 1)[0];
      groups.unshift(firstItem);
      this.activityGroups = groups;
    });
  },
  async mounted() {
    // load Google Maps API
    const google = await GoogleMapsApiLoader({ 
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      language: this.$i18n.locale
    });

    // create map
    this.map = new google.maps.Map(this.$refs.googleMap, this.mapConfig);
    this.bounds = new google.maps.LatLngBounds();

    this.showMarkers();
  },
  methods: {
    ...mapActions({
      getActivities: 'activities/getActivities',
      getActivityGroups: 'activities/getActivityGroups',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
    }),
    showMarkers() {
      this.clearMarkers();

      if (!this.activitiesFiltered || !this.map) {
        return;
      }

      this.map.setOptions({ maxZoom: 5 });

      for (const activity of this.activitiesFiltered) {
        if (activity.latitude && activity.longitude) {
          let myLatLng = new google.maps.LatLng(parseFloat(activity.latitude), parseFloat(activity.longitude));
          const marker = new google.maps.Marker({
            position: myLatLng,
            map: this.map,
            title: activity.name,
            icon: {
              url: "img/markers/" + activity.activityGroup.slug + ".png",
              scaledSize: new google.maps.Size(32, 32)
            } 
          });
          marker.addListener("click", () => {
            this.$router.push({ 
              name: 'activity-details', 
              params: { 
                episodeId: activity.episode.id, 
                activityId: activity.id
              } 
            });
          });
          this.markers.push(marker);
          this.bounds.extend(myLatLng);
        }
      }
      this.map.fitBounds(this.bounds);
      this.map.setOptions({ maxZoom: undefined });
    },
    clearMarkers() {
      for (const marker of this.markers) {
        marker.setMap(null);
      }
      this.markers = [];
    }
  }
}
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background-color: var(--color-image-background);
}
.google-map--loading {
  visibility: hidden;
}
</style>
