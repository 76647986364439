import TimelinePage from '@/modules/timeline/pages/TimelinePage.vue';

export default [
  {
    path: '/timeline',
    name: 'timeline',
    component: TimelinePage,
    meta: { requiresAuth: true }
  }
];
