<template>
  <div class="l-vertical__scroll l-vertical">
    <v-container class="container--narrow">

      <v-form
        ref="form"
        v-model="item.tab1Valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >

        <form-fields-1
          :item="item"
        />
          
        <melody-combobox
          :item="item"
        />

      </v-form>
    </v-container>

    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn  
              outlined
              block
              :to="{ name: 'activity-details', params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId } }"
            >{{ $t('actions.back') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :disabled="!item.tab1Valid"
              :loading="saving"
              @click="submit"
            >{{ $t('actions.save') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields1 from '../../components/FormFields/FormFields1.vue';
import MelodyCombobox from '@/modules/melodies/components/MelodyCombobox/MelodyCombobox.vue';
import UploadImage from "@/components/form/UploadImage/UploadImage.vue";

export default { 
  components: {
    FormFields1,
    MelodyCombobox,
    UploadImage
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    saving: {
      type: Boolean,
      default: false
    }
  }, 
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.$emit('submit');
    }
  }
}
</script>