<template>
  <v-radio-group
    class="search-for-field"
    :value="value"
    hide-details
    @change="$emit('input', $event)"
  >
    <v-radio
      v-for="(item, i) in options"
      :key="i"
      :label="item.name"
      :value="item.id"
      on-icon="mdi-checkbox-marked-outline"
      off-icon="mdi-checkbox-blank-outline"
    ></v-radio>
  </v-radio-group>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      options : [
        { id: 'episodes', name: this.$t('search.model.searchFor.episodes') },
        { id: 'activities', name: this.$t('search.model.searchFor.activities') },
        { id: 'photos', name: this.$t('search.model.searchFor.photos') },
        { id: 'videos', name: this.$t('search.model.searchFor.videos') },
        { id: 'notes', name: this.$t('search.model.searchFor.notes') }
      ]
    }
  }
}
</script>

<style scoped>
.search-for-field {
  margin-top: 0;
  color: var(--v-primary-base);
}
.search-for-field /deep/ .v-input--radio-group__input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6px
}
.search-for-field /deep/ .v-radio > .v-label {
  color: var(--color-text);
}
</style>