<template>
  <div
    class="menu-button"
  >
    <offline-badge>
      <v-btn  
        icon
        :width="size"
        :height="size"
        :title="$t('actions.menuOpen')" 
        class="menu-button__button mr-2"
      >
        <user-avatar 
          :src="user.urlAvatar"  
          :size="size"
        />
      </v-btn>
    </offline-badge>

    <div class="user-name">{{ alias }}</div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import OfflineBadge from '@/components/util/OfflineBadge/OfflineBadge';
import UserAvatar from "@/modules/user/components/UserAvatar.vue";

export default {
  components: {
    OfflineBadge,
    UserAvatar
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    alias() {
      return this.user.alias.substring(0, 10);
    },
    size() {
      return this.$vuetify.breakpoint.smAndDown ? 30 : 48;
    }
  },
};
</script>

<style scoped>
.menu-button {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.menu-button__avatar {
  margin-right: 18px;
}
.user-name {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}


/* mobile */
@media (max-width: 1024px) {
  .user-name {
    font-size: 13px;
  }
}
</style>