<template>
  <div 
    v-if="item"
    class="group-item py-2"
  >
    <group-thumbnail 
      :src="item.urlFilename" 
      class="group-item__img mr-2"
      disable-modal
    />
    <div class="group-item__name">
      {{ item.title }}
    </div>
  </div>
</template>

<script>
import GroupThumbnail from '@/modules/contacts/components/GroupThumbnail.vue';

export default {
  components: {
    GroupThumbnail
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  }
};
</script>

<style scoped>
.group-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group-item__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
}
</style>