<template>
  <page-tabs v-bind="$attrs">

    <v-tab 
      :to="{ name: 'admin-statistics-users' }"
    >Usuarios</v-tab>  

    <v-tab 
      :to="{ name: 'admin-statistics-subscriptions' }"
    >Suscripciones</v-tab>  

    <v-tab 
      :to="{ name: 'admin-statistics-google-api' }"
    >API Google</v-tab>  

    <v-tab 
      :to="{ name: 'admin-statistics-space' }"
      exact
    >Datos</v-tab>  

  </page-tabs>
</template>

<script>
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';

export default {
  components: {
    PageTabs
  }
}
</script>
