export default {
  invited_to_episode: 'invited_to_episode',
  invited_to_episode_collaborators: 'invited_to_episode_collaborators',
  role_changed: 'role_changed',
  role_changed_collaborators: 'role_changed_collaborators',
  messages_in_chat: 'messages_in_chat',
  removed_from_episode: 'removed_from_episode',
  removed_from_episode_collaborators: 'removed_from_episode_collaborators',
  user_content_upload: 'user_content_upload',
  user_exit_app: 'user_exit_app',
  user_exit_episode: 'user_exit_episode',
  user_new_creator: 'user_new_creator',
  // transfers
  created_transfer: 'created_transfer',
  accepted_transfer: 'accepted_transfer',
  rejected_transfer: 'rejected_transfer',
  deleted_transfer: 'deleted_transfer',
  // procedures
  created_procedure: 'created_procedure',
  updated_procedure: 'updated_procedure',
  deleted_procedure: 'deleted_procedure'
};