<template>
  <v-text-field
    v-model="filters.title"
    :label="label"
    hide-details
    clearable
    outlined
    dense
  />
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: null
    },
    searchFor: {
      type: String,
      default: null
    },
  },
  computed: {
    label() {
      switch (this.searchFor) {
        case 'episodes':
          return this.$t('episodes.form.label.title');
        case 'activities':
          return this.$t('activities.form.label.title');
        case 'photos':
          return this.$t('search.form.photoDescription');
        case 'videos':
          return this.$t('search.form.videoDescription');
        case 'notes':
          return this.$t('search.form.noteTitle');
      }
      return '';
    }
  },
}
</script>