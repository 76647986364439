<template>
  <page-tabs v-bind="$attrs">

    <v-tab 
      :to="{ name: 'contact-list' }"
    >
      {{ $t('contacts.titles.myContacts') }}
    </v-tab>    
    
    <v-tab 
      :to="{ name: 'contact-groups-list'}"
    >
      {{ $t('contacts.titles.myGroups') }}
    </v-tab>

  </page-tabs>
</template>

<script>
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';

export default {
  components: {
    PageTabs
  }
}
</script>
