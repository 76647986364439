<template>
  <file-thumbnail 
    v-if="layout == 'thumbnail'"
    :file="file"
    :selection-enabled="selectionEnabled"
    :disable-click="disableClick"
    class="file-thumbnail"
    :shared="shared"
    :favorite-button="favoriteButton" 
    :episode-role="episodeRole"
    @click="$emit('click')" 
    @modified="$emit('modified')"
  />
  <div 
    v-else
    :v-ripple="!notClickable"
    class="file-item"
    :class="{
      'file-item--selected': file.selected,
      'file-item--not-clickable': notClickable
    }"
    @click="selectionEnabled ? (file.canEdit ? file.selected = !file.selected : null) : $emit('click')" 
  >
    <div class="file-item__thumbnail">
      <file-thumbnail 
        :file="file" 
        small 
        disable-click
        :shared="shared"
        :favorite-button="favoriteButton"
        :episode-role="episodeRole"
      />
    </div>

    <div class="file-item__details">
      <div class="file-item__user-row">
        <div class="file-item__user-alias">
          <div class="small height-lines-1">
            <span class="overline small">{{ $t('files.titles.owner') }}</span>: {{ file.user.alias }}
          </div>
          <div class="body-2 height-lines-1">{{ file.activity.episode ? file.activity.episode.title : episodeTitle }}</div>
          <div class="height-lines-1 mb-1">{{ file.activity.name }}</div>
        </div>
        <div class="file-item__user-image">
          <user-avatar 
            :src="file.user.urlAvatar" 
            class="user-item__avatar" 
            disable-modal
          />
        </div>
      </div>
      <div
        v-if="file.description" 
        class="small height-lines-1 mb-1"
      >{{ file.description }}</div>
      <div class="small overline d-flex">
        {{ file.date ? $moment(file.date).format($t('locale.formatDate')) : '' }}
        {{ file.time ? $helperFunctions.fixApiTime(file.time) : '' }}
        <div 
          v-if="file.duration"
          class="ml-auto mr-2"
        >{{ $helperFunctions.formatSeconds(file.duration) }}</div>
        <div :class="{ 'ml-auto': !file.duration }">
          {{ $helperFunctions.formatFileSize(file.size, 1, 3) }}
        </div>
      </div>
    </div>

    <v-btn
      v-if="showActivityLink && !selectionEnabled"
      icon
      small
      class="file-item__link"
      :to="{ name: 'activity-files', params: { 
        episodeId: file.activity.episode.id,
        activityId: file.activity.id
      }}"
    >
      <v-icon class="file-item__link secondary--text">mdi-chevron-right</v-icon>
    </v-btn>

    <v-simple-checkbox
      v-if="selectionEnabled && file.canEdit"
      v-model="file.selected" 
      color="primary"
      class="file-item__checkbox"
    />
  </div>
</template>

<script>
import FileThumbnail from "./FileThumbnail.vue";
import UserAvatar from "@/modules/user/components/UserAvatar.vue";

export default {
  components: {
    FileThumbnail,
    UserAvatar
  },
  props: {
    file: {
      type: Object,
      default: Object,
    },
    selectionEnabled: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'thumbnail' // 'thumbnail' | 'details'
    },
    showActivityLink: {
      type: Boolean,
      default: false
    },
    disableClick: {
      type: Boolean,
      default: false
    },
    episodeTitle: {
      type: String,
      default: null
    },
    episodeRole: {
      type: String,
      default: null
    },
    shared: {
      type: Boolean,
      default: false
    },
    favoriteButton: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    notClickable() {
      return this.disableClick || (this.selectionEnabled && !this.file.canEdit);
    },
  },
};
</script>

<style scoped>
.file-thumbnail {
  margin: 0 1px 2px 1px;
}
.file-item {
  margin: 0 12px 2px 0px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 0 6px 6px 0;
}
.file-item--not-clickable {
  cursor: default;
}
.file-item--selected::before {
  content: "";
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: var(--v-primary-base);
  opacity: 0.1;
}
.file-item__thumbnail {
  width: 124px;
  height: 124px;
  flex-shrink: 0;
  flex-grow: 0;
}
.file-item__details {
  flex-grow: 1;
  padding: 0 6px 0 6px;
}
.file-item__user-row {
  display: flex;
}
.file-item__user-alias {
  flex-grow: 1;
}
.file-item__user-image {
  flex-grow: 0;
  padding-left: 4px;
}
.file-item__link {
  flex-shrink: 0;
  flex-grow: 0;
}
.file-item__checkbox {
  margin-right: 8px;
}
</style>
