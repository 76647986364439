<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
    >
      {{ $t('dataUpload.titles.dataUpload') }}
    </page-title>

    <data-upload-tabs />

    <alerts-panel /> 

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <v-container>

        <div class="small mb-3">
          <span class="overline small">{{ $t('dataUpload.titles.folderStructure') }}:</span>
          {{ $t(nested ? 'dataUpload.model.folderStructure.nested.title' : 'dataUpload.model.folderStructure.flat.title') }}

          <span class="ml-4 overline small">{{ $t('dataUpload.titles.folder') }}:</span>
          {{ rootName }}
        </div>

        <loading v-if="loadingFiles" />

        <section
          class="data-upload__list well mb-4"
        >
          <template v-if="!loadingFiles && !!total">
            <list-header 
              :nested="nested"
            />

            <list-item 
              v-for="activity in activities"
              :key="activity.key" 
              :item="activity"
              :nested="nested"
              :uploading="uploading"
              :current="currentActivity && (currentActivity.key == activity.key)"
            />
          </template>

          <v-alert
            v-if="!loadingFiles && !total"
            type="info"
            icon="mdi-information"
          >
            {{ $t('dataUpload.messages.info.noEpisodes') }}
          </v-alert>
          
        </section>

      </v-container>
    </div>

    <upload-footer
      v-if="!loadingFiles && total"
      :loading="loadingData"
      :uploading="uploading"
      :pausing="pausing"
      :paused="paused"
      :finished="finished"
      :current-file="currentFile"
      :current-file-number="currentFileNumber"
      :total="total"
      :activities="activities"
      :available-space="availableSpace"
      @upload="submit"
      @pause="pausing = true"
    />

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy';
import AlertsPanel from '@/modules/dataUpload/components/AlertsPanel/AlertsPanel.vue';
import AverageSize from '@/modules/dataUpload/model/AverageSize.js';
import DataUploadTabs from '@/modules/dataUpload/components/DataUploadTabs';
import FilePrivacy from '@/modules/files/model/FilePrivacy.js';
import ItemsTotal from './UploadFooter/ItemsTotal.vue';
import ListHeader from './ListHeader.vue';
import ListItem from './ListItem/ListItem.vue';
import Loading from '@/components/util/Loading.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import statusTap from '@/mixins/statusTap.js';
import UploadFooter from './UploadFooter/UploadFooter.vue';

export default {
  components: {
    AlertsPanel,
    DataUploadTabs,
    ItemsTotal,
    ListHeader,
    ListItem,
    Loading,
    PageTitle,
    UploadFooter,
  },
  mixins: [statusTap],
  data() {
    return {
      activities: [],
      activityGroupId: null,
      activityTypeId: null,
      availableSpace: null,
      averagePhotoSize: 0,
      currentActivity: null,
      currentActivityIndex: 0,
      currentFile: null,
      currentFileIndex: 0,
      currentFileNumber: 0,
      finished: false,
      loadingData: true,
      loadingFiles: true,
      notUploadedCount: 0,
      paused: false,
      pausing: false,
      percents: 50,
      statistics: {},
      subscription: null,
      uploading: false,
      uploadedCount: 0,
    }
  },
  computed: {
    ...mapGetters({
      folders: 'dataUpload/folders',
      nested: 'dataUpload/nested',
      rootName: 'dataUpload/rootName',
    }),
    files() {
      let files = [];
      for (let i in this.activities) {
        let activity = this.activities[i];
        if (!activity.selected) {
          continue;
        }
        files = files.concat(activity.files);
      }
      return files;
    },
    total() {
      return this.files.length;
    },
  },
  created() {
    if (!this.folders) {
      this.$router.push({ name: 'data-upload-step-1' });
      return;
    }
    this.clearAlerts();

    this.loadAllEndpoints();
    
    // for testing only:
    // this.getAveragePhotoSize();

    if (this.nested) {
      this.filesToActivitiesNested();
    }
    else {
      this.filesToActivitiesFlat();
    }
    this.estimateActivitiesSize();
  },
  methods: {    
    ...mapMutations({
      addAlert: 'dataUpload/addAlert',
      addFlash: 'addFlash',
      clearAlerts: 'dataUpload/clearAlerts',
    }),
    ...mapActions({
      assignActivityImage: 'files/assignActivityImage',
      assignEpisodeImage: 'files/assignEpisodeImage',
      createActivity: 'activities/createActivity',
      createEpisode: 'episodes/createEpisode',
      getActivityGroups: 'activities/getActivityGroups',
      getActivityTypes: 'activities/getActivityTypes',
      getAvailableSpace: 'user/getAvailableSpace',
      getAveragePhotoSize: 'dataUpload/getAveragePhotoSize',
      uploadFile: 'files/uploadFile',
    }),
    loadAllEndpoints() {
      Promise.all([
        this.getActivityGroups(),
        this.getActivityTypes(),
        this.getAvailableSpace(),
      ])
      .then(response => {
        // find default group
        let groups = response[0].data;
        let defaultGroup = groups.find(item => item.slug == 'none');
        this.activityGroupId = defaultGroup.id;

        // set default type
        let types = response[1].data;
        types = types.find(item => item.id == this.activityGroupId);
        this.activityTypeId = types.activityTypes[0].id;

        // set available space
        this.availableSpace = response[2];

        this.loadingData = false;
      });
    },
    filesToActivitiesNested() {
      for (let i in this.folders) {
        const folder = this.folders[i];
        if (!folder.files) {
          continue;
        }

        const path = folder.path.split('/');
        const hasSubfolders = path.length > 1;
        let episodeName = hasSubfolders 
          ? path[0].substring(0, 12) + ' ' + path[1]
          : path[0];
        // max length of episode name is 32
        episodeName = episodeName.substring(0, 32);
        let activityName = hasSubfolders
          ? path[1] 
          : path[0];
        // max length of activity name is 128
        activityName = activityName.substring(0, 128);

        let activity = {
          path: folder.path,
          key: folder.path,
          files: folder.files,
          episodeName: episodeName,
          episodeDate: null,
          episodeId: null,
          activityName: activityName,
          activityDate: null,
          activityId: null,
          selected: true,
          finished: false,
          estimatedSize: null,
        }

        this.activities.push(activity);
      }

      // sort activities alphabetically
      this.activities.sort((a, b) => a.key.toString().localeCompare(b.key));

      this.findActivitiesImage();
      this.findEpisodesImage();
      this.loadingFiles = false;
    },
    filesToActivitiesFlat() {
      const folder = this.folders['flat'];

      for (let file of folder.files) {
        const date = this.$moment(file.date, "YYYY-MM-DD HH:mm:ss");
        const episodeName = date.format('YYYY') + ' - ' + date.format('MMMM');
        const activityName = this.capitalizeFirstLetter(date.format('MMMM'));
        const key = episodeName + '/' + date.format('MM');

        let activity = this.activities.find(item => item.key == key);
        if (!activity) {
          activity = {
            path: folder.path,
            key: key,
            files: [],
            episodeName: episodeName,
            episodeDate: null,
            episodeId: null,
            activityName: activityName,
            activityDate: null,
            activityId: null,
            selected: true,
            finished: false,
            estimatedSize: null,
          }
          this.activities.push(activity);
        }

        activity.files.push(file);
      }

      // sort activities by key
      this.activities.sort((a, b) => a.key.toString().localeCompare(b.key));

      this.findActivitiesImage();
      this.findEpisodesImage();
      this.loadingFiles = false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    findActivitiesImage() {
      // find oldes image for each activity
      for (let activity of this.activities) {
        // find oldest file 
        activity.activityDate = activity.files[0].date; 
        // find oldest image
        for (let file of activity.files) {
          if (file.type == 'photo') {
            activity.activityImage = file;
            break;
          }
        }
      };
    },
    findEpisodesImage() {
      // find oldes image for each episode
      let episodes = {};
      for (let activity of this.activities) {
        if (!episodes[activity.episodeName]) {
          episodes[activity.episodeName] = {
            date: "9999-12-31 00:00:00",
            imageDate: "9999-12-31 00:00:00",
            image: null,
          };
        }
        if (activity.activityDate < episodes[activity.episodeName].date) {
          episodes[activity.episodeName].date = activity.activityDate;
        }
        if (activity.activityImage && (activity.activityDate < episodes[activity.episodeName].imageDate)) {
          episodes[activity.episodeName].imageDate = activity.activityDate;
          episodes[activity.episodeName].image = activity.activityImage;
        }
      }

      for (let activity of this.activities) {
        activity.episodeDate = episodes[activity.episodeName].date;
        activity.episodeImage = episodes[activity.episodeName].image;
      }
    },
    estimateActivitiesSize() {
      for (let activity of this.activities) {
        activity.estimatedSize = 0;
        for (let file of activity.files) {
          if (file.type == 'photo') {
            activity.estimatedSize += AverageSize.AVG_PHOTO + AverageSize.AVG_PHOTO_MEDIUM;
          }
          else {
            activity.estimatedSize += file.file.size;
          }
        }
      };
    },
    async submit() {
      this.uploading = true;
      this.paused = false;
      let routeName = this.$route.name;

      this.addFlash({
        text: this.$t('dataUpload.messages.info.doNotClose'),
        type: 'info'
      });

      while (this.currentActivityIndex < this.activities.length) {
        this.currentActivity = this.activities[this.currentActivityIndex];

        if (!this.currentActivity.selected) {
          this.currentActivityIndex++;
          continue;
        }

        // create episode
        if (!this.currentActivity.episodeId) {
          await this.addEpisode();
        }

        // create activity
        if (!this.currentActivity.activityId) {
          await this.addActivity();
        }

        // upload files
        while (this.currentFileIndex < this.currentActivity.files.length) {
          if (routeName != this.$route.name) {
            return;
          }
          this.currentFileNumber++;
          this.currentFile = this.currentActivity.files[this.currentFileIndex];
          await this.addFile(this.currentFile);
          await this.addEpisodeImage(this.currentActivity, this.currentFile);
          await this.addActivityImage(this.currentActivity, this.currentFile);
          this.currentFile = null;
          this.currentFileIndex++;
          if (this.pausing) {
            this.pausing = false;
            this.paused = true;
            this.uploading = false;
            return;
          }
        }
        this.currentFileIndex = 0;

        this.currentActivity.finished = true;
        this.currentActivityIndex++;
      }

      this.uploading = false;
      this.finished = true;

      if (this.notUploadedCount > 0) {
        this.addAlert({
          text: this.$tc('dataUpload.messages.error.notUploadedCount', this.notUploadedCount, { count: this.notUploadedCount }),
          type: 'error'
        });
      }
      this.addAlert({
        text: this.$tc('dataUpload.messages.success.uploadedCount', this.uploadedCount, { count: this.uploadedCount, total: this.total }),
        type: 'success'
      });

      this.addFlash({
        text: this.$t('dataUpload.messages.success.uploadComplete'),
        type: 'success'
      });
    },
    async addEpisode() {
      let response = await this.createEpisode({
        title: this.currentActivity.episodeName,
        date: this.$moment(this.currentActivity.episodeDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD'),
      });
      const episodeId = response.data.episode.id;

      for (let a in this.activities) {
        if (this.activities[a].episodeName == this.currentActivity.episodeName) {
          this.activities[a].episodeId = episodeId;
        }
      }
    },
    async addActivity() {
      let response = await this.createActivity({ 
        episodeId: this.currentActivity.episodeId,
        data: {
          name: this.currentActivity.activityName,
          activityGroup: this.activityGroupId,
          activityType: this.activityTypeId,
          date: this.$moment(this.currentActivity.activityDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD'),
          time: this.$moment(this.currentActivity.activityDate, "YYYY-MM-DD HH:mm:ss").format('HH:mm'),
          privacy: ActivityPrivacy.VISIBLE,
        }
      });
      this.currentActivity.activityId = response.data.activity.id;
    },
    async addFile(file) {
      let date = this.$moment(file.date, "YYYY-MM-DD HH:mm:ss");
      let response = await this.uploadFile({
        activityId: this.currentActivity.activityId,
        data: {
          activity: this.currentActivity.activityId,
          filenameFile: file.file,
          description: null,
          privacy: FilePrivacy.VISIBLE,
          date: date.format('YYYY-MM-DD'),
          time: date.format('HH:mm'),
          type: file.type,
          duration: null,
        },
      }).catch(error => {
        console.error(error.response.data.message);
        this.addAlert({
          text: this.$t('dataUpload.messages.error.fileUpload', { filename: file.file.webkitRelativePath }),
          type: 'error'
        });
        this.notUploadedCount++;
        return;
      });
      if (response && response.data) {
        file.id = response.data.activity_file.id;
        this.uploadedCount++;
      }
      else {
        this.notUploadedCount++;
      }
    },
    async addEpisodeImage(activity, file) {
      if (activity.episodeImage && (activity.episodeImage.file.webkitRelativePath == file.file.webkitRelativePath) && file.id) {
        await this.assignEpisodeImage({
          episodeId: activity.episodeId,
          fileId: file.id,
        }).catch(error => {
          this.addAlert({
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    async addActivityImage(activity, file) {
      if (activity.activityImage && (activity.activityImage.file.webkitRelativePath == file.file.webkitRelativePath) && file.id) {
        await this.assignActivityImage({
          episodeId: activity.episodeId,
          activityId: activity.activityId,
          fileId: file.id,
        }).catch(error => {
          this.addAlert({
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
  },
}
</script>
