<template>
  <div>
    <v-btn
      icon
      :label="$t('actions.select')"
      @click="selectionEnabled = true"
    >
      <v-icon>mdi-checkbox-blank-outline</v-icon>
    </v-btn>

    <div 
      v-if="selectionEnabled"
      class="selection-toolbar"
    >
      <v-container class="selection-toolbar__container">
        <div class="selection-toolbar__inner">

          <v-btn
            icon
            :title="$t('actions.close')"
            class="mr-2"
            @click="selectionEnabled = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <div class="selection-toolbar__count">
            <div class="selection-toolbar__count-text">{{ $t('texts.selected') }}</div>
            {{ selectedNotes.length }} {{ $t('texts.selectedOf') }} {{ notes ? notes.length : 0 }}
          </div>

          <v-btn
            v-if="selectAllFlag"
            icon
            :title="$t('actions.selectAll')"
            class="mr-2"
            @click="selectAll(); selectAllFlag = !selectAllFlag"
          >
            <v-icon>mdi-checkbox-multiple-marked-outline</v-icon>
          </v-btn>

          <v-btn
            v-else
            icon
            :title="$t('actions.clearSelection')"
            class="mr-2"
            @click="clearSelection(); selectAllFlag = !selectAllFlag"
          >
            <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
          </v-btn>

          <actions-menu 
            :disabled="!selectedNotes.length"
            @play="onPlay"
            @success="onSuccess"
          />        

        </div>
      </v-container>
    </div>

    <fullscreen-audio-player 
      ref="fullscreenAudioPlayer"
      :playlist="playlist"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';
import FullscreenAudioPlayer from '@/components/FullscreenAudioPlayer.vue';

export default {
  components: {
    ActionsMenu,
    FullscreenAudioPlayer
  },
  props: {  
    notes: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      selectAllFlag: true,
      selectionEnabled: false
    }
  },
  computed: {
    selectedNotes() {
      return this.notes
        ? this.notes.filter(note => note.selected)
        : [];
    },
    playlist() {
      return this.notes
        ? this.selectedNotes.filter(note => note.urlAudioFile).map(note => note.urlAudioFile)
        : [];
    }
  },
  watch: {
    selectedNotes() {
      this.setSelectedNotes(this.selectedNotes);
    }, 
    selectionEnabled() {
      this.clearSelection();
    }
  },
  methods: {
    ...mapMutations({
      setSelectedNotes: 'notes/setSelectedNotes'
    }),
    clearSelection() {
      let i;
      for (i in this.notes) {
        this.notes[i].selected = false;
      }
    },
    selectAll() {
      let i;
      for (i in this.notes) {
        if (this.notes[i].canEdit) {
          this.notes[i].selected = true;
        }
      }
    },
    onSuccess() {
      this.selectionEnabled = false;
      this.clearSelection();
      this.$emit('success');
    },
    onPlay() {
      this.$refs.fullscreenAudioPlayer.open();
    }
  }
}
</script>
