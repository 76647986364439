<template>
  <div 
    class="alert-item"
    :class="'alert-item--' + item.type"
  >
    <v-icon
      class="mr-2 small"
      :class="'alert-item__icon--' + item.type"
    >{{ icon }}</v-icon>
    <div
      v-html="item.text"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    icon() {
      switch (this.item.type) {
        case 'error':
          return 'mdi-alert-circle';
        case 'success':
          return 'mdi-check-circle';
        default:
          return 'mdi-information-variant-circle-outline';
      }
    },
  },
}
</script>

<style scoped>
.alert-item {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  font-size: 13px;
  background-color: var(--color-well-background);
  border-radius: 12px;
  margin-bottom: 12px;
}
.alert-item--error {
  color: var(--v-error-base);
}
.alert-item__icon--success {
  color: var(--v-success-base) !important;
}
</style>