<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'activity-details', params: { 
        episodeId: this.$route.params.episodeId,
        activityId: this.$route.params.activityId
      } }"
    >
      {{ $t('activities.actions.editActivity') }}
    </page-title>

    <page-tabs v-model="tab">
      <v-tab 
        @click="tab = 0"
      >
        {{ $t('activities.form.tabs.details') }}
      </v-tab>
      <v-tab 
        @click="tab = 1"
      >
        {{ $t('activities.form.tabs.location') }}
      </v-tab>   
    </page-tabs>

    <loading v-if="loading" />

    <v-tabs-items 
      v-else
      v-model="tab"
      class="l-vertical__grow l-vertical"
    >
      <v-tab-item 
        class="l-vertical__grow l-vertical"
        eager
      >
        <tab-1
          :item="item"
          :saving="saving"
          @submit="submit"
        />
      </v-tab-item>

      <v-tab-item 
        class="l-vertical__grow l-vertical"
        eager
      >
        <tab-2
          :item="item"
          :saving="saving"
          @submit="submit"
        />
      </v-tab-item>

    </v-tabs-items>

    <geocode-service
      ref="geocodeService"
      @geocoded="onGeocoded"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import GeocodeService from '@/modules/activities/components/GeocodeService.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import Tab1 from './Tab1';
import Tab2 from './Tab2';

export default {
  components: {
    GeocodeService,
    Loading,
    PageTabs,
    PageTitle,
    Tab1,
    Tab2,
  },
  data() {
    return {
      item: {},
      loading: false,
      tab: 0,
      saving: false
    }
  },
  created() {
    // load activity
    this.loading = true;
    this.getActivity({
      episodeId: this.$route.params.episodeId,
      activityId: this.$route.params.activityId
    })
    .then(response => {
      this.item = response.data.activity;
      this.loading = false;

      // fix date & time format from API
      this.item.date = this.$helperFunctions.fixApiDate(this.item.date);
      this.item.time = this.$helperFunctions.fixApiTime(this.item.time);
      this.item.tab1Valid = true;
      this.item.tab2Valid = true;
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getActivity: 'activities/getActivity',
      updateActivity: 'activities/updateActivity',
      uploadFile: 'files/uploadFile'
    }),
    submit() {
      if (!this.item.tab1Valid || !this.item.tab2Valid) {
        this.addFlash({
          text: this.$t("messages.error.formInvalid"),
          type: 'error'
        });
        return false;
      }

      this.saving = true;

      // clear coordinates
      if (!this.item.country && !this.item.city && !this.item.address) { 
        this.item.latitude = '';
        this.item.longitude = '';
      }
      else {
        // geocode
        if (!this.item.latitude || !this.item.longitude) {
          return this.$refs.geocodeService.geocode(
            this.item.address, 
            this.item.city, 
            this.item.province ? this.item.province.name : '', 
            this.item.country ? this.item.country.name : ''
          );
        }
      }

      this.save();
    },
    onGeocoded({ latitude, longitude }) {
      this.item.latitude = latitude;
      this.item.longitude = longitude;
      this.save();
    },
    async save() {
      try {
        const data = {
          name: this.item.name,
          establishment: this.item.establishment,
          address: this.item.address,
          country: this.item.country ? this.item.country.id : null,
          description: this.item.description,
          province: this.item.province ? this.item.province.id : null,
          city: this.item.city ? this.item.city : null,
          date: this.item.date,
          time: this.item.time,
          privacy: this.item.privacy,
          activityGroup: this.item.activityGroup.id,
          activityType: this.item.activityType.id,
          imageFile: this.item.imageFile || undefined,
          latitude: this.item.latitude,
          longitude: this.item.longitude,
          melody: this.item.melody ? this.item.melody.id : undefined
        }

        let response = await this.updateActivity({ 
          activityId: this.$route.params.activityId,
          episodeId: this.$route.params.episodeId,
          data: data
        });
        const activity = response.data.activity;

        // upload activity image to gallery
        if (this.item.imageFile) {
          await this.uploadFile({
            activityId: activity.id,
            data: {
              privacy: activity.privacy,
              date: this.$helperFunctions.fixApiDate(activity.date),
              time: this.$helperFunctions.fixApiTime(activity.time),
              description: activity.name,
              activity: activity.id,
              filenameFile: this.item.imageFile,
              type: 'photo'
            }
          });
        }

        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'          
        });
        // redirect to activity details
        this.$router.push({ name: 'activity-details', params: { 
          episodeId: this.$route.params.episodeId,
          activityId: this.$route.params.activityId
        } });
      }
      catch(error) {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      }

      this.saving = false;
    }
  }
}
</script>