import WorldMapPage from '@/modules/worldmap/pages/WorldMapPage/WorldMapPage.vue';

export default [
  {
    path: '/worldmap',
    name: 'worldmap',
    component: WorldMapPage,
    meta: { requiresAuth: true }
  }
];
