<template>
  <div class="list-item">
    <user-avatar
      v-if="item.urlAvatar"
      :src="item.urlAvatar"
      :size="35"
      class="mr-3"
    />
    <div class="small">
      {{ item.title }}
    </div>
    
    <div class="list-item__price">
      <various-currencies v-if="item.errorVariousCurrencies" />
      <template v-else>{{ $helperFunctions.formatMoney(item.amount) }} {{ item.currency }}</template>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/modules/user/components/UserAvatar.vue';
import VariousCurrencies from '@/components/util/VariousCurrencies.vue';

export default {
  components: {
    UserAvatar,
    VariousCurrencies,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
  },
}
</script>

<style scoped>
.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.list-item__price {
  margin-left: auto;
}
</style>
