<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :title="$t('actions.filter')"
        :class="{
          'ml-1': $vuetify.breakpoint.mdAndUp
        }"
        v-on="on"
      >
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">


      <!-- filter by cost ------------------------------- -->

      <v-list-item 
        dense
        @click.stop.prevent="cost = 'all'"
      >
        <v-checkbox
          v-model="cost"
          value="all"
          :label="$t('procedures.texts.filter.all')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="cost = 'withCost'"
      >
        <v-checkbox
          v-model="cost"
          value="withCost"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        >
          <template v-slot:label>
            <v-icon class="mr-2">mdi-cash-multiple</v-icon> 
            {{ $t('procedures.texts.filter.withCost') }}
          </template>
        </v-checkbox>
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="cost = 'withoutCost'"
      >
        <v-checkbox
          v-model="cost"
          value="withoutCost"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        >
          <template v-slot:label>
            <v-icon class="mr-2">mdi-cash-remove</v-icon> 
            {{ $t('procedures.texts.filter.withoutCost') }}
          </template>
        </v-checkbox>
      </v-list-item>


      <hr class="my-2">
        

      <!-- filter by type ------------------------------- -->

      <v-list-item 
        dense
        @click.stop.prevent="selectAllTypes()"
      >
        <v-checkbox
          v-model="typesAll"
          :value="true"
          :label="$t('procedures.texts.filter.all')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        v-for="type in ProcedureTypes"
        :key="type.id"
        dense
        @click.stop.prevent="onTypeClick(type.id)"
      >
        <v-checkbox
          v-model="types[type.id]"
          :value="true"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        >
          <template v-slot:label>
            <div @click="onTypeClick(type.id)">
              <v-icon class="mr-2">{{ type.icon }}</v-icon> 
              {{ $t('procedures.model.types.' + type.id) }}
            </div>
          </template>
        </v-checkbox>
      </v-list-item>


      <hr class="mt-2">


      <v-row class="mx-0 my-2">
        <v-col 
          cols="6" 
          class="pr-2"
        >
          <v-btn
            outlined
            block
            small
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>
        <v-col 
          cols="6" 
          class="pl-2"
        >
          <v-btn 
            block
            small
            color="primary"
            @click="$emit('change', { cost, types: { ...types} , typesAll })"
          >{{ $t('actions.apply') }}</v-btn>
        </v-col>
      </v-row>

    </v-list>
  </v-menu>
</template>


<script>
import ProcedureTypes from '@/modules/procedures/model/ProcedureTypes.js';

export default {
  data() {
    return {
      cost: 'all',
      types: {},
      typesAll: true,
      ProcedureTypes: ProcedureTypes
    }
  },
  created() {
    // load types
    for (let i in ProcedureTypes) {
      this.$set(this.types, ProcedureTypes[i].id, false);
    }

    // pre-select filter
    if (this.$route.query.type) {
      this.typesAll = false;
      this.types[this.$route.query.type] = true;
    }
  },
  methods: {
    selectAllTypes() {
      this.typesAll = true;
      for (let i in this.types) {
        this.types[i] = false;
      }
    },
    onTypeClick(id) {
      this.types[id] = !this.types[id]; 
      this.typesAll = false;
    }
  }
}
</script>