<template>
  <v-dialog
    v-if="item"
    v-model="isOpen" 
    width="450"
  >
    <v-container class="py-3 px-6">
      <div class="subtitle-1 mt-2 mb-6">{{ $t('episodeAccesses.dialogs.edit.title') }}</div>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >

        <v-text-field
          v-model="alias"
          :label="$t('episodeAccesses.form.label.alias')"
          maxlength="12"
          outlined
          dense
          class="v-input--darker"
          validate-on-blur
          :hint="$t('episodeAccesses.form.hint.alias')"
          persistent-hint
        />

      </v-form>

    </v-container>

    <hr>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="$emit('close')"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            class="primary"
            block
            small
            :disabled="!valid"
            :loading="saving"
            @click="submit"
          >{{ $t('actions.save') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      alias: null,
      isOpen: false,
      email: null,
      saving: false,
      valid: false
    };
  },
  watch: {
    item(item) {
      this.isOpen = !!item;
    },
    isOpen(isOpen) {
      if (!isOpen) {
        this.$emit('close');
      }
      else {
        this.alias = this.item.alias;
      }
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      updateAccess: 'episodeAccesses/updateAccess'
    }),
    submit() {
      this.saving = true;

      let data = { 
        episodeId: this.$route.params.episodeId,
        accessId: this.item.id,
        alias: this.alias
      };

      this.updateAccess(data)
      .then(() => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.item.alias = this.alias;
        this.$emit('success');
        this.$emit('close');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>
