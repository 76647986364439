export default {
  /**
   * This function is using cordova-plugin-photo-library-wkwebview for downloading files on iOS.
   */
  downloadImage: function(url) {
    return new Promise(
      (resolve, reject) => {
        cordova.plugins.photoLibrary.saveImage(url, 'timlapp', cameraRollAssetId => {
          resolve(cameraRollAssetId);
        }, error => {
          reject(error);
        });
      }
    );
  },
  downloadVideo: function(url) {
    return new Promise(
      (resolve, reject) => {
        // console.log('downloadVideo url', url);
        let extension = url.split(/[#?]/)[0].split('.').pop().trim();
        let targetFile = cordova.file.tempDirectory + 'video.' + extension;
        // console.log('targetFile', targetFile);
        let fileTransfer = new FileTransfer();
        fileTransfer.download(
          url, 
          targetFile, 
          entry => {
            let fileUrl = entry.toURL();
            // console.log("download complete: " + fileUrl);
            cordova.plugins.photoLibrary.saveVideo(fileUrl, 'timlapp', cameraRollAssetId => {
              entry.remove();
              resolve(cameraRollAssetId);
            }, error => {
              entry.remove();
              reject(error);
            })
          },
          error => {
            reject(error);
          }
        );
    });
  }
};