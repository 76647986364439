import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    createTransfer(context, { toUser, files }) {
      return axios.post('api/transfer', { toUser, files });
    },
    getReceivedTransfers(context){
      return axios.get('/api/transfer/incoming');
    },
    getSentTransfers(context){
      return axios.get('/api/transfer/outgoing');
    },
    getTransfer(context, id){
      return axios.get('/api/transfer/' + id);
    },
    acceptTransfer(context, id){
      return axios.post('/api/transfer/' + id + '/accept');
    },
    rejectTransfer(context, id){
      return axios.post('/api/transfer/' + id + '/reject');
    },
    deleteTransfer(context, id){
      return axios.delete('/api/transfer/' + id);
    },
    forwardTransfer(context, { transferId, userId }){
      return axios.post('/api/transfer/' + transferId + '/forward/' + userId);
    },
  }
}
