<template>
  <div 
    v-if="item"
    class="user-item"
  >
    <user-avatar 
      :src="item.urlAvatar" 
      class="user-item__avatar" 
      :size="height"
      :disable-modal="disableModal"
    />
    <div class="user-item__data">
      <div 
        v-if="title"
        class="overline small"
      >{{ title }}</div>
      <div class="user-item__alias">{{ item.alias }}</div>
      <div 
        v-if="showName"
        class="user-item__name"
        :class="{ 'user-item__name--no-ellipsis': noEllipsis }"
      >{{ item.name }} {{ item.lastname }}</div>
      <div 
        v-if="showEmail && (height > 30)"
        class="user-item__email"
        :class="{ 'user-item__email--no-ellipsis': noEllipsis }"
      >{{ item.email }}</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import UserAvatar from "./UserAvatar";

export default {
  components: {
    UserAvatar
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    height: {
      type: Number,
      default: 56
    },
    disableModal: {
      type: Boolean,
      default: false
    },
    showName: {
      type: Boolean,
      default: false
    },
    showEmail: {
      type: Boolean,
      default: true
    },
    noEllipsis: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
.user-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-item__avatar {
  margin-right: 12px;
}
.user-item__data {
  overflow: hidden;
}
.user-item__alias {
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-item__name {
  color: var(--color-grey);
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-item__name--no-ellipsis {
  overflow: visible;
  text-overflow: unset;
}
.user-item__email {
  color: var(--color-grey);
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-item__email--no-ellipsis {
  overflow: visible;
  text-overflow: unset;
  word-break: break-word;
}
</style>