<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episode-details', params: { episodeId: $route.params.episodeId } }"
      :back-to-routes-names="['favorites', 'search-activities-results']"
    >
      {{ activity ? activity.name : '' }}
      <template slot="buttons">
        <app-fab 
          v-if="episode && (episode && episode.rol && (episode.rol != roles.COMMENTATOR))"
          add-files
          add-notes
        />
      </template>
    </page-title>

    <activity-tabs />

    <div class="l-vertical__scroll">

      <activity-header
        :episode="episode"
        :activity="activity"
        @modified="loadActivity"
      />
        
      <loading v-if="!activity" />

      <v-container
        v-if="activity"
        class="container--narrow"
      >

        <user-item
          :item="activity.user"
          :title="$t('activities.titles.owner')"
          no-ellipsis
          class="mt-2 mb-6"
        />

        <div 
          v-if="activity.description"
          class="mb-6 text-center"
        >
          <collapse-text center-button>
            {{ activity.description }}
          </collapse-text>
        </div>

        <v-row 
          v-if="activity.establishment || activity.address || activity.province || activity.country"
          class="mb-6"
        >
          <v-col class="flex-grow-0 d-flex align-center">
            <v-btn
              icon
              :to="{ 
                name: 'episode-map', 
                params: { episodeId: $route.params.episodeId },
                query: { activity: activity.id } 
              }"
              color="primary"
            >
              <v-icon 
                large 
              >mdi-map</v-icon>
            </v-btn>
          </v-col>

          <v-col class="pa-0 d-flex justify-center flex-column">
            <div>{{ activity.establishment }}</div>
            <div>{{ activity.address }}</div>
            <div>
              <template v-if="activity.city">{{ activity.city }}, </template>
              <template v-if="activity.province">{{ activity.province.name }}, </template>
              <template v-if="activity.country">{{ activity.country.name }}</template>
            </div>
          </v-col>
        </v-row>

        <v-row 
          class="mb-6"
        >
          <v-col class="flex-grow-0 d-flex align-center pr-2">
            <v-switch
              v-if="episode && episode.rol && (episode.rol != roles.COMMENTATOR)"
              v-model="attended"
              :loading="savingNotAttended"
              :disabled="savingNotAttended"
              hide-details
              class="mt-0 mb-3"
              @change="onNotAttendedClick"
            />
            <v-switch
              v-else
              :value="false"
              disabled
              hide-details
              class="mt-0 mb-3"
            />
          </v-col>

          <v-col class="px-0 pt-1 pb-0">
            <template v-if="episode && episode.rol && (episode.rol != roles.COMMENTATOR)"> 
              {{ attended ? $t('activities.titles.attended') : $t('activities.titles.notAttended') }}
            </template>
            <template v-else>{{ $t('activities.titles.notAttended') }}</template>

            <div class="small overline mt-1">
              <template v-if="episode && episode.rol && (episode.rol != roles.COMMENTATOR)">
                {{ attended ? $t('activities.titles.attendedHint') : $t('activities.titles.notAttendedHint') }}
              </template>
              <template v-else>
                {{ $t('activities.titles.notAttendedHintCommentator') }}
              </template>
            </div>
          </v-col>
        </v-row>



        <v-row 
          v-if="activity.melody"
          class="mb-6"
        >
          <v-col class="flex-grow-0 d-flex align-center pr-4">
            <v-icon 
              class="grey--text"
              large
            >mdi-music-note</v-icon>
          </v-col>

          <v-col class="pa-0 d-flex justify-center flex-column">
            <div>{{ activity.melody.name }}</div>
            <div>{{ activity.melody.author }}</div>
            <div>{{ activity.melody.type }}</div>
          </v-col>
        </v-row>
        
      </v-container>
      
    </div>

    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ActivityHeader from '@/modules/activities/components/ActivityHeader.vue';
import ActivityTabs from '@/modules/activities/components/ActivityTabs';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import CollapseText from '@/components/util/CollapseText';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    ActivityTabs,
    ActivityHeader,
    AppFab,
    CollapseText,
    Loading,
    MenuBottom,
    PageTitle,
    UserItem
  },
  data() {
    return {
      attended: false,
      roles: roles,
      savingNotAttended: false
    }
  }, 
  computed: {
    ...mapGetters({
      activity: 'activities/cachedActivity',
      episode: 'episodes/cachedEpisode'
    })
  },
  watch: {
    'activity.notAttended': function(value) {
      if (!this.savingNotAttended) {
        this.attended = !value;
      } 
    }
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    this.loadActivity();

    this.attended = this.activity ? !this.activity.notAttended : true;
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      loadActivityToCache: 'activities/loadActivityToCache',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
      setNotAttended: 'activities/setNotAttended',
    }),
    loadActivity() {
      // load activity
      this.loadActivityToCache({
        episodeId: this.$route.params.episodeId,
        activityId: this.$route.params.activityId
      });
    },
    onNotAttendedClick() {
      this.savingNotAttended = true;
      this.setNotAttended({
        episodeId: this.$route.params.episodeId,
        activityId: this.$route.params.activityId,
        notAttended: this.attended ? 0 : 1
      })      
      .then(response => {
        this.attended = !response.data.activity.notAttended;
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.loadActivityToCache({
          episodeId: this.$route.params.episodeId,
          activityId: this.$route.params.activityId
        });
      })
      .finally(() => {
        this.savingNotAttended = false;
      })
    }
  }
}
</script>

<style scoped>
.activity__type {
  margin-top: 6px;
  margin-left: 300px;
  height: 80px;
}

/* mobile */
@media (max-width: 1024px) {
  .activity__type {
    margin-left: 180px;
  }
}
</style>