export default {
  /**
   * This function is using cordova-plugin-file for downloading files to 'Downloads' folder on Android.
   */
  downloadBlob: function(blob, filename) {
    document.addEventListener("deviceready", function () {
      var storageLocation = 'file:///storage/emulated/0/Download';

      window.resolveLocalFileSystemURL(
        storageLocation,
        function (dir) {
          dir.getFile(
            filename,
            {
              create: true
            },
            function (file) {
              file.createWriter(
                function (fileWriter) {
                  fileWriter.onwriteend = function () {
                    console.log("success with downloading the file");
                  };

                  fileWriter.onerror = function (err) {
                    alert("Unable to download");
                    console.error("Error fileWriter.write", err);
                  };

                  fileWriter.write(blob);
                },
                function (err) {
                  // failed
                  alert("Unable to download");
                  console.error("Error file.createWriter", err);
                }
              );
            },
            function (err) {
              alert("Unable to download");
              console.error("Error dir.getFile", err);
            }
          );
        },
        function (err) {
          alert("Unable to download");
          console.error("Error window.resolveLocalFileSystemURL", err);
        }
      );
    });
  }
};