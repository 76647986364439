<template>
  <v-menu 
    v-model="open"
    offset-y
    left
    eager
    :disabled="selectionHasPendingFiles"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :disabled="disabled"
        :class="disabled ? 'actions-menu__button-disabled' : 'primary'"
        v-on="on"
        @click="onButtonClick"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">        

      <v-list-item :to="{ name: 'multiple-files-edit'}">
        <v-icon class="mr-3 primary--text">mdi-pencil-outline</v-icon> 
        {{ $t('actions.edit') }}
      </v-list-item>

      <move-dialog @success="$emit('success')" />

      <download-item 
        :selected-files="selectedFiles"
        @close="open = false; $emit('close')"
      />

      <transfer-item 
        :selected-files="selectedFiles"
        @close="open = false"
      /> 

      <delete-dialog @success="$emit('success')" />

    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import DeleteDialog from './DeleteDialog';
import DownloadItem from './DownloadItem.vue';
import MoveDialog from './MoveDialog';
import TransferItem from './TransferItem.vue';

export default {
  components: {
    DeleteDialog,
    DownloadItem,
    MoveDialog,
    TransferItem
  },
  props: {  
    selectedFiles: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    disabled() {
      return !this.selectedFiles.length;
    },
    selectionHasPendingFiles() {
      /**
       * check if any selected file is transferPending
       */ 
      for (let i in this.selectedFiles) {
        if (this.selectedFiles[i].transferPending) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    onButtonClick() {
      if (this.selectionHasPendingFiles) {
        this.addFlash({
          text: this.$t("transfers.messages.selectionHasPendingFiles"),
          type: 'warning'
        });
      }
    }
  }
}
</script>

<style scoped>
.actions-menu__button-disabled {
  visibility: hidden;
}
</style>