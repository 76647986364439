<template>
  <div class="l-vertical__grow l-vertical">
    <page-title history-back>
      {{ $t('contacts.texts.contact') }}
      <template slot="buttons">
        <filter-menu @change="filters = $event" />

        <sort-menu @change="sortBy = $event" />
      </template>
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('contacts.titles.sharedEpisodes') }}
      </v-tab>
    </page-tabs>



    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <v-container class="container--narrow pb-0">

        <loading v-if="loading" />


        <div v-else>

          <div class="button-edit__wrap">
            <v-btn
              :title="$t('contacts.actions.changeAlias')"
              icon
              class="button-edit"
              color="primary"
              @click="aliasDialogOpen = true"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </div>

          <user-item
            :item="contact.user"
            class="mb-5"
            show-name
            no-ellipsis
          />

          <div class="small mb-3">
            {{ $tc('contacts.texts.collaboratesInEpisodes', contact.episodes.length, { episodesCount: contact.episodes.length }) }}:
          </div>

          <episode-item
            v-for="episode in episodesFilteredSorted"
            :key="episode.id"
            :item="episode"
          />

        
          <v-alert
            v-if="contact && contact.episodes && contact.episodes.length && !episodesFilteredSorted.length"
            type="info"
          >
            {{ $t('texts.noElementsMatching') }}
          </v-alert>

        </div>

      </v-container>

    </div>

    <v-dialog
      v-if="aliasDialogOpen"
      :value="true"
      width="450"
    >
      <alias-dialog
        v-if="contact" 
        :user="contact.user"
        @close="aliasDialogOpen = false"
        @saved="loadUser"
      />
    </v-dialog>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AliasDialog from './AliasDialog.vue';
import EpisodeItem from './EpisodeItem';
import EpisodeRoles from '@/modules/episodes/model/EpisodeRoles.js';
import FilterMenu from './FilterMenu';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import SortMenu from './SortMenu';
import statusTap from '@/mixins/statusTap.js';
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    AliasDialog,
    EpisodeItem,
    FilterMenu,
    Loading,
    PageTabs,
    PageTitle,
    SortMenu,
    UserItem
  },
  mixins: [statusTap],
  data() {
    return {
      aliasDialogOpen: false,
      contact: null,
      filters: 'all',
      loading: false,
      sortBy: 'dateAsc'
    };
  }, 
  computed: {
    episodesFiltered() {
      if (!this.contact.episodes) {
        return [];
      }
      return this.contact.episodes.filter(item => {
        // filter by role
        switch (this.filters) {
          case 'editor':
            return (item.rol == EpisodeRoles.CREATOR) || (item.rol == EpisodeRoles.EDITOR);
          case 'commentator':
            return item.rol == EpisodeRoles.COMMENTATOR;
        }

        return true;
      });
    },
    episodesFilteredSorted() {
      if (!this.episodesFiltered) {
        return [];
      }
      let episodes = [...this.episodesFiltered];
      switch (this.sortBy) {      
        case 'dateAsc':
          episodes.sort((a, b) => a.date < b.date ? -1 : 1);       
          break;       
        case 'dateDesc':
          episodes.sort((a, b) => a.date < b.date ? 1 : -1);       
          break;       
        case 'title':
          episodes.sort((a, b) => (a.title || '').localeCompare(b.title || ''));      
          break;
      }
      return episodes;
    }
  },
  created() {
    this.loadUser();
  },
  methods: {
    ...mapActions({
      getContact: 'contacts/getContact'
    }),
    loadUser() {
      this.loading = true;
      this.getContact(this.$route.params.id).then(
        response => {
          this.contact = response.data.contact;
          this.loading = false;
        }
      );
    }
  }
}
</script>

<style scoped>
.button-edit__wrap {
  position: relative;
}
.button-edit {
  position: absolute;
  right: 0;
}
</style>