<template>
  <div class="generic-list-item">

    <div class="generic-list-item__main">  

      <div class="d-flex align-start">
        <v-icon 
          size="30"
          class="mr-2 mb-1"
          :title="item.activityGroup.name"
        >{{ item.activityGroup.icon }}</v-icon>
        <div class="flex-grow-1">
          <div class="overline small mb-1">
            {{ $moment(item.date).format($t('locale.formatDate')) }} {{ $helperFunctions.fixApiTime(item.time) }}
          </div>
          <div class="mb-1">{{ item.title }}</div>
          <div>{{ $helperFunctions.formatMoney(item.amount) }} {{ item.currency }}</div>
        </div>
        <div class="flex-grow-0 expenses-list-item__user">
          <user-avatar
            :src="item.userDeleted ? null : item.user.urlAvatar"
            :size="35"
            class="mb-1"
          />
          <div class="small">
            {{ item.userDeleted ? $t('user.titles.userDeleted') : item.user.alias }}
          </div>
        </div>
      </div>
    </div>

    <div class="generic-list-item__buttons">
      <actions-menu 
        v-if="showMenu"
        :item="item" 
        @delete="$emit('delete')"
      />
    </div>

  </div>
</template>

<script>
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';
import UserAvatar from '@/modules/user/components/UserAvatar.vue';

export default {
  components:{
    ActionsMenu,
    UserAvatar,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.expenses-list-item__user {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
}
</style>
