import axios from 'axios';
import i18n from './i18n';

export default (store) =>  {

  axios.defaults.baseURL = process.env.VUE_APP_API_URL;

  axios.interceptors.request.use(function (request) {
    let apiToken = store.getters['user/apiToken'];
    if (null !== apiToken) {
      request.headers.common['Authorization'] = 'Bearer ' + apiToken;
    }
    request.headers.common['Accept'] = 'application/json, text/plain, */*';
    request.headers.common['Accept-Language'] = i18n.locale;

    return request;
  }, function (error) {
    return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    store.commit('offline', false);
    return response;
  }, function (error) {

    if (error == 'Cancel') {
      return;
    }
      
    // server is not responding - show OFFLINE icon
    if (!error.response) {
      store.commit('offline', true);
      return Promise.reject(error);
    }

    // Expired JWT Token
    let originalRequest = error.config; // copy original request

    if (error.response
      && error.response.status === 401 
      && error.response.data.message === "Expired JWT Token"
      && !(originalRequest.data && originalRequest.data.refresh_token)
    ) {      
      return store.dispatch('user/refreshToken')
      .then(result => {
        // add new token to original request
        originalRequest.headers['Authorization'] = 'Bearer ' + store.getters['user/apiToken'];
        // return original request
        return axios(originalRequest);
      })
      .catch(error => {
        store.commit('user/logout');
      })
    }

    // Invalid JWT Token
    if (error.response
      && error.response.status === 401 
      && error.response.data.message === "Invalid JWT Token"
    ) {
      store.commit('user/logout');
    } 

    // Refresh token xxx does not exist
    if (error.response
      && error.response.status === 401 
      && error.response.data.message.indexOf("Refresh token") === 0
      && error.response.data.message.indexOf("does not exist")
    ) {
      store.commit('user/logout');
    } 

    return Promise.reject(error);
  });

}

