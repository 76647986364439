<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('dataPolicy.titles.title') }}
    </page-title>
    
    <page-tabs v-model="tab">
      <v-tab @click="tab = 0">{{ $t('dataPolicy.titles.legal') }}</v-tab>
      <v-tab @click="tab = 1">{{ $t('dataPolicy.titles.cookies') }}</v-tab>
      <v-tab @click="tab = 2">{{ $t('dataPolicy.titles.policies') }}</v-tab>
      <v-tab @click="tab = 3">{{ $t('dataPolicy.titles.privacy') }}</v-tab>
    </page-tabs>

    <v-tabs-items 
      ref="statusTap"
      v-model="tab"
      class="l-vertical__grow l-vertical"
    >
    
      <v-tab-item class="l-vertical__grow l-vertical">
        <div class="l-vertical__scroll">
          <v-container v-html="$t('dataPolicyLegal')"></v-container>
        </div>
      </v-tab-item>

      <v-tab-item class="l-vertical__grow l-vertical">
        <div class="l-vertical__scroll">
          <v-container v-html="$t('dataPolicyCookies')"></v-container>
        </div>
      </v-tab-item>

      <v-tab-item class="l-vertical__grow l-vertical">
        <div class="l-vertical__scroll">
          <v-container v-html="$t('dataPolicyPolicies')"></v-container>
        </div>
      </v-tab-item>

      <v-tab-item class="l-vertical__grow l-vertical">
        <div class="l-vertical__scroll">
          <v-container v-html="$t('dataPolicyPrivacy')"></v-container>
        </div>
      </v-tab-item>

    </v-tabs-items>
    
    
    <menu-bottom />
  </div>
</template>

<script>
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    MenuBottom,
    PageTabs,
    PageTitle
  },  
  data() {
    return {
      tab: 0
    }
  },
};
</script>
