<template>
  <v-menu 
    offset-y
    left
    eager
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :disabled="disabled"
        :class="disabled ? 'actions-menu__button-disabled' : 'primary'"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">   

      <delete-dialog 
        :selected-items="selectedItems"
        @success="$emit('success')" 
      />

    </v-list>
  </v-menu>
</template>

<script>
import DeleteDialog from './DeleteDialog';

export default {
  components: {
    DeleteDialog
  },
  props: {  
    selectedItems: {
      type: Array,
      default: Array
    }, 
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.actions-menu__button-disabled {
  visibility: hidden;
}
</style>