<template>
  <div class="l-vertical__grow l-vertical">

    <page-title :back-to="{ name: 'user-login'}">
      {{ $t('user.titles.createAccount') }}
    </page-title>

    <register-tabs 
      v-if="currentStep != 3"
      :step="currentStep"
      :user-data="userData"
      @change="currentStep = $event"
    />

    <step-1 
      v-show="currentStep == 1"
      :user-data="userData"
      @go-to-step="currentStep = $event"
    />
    <step-2 
      v-show="currentStep == 2"
      :user-data="userData"
      @go-to-step="currentStep = $event"
    />
    <step-3
      v-show="currentStep == 3"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/layout/PageTitle.vue";
import RegisterTabs from './RegisterTabs';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

export default {
  components: {
    PageTitle,
    RegisterTabs,
    Step1,
    Step2,
    Step3
  },
  data() {
    return {
      currentStep: 1,
      userData: {}
    }
  }
}
</script>
