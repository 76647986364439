import TransferAddPage from '@/modules/transfers/pages/TransferAddPage.vue';
import TransferForwardPage from '@/modules/transfers/pages/TransferForwardPage.vue';
import TransferSentDetailsPage from '@/modules/transfers/pages/TransferSentDetailsPage.vue';
import TransferReceivedDetailsPage from '@/modules/transfers/pages/TransferReceivedDetailsPage.vue';
import TransferListPage from '@/modules/transfers/pages/TransferListPage/TransferListPage.vue';

export default [
  {
    path: '/transfer/add/:episodeId/:activityId?',
    name: 'transfer-add',
    component: TransferAddPage,
    meta: { requiresAuth: true }
  },  
  {
    path: '/transfers/:tab?',
    name: 'transfers-list',
    component: TransferListPage,
    meta: { requiresAuth: true }
  }, 
  {
    path: '/transfer-sent/:transferId',
    name: 'transfer-sent-details',
    component: TransferSentDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/transfer-received/:transferId',
    name: 'transfer-received-details',
    component: TransferReceivedDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/transfer-forward/:transferId',
    name: 'transfer-forward',
    component: TransferForwardPage,
    meta: { requiresAuth: true }
  },
]
