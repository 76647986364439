/* 
 * This mixin is intended to be used in App.vue
 */

import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'user/user'
    })  
  },
  watch: {
    user(value) {
      if (this.$helperFunctions.isCordova()) {
        if (value) {
          this.afterLogin();
        }
      }
    }
  },
  methods: {
    ...mapActions({
      updateMessagingToken: 'user/updateMessagingToken',
    }),
    afterLogin() {
      console.log('afterLogin', window.FirebasePlugin);

      // register message handler
      window.FirebasePlugin.onMessageReceived(message => {
        console.log("onMessageReceived success", message);
        if (message.messageType === "notification"){
          // on tap
          if (message.tap) {
            // go to notifications page
            this.$router.push({ name: 'notifications-list', params: { refresh: this.$moment().unix() } });
          }
        }
      }, function(error) {
          console.log(error);
      });

      // get user permission
      window.FirebasePlugin.grantPermission(() => {
        console.log("grantPermission success");
      });

      // get token
      window.FirebasePlugin.getToken(messagingToken => {
        console.log("getToken success", messagingToken);
        // save token to the DB
        this.updateMessagingToken(messagingToken);
      }, error => {
        console.log("Failed to get messagingToken: " + error);
      });   

      // register TokenRefresh handler
      window.FirebasePlugin.onTokenRefresh(messagingToken => {
        console.log("onTokenRefresh success", messagingToken);
        if (this.user) {
          // save token to the DB
          this.updateMessagingToken(messagingToken);
        }
      }, function(error) {
        console.log("Failed to refresh messagingToken: " + error);
      });
    }
  }
}
