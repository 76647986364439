import ProcedureAddPage from '@/modules/procedures/pages/ProcedureAddPage.vue';
import ProcedureEditPage from '@/modules/procedures/pages/ProcedureEditPage/ProcedureEditPage.vue';
import ProcedureListPage from '@/modules/procedures/pages/ProcedureListPage/ProcedureListPage.vue';

export default [
  {
    path: '/episode/:episodeId/add-procedure',
    name: 'procedure-add',
    component: ProcedureAddPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/procedure/:procedureId',
    name: 'procedure-edit',
    component: ProcedureEditPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/procedures',
    name: 'procedure-list',
    component: ProcedureListPage,
    meta: { requiresAuth: true }
  }
]
