x<template>
  <v-menu 
    v-model="open"
    offset-y
    left
    eager
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :disabled="disabled"
        :class="disabled ? 'actions-menu__button-disabled' : 'primary'"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">  

      <add-to-group-dialog 
        :selected-items="selectedItems"
        @success="$emit('success')"
      />      

      <v-list-item @click="onNewGroupClicked">
        <v-icon class="mr-3 primary--text">mdi-account-group-outline</v-icon> 
        {{ $t('contacts.actions.newGroup') }}
      </v-list-item>

    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import AddToGroupDialog from './AddToGroupDialog.vue';

export default {
  components: {
    AddToGroupDialog,
  },
  props: {  
    selectedItems: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    disabled() {
      return !this.selectedItems.length;
    }
  },
  methods: {
    ...mapMutations({
      setSelectedItems: 'contacts/setSelectedContacts'
    }),
    onNewGroupClicked() {
      this.setSelectedItems(this.selectedItems);
      this.$router.push({ name: 'contact-group-add' });
    }
  }
}
</script>

<style scoped>
.actions-menu__button-disabled {
  visibility: hidden;
}
</style>