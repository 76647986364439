<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="mb-5 mt-4"
    >
      <v-text-field
        label="Text field"
        placeholder="Placeholder"
        required
        :rules="[v => !!v || $t('messages.error.empty')]"
      />

      <v-text-field
        label="Text field"
        placeholder="Placeholder"
        required
        outlined
        :rules="[v => !!v || $t('messages.error.empty')]"
      />

      <v-text-field
        label="Text field"
        placeholder="Placeholder"
        required
        outlined
        dense
        :rules="[v => !!v || $t('messages.error.empty')]"
      />
      
      <v-textarea
        label="Textarea"
        placeholder="Placeholder"
        auto-grow
        rows="4"
        required
        :rules="[v => !!v || $t('messages.error.empty')]"
      />

      <v-checkbox
        label="Checkbox"
        class="pl-0"
        required 
      />

      <v-radio-group
        v-model="radios"
        class="mt-0 pl-0"
        hide-details
      >
        <v-radio 
          label="Radio 1" 
          value="radio-1" 
        />
        <v-radio
          label="Radio 2"
          value="radio-2" 
        />
      </v-radio-group>

      <v-select
        label="Select"
        placeholder="Placeholder"
        :items="items"
        item-text="name"
        item-value="id"
        return-object
        required
        :rules="[v => !!v || $t('messages.error.empty')]"
      >
        <span slot="no-options">No options message</span>
      </v-select>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'TabForms',
  data() {
    return {
      items: [
        { id: 1, name: 'Item 1' },
        { id: 2, name: 'Item 2' },
        { id: 3, name: 'Item 3' }
      ],
      radios: 'radio-1',
      valid: true
    };
  }
};
</script>