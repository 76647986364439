import Vue from "vue";
import ExifReader from 'exifreader';
import moment from 'moment';

const imageFunctions = {

  /**
   * Get EXIF date from image file
   * @param {*} File file
   * @return {String} date in format YYYY-MM-DD HH:mm:ss
   */
  async getExifDate(file) { 
    const tags = await ExifReader.load(file);
    if (!tags['DateTimeOriginal']) {
      return null;
    }
    const imageDate = tags['DateTimeOriginal'].description;
    if (imageDate.substring(0, 4) === '0000') {
      return null;
    }
    if (imageDate.substring(10, 11) === 'T') {
      return moment(imageDate).format('YYYY:MM:DD HH:mm:ss');
    }
    if (imageDate.substring(10, 11) === ' ') {
      return imageDate;
    }
    return imageDate;
  },

  /**
   * Compress and resize image as JPEG
   * https://zocada.com/compress-resize-images-javascript-browser/
   *
   * @param {*} File file
   * @param String maxWidth 
   * @param String maxHeight 
   * @param Number quality - JPEG quality 0-100
   * 
   * @return File 
   */
  scale(file, maxWidth, maxHeight, quality = 80) {
    return new Promise((resolve, reject) => {
      const fileName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;
        (img.onload = () => {

          // calculate output size
          let width, height;

          if (maxHeight && !maxWidth && img.height > maxHeight) { // scale by height
            height = maxHeight;
            width = img.width * maxHeight / img.height;		
          } 
          else if (!maxHeight && maxWidth && img.width > maxWidth) { // scale by width
            width = maxWidth;
            height = img.height * maxWidth / img.width;
          } 
          else if (maxHeight && maxWidth && (img.height > maxHeight || img.width > maxWidth)){
            if (img.height / maxHeight > img.width/maxWidth) { // scale by height
              height = maxHeight;
              width = img.width * maxHeight / img.height;
            } 
            else { // scale by width
              width = maxWidth;
              height = img.height * maxWidth / img.width;
            }
          } else { //  no scaling needed, original image is smaller
            width = img.width;
            height = img.height;
          }


          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          canvas.width = width;
          canvas.height = height;
          context.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            blob => {
              resolve(blob);
            },
            "image/jpeg",
            quality / 100
          );

        }),
          (reader.onerror = error => console.log(error));
      };
    });
  }
  
};

const plugin = {
  install() {
    Vue.imageFunctions = imageFunctions;
    Vue.prototype.$imageFunctions = imageFunctions;
  }
};

Vue.use(plugin);

export default imageFunctions;
