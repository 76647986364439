<template>
  <div>

    <type-field
      :item="item"
    />

    <v-textarea
      v-model="item.text"
      :label="$t('procedures.form.label.text')"
      :placeholder="item.type ? $t('procedures.form.placeholder.text.' + item.type) : ' '"
      :rules="[$validationRules.required]"
      rows="4"
      :auto-grow="true"
      :disabled="!item.type"
      maxlength="250"
    />

    <v-row>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model="item.cost"
          :label="$t('procedures.form.label.cost')"
          type="number"
          placeholder=" "
          :disabled="!item.type"
        />
      </v-col>

      <v-col
        cols="6"
      >
        <currency-select
          v-model="item.currency"
          :disabled="!item.type"
        />
      </v-col>
    </v-row>

    <v-file-input
      v-model="item.file"       
      :accept="ProcedureFile.FILE_TYPES_ACCEPT"     
      :rules="fileRules"
      :placeholder="filePlaceholder"
      :disabled="!item.type"
      :clearable="context == 'add'"
      :append-outer-icon="context == 'edit' ? 'mdi-delete-outline' : null"
      @click:append-outer="onDeleteFileClick"
    />

    <delete-file-dialog 
      ref="deleteFileDialog"
      :item="item"
    />

  </div>
</template>

<script>
import CurrencySelect from '@/components/form/CurrencySelect';
import DeleteFileDialog from './DeleteFileDialog.vue';
import ProcedureFile from "@/modules/procedures/model/ProcedureFile.js";
import TypeField from './TypeField.vue';

export default {
  components: {
    CurrencySelect,
    DeleteFileDialog,
    TypeField
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    context: { // add | edit
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      fileRules: [
        value => 
          !value 
          || value.size < ProcedureFile.MAX_SIZE 
          || this.$t('messages.error.maxFileSize', {  size: this.$helperFunctions.formatFileSize(ProcedureFile.MAX_SIZE) })
      ],
      ProcedureFile: ProcedureFile,
    }
  },
  computed: {
    filePlaceholder() {
      return (this.item.urlFilename && !this.item.deleteFile) 
        ? this.item.originalFilename
        : this.$t('procedures.form.placeholder.file')
    }
  },
  methods: {
    onDeleteFileClick() {
      this.$refs.deleteFileDialog.openDialog();
    }
  }
}
</script>
