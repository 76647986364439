<template>
  <div>
    <v-list-item 
      class="error--text"
      @click="onButtonClick"
    >
      <v-icon class="mr-3">mdi-trash-can-outline</v-icon>
      {{ $t('actions.delete') }}
    </v-list-item>

    <v-dialog
      v-model="open"
      width="450"
    >
      <v-container class="py-3 px-6">
        <div class="subtitle-1 text-center mt-2 mb-6 error--text">
          {{ $t('transfers.dialogs.delete.title') }}
        </div>
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="error"
              :loading="saving"
              block
              small
              @click="submit"
            >{{ $t('actions.delete') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    transferId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      open: false,
      saving: false
    };
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      deleteTransfer: 'transfers/deleteTransfer',
    }),
    submit() {
      this.saving = true;

      this.deleteTransfer(this.transferId)
      .then(() => {
        this.addFlash({
          text: this.$t("transfers.messages.success.deleted"),
          type: 'success'
        });
        this.$emit('success');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
        this.open = false;
      });
    },
    onButtonClick() {
      setTimeout(() => {
        this.open = true;
      }, 0);
    }
  }
};
</script>
