import ActivityAddPage from '@/modules/activities/pages/ActivityAddPage/ActivityAddPage.vue';
import ActivityDetailsPage from '@/modules/activities/pages/ActivityDetailsPage/ActivityDetailsPage.vue';
import ActivityEditPage from '@/modules/activities/pages/ActivityEditPage/ActivityEditPage.vue';
import ActivityFilesPage from '@/modules/activities/pages/ActivityFilesPage/ActivityFilesPage.vue';


export default [
  {
    path: '/episode/:episodeId/activity/:activityId',
    name: 'activity-details',
    component: ActivityDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-activity/:episodeId?',
    name: 'activity-add',
    component: ActivityAddPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/edit-activity/:activityId',
    name: 'activity-edit',
    component: ActivityEditPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/activity/:activityId/files/:fileId?',
    name: 'activity-files',
    component: ActivityFilesPage,
    meta: { requiresAuth: true }
  }
];
