import TrashPage from '@/modules/trash/pages/TrashPage/TrashPage.vue';

export default [
  {
    path: '/trash',
    name: 'trash',
    component: TrashPage,
    meta: { requiresAuth: true }
  }
];
