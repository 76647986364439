<template>
  <v-list-item 
    :disabled="!transferEnabled || loading"
    @click.native.stop.prevent
    @click="onClick"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="24"
      :width="3"
      class="mr-3"
    />
    <v-icon 
      v-else
      class="mr-3 primary--text"
    >mdi-account-arrow-left-outline</v-icon> 
    {{ $t('transfers.actions.transfer') }}
  </v-list-item>  
</template>


<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy';
import FilePrivacy from '@/modules/files/model/FilePrivacy.js';

export default {
  props: {  
    selectedFiles: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      loading: false
    }
  },  
  computed: {
    ...mapGetters({
      activity: 'activities/cachedActivity',
      user: 'user/user',
    }),
    transferEnabled() {
      /**
       * enable transfer if:
       * - activity is public
       * - and all selected files are public 
       * - and all selected files are owned by the user
       */ 
      if (this.$route.params.activityId) {
        if (!this.activity || (this.activity.privacy !== ActivityPrivacy.VISIBLE)) {
          return false;
        }
      }
      for (let i in this.selectedFiles) {
        if (this.selectedFiles[i].privacy !== FilePrivacy.VISIBLE) {
          return false;
        }
        if (this.selectedFiles[i].user.id !== this.user.id) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getCollaborators: 'collaborators/getCollaborators'
    }),
    onClick() {
      // load collaborators
      this.loading = true;
      this.getCollaborators(this.$route.params.episodeId)
      .then(response => {
        // if the episode has other protagonists
        if (response.data.collaborators 
          && response.data.collaborators.filter(item => item.id != this.user.id).length
        ) {
          this.$router.push({ 
            name: 'transfer-add', 
            params: { 
              episodeId: this.$route.params.episodeId,
            }
          });
        }
        else {
          this.addFlash({
            text: this.$t("transfers.messages.noOtherProtagonistsInEpisode"),
            type: 'warning'
          });
        }
        this.loading = false;
        this.$emit('close');
      })
    }
  }
}
</script>

<style scoped>
.actions-menu__button-disabled {
  visibility: hidden;
}
</style>