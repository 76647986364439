import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js';

/* Pages */
import DataPolicyPage from '@/pages/DataPolicyPage.vue';
import FAQPage from '@/pages/FAQPage.vue';
import NotFoundPage from '@/pages/NotFoundPage.vue';
import UIExplorerPage from '@/pages/UIExplorerPage/UIExplorerPage';

/* Modules */
import ActivitiesRoutes from '@/modules/activities/router/routes.js';
import AdminStatisticsRoutes from '@/modules/adminStatistics/router/routes.js';
import ChatRoutes from '@/modules/chat/router/routes.js';
import CollaboratorsRoutes from '@/modules/collaborators/router/routes.js';
import ContactsRoutes from '@/modules/contacts/router/routes.js';
import DataUploadRoutes from '@/modules/dataUpload/router/routes.js';
import EpisodeAccessesRoutes from '@/modules/episodeAccesses/router/routes.js';
import EpisodesRoutes from '@/modules/episodes/router/routes.js';
import ExpensesRoutes from '@/modules/expenses/router/routes.js';
import FavoritesRoutes from '@/modules/favorites/router/routes.js';
import FilesRoutes from '@/modules/files/router/routes.js';
import MelodiesRoutes from '@/modules/melodies/router/routes.js';
import NotesRoutes from '@/modules/notes/router/routes.js';
import NoticesRoutes from '@/modules/notices/router/routes.js';
import NotificationsRoutes from '@/modules/notifications/router/routes.js';
import ProceduresRoutes from '@/modules/procedures/router/routes.js';
import SearchRoutes from '@/modules/search/router/routes.js';
import SharedRoutes from '@/modules/shared/router/routes.js';
import SubscriptionRoutes from '@/modules/subscription/router/routes.js';
import TimelineRoutes from '@/modules/timeline/router/routes.js';
import Transfers from '@/modules/transfers/router/routes.js';
import TrashRoutes from '@/modules/trash/router/routes.js';
import UserRoutes from '@/modules/user/router/routes.js';
import WorldMapRoutes from '@/modules/worldmap/router/routes.js';


Vue.use(VueRouter);

const routes = [

  /* Modules */
  ...ActivitiesRoutes,
  ...AdminStatisticsRoutes,
  ...ChatRoutes,
  ...CollaboratorsRoutes,
  ...ContactsRoutes,
  ...DataUploadRoutes,
  ...EpisodeAccessesRoutes,
  ...EpisodesRoutes,
  ...ExpensesRoutes,
  ...FavoritesRoutes,
  ...FilesRoutes,
  ...MelodiesRoutes,
  ...NotesRoutes,
  ...NoticesRoutes,
  ...NotificationsRoutes,
  ...ProceduresRoutes,
  ...SearchRoutes,
  ...SharedRoutes,
  ...SubscriptionRoutes,
  ...TimelineRoutes,
  ...Transfers,
  ...TrashRoutes,
  ...UserRoutes,
  ...WorldMapRoutes,
 
  /* Pages */
  {
    path: '/data-policy',
    name: 'data-policy',
    component: DataPolicyPage
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQPage
  },  
  {
    path: '/ui-explorer',
    name: 'ui-explorer',
    component: UIExplorerPage
  }, 
  { 
    path: "*", 
    component: NotFoundPage
  }
];

const router = new VueRouter({
  mode: process.env.NODE_ENV == 'cordova' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // save last route name
  store.commit('history/setLastRouteName', from.name);

  // user authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.getters['user/user'] || to.path == '/login'
      ? next()
      : next('/login');
  }

  // admin authentication
  else if (to.matched.some(record => record.meta.requiresAdmin)) {
    store.getters['user/user'] && store.getters['user/user'].isAdmin
      ? next()
      : next('/login');
  } 
  
  else {
    next();
  }
});

export default router;
