import Step1Page from '@/modules/dataUpload/pages/Step1Page/Step1Page.vue';
import Step2Page from '@/modules/dataUpload/pages/Step2Page/Step2Page.vue';
import Step3Page from '@/modules/dataUpload/pages/Step3Page/Step3Page.vue';

export default [
  {
    path: '/data-upload',
    name: 'data-upload-step-1',
    component: Step1Page,
    meta: { requiresAuth: true }
  },
  {
    path: '/data-upload-step-2',
    name: 'data-upload-step-2',
    component: Step2Page,
    meta: { requiresAuth: true }
  },
  {
    path: '/data-upload-step-3',
    name: 'data-upload-step-3',
    component: Step3Page,
    meta: { requiresAuth: true }
  },
]
