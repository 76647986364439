<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :title="$t('actions.filter')"
        :class="{
          'ml-1': $vuetify.breakpoint.mdAndUp
        }"
        v-on="on"
      >
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">

      <v-list-item
        :class="{ 'v-list-item--active': value == 'all' }"
        @click="$emit('change', 'all')"
      >{{ $t('search.filter.all') }}</v-list-item>

      <v-list-item
        :class="{ 'v-list-item--active': value == 'favorites' }"
        @click="$emit('change', 'favorites')"
      >{{ $t('search.filter.favorites') }}</v-list-item>
      
      <v-list-item
        :class="{ 'v-list-item--active': value == 'wishList' }"
        @click="$emit('change', 'wishList')"
      >{{ $t('search.filter.wishList') }}</v-list-item>

    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    }
  }
}
</script>