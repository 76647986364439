<template>
  <div>
    <v-container class="px-6">
      <template v-if="item.hasCoStars">
        <div class="subtitle-1 text-center mt-2 mb-6">
          {{ $t('episodes.actions.deleteEpisode') }}
        </div>
        <div
          class="mb-8"
          v-html="$t('episodes.dialogs.delete.episodeWithProtagonists')"
        ></div>
      </template>
      <div
        v-else
        class="subtitle-1 text-center mt-2 mb-6 error--text"
      >{{ $t('episodes.dialogs.delete.episodeWithoutProtagonists') }}</div>
    </v-container>

    <hr>

    <v-container>
      <v-row v-if="!item.hasCoStars">
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="$emit('close')"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            color="error"
            :loading="saving"
            block
            small
            @click="submit"
          >{{ $t('actions.delete') }}</v-btn>
        </v-col>

      </v-row>

      <v-btn
        v-if="item.hasCoStars"
        outlined
        block
        small
        @click="$emit('close')"
      >{{ $t('actions.cancel') }}</v-btn>
    </v-container>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      saving: false
    };
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      deleteEpisode: 'episodes/deleteEpisode'
    }),
    submit() {
      this.saving = true;

      this.deleteEpisode(this.item.id)
      .then(() => {
        this.addFlash({
          text: this.$t("episodes.messages.success.deleted"),
          type: 'success'
        });
        this.$router.push({ name: 'episodes-list' });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>
