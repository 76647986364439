<template>
  <v-list-item 
    :disabled="loading"
    class="download-item"
    @click.stop.prevent="onClick"
  >
    <v-progress-linear
      v-if="loading"
      :value="percentCompleted"
      color="primary"
      stream
      buffer-value="0"
    />
    <template v-else>
      <v-icon class="mr-3 primary--text">mdi-cloud-download-outline</v-icon> 
      {{ $t(isZip ? 'actions.downloadZip' : 'actions.download') }}
    </template>
  </v-list-item>  
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import downloadAndroidFunctions from '@/modules/files/functions/downloadAndroidFunctions.js';
import downloadBrowserFunctions from '@/modules/files/functions/downloadBrowserFunctions.js';
import downloadIosFunctions from '@/modules/files/functions/downloadIosFunctions.js';

export default {
  props: {  
    selectedFiles: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      loading: false,
      percentCompleted: 0
    };
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    isZip() {
      return (this.selectedFiles.length > 1) && !this.$helperFunctions.isIos();
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapMutations({
      setSelectedFiles: 'files/setSelectedFiles',
    }),
    ...mapActions({
      downloadFiles: 'files/downloadFiles'
    }),
    onClick() {
      if (this.$helperFunctions.isIos()) {
        this.downloadIos();
      } else {
        this.download();
      }
    },
    download() {
      // Download one file or ZIP on Browser and Android
      this.loading = true;
      this.percentCompleted = 0;

      let filesIds = this.selectedFiles.map(item => item.id);
      
      let onDownloadProgress = (progressEvent) => {
        this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      }

      this.downloadFiles({ 
        filesIds, 
        onDownloadProgress 
      })
      .then((response) => {
        let filename = response.headers['content-disposition'].split('filename=')[1];
        if (this.isZip) {
          filename = this.$t('files.titles.zipFilename') + '.zip';
        }
        if (this.$helperFunctions.isAndroid()) {
          downloadAndroidFunctions.downloadBlob(response.data, filename);
        } 
        else {
          downloadBrowserFunctions.downloadBlob(response.data, filename);
        }
        this.addFlash({
          text: this.$t('files.messages.success.files-downloaded'),
          type: 'success'
        });
        this.setSelectedFiles(null);
        this.$emit('close');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.loading = false;
      });  

    },
    async downloadIos() {
      // Files are downloaded one by one from URL (urlImageThumbnails.original_) and saved to iOS 'Photos'
      this.loading = true;

      for (let file of this.selectedFiles) {
        try {
          if (file.type == 'video') {
            let url = file.urlFilename + '?bearer=' + this.apiToken;
            await downloadIosFunctions.downloadVideo(url);
          }
          else {
            let url = file.urlImageThumbnails.original_ + '?bearer=' + this.apiToken;
            await downloadIosFunctions.downloadImage(url);
          }
        } catch(error) {
          console.error(this.$helperFunctions.errorToString(error));
        }
      }

      this.loading = false;
      this.addFlash({
        text: this.$t('files.messages.success.files-downloaded'),
        type: 'success'
      });
      this.setSelectedFiles(null);
      this.$emit('close');
    }
  }
}
</script>