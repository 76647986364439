<template>
  <div class="collaborator-item">
    <user-item
      :item="item"
      class="collaborator-item__user-item"
      :show-email="false"
    >
      <small class="overline">{{ $t('collaborators.model.role.' + item.rol) }}</small>
    </user-item>

    <router-link
      v-if="canEdit && !selectionEnabled"
      :to="{ name: 'collaborator-edit', params: {
        episodeId: $route.params.episodeId,
        collaboratorId: item.id
      } }"
    >
      <v-icon large>mdi-chevron-right</v-icon>
    </router-link>

    <v-simple-checkbox
      v-if="canEdit && selectionEnabled"
      v-model="item.selected" 
      v-ripple
      color="primary"
      class="collaborator-item__checkbox"
    />
  </div>
</template>

<script>
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    UserItem
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    selectionEnabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.collaborator-item {
  display: flex;
  border-bottom: 2px solid currentColor;
  padding-bottom: 8px;
  align-items: center;
}
.collaborator-item__user-item {
  flex-grow: 1;
}
</style>