<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('user.titles.statistics') }}
      <template slot="buttons">
        <app-fab 
          add-files
          add-episode
          add-activity
          add-notes
        />
      </template>
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('user.titles.summaryContents') }}
      </v-tab>
    </page-tabs>


    <div class="l-vertical__scroll"> 
      <loading v-if="loading || loadingSubscription" />

      <v-container        
        v-else
        class="container--narrow pt-5"
      >
        <div class="subtitle-1 mb-2">
          {{ $t('user.statistics.episodesTitle') }}
        </div>

        <v-row>
          <v-col 
            cols="7"
            class="pt-2 pb-0"
          >
            {{ $t('user.statistics.asMember') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right"
          >
            {{ statistics.coStar }}
          </v-col>
        </v-row>

        <v-row class="mb-8">
          <v-col 
            cols="7"
            class="pt-2 pb-0"
          >
            {{ $t('user.statistics.asFollower') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right"
          >
            {{ statistics.shared }}
          </v-col>
        </v-row>

        <div class="subtitle-1 mb-2">
          {{ $t('user.statistics.contentTitle') }}
        </div>

        <v-row>
          <v-col 
            cols="7"
            class="pt-2 pb-0"
          >
            {{ $t('user.statistics.pictures') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right"
          >
            <span class="overline small">({{ $helperFunctions.formatFileSize(statistics.space_pictures, 0) }})</span> 
            {{ statistics.pictures }}
          </v-col>
        </v-row>

        <v-row>
          <v-col 
            cols="7"
            class="pt-2 pb-0"
          >
            {{ $t('user.statistics.videos') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right"
          >
            <span class="overline small">({{ $helperFunctions.formatFileSize(statistics.space_videos, 0) }})</span> 
            {{ statistics.videos }}
          </v-col>
        </v-row>
        
        <v-row>
          <v-col 
            cols="7"
            class="pt-2 pb-0"
          >
            {{ $t('user.statistics.notes') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right"
          >
            <span class="overline small">({{ $helperFunctions.formatFileSize(statistics.space_notes, 0) }})</span> 
            {{ statistics.notes }}
          </v-col>
        </v-row>
        
        <v-row class="mb-6">
          <v-col 
            cols="7"
            class="pt-2 pb-0"
          >
            {{ $t('user.statistics.melodies') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right"
          >
            <span class="overline small">({{ $helperFunctions.formatFileSize(statistics.space_melodies, 0) }})</span> 
            {{ statistics.melodies }}
          </v-col>
        </v-row>


        <v-row class="mb-2">
          <v-col 
            cols="7"
            class="pb-0 subtitle-1"
          >
            {{ $t('user.statistics.spaceTitle') }}:
          </v-col>
          <v-col 
            cols="5"
            class="pb-0 text-right subtitle-1"
          >
            {{ $helperFunctions.formatFileSize(totalSpace) }}
          </v-col>
        </v-row>

        <div class="mb-4">
          <v-progress-linear
            class="progress-linear--border"
            rounded
            height="10"
            background-color="transparent"
            color="primary"
            :value="percentageUsed"
          />
        </div>

        <v-row>
          <v-col 
            cols="7"
            class="pt-2 pb-0"
          >
            {{ $t('user.statistics.available') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right"
          >
            {{ $helperFunctions.formatFileSize(availableSpace, 2) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col 
            cols="7"
            class="pt-2 pb-0"
          >
            {{ $t('user.statistics.used') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right"
          >
            {{ $helperFunctions.formatFileSize(usedSpaceTotal, 2) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col 
            cols="7"
            class="pt-2 pb-0 pl-4 small"
          >
            - {{ $t('user.statistics.usedSpaceEpisodes') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right small"
          >
            {{ $helperFunctions.formatFileSize(usedSpace, 2) }}
          </v-col>
        </v-row>

        <v-row class="mb-8">
          <v-col 
            cols="7"
            class="pt-2 pb-0 pl-4 small"
          >
            - {{ $t('user.statistics.usedSpaceTrash') }}
          </v-col>
          <v-col 
            cols="5"
            class="pt-2 pb-0 text-right small"
          >
            {{ $helperFunctions.formatFileSize(statistics.space_soft_deleted, 2) }}
          </v-col>
        </v-row>

        <v-btn 
          v-if="user.isAdmin"
          small
          class="primary secondary--text mb-6"
          :to="{ name: 'admin-statistics-users' }"
        >
          Mis estadísticas
        </v-btn>

      </v-container>
    </div>
    <menu-bottom />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import SpaceLimit from '@/modules/user/model/SpaceLimit';
import SubscritpionModel from '@/modules/subscription/model/SubscriptionModel.js';

export default {
  components: {
    AppFab,
    Loading,
    MenuBottom,
    PageTabs,
    PageTitle
  },
  data () {
    return {
      loading: false,
      loadingSubscription: false,
      statistics: {},
      subscription: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    availableSpace() {
      return (this.totalSpace < this.usedSpaceTotal) ? 0 : (this.totalSpace - this.usedSpaceTotal);
    },
    percentageUsed() {
      return (this.usedSpace / this.totalSpace) * 100
    },
    totalSpace() {
      // convert from GB to B
      return this.$helperFunctions.gigaBytesToBytes(this.subscriptionIsValid ? SpaceLimit.subscribed : SpaceLimit.notSubscribed);
    },
    usedSpace() {
      return this.statistics.space_pictures 
        + this.statistics.space_videos 
        + this.statistics.space_melodies
        + this.statistics.space_notes;
    },
    usedSpaceTotal() {
      return this.usedSpace + this.statistics.space_soft_deleted;
    },
    subscriptionIsValid() {
      return SubscritpionModel.subscriptionIsValid(this.subscription);
    },
  },
  created() {
    this.loading = true;
    this.getStatistics(this.user.id)
    .then(response => {
      this.statistics = response.data.statistics;
      this.loading = false;
    });
    this.loadSubscription();
  },
  methods: {
    ...mapActions({
      getStatistics: 'user/getStatistics',
      getSubscription: 'subscription/getSubscription',
    }),
    loadSubscription() {
      this.loadingSubscription = true;
      this.getSubscription()
      .then(response => {
        this.subscription = response.data.subscription;
        this.loadingSubscription = false;
      });
    },
  }
}
</script>
<style scoped>
.progress-linear--border{
  border: 1px solid var(--v-primary-base) !important;
}
</style>

