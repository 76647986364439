<template>
  <div class="upload-footer__status small">    
    <v-progress-linear 
      v-if="uploading"
      color="primary" 
      height="4" 
      class="mb-1"
      :value="currentFileNumber / total * 100"
    />
    <div v-if="uploading">
      <span class="overline">{{ $t('dataUpload.titles.uploadingFile') }}:</span> <strong>{{ currentFileNumber }}</strong> {{ $t('dataUpload.titles.uploadingFileOf') }} <strong>{{ total }}</strong>
    </div>
    <template v-else>&nbsp;</template>
    <div
      class="upload-footer__filepath"
    ><small>{{ currentFile ? currentFile.file.webkitRelativePath : '' }}</small></div>


  </div>
</template>

<script>
export default {
  props: {
    currentFile: {
      type: Object,
      default: null,
    },
    currentFileNumber: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    progress() {
      return this.total ? (this.uploadedCount / this.total) * 100 : 0;
    },
  },
}
</script>

<style scoped>
.upload-footer__status {
  flex: 1 1 0;
  line-height: 1.2;
  padding-top: 6px;
  letter-spacing: 0;
}
.upload-footer__filepath {
  word-wrap: break-word;
}
</style>