<template>
  <v-text-field
    v-model="keyword"
    :placeholder="$t('actions.search')"
    class="search-field"
    hide-details
    append-icon="mdi-magnify"
    :disabled="$route.name == 'search'"
    @click:append="submitSearch"
    @keyup.native.enter="submitSearch"
  />
</template>

<script>
import { eventBus } from '@/eventBus.js';

export default {
  data() {
    return {
      keyword: null
    }
  },
  methods: {
    submitSearch($event) {
      eventBus.$emit('clearAlivePages');
      this.$router.push({ name: 'search', query: { keyword: this.keyword } });
      this.keyword = null;
    }
  }
}
</script>

<style scoped>
.search-field {
  width: 211px;
}
</style>