import ConfigurationPage from '@/modules/user/pages/ConfigurationPage/ConfigurationPage.vue';
import DeleteAccountPage from '@/modules/user/pages/DeleteAccountPage/DeleteAccountPage.vue';
import LoginPage from '@/modules/user/pages/LoginPage/LoginPage.vue';
import NewPasswordPage from '@/modules/user/pages/NewPasswordPage.vue';
import ProfilePage from '@/modules/user/pages/ProfilePage/ProfilePage.vue';
import RegisterPage from '@/modules/user/pages/RegisterPage/RegisterPage.vue';
import ResetPasswordPage from '@/modules/user/pages/ResetPasswordPage.vue';
import StatisticsPage from '@/modules/user/pages/StatisticsPage.vue';

export default [
  {
    path: '/login/:token?',
    name: 'user-login',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'user-register',
    component: RegisterPage
  },
  {
    path: '/password-reset',
    name: 'user-reset-password',
    component: ResetPasswordPage,
  },
  {
    path: '/new-password/:token',
    name: 'user-new-password',
    component: NewPasswordPage,
  },
  {
    path: '/profile',
    name: 'user-profile',
    component: ProfilePage,
    meta: { requiresAuth: true }
  },  
  {
    path: '/configuration',
    name: 'user-configuration',
    component: ConfigurationPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/statistics',
    name: 'user-statistics',
    component: StatisticsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/delete-account/:userId/:token',
    name: 'delete-account',
    component: DeleteAccountPage,
  },
];
