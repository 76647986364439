<template>
  <v-col
    :xl="layout == 'thumbnail' ? 1 : 3"
    :lg="layout == 'thumbnail' ? 2 : 4"
    :md="layout == 'thumbnail' ? 2 : 4"
    :sm="layout == 'thumbnail' ? 3 : 6"
    :cols="layout == 'thumbnail' ? 4 : 12"
    class="pa-0"
  >
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  props: {
    layout: {
      type: String,
      default: 'thumbnail'
    }
  }
}
</script>