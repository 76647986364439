<template>
  <div class="slideshow-item">
    <div class="slideshow-item__wrap">
      <img
        v-if="item.type == 'photo'"
        :src="item.urlImageThumbnails ? item.urlImageThumbnails.large_ + '?' + tokenParameter : ''"
        :alt="item.name"
        class="slideshow-item__img"
      />
      <video
        v-if="item.type == 'video'"
        :ref="'video-' + item.id"
        class="slideshow-item__video"
        :src="item.urlFilename + '?' + tokenParameter"
        controls
        @ended="$emit('video-ended')"
      ></video>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken',
    }),
    tokenParameter() {
      return this.$route.meta.shared 
        ? 'hash=' + this.$route.query.hash 
        : 'bearer=' + this.apiToken;
    },
  },
  methods: {
    playVideo() {
      this.$refs['video-' + this.item.id].play();
    }
  }
};
</script>


<style scoped>
.slideshow-item {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.slideshow-item__wrap {
  position: relative;
  flex-grow: 0;
  max-width: 100%;
  max-height: 100%;
}
.slideshow-item__img {
  max-width: 100%;
  max-height: 100%;
}
.slideshow-item__video {
  width: 100%;
  max-height: 100%;
  flex: 0 0;
}
.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-size: 60px !important;
}
.video-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(calc(-50%), calc(-50% - 17px));
}
.video-progress-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(calc(-50%), calc(-50% - 37px));
}
</style>