<template>
  <div class="l-vertical__scroll l-vertical"> 
    <v-container class="container--narrow">

      <v-form
        ref="form"
        :value="valid"
        @input="$emit('update:valid', $event)"
        @submit.prevent
        @keyup.native.enter="submit"
      >

        <v-text-field
          v-model="userData.currentPassword"
          :label="$t('user.form.label.currentPassword')"
          :rules="[currentPasswordRule]"
          :type="showCurrentPassword ? 'text' : 'password'"
          :append-icon="showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye'"
          maxlength="128"
          autocomplete="off"
          @click:append="showCurrentPassword = !showCurrentPassword"
        />

        <v-text-field
          v-model="userData.password"
          :label="$t('user.form.label.passwordNew')"
          :rules="[$validationRules.password]"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          maxlength="128"
          :hint="$t('messages.error.password')"
          autocomplete="off"
          @click:append="showPassword = !showPassword"
        />
        <v-text-field
          v-model="userData.passwordRepeat"
          :label="$t('user.form.label.passwordRepeat')"
          :rules="[passwordConfirmationRule]"
          :type="showPassword2 ? 'text' : 'password'"
          :append-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
          maxlength="128"
          autocomplete="off"
          @click:append="showPassword2 = !showPassword2"
        />
      </v-form>

    </v-container>

    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn  
              outlined
              block
              :to="'/'"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :disabled="!valid"
              :loading="saving"
              @click="submit"
            >{{ $t('actions.save') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    
  </div>
</template>

<script>
export default { 
  props: {
    userData: {
      type: Object,
      default: null
    },
    valid: {
      type: Boolean,
      default: true
    },
    saving: {
      type: Boolean,
      default: false
    }
  }, 
  data() {
    return {
      showCurrentPassword: false,
      showPassword: false,
      showPassword2: false,
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.userData.password === this.userData.passwordRepeat) || this.$t('messages.error.passwordMatch')
    },
    currentPasswordRule() {
      if (this.userData.password && !this.userData.currentPassword) {
        return this.$i18n.t('messages.error.empty');
      }
      return true;
    }
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.$emit('submit');
    }
  }
}
</script>