<template>
  <div class="l-vertical__grow l-vertical">
    <page-actions>
      <filter-menu 
        :activity-groups="activityGroups"
        :disabled="!!$route.query.activity"
        @change="filters = $event; showMarkers()" 
      />

      <map-legend :activity-groups="activityGroups" />
    </page-actions>

    <episode-tabs />

    <div class="l-vertical__grow l-vertical">
      <loading v-if="loading" />

      <div 
        ref="googleMap" 
        class="google-map"
        :class="loading ? 'google-map--loading' : ''"
      ></div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import EpisodeTabs from '@/modules/shared/components/EpisodeTabs';
import FilterMenu from './FilterMenu';
import GoogleMapsApiLoader from "google-maps-api-loader";
import Loading from '@/components/util/Loading';
import MapLegend from "@/modules/worldmap/components/MapLegend.vue";
import PageActions from '@/modules/shared/components/PageActions.vue';

export default {
  components: {
    EpisodeTabs,
    FilterMenu,
    Loading,
    MapLegend,
    PageActions
  },
  data() {
    return {
      activities: null,
      activityGroups: null,
      bounds: null,
      filters: { 
        groups: {},
        groupsAll: true
      },
      loading: true,
      map: null,
      mapConfig: {
        center: { lat: 0, lng: 0 },
        zoom: 2,
        disableDefaultUI: true,
      },
      markers: [],
    }
  },
  computed: {
    activitiesFiltered() {
      if (!this.activities) {
        return [];
      }

      // filter activity selected from activity details page
      if (this.$route.query.activity) {
        return this.activities.filter(item => item.id == this.$route.query.activity);
      }

      return this.activities.filter(item => {

        // filter by group
        if (!this.filters.groupsAll) {
          if (!this.filters.groups[item.activityGroup.id]) {
            return false;
          }
        }

        return true;
      });
    }
  },
  watch: {
    '$route.query.activity': function (id) {
      this.showMarkers();
    }
  },
  created() {
    // load activities
    this.loading = true;
    this.getActivities({
      episodeId: this.$route.params.episodeId,
      hash: this.$route.query.hash
    })
    .then(response => {
      this.activities = response.data.activities;
      this.loading = false;
      this.showMarkers();
    });

    // load activity groups
    this.getActivityGroups()
    .then(response => {
      this.activityGroups = response.data;
      this.activityGroups.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    });
  },
  async mounted() {
    // load Google Maps API
    const google = await GoogleMapsApiLoader({ 
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      language: this.$i18n.locale
    });

    // create map
    this.map = new google.maps.Map(this.$refs.googleMap, this.mapConfig);
    this.bounds = new google.maps.LatLngBounds();

    this.showMarkers();
  },
  methods: {
    ...mapActions({
      getActivities: 'shared/getActivities',
      getActivityGroups: 'activities/getActivityGroups',
    }),
    showMarkers() {
      this.clearMarkers();

      if (!this.activitiesFiltered || !this.map) {
        return;
      }

      this.map.setOptions({ maxZoom: 5 });

      for (const activity of this.activitiesFiltered) {
        if (activity.latitude && activity.longitude) {
          let myLatLng = new google.maps.LatLng(parseFloat(activity.latitude), parseFloat(activity.longitude));
          const marker = new google.maps.Marker({
            position: myLatLng,
            map: this.map,
            title: activity.name,
            icon: {
              url: "img/markers/" + activity.activityGroup.slug + ".png",
              scaledSize: new google.maps.Size(32, 32)
            } 
          });
          marker.addListener("click", () => {
            this.$router.push({ 
              name: 'shared-activity-details', 
              params: { 
                episodeId: this.$route.params.episodeId, 
                activityId: activity.id
              },
              query: { hash: this.$route.query.hash } 
            });
          });
          this.markers.push(marker);
          this.bounds.extend(myLatLng);
        }
      }
      this.map.fitBounds(this.bounds);
      this.map.setOptions({ maxZoom: undefined });
    },
    clearMarkers() {
      for (const marker of this.markers) {
        marker.setMap(null);
      }
      this.markers = [];
    }
  }
}
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background-color: var(--color-image-background);
}
.google-map--loading {
  visibility: hidden;
}
</style>
