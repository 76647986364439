<template>
  <div class="generic-list-item">
    <div class="generic-list-item__main d-flex">
      <div class="flex-grow-0 mr-3">
        <group-thumbnail 
          :src="item.urlFilename"  
        />
      </div>
      <div class="flex-grow-1">
        <div class="mb-1">{{ item.title }}</div>      
        <div class="group-item__description small">
          <collapse-text>{{ item.description }}</collapse-text>   
        </div>
      </div>

    </div>
 
    <div class="generic-list-item__buttons">
      <v-btn 
        v-if="!selectionEnabled"
        icon
        small
        color="primary mb-2"
        :to="{ name: 'contact-group-details', params: {
          id: item.id
        } }"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>    
      
      <v-simple-checkbox
        v-if="selectionEnabled"
        v-model="item.selected" 
        v-ripple
        color="primary"
      /> 
    </div>
  </div>
</template>

<script>
import CollapseText from '@/components/util/CollapseText';
import GroupThumbnail from '@/modules/contacts/components/GroupThumbnail.vue';

export default {
  components: {
    CollapseText,
    GroupThumbnail
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    selectionEnabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.group-item__description {
  color: var(--color-grey);
}
</style>