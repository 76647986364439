<template>
  <div 
    class="slideshow"
    :class="[
      transitionPlaying ? 'slideshow--transition-playing' : null,
      'slideshow--' + settings.transition
    ]"
    @click="startTransition"
  >
    <slideshow-item
      :key="item1.id + '-1'"
      :ref="'item-' + item1.id"
      :item="item1"
      class="slideshow-item--current"
      @video-ended="onVideoEnded"
    />
    <slideshow-item
      :key="item2.id + '-2'"
      :item="item2"
      class="slideshow-item--next"
    />
  </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SlideshowItem from './SlideshowItem.vue';

export default {
  components: {
    SlideshowItem
  },
  props: {
    files: {
      type: Array,
      default: Array
    },
    startIndex: {
      type: Number,
      default: 0
    },
    settings: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      fileIndex: this.startIndex,
      index1: null,
      index2: null,
      timeout: null,
      transitionDuration: 750,
      transitionPlaying: false,
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken',
    }),
    item1() {
      return this.files[this.index1]
    },
    item2() {
      return this.files[this.index2];
    },
    tokenParameter() {
      return this.$route.meta.shared 
        ? 'hash=' + this.$route.query.hash 
        : 'bearer=' + this.apiToken;
    },
  },
  created() {
    this.index1 = this.startIndex;
    this.index2 = this.increaseIndex(this.index1);
    this.startTimeout();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    increaseIndex(index) {
      index++;
      if (index >= this.files.length) {
        // infinite loop
        index = 0;
      }
      return index;
    },
    startTransition() {
      this.transitionPlaying = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.onTransitionEnd();
      }, this.transitionDuration);
      this.$emit('change', this.increaseIndex(this.index1));
    },
    onTransitionEnd() {
      this.index1 = this.increaseIndex(this.index1);
      this.index2 = this.increaseIndex(this.index2);
      this.transitionPlaying = false;
      this.$emit('change', this.index1);
      clearTimeout(this.timeout);

      // if video
      if (this.isValidVideo(this.item1)) {
        this.$nextTick(() => {
          this.$refs['item-' + this.item1.id].playVideo();
        });
      }
      else {
        this.startTimeout();
      }
    },
    isValidVideo(file) {
      return file && file.type == 'video';
    },
    startTimeout() {
      this.timeout = setTimeout(() => {
        this.startTransition();
      }, this.settings.interval * 1000);
    },
    onVideoEnded() {
      this.startTransition();
    }
  }
};
</script>

<style scoped>
.slideshow {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
<style scoped src="./transitions/reveal.css"></style>
<style scoped src="./transitions/iris.css"></style>
<style scoped src="./transitions/flip.css"></style>
<style scoped src="./transitions/fall.css"></style>
<style scoped src="./transitions/dissolve.css"></style>
