<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ episode ? episode.title : '' }}
      <template slot="buttons">
        <sort-menu 
          v-if="tab == 1"
          @change="sortBy = $event" 
        />

        <app-fab 
          v-if="canEdit"
        >
          <template slot="list">
            <router-link 
              class="app-fab__menu-item"
              :to="{ name: 'expense-add', params: { episodeId: $route.params.episodeId } }"
            >
              <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
                {{ $t('expenses.actions.addExpense') }}
              </v-btn>
              <v-btn
                fab
                small
                class="primary"
              >
                <v-icon>mdi-cash-plus</v-icon>
              </v-btn>
            </router-link>

          </template>
        </app-fab>
      </template>
    </page-title>

    <episode-tabs
      :episode="episode"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <v-container 
        class="container--narrow"
      >
        
        <v-tabs 
          v-model="tab" 
          centered
        >
          <v-tab>{{ $t('expenses.titles.summary') }}</v-tab>
          <v-tab>{{ $t('expenses.titles.details') }}</v-tab>
          <v-tab>{{ $t('expenses.titles.balance') }}</v-tab>

          <v-tab-item>
            <loading v-if="loading" />
            <tab-summary 
              v-else
              :expenses="expenses" 
              :groups="groups"
              :procedures="procedures"
            />
          </v-tab-item>
          
          <v-tab-item>
            <loading v-if="loading" />
            <tab-details 
              v-else
              :expenses="expenses" 
              :sort-by="sortBy"
              :can-edit="canEdit"
              @deleted="reloadExpensesData"
            />
          </v-tab-item>

          <v-tab-item>
            <loading v-if="loading" />
            <tab-balance 
              v-else
              :expenses="expenses" 
            />
          </v-tab-item>

        </v-tabs>

      </v-container>

    </div>

    <menu-bottom />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs.vue';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle";
import TabBalance from "./TabBalance/TabBalance";
import TabDetails from "./TabDetails/TabDetails";
import TabSummary from "./TabSummary/TabSummary";
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import SortMenu from './SortMenu.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    AppFab,
    EpisodeTabs,
    Loading,
    MenuBottom,
    PageTitle,
    TabBalance,
    TabDetails,
    TabSummary,
    SortMenu,
  },
  mixins: [statusTap],
  data() {
    return {
      loading: true,
      expenses: null,
      groups: null,
      procedures: null,
      roles: roles,
      sortBy: 'date',
      tab: 0,
    }
  },  
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
    }),
    canEdit() {
      return this.episode && ((this.episode.rol == roles.CREATOR) || (this.episode.rol == roles.EDITOR));
    },
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    Promise.all([
      this.getEpisodeExpenses(this.$route.params.episodeId),
      this.getActivityGroups(),
      this.getProcedures(this.$route.params.episodeId)
    ])
    .then(response => {
      this.expenses = response[0].data.expenses;
      this.groups = response[1].data;
      this.procedures = response[2].data.procedures;

      this.loading = false;
    });

    if (this.$route.query.details) {
      this.tab = 1;
    }
  },
  methods: {
    ...mapActions({
      getActivityGroups: 'activities/getActivityGroups',
      getEpisodeExpenses: 'expenses/getEpisodeExpenses',
      getProcedures: 'procedures/getProcedures',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
    }),
    reloadExpensesData() {
      this.loading = true;
      this.getEpisodeExpenses(this.$route.params.episodeId)
      .then(response => {
        this.expenses = response.data.expenses;
        this.loading = false;
      });
    },
  }
}
</script>