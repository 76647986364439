<template>
  <div  
    v-ripple
    class="item"
    @click="setCachedEpisode(item)"
  >
    <img
      v-lazy="imageUrl"
      class="item__image"
      alt=""
    >
    <div class="image-overlay"></div>
    <router-link 
      class="item__link"
      :to="{ name: 'episode-details', params: { episodeId: item.id }}"
    >
      <div class="item__top">
        <h2 class="subtitle-1 item__title">{{ item.title }}</h2>

        <v-icon 
          v-if="item.hasCost"
          class="mx-2"
        >mdi-cash-multiple</v-icon>

        <v-btn
          v-if="favoriteButton"
          icon
          dark
          class="primary--text mt-n2"
          :title="item.isFavorite ? $t('actions.removeFavorite') : $t('actions.addFavorite')"
          :loading="savingFavorite"
          @click.prevent="onFavoriteClick"
        >
          <v-icon>{{ item.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
        </v-btn>
        
        <template v-else>   
          <v-icon
            v-if="item.isFavorite"
            :title="$t('titles.favorite')"
            class="mx-2"
          >mdi-heart</v-icon>
          <span 
            v-else
            class="icon-placeholder mx-2"
          ></span>
        </template>

        <v-icon
          v-if="item.isWish"
          :title="$t('episodes.titles.wishList')"
          class="mx-2"
        >mdi-briefcase-clock</v-icon>
        <span 
          v-else
          class="icon-placeholder mx-2"
        ></span>
        
        <v-icon class="primary--text">mdi-arrow-right</v-icon>
      </div>
      
      <div class="item__bottom">
        <div class="overline small">{{ item.rol ? $t('collaborators.model.role.' + item.rol) : '' }}</div>
        <v-spacer />
        <div class="overline small">{{ item.date | moment($t('locale.formatDate')) }}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    favoriteButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      randomImage: '',
      savingFavorite: false,
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageUrl() {
      return (this.item && this.item.urlImageThumbnails) 
        ? this.item.urlImageThumbnails.medium_ + '?bearer=' + this.apiToken 
        : 'img/placeholder/episode-list.png';
    }
  },
  methods: {
    ...mapMutations({
      setCachedEpisode: 'episodes/setCachedEpisode',
    }),
    ...mapActions({
      setFavoriteEpisode: 'episodes/setFavoriteEpisode',
    }),
    onFavoriteClick() {
      this.savingFavorite = true;
      this.setFavoriteEpisode(this.item.id)
      .then(response => {
        this.item.isFavorite = response.data.episode.isFavorite;
        this.$emit('modified');
      })
      .finally(() => {
        this.savingFavorite = false;
      })
    },
  }
}
</script>

<style scoped>
.item {
  height: 273px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: 1px 1px 0 0;
}
.item__image {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.item__image[lazy=loaded] {
  object-fit: cover;
}
.item__link {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  color: white;
  padding: 14px 18px;
  display: flex;
  flex-wrap: wrap;
}
.item__top {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.item__title {
  flex-shrink: 1;
  margin-right: auto;
}
.item__bottom {
  display: flex;
  width: 100%;
  margin-top: auto;
  justify-content: space-between;
  align-items: flex-end;
}
.icon-placeholder {
  width: 28px;
  height: 28px;
}


/* mobile */
@media (max-width: 1024px) {
  .item {
    height: 154px;
  }
  .item__link {
    padding: 14px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}
</style>