<template>
  <v-dialog 
    v-model="isOpen" 
    width="450"
  >
    <v-container class="py-3 px-6">
      <div class="subtitle-1 text-center mt-2 mb-6">{{ $t('episodeAccesses.dialogs.resend') }}</div>
    </v-container>

    <hr>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="isOpen = false"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            class="primary"
            :loading="saving"
            block
            small
            @click="submit"
          >{{ $t('actions.share') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    itemId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      isOpen: false,
      saving: false
    };
  },
  watch: {
    itemId(itemId) {
      this.isOpen = !!itemId;
    },
    isOpen(isOpen) {
      if (!isOpen) {
        this.$emit('close');
      }
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      resendAccess: 'episodeAccesses/resendAccess'
    }),
    submit() {
      this.saving = true;

      this.resendAccess({
        episodeId: this.$route.params.episodeId,
        accessId: this.itemId
      })
      .then(() => {
        this.addFlash({
          text: this.$t("episodeAccesses.messages.success.created"),
          type: 'success'
        });
        this.isOpen = false;
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>
