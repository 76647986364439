<template>
  <v-dialog 
    v-model="open" 
    width="450"
  >
    <v-container class="py-3 px-6">
      <div class="subtitle-1 text-center mt-2 mb-2 error--text">
        {{ $t('procedures.dialogs.deleteFile.title') }}
      </div>
    </v-container>

    <hr>


    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="open = false"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            color="error"
            block
            small
            @click="submit"
          >{{ $t('actions.delete') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    openDialog() {
      if (this.item.originalFilename) {
        this.open = true;
      }
    },
    submit() {
      this.item.file = null;
      this.item.deleteFile = true;
      this.open = false;
    }
  }
};
</script>
