<template>
  <div class="trash-item">
    <div class="file-item__thumb">
      <file-thumbnail 
        :file="item" 
        @click="$emit('click')" 
      />
    </div>
    <div class="trash-item__details">
      <div class="body-2 height-lines-1">{{ item.activity.episode.title }}</div>
      <div class="height-lines-1">{{ item.activity.name }}</div>
      <div class="small height-lines-1">{{ item.description }}</div>
      <div class="overline small">
        {{ $moment(item.date).format($t('locale.formatDate')) }}
        {{ $helperFunctions.fixApiTime(item.time) }}
      </div>
    </div>
    <v-checkbox
      v-model="item.selected" 
      class="trash-item__checkbox"
    />
  </div>
</template>

<script>
import FileThumbnail from "@/modules/files/components/FileListItem/FileThumbnail.vue";

export default {
  components: {
    FileThumbnail,
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  }
};
</script>

<style scoped>
.file-item__thumb {
  width: 76px;
  height: 76px;
  margin-right: 18px;
  flex-shrink: 0;
  flex-grow: 0;
}
</style>