<template>
  <div 
    class="local-item"
    :class="{ 'local-item--nested': nested }"
  >
    <v-simple-checkbox
      v-model="item.selected" 
      class="local-item__checkbox" 
      :disabled="uploading"
    />
    <template v-if="nested">
      <v-icon 
        class="local-item__icon"
      >mdi-folder-outline</v-icon>
      <div class="local-item__title">
        {{ item.path }}
      </div>
      <v-icon 
        class="local-item__arrow"
      >
        mdi-arrow-right-bold-outline
      </v-icon>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
}
</script>

<style scoped>
.local-item {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
}
.local-item--nested {
  width: 50%;
}
.local-item__checkbox {
  align-self: flex-start;
}
.local-item__icon {
  margin-right: 4px;
}
.local-item__title {
  width: calc(100% - 83px);
  word-wrap: break-word;
  flex-shrink: 1;
}
.local-item__arrow {
  margin-left: auto;
}
</style>
