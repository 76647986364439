import EpisodeAddPage from '@/modules/episodes/pages/EpisodeAddPage/EpisodeAddPage.vue';
import EpisodeDetailsPage from '@/modules/episodes/pages/EpisodeDetailsPage/EpisodeDetailsPage.vue';
import EpisodeEditPage from '@/modules/episodes/pages/EpisodeEditPage/EpisodeEditPage.vue';
import EpisodeFilesPage from '@/modules/episodes/pages/EpisodeFilesPage/EpisodeFilesPage.vue';
import EpisodesListPage from '@/modules/episodes/pages/EpisodesListPage/EpisodesListPage.vue';
import EpisodeMapPage from '@/modules/episodes/pages/EpisodeMapPage/EpisodeMapPage.vue';
import EpisodeStatisticsPage from '@/modules/episodes/pages/EpisodeStatisticsPage/EpisodeStatisticsPage.vue';
import WishListPage from '@/modules/episodes/pages/WishListPage.vue';

export default [
  {
    path: '/',
    name: 'episodes-list',
    component: EpisodesListPage,
    meta: { requiresAuth: true }
  },   
  {
    path: '/wishlist',
    name: 'episodes-wishlist',
    component: WishListPage,
    meta: { requiresAuth: true }
  }, 
  {
    path: '/add-episode',
    name: 'episode-add',
    component: EpisodeAddPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-episode/:episodeId',
    name: 'episode-edit',
    component: EpisodeEditPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId',
    name: 'episode-details',
    component: EpisodeDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/files',
    name: 'episode-files',
    component: EpisodeFilesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/statistics',
    name: 'episode-statistics',
    component: EpisodeStatisticsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/map',
    name: 'episode-map',
    component: EpisodeMapPage,
    meta: { requiresAuth: true }
  },
];
