import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getMessages(context, episodeId) {
      return axios.get('/api/episode/' + episodeId + '/messages');
    },
    getJwt(context, episodeId) {
      return axios.get('/api/episode/' + episodeId + '/messages-jwt');
    },
    createMessage(context, { episodeId, data }) {
      let formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }

      return axios.post('/api/episode/' + episodeId + '/message', formData);
    },
  }
}
