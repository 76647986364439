<template>
  <section class="well well--with-border well--dense">

    <div class="text-center"><strong>{{ $t('episodes.titles.statistics.videos') }}</strong></div>

    <v-row>
      <v-col class="pt-0">
        <strong>{{ $t('episodes.titles.statistics.videosPublic') }}:</strong>
      </v-col>
      <v-col class="pt-0 text-right">
        <small class="mr-2">({{ $helperFunctions.formatFileSize(publicSpace, 1) }})</small> 
        <strong>{{ publicCount }}</strong>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0">
        - {{ $t('episodes.titles.statistics.videosMinePublic') }}
      </v-col>
      <v-col class="pt-0 text-right">
        <small class="mr-1">({{ $helperFunctions.formatFileSize(minePublicSpace, 1) }})</small> 
        {{ minePublicCount }}
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col class="pt-0">
        - {{ $t('episodes.titles.statistics.videosOthersPublic') }}
      </v-col>
      <v-col class="pt-0 text-right">
        <small class="mr-1">({{ $helperFunctions.formatFileSize(othersPublicSpace, 1) }})</small> 
        {{ othersPublicCount }}
      </v-col>
    </v-row>


    <v-row>
      <v-col class="pt-0">
        <strong>{{ $t('episodes.titles.statistics.videosPrivate') }}:</strong>
      </v-col>
      <v-col class="pt-0 text-right">
        <small class="mr-2">({{ $helperFunctions.formatFileSize(minePrivateSpace, 1) }})</small> 
        <strong>{{ minePrivateCount }}</strong>
      </v-col>
    </v-row>

  </section>
</template>

<script>
export default {
  props: {
    statistics: {
      type: Object,
      default: null
    }
  },
  computed: {
    minePublicCount() {
      return this.statistics.public_my.count;
    },
    othersPublicCount() {
      return this.statistics.public_people.count;
    },
    publicCount() {
      return Number(this.statistics.public_my.count) + Number(this.statistics.public_people.count);
    },
    minePrivateCount() {
      return this.statistics.private.count;
    },
    minePublicSpace() {
      return this.statistics.public_my.size;
    },
    othersPublicSpace() {
      return this.statistics.public_people.size;
    },
    publicSpace() {
      return Number(this.statistics.public_my.size) + Number(this.statistics.public_people.size);
    },
    minePrivateSpace() {
      return this.statistics.private.size;
    },
  }
}
</script>