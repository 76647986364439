import axios from 'axios';

export default {
  namespaced: true,
  state: {
    filters: null,
    searchFor: null,
    searchCached: false,
  },
  getters: {
    getFilters: state => {
      return state.filters;
    },
    getSearchFor: state => {
      return state.searchFor;
    },
    getSearchCached: state => {
      return state.searchCached;
    },
  },
  mutations: {
    setFilters: (state, filters) => {
      state.filters = filters;
    },
    setSearchFor: (state, searchFor) => {
      state.searchFor = searchFor;
    },
    setSearchCached: (state, searchCached) => {
      state.searchCached = searchCached;
    }
  },
  actions: {
    searchEpisodes({ state }, params) {
      // cancel last call
      if (state.cancelToken) { state.cancelToken() }
      const CancelToken = axios.CancelToken;
      return axios.get('/api/search-episodes', {
        params: params,
        cancelToken: new CancelToken(c => { state.cancelToken = c })
      });
    },
    searchActivities(context, params) {
      return axios.get('/api/search-activity', { params: params });
    },
    searchFiles(context, params) {
      return axios.get('/api/search-file', { params: params });
    },
    searchNotes(context, params) {
      return axios.get('/api/search-note', { params: params });
    },
  }
};
