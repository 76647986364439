<template>
  <v-container class="pt-1 pb-2">
    <v-row class="filters-chips__row">

      <v-col class="flex-grow-0">
        <v-badge
          :value="hasFilters"
          overlap
        >
          <v-btn 
            icon
            outlined
            x-large
            :class="hasFilters ? 'white--background primary--text' : ''"
            :to="{ name: 'advanced-search' }"
          ><v-icon>mdi-tune</v-icon></v-btn>
          <template v-slot:badge>
            <strong>{{ countFilters }}</strong>
          </template>
        </v-badge>
      </v-col>
    
      <v-col 
        v-if="filters"
        class="pl-0 filters-chips__chips"
      >

        <filter-chip
          :value="$t('search.model.searchFor.' + searchFor)"
          hide-close
        />
        
        <filter-chip
          v-if="filters.title"
          :label="titleLabel"
          :value="filters.title"
          @close="filters.title = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.activityGroup"
          :label="$t('activities.form.label.group')"
          :value="filters.activityGroup.name"
          @close="filters.activityGroup = null; filters.activityType = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.activityType"
          :label="$t('activities.form.label.group')"
          :value="filters.activityType.name"
          @close="filters.activityType = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.dateFrom"
          :label="$t('episodes.form.label.from')"
          :value="$moment(filters.dateFrom).format($t('locale.formatDate'))"
          @close="filters.dateFrom = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.dateTo"
          :label="$t('episodes.form.label.to')"
          :value="$moment(filters.dateTo).format($t('locale.formatDate'))"
          @close="filters.dateTo = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.country"
          :label="$t('form.label.country')"
          :value="filters.country.name"
          @close="filters.country = null; filters.province = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.province"
          :label="$t('form.label.province')"
          :value="filters.province.name"
          @close="filters.province = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.city"
          :label="$t('user.form.label.city')"
          :value="filters.city"
          @close="filters.city = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.establishment"
          :label="$t('search.form.establishment')"
          :value="filters.establishment"
          @close="filters.establishment = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.address"
          :label="$t('activities.form.label.address')"
          :value="filters.address"
          @close="filters.address = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.user"
          :label="$t('episodes.form.label.sharedWith')"
          :value="filters.user.alias"
          @close="filters.user = null; $emit('change')"
        />

        <filter-chip
          v-if="filters.sharedEpisodes"
          :value="$t('search.titles.sharedEpisodesChip')"
          hide-close
        />
        
      </v-col>

    </v-row>
  </v-container>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FilterChip from './FilterChip.vue';

export default {
  components: {
    FilterChip
  },
  props: {
    searchFor: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      filters: 'search/getFilters'
    }),  
    hasFilters() {
      return !!this.countFilters;
    },  
    countFilters() {
      let count = 0;
      for (let key in this.filters) {
        if (this.filters[key]) {
          count++;
        }
      }
      return count;
    },
    selectedFilters() {
      let selectedFilters = {};
      let count = 0;
      for (let key in this.filters) {
        if (this.filters[key]) {
          selectedFilters[key] = this.filters[key];
        }
      }
      return selectedFilters;
    },
    titleLabel() {
      switch (this.searchFor) {
        case 'episodes':
          return this.$t('episodes.form.label.title');
        case 'activities':
          return this.$t('activities.form.label.title');
        case 'photos':
          return this.$t('search.form.photoDescription');
        case 'videos':
          return this.$t('search.form.videoDescription');
        case 'notes':
          return this.$t('search.form.noteTitle');
      }
      return '';
    }
  },
}
</script>

<style scoped>
.filters-chips__row {
  flex-wrap: nowrap;
}
.filters-chips__chips {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
/deep/ .filters-chips__chip.v-chip {
  flex-shrink: 0;
  border-color: white !important;
}

/* mobile */
@media (max-width: 1024px) {
  .filters-chips__chips {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
</style>