<template>
  <div>

    <loading v-if="loading" />

    <div v-if="!loading && transfers.length">
      <transfer-list-item
        v-for="transfer in transfers"
        :key="transfer.id"
        :item="transfer"
        @saved="loadTransfers(); $emit('saved')"
      />
    </div>
    
    <v-alert 
      v-if="!loading && !transfers.length"
      type="info"
    >
      {{ $t('transfers.texts.noPendingTransfers') }}
    </v-alert>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from '@/components/util/Loading';
import TransferListItem from './TransferListItem.vue';

export default {
  components: {
    Loading,
    TransferListItem
  },
  data() {
    return {
      loading: false,
      transfers: null
    }
  },
  created() {
    this.loadTransfers();
  },
  methods: {
    ...mapActions({
      getReceivedTransfers: 'transfers/getReceivedTransfers',
    }),
    loadTransfers() {
      this.loading = true;
      this.getReceivedTransfers()
      .then(response => {
        this.transfers = response.data.transfers;
        this.loading = false;
      });
    }
  }
}
</script>
