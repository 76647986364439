<template>
  <div>
    <v-menu 
      v-model="open"
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-show="item"
          icon
          small
          class="primary--text"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">

        <v-list-item
          :to="{ name: 'edit-notice', params:{
            noticeId: item.id
          } }"
        >
          <v-icon class="mr-3 primary--text">mdi-pencil-outline</v-icon> 
          {{ $t('actions.edit') }}
        </v-list-item>

        <v-list-item 
          v-if="!item.published"
          class="success--text"
          @click="$emit('publish')"
        >
          <v-icon class="mr-3">mdi-message-reply-text-outline</v-icon> 
          {{ $t('actions.publish') }}
        </v-list-item>

        <v-list-item 
          v-if="item.published && !item.finished"
          class="success--text"
          @click="$emit('republish')"
        >
          <v-icon class="mr-3">mdi-message-reply-text-outline</v-icon> 
          {{ $t('notices.actions.republish') }}
        </v-list-item>

        <v-list-item 
          v-if="item.published && !item.finished"
          @click="$emit('finish')"
        >
          <v-icon class="mr-3">mdi-close</v-icon> 
          {{ $t('notices.actions.finish') }}
        </v-list-item>

        <v-list-item 
          class="error--text"
          @click="$emit('delete')"
        >
          <v-icon class="mr-3">mdi-trash-can-outline</v-icon> 
          {{ $t('actions.delete') }}
        </v-list-item>

      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      open: false
    }
  }
}
</script>