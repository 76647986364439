<template>
  <div class="l-vertical__grow l-vertical">
    
    <page-title :back-to="{ name: 'user-login'}">
      {{ $t('user.titles.resetPassword') }}
    </page-title>

    <v-container class="container--narrow">
      
      <template v-if="!success">
        
        <v-alert 
          type="info"
          class="mt-6 mb-12"
        >{{ $t('user.texts.resetPasswordDescription') }}</v-alert>  

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submitForm"
        >
          <v-text-field
            v-model="email"
            :label="$t('user.form.label.email')"
            :rules="[$validationRules.required, $validationRules.email]"
            type="email"
            class="mb-6"
            validate-on-blur
          />

          <v-alert 
            v-if="error" 
            type="error"
            class="mb-6"
          >{{ error }}</v-alert>


          <v-btn
            block
            large
            class="primary secondary--text mt-12 mb-6"
            :loading="loading"
            @click="submitForm"
          >{{ $t('user.actions.recoverPassword') }}</v-btn>

          <v-btn
            block
            large
            outlined
            class="mb-6"
            :to="{ name: 'user-login' }" 
          >{{ $t('actions.back') }}</v-btn>          
        </v-form>


      </template>

      <template v-if="success">
        
        <v-alert 
          type="warning" 
          class="mt-6 mb-6"
        >{{ $t('user.texts.resetPasswordSuccess') }}</v-alert>

        <v-btn 
          block 
          large
          class="primary"
          :to="{ name: 'user-login' }"
        >
          {{ $t('actions.enter') }}
        </v-btn>
      </template>

    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      email: null,
      error: null,
      loading: false,
      success: false,
      valid: false
    };
  },
  methods: {
    ...mapActions({
      resetPassword: 'user/resetPassword'
    }),
    submitForm() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.loading = true;
      this.success = null;
      this.error = null;

      this.resetPassword(this.email)
      .then(response => {
        this.success = true;
      })
      .catch(error => {
        this.error = this.$helperFunctions.errorToString(error);
      })
      .finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
