<template>
  <loading v-if="loading" />
  <div 
    v-else
    class="expenses-balance"
  >

    <v-alert 
      v-if="expenses && !expenses.length"
      type="info"
    >
      {{ $t('texts.noElements') }}
    </v-alert>

    <template v-else> 

      <list-item
        v-for="item in users"
        :key="item.id"
        :item="item"
      />

    </template>

    <hr class="mb-5">

    <list-item
      :item="totalItem"
      class="expenses-balance__total"
    />   

  </div>
</template>

<script>
import ListItem from './ListItem.vue';

export default {
  components: {
    ListItem,
  },
  props: {
    expenses: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      users: {},
      totalItem: {
        title: this.$t('expenses.titles.totalExpenses'),
        amount: 0,
        errorVariousCurrencies: false,
      },
    }
  },  
  computed: {
  },
  created() {
    // calculate users total
    for (let expense of this.expenses) {
      let user = this.users[expense.user.id];
      if (!user) {
        user = {
          title: expense.user.alias,
          urlAvatar: expense.user.urlAvatar,
          amount: 0,
          currency: null,
          errorVariousCurrencies: false,
        }
        this.users[expense.user.id] = user;
      }
      if (user.currency && (user.currency !== expense.currency)) {
        user.errorVariousCurrencies = true;
      } 
      else {
        user.amount += expense.amount;
        user.currency = expense.currency;
      } 
    }  

    // calculate total
    for (let user of Object.values(this.users)) {
      if (user.amount) {
        if (!this.totalItem.currency) {
          this.totalItem.currency = user.currency;
        }
        else if (this.totalItem.currency !== user.currency) {
          this.totalItem.errorVariousCurrencies = true;
          continue;
        }
        this.totalItem.amount += user.amount;
      }
    }
  },
}
</script>

<style scoped>
.expenses-balance {
  max-width: 300px;
  margin: 20px auto 0 auto;
}
.expenses-balance__total {
  text-transform: uppercase;
}
</style>
```