<template>
  <v-badge
    :value="!!label"
    offset-x="calc(100% - 9px)"
    offset-y="17px"
    color="var(--color-background)"
  >
    <v-chip
      class="mx-1 my-2 secondary filters-chips__chip"
      label
      outlined
      :close="!hideClose"
      @click:close="$emit('close')"
    >
      {{ value }}
    </v-chip>
    <template v-slot:badge>
      {{ label }}
    </template>
  </v-badge>
</template>


<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },    
    value: {
      type: String,
      default: null
    },   
    hideClose: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped>
.filters-chips__chip {
  flex-shrink: 0;
}
</style>