<template>
  <v-menu 
    offset-y
    left
    eager
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        class="primary"
        :disabled="!selectedItems.length"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">        

      <restore-dialog 
        :selected-items="selectedItems" 
        @success="$emit('success')"
      />
      
      <delete-dialog 
        :selected-items="selectedItems"
        @success="$emit('success')"
      />

    </v-list>
  </v-menu>
</template>

<script>
import DeleteDialog from './DeleteDialog';
import RestoreDialog from './RestoreDialog';

export default {
  components: {
    DeleteDialog,
    RestoreDialog
  },
  props: {
    selectedItems: {
      type: Array,
      default: null
    }
  }
}
</script>