<template>
  <div class="l-vertical__grow l-vertical">
    <page-title history-back>
      {{ $t('notes.titles.notes') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('actions.edit') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">

      <v-container class="container--narrow pb-0">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >
          <form-fields
            :item="item"
            :selected-fields="selectedFields"
          />
        </v-form>  

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn 
                outlined
                block
                @click="$router.back()"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                :disabled="!notes || !notes.length || !hasSelectedFields"
                block
                :loading="saving"
                @click="submit"
              >
                {{ $t('actions.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from './FormFields.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {},
      loading: false,
      saving: false,
      selectedFields: {},
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      notes: 'notes/getSelectedNotes'
    }),  
    notesIds() {
      return this.notes.map(note => note.id);
    },
    hasSelectedFields() {
      let i;
      for (i in this.selectedFields) {
        if (this.selectedFields[i]) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {  
    ...mapMutations({
      addFlash: 'addFlash',
      setSelectedNotes: 'notes/setSelectedNotes'
    }),
    ...mapActions({
      updateNotes: 'notes/updateNotes'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        privacy: this.selectedFields.privacy ? this.item.privacy : undefined,
        date: this.selectedFields.date ? this.item.date : undefined,
        time: this.selectedFields.time ? this.item.time : undefined,
        description: this.selectedFields.description ? this.item.description : undefined
      }

      this.updateNotes({
        notesIds: this.notesIds,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.setSelectedNotes(null);
        this.$router.back();
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
    }
  }
}
</script>
