<template>
  <v-autocomplete
    v-model="filters.user"
    :items="contacts"
    :label="$t('episodes.form.label.sharedWith')"
    item-text="email"
    item-value="id"
    return-object
    :filter="customFilter"
    :loading="loading"
    :no-data-text="$t('collaborators.texts.searchUserNotFound')"
    clearable
    outlined
    hide-details
    dense
  >
    <template v-slot:selection="data">
      <user-item
        :item="data.item"
        :height="28"
      />
    </template>
    <template v-slot:item="data">
      <user-item
        :item="data.item"
        class="my-1"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    UserItem
  },
  props: {
    filters: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      search: null,
      contacts: []
    }
  },
  created() {
    this.loading = true;
    this.getContacts()
    .then(response => {
      this.contacts = response.data.users;
      this.contacts.sort((a, b) => (a.alias || '').localeCompare(b.alias || ''));
      this.loading = false;
    })
  },
  methods: {
    ...mapActions({
      getContacts: 'contacts/getContacts',
    }),
    customFilter (item, queryText, itemText) {
      const searchText = this.$helperFunctions.normalizeString(queryText);
      const alias = this.$helperFunctions.normalizeString(item.alias);
      const email = this.$helperFunctions.normalizeString(item.email);
      const lastname = this.$helperFunctions.normalizeString(item.lastname);
      const name = this.$helperFunctions.normalizeString(item.name);

      return alias.indexOf(searchText) > -1 
        || email.indexOf(searchText) > -1
        || lastname.indexOf(searchText) > -1
        || name.indexOf(searchText) > -1
    },
  }
}
</script>