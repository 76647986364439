<template>
  <div class="generic-list-item">

    <div class="generic-list-item__main">  
      {{ item.title }}

      <div 
        v-if="!item.published && !item.finished"
        class="overline error--text"
      ><strong>{{ $t('notices.model.state.pending') }}</strong></div>
      <div 
        v-if="item.published"
        class="overline success--text"
      ><strong>{{ $t('notices.model.state.published') }}</strong></div>
      <div 
        v-if="item.finished"
        class="overline"
      ><strong>{{ $t('notices.model.state.finished') }}</strong></div>

      <div class="overline small">
        {{ item.publishDate ? $moment(item.publishDate).format($t('locale.formatDateTime')) : '' }}
      </div>

      <v-row class="overline small">
        <v-col cols="6">
          <strong>
            {{ item.ios ? $t('notices.model.platform.ios') : '' }}
            {{ item.ios && item.android ? '·' : '' }}
            {{ item.android ? $t('notices.model.platform.android') : '' }}
            {{ (item.ios || item.android) && item.web ? '·' : '' }}
            {{ item.web ? $t('notices.model.platform.web') : '' }}
          </strong>
        </v-col>
        <v-col cols="3"><strong>{{ item.lang ? $t('languages.' + item.lang) : '' }}</strong></v-col>
        <v-col 
          cols="3"
          class="text-right"
        >
          <a 
            @click="open = !open"
          ><strong>VER</strong></a>
        </v-col>
      </v-row>
      <div 
        class="item__description"
        :class="open ? 'item__description--open' : ''"
      >
        {{ item.description }}
      </div>
      
    </div>

    <div 
      v-if="!item.finished"
      class="generic-list-item__buttons"
    >
      
      <actions-menu 
        :item="item" 
        class="mb-1"
        @publish="$emit('publish')"
        @republish="$emit('republish')"
        @finish="$emit('finish')"
        @delete="$emit('delete')"
      />

    </div>    

  </div>
</template>

<script>
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';

export default {
  components:{
    ActionsMenu
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      open: false
    }
  }
}
</script>

<style scoped>
.generic-list-item__main {
  padding-bottom: 8px;
}
.item__description {
    max-height: 0;
    transition: max-height 0.35s ease-out;
    overflow: hidden;
    padding-top: 4px;
}
.item__description--open {
    max-height: 2000px;
}
</style>