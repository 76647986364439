<template>
  <v-select
    value="recent"
    :items="options"
    item-value="id"
    item-text="name"
    hide-details
    class="sort-menu"
    dense
    outlined
    @change="$emit('change', $event)"
  >
    <template 
      slot="item" 
      slot-scope="data"
    >
      <div class="sort-menu__item">
        {{ data.item.name }}
        <v-icon 
          v-if="data.item.direction == 'asc'"
          small 
          class="ml-2"
          :title="$t('titles.sortBy.ascending')"
        >mdi-arrow-up</v-icon>
        <v-icon 
          v-else
          small 
          class="ml-2"
          :title="$t('titles.sortBy.descending')"
        >mdi-arrow-down</v-icon>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          id: 'recent',
          name: this.$t('titles.sortBy.recent'),
          direction: 'desc'
        },
        {
          id: 'date',
          name: this.$t('titles.sortBy.date'),
          direction: 'asc'
        },
        {
          id: 'name',
          name: this.$t('titles.sortBy.title'),
          direction: 'asc'
        }
      ]
    }
  }
};
</script>

<style scoped>
.sort-menu {
  flex-grow: 0;
  width: 170px;
}
.sort-menu__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.sort-menu__item::after {
  display: none;
}
</style>