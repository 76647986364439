<template>
  <div>
    <list-item-header :item="item" />
    <i18n
      path="notifications.types.deleted_procedure"
      tag="div"
    >
      <template v-slot:user>{{ item.data.alias }}</template>
      <template v-slot:episode>
        <router-link
          :to="{ name: 'procedure-list', params: { episodeId: item.data.episode.id } }"
        >{{ item.data.episode.title }}</router-link>
      </template>
    </i18n>
  </div>
</template>

<script>
import ListItemHeader from '../ListItemHeader.vue';

export default {
  components: {
    ListItemHeader
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  }
}
</script>