<template>
  <div>

    <v-alert 
      type="info"
    >Info alert</v-alert>

    <v-alert 
      type="success" 
    >Success alert</v-alert>

    <v-alert 
      type="error"
    >Error alert</v-alert>

    <v-alert 
      type="warning"
    >Warning alert</v-alert>

    <v-alert 
      prominent 
      type="success" 
    >Success alert <br>Prominent</v-alert>

    <br>

    <div class="subtitle-1">Flash messages</div>
    <br>

    <v-btn
      outlined
      class="mr-3"
      @click="flashSuccess"
    >flash success</v-btn>

    <v-btn
      outlined
      class="mr-3"
      @click="flashError"
    >flash error</v-btn>

    <v-btn
      outlined
      class="mr-3"
      @click="flashWarning"
    >flash warning</v-btn>

    <v-btn
      outlined
      class="mr-3"
      @click="flashInfo"
    >flash info</v-btn>

  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    flashSuccess() {
      this.addFlash({
        text: "Success message",
        type: 'success'
      });
    },    
    flashError() {
      this.addFlash({
        text: "Error message",
        type: 'error'
      });
    },    
    flashWarning() {
      this.addFlash({
        text: "Warning message",
        type: 'warning'
      });
    },    
    flashInfo() {
      this.addFlash({
        text: "Info message",
        type: 'info'
      });
    }
  }
};
</script>
