import helperFunctions from '@/plugins/helperFunctions';
const moment = require('moment');

export default {
  subscriptionIsValid(subscription) {
    if (!subscription) {
      return false;
    }
    if (subscription.status != 'confirmed') {
      return false;
    }
    const expiresAt = helperFunctions.fixApiDate(subscription.expiresAt) + ' 23:59:59';
    return !moment(expiresAt).isBefore(moment());
  }
};