<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'expenses-list', params: { episodeId: $route.params.episodeId }, query: { details: 1 } }">
      {{ $t('expenses.titles.expenses') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('expenses.titles.edit') }}
      </v-tab>
    </page-tabs>

    <loading v-if="loading" />

    <div
      v-else 
      class="l-vertical__scroll l-vertical"
    >

      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <form-fields
            :item="item"
            context="edit"
          />

        </v-form>

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'expenses-list', params: { episodeId: $route.params.episodeId }, query: { details: 1 }}"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from '../components/FormFields.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    Loading,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {},
      loading: false,
      saving: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
    })
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    // load expense
    this.loading = true;
    this.getExpense({
      episodeId: this.$route.params.episodeId,
      expenseId: this.$route.params.expenseId
    })
    .then(response => {
      this.item = response.data.expense;
      this.loading = false;
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getExpense: 'expenses/getExpense',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
      updateExpense: 'expenses/updateExpense'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        date: this.item.date,
        activityGroup: this.item.activityGroup.id,
        title: this.item.title,
        user: this.item.user.id,
        amount: String(this.item.amount).replace(',', '.'),
        currency: this.item.currency,
      }

      this.updateExpense({
        expenseId: this.item.id,
        episodeId: this.episode.id,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("expenses.messages.success.updated"),
          type: 'success'
        });
        this.$router.push({ name: 'expenses-list', params: { episodeId: this.$route.params.episodeId }, query: { details: 1 } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>