export default {
  state: {
    offline: false
  },
  getters: {
    offline: state => {
      return state.offline; 
    }
  },
  mutations: {
    offline : (state, value) => {
      state.offline = value; 
    }
  }
}
