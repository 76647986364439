<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :title="$t('worldmap.titles.legend')"
        :class="{
          'ml-1': $vuetify.breakpoint.mdAndUp
        }"
        :disabled="!activityGroups"
        v-on="on"
      >
        <v-icon>mdi-help</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">

      <v-list-item
        v-for="group in activityGroups"
        :key="group.id"
      >
        <v-icon 
          class="mr-3"
          :style="{ color: 'var(--color-activity-group--' + group.slug + ')' }"
        >mdi-circle</v-icon> 
        <v-icon class="mr-2">{{ group.icon }}</v-icon> 
        {{ group.name }}
      </v-list-item>

    </v-list>
  </v-menu>
</template>


<script>
export default {
  props: {
    activityGroups: {
      type: Array,
      default: null
    }
  }
}
</script>