<template>
  <language-select
    :value="$i18n.locale"
    class="mt-0"
    @input="onClick"
  />
</template>

<script>
import LanguageSelect from '@/components/form/LanguageSelect';

export default {
  components: {
    LanguageSelect
  },
  methods: {
    onClick(lang) {
      this.$i18n.locale = lang;
      localStorage.lang = lang;
      this.$vuetify.lang.current = lang;
      this.$moment.locale(lang);
    }
  }
}
</script>