<template>
  <page-tabs v-bind="$attrs">
    
    <v-tab 
      :to="{ 
        name: 'shared-episode', 
        params: { episodeId: $route.params.episodeId },
        query: { hash: $route.query.hash } 
      }"
      exact
    >{{ $t('shared.titles.episodeNav.episode') }}</v-tab>

    <v-tab 
      :to="{ 
        name: 'shared-episode-files', 
        params: { episodeId: $route.params.episodeId },
        query: { hash: $route.query.hash } 
      }"
    >
      {{ $t('episodes.titles.episodeNav.gallery') }}
    </v-tab>

    <v-tab 
      :to="{ 
        name: 'shared-episode-map', 
        params: { episodeId: $route.params.episodeId } ,
        query: { hash: $route.query.hash } 
      }"
    >{{ $t('episodes.titles.episodeNav.map') }}</v-tab>  

  </page-tabs>
</template>

<script>
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';

export default {
  components: {
    PageTabs
  }
}
</script>
