<template>
  <page-tabs>
    
    <v-tab 
      :to="{ name: 'data-upload-step-1' }"
      exact
    >{{ $t('dataUpload.titles.step1') }}</v-tab>
    
    <v-tab 
      :to="{ name: 'data-upload-step-2' }"
      :disabled="$router.currentRoute.name === 'data-upload-step-1'"
    >{{ $t('dataUpload.titles.step2') }}</v-tab>

    <v-tab 
      :to="{ name: 'data-upload-step-3' }"
      :disabled="$router.currentRoute.name === 'data-upload-step-1' || $router.currentRoute.name === 'data-upload-step-2'"
    >{{ $t('dataUpload.titles.step3') }}</v-tab>

  </page-tabs>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';

export default {
  components: {
    PageTabs
  },
  computed: {
    ...mapGetters({
      folders: 'dataUpload/folders',
      nested: 'dataUpload/nested',
    }),
  },
}
</script>
