<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('user.titles.profile') }}
    </page-title>

    <page-tabs v-model="selectedTab">
      <v-tab @click="selectedTab = 0">
        {{ $t('user.titles.profileTab1') }}
      </v-tab>     
      <v-tab @click="selectedTab = 1">
        {{ $t('user.titles.profileTab2') }}
      </v-tab>   
      <v-tab @click="selectedTab = 2">
        {{ $t('user.titles.profileTab3') }}
      </v-tab> 
    </page-tabs>


    <loading v-if="loading" />

    <v-tabs-items 
      v-else
      v-model="selectedTab"
      class="l-vertical__grow l-vertical"
    >
      <v-tab-item 
        class="l-vertical__grow l-vertical"
        eager
      >
        <tab-1 
          :user-data="userData"
          :valid.sync="valid"
          :saving="saving"
          @submit="submit"
        />
      </v-tab-item>

      <v-tab-item 
        class="l-vertical__grow l-vertical"
        eager
      >
        <tab-2 
          :user-data="userData"
          :valid.sync="valid"
          :saving="saving"
          @submit="submit"
        />   
      </v-tab-item>

      <v-tab-item 
        class="l-vertical__grow l-vertical"
        eager
      >
        <tab-3 />
      </v-tab-item>

    </v-tabs-items>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';

export default {
  components: {
    Loading,
    PageTabs,
    PageTitle,
    Tab1,
    Tab2,
    Tab3
  },
  data() {
    return {
      loading: false,
      saving: false,
      userData: null,
      selectedTab: 0,
      valid: true
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  created() {
    // load user data
    this.loading = true;
    this.getUser(this.user.id)
    .then(response => {
      this.userData = response.data.user;
      this.loading = false;
    });
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      mutateUser: 'user/mutateUser'
    }),
    ...mapActions({
      getUser: 'user/getUser',
      updateUser: 'user/updateUser',
    }),
    submit() {
      this.saving = true;

      let data = {
        name: this.userData.name,
        lastname: this.userData.lastname,
        alias: this.userData.alias,
        currentPassword: this.userData.currentPassword ? this.userData.currentPassword : '',
        plainPassword: this.userData.password ? this.userData.password : ''
      }
      if (this.userData.avatarFile) {
        data.avatarFile = this.userData.avatarFile;
      }

      this.updateUser(data)
      .then(response => {
        this.mutateUser(response.data.user);
        this.addFlash({
          text: response.data.message,
          type: 'success'
        });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
