<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ $t('search.titles.search') }}
      <template slot="buttons">
        <sort-menu 
          v-if="episodes && episodes.length"
          @change="sortBy = $event" 
        />
      </template>
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('search.titles.searches') }}
      </v-tab>
    </page-tabs>

    <v-container class="container--800">
      <v-row>
        <v-col class="flex-grow-1 pr-0">

          <v-text-field
            v-model="keyword"
            :placeholder="$t('actions.search')"
            hide-details
            outlined
            rounded
            clearable
            @input="submit"
          />

        </v-col>
        <v-col class="flex-grow-0">

          <v-btn 
            icon
            outlined
            x-large
            :to="{ name: 'advanced-search', query: { clear: 1 } }"
          ><v-icon>mdi-tune</v-icon></v-btn>

        </v-col>
      </v-row>
    </v-container>

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
    
      <v-container 
        v-if="!keyword"
        class="container--800"
      >
        <v-alert type="info">{{ $t('search.texts.search') }}</v-alert>
      </v-container>

      <loading v-if="loading" />

      <episodes-list 
        v-if="episodes && episodes.length" 
        :items="episodesSorted"
      />    

      <v-container 
        v-if="noResults"
        class="container--800"
      >
        <v-alert type="info">{{ $t('messages.results.notFound') }}</v-alert>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EpisodesList from '@/modules/episodes/components/EpisodesList/EpisodesList';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle";
import SortMenu from '@/modules/episodes/components/EpisodesList/SortMenu';
import statusTap from '@/mixins/statusTap.js';

export default {
  name: 'SearchPage',
  components: {
    EpisodesList,
    Loading,
    PageTabs,
    PageTitle,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      episodes: null,
      keyword: '',
      loading: false,
      sortBy: 'recent'
    }
  },
  computed: {
    episodesSorted() {
      let episodes = [...this.episodes];
      switch (this.sortBy) {
        case 'recent':
          episodes.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'dateAsc':
          episodes.sort((a, b) => a.date < b.date ? -1 : 1);      
          break;       
        case 'dateDesc':
          episodes.sort((a, b) => a.date < b.date ? 1 : -1);      
          break;     
        case 'title':
          episodes.sort((a, b) => (a.title || '').localeCompare(b.title || ''));      
          break;
      }
      return episodes;
    },
    noResults() {
      return this.validKeyword && this.episodes && !this.episodes.length && !this.loading;
    },
    validKeyword() {
      return this.keyword && this.keyword.length >= 3;
    }
  },
  created() {
    this.keyword = this.$route.query.keyword;   

    if (this.validKeyword) {
      this.submit();
    }  
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
    }),
    ...mapActions({
      searchEpisodes: 'search/searchEpisodes'
    }),
    submit() {
      if (!this.validKeyword) {
        return;
      }

      this.episodes = null;
      this.loading = true;

      let params = {
        search: this.keyword
      };

      this.searchEpisodes(params)
      .then(response => {
        if (response) {
          if (this.validKeyword) {
            this.episodes = response.data.episodes;
          }
          this.loading = false;
        }
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      });
    },
  }
}
</script>