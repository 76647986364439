<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'transfers-list' }">
      {{ $t('transfers.titles.transferReceived') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('titles.details') }}
      </v-tab>
    </page-tabs>


    <loading v-if="loading" />

    <div 
      v-else
      class="l-vertical__scroll"
    >
      <v-container class="container--narrow mb-2">

        <div class="subtitle-1 mb-3">{{ $t('transfers.titles.from') }}:</div>

        <user-item 
          :item="item.fromUser" 
          class="mb-4"
        /> 
        
        <div 
          v-if="item.photosCount"
          class="d-flex mb-1"
        >
          <div class="flex-grow-1">
            {{ $t('transfers.titles.numberOfPhotos') }}
          </div>
          <div class="flex-shrink-0">
            <small class="mr-4">({{ $helperFunctions.formatFileSize(item.totalSizePhotos, 1) }})</small> 
            {{ item.photosCount }}
          </div>
        </div>
        
        <div 
          v-if="item.moviesCount"
          class="d-flex mb-1"
        >
          <div class="flex-grow-1">
            {{ $t('transfers.titles.numberOfVideos') }}
          </div>
          <div class="flex-shrink-0">
            <small class="mr-4">({{ $helperFunctions.formatFileSize(item.totalSizeVideos, 1) }})</small> 
            {{ item.moviesCount }}
          </div>
        </div>

      </v-container>

      <v-container 
        class="py-0"
        :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
      > 
        <v-row 
          v-if="item.files && item.files.length" 
          no-gutters
          class="file-list__row"
        >
          <v-col
            v-for="file in item.files" 
            :key="file.id"
            :cols="cols"
          >
            <file-list-item 
              :file="file" 
              @click="openFile = file"
            />
          </v-col>
        </v-row>

      </v-container>
    </div>

    <file-details-modal
      :files="item.files"
      :file="openFile"
      :open="!!(openFile && openFile.id)"
      hide-menu
      @close="openFile = null"
    />
   
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FileDetailsModal from '@/modules/files/components/FileDetailsModal/FileDetailsModal.vue';
import FileListItem from '@/modules/files/components/FileListItem/FileListItem.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    FileDetailsModal,
    FileListItem,
    Loading,
    PageTabs,
    PageTitle,
    UserItem
  },
  data() {
    return {
      item: {},
      loading: false,
      openFile: null
    }
  },
  computed: {
    cols() {    
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 2;
      }
      if (this.$vuetify.breakpoint.sm) {
        return 3;
      }        
      if (this.files && this.files.length) {
        switch (this.files.length) {
          case 1:
          case 2:
            return 6;
        }
      }
      return 4;
    },
  },
  created() {
    // load transfer
    this.loading = true;
    this.getTransfer(this.$route.params.transferId)
    .then(response => {
      this.item = response.data.transfer;
      this.loading = false;
    });
  },
  methods: {  
    ...mapActions({
      getTransfer: 'transfers/getTransfer'
    }),
  }
}
</script>

<style scoped>
.file-list__row {
  margin-left: -1px;
  margin-right: -1px;
}
</style>
