<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'procedure-list', params: { episodeId: $route.params.episodeId } }">
      {{ $t('procedures.titles.procedures') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('procedures.titles.edit') }}
      </v-tab>
    </page-tabs>

    <loading v-if="loading" />

    <div
      v-else 
      class="l-vertical__scroll l-vertical"
    >

      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <form-fields
            :item="item"
            context="edit"
          />

        </v-form>

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'procedure-list', params: { episodeId: $route.params.episodeId }}"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from '../../components/FormFields.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    Loading,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {},
      loading: false,
      saving: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
      user: 'user/user'
    })
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    // load procedure
    this.loading = true;
    this.getProcedure({
      episodeId: this.$route.params.episodeId,
      procedureId: this.$route.params.procedureId
    })
    .then(response => {
      this.item = response.data.procedure;
      this.$set(this.item, 'file', null);
      this.$set(this.item, 'deleteFile', false);
      this.loading = false;
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getProcedure: 'procedures/getProcedure',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
      updateProcedure: 'procedures/updateProcedure'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        type: this.item.type,
        text: this.item.text,
        cost: this.item.cost ? this.item.cost : '',
        currency: this.item.currency
      }
      // upload file
      if (this.item.file) {
        data.filenameFile = this.item.file;
      }
      // delete file
      if (this.item.urlFilename && this.item.deleteFile && !this.item.file) {
        data.deleteFile = true;
      }


      this.updateProcedure({
        procedureId: this.item.id,
        episodeId: this.episode.id,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("procedures.messages.success.updated"),
          type: 'success'
        });
        this.$router.push({ name: 'procedure-list', params: { episodeId: this.episode.id } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>