import GoogleApiPage from '@/modules/adminStatistics/pages/GoogleApiPage.vue';
import SpacePage from '@/modules/adminStatistics/pages/SpacePage.vue';
import SubscriptionsPage from '@/modules/adminStatistics/pages/SubscriptionsPage.vue';
import UsersPage from '@/modules/adminStatistics/pages/UsersPage.vue';

export default [
  {
    path: '/admin-statistics/users',
    name: 'admin-statistics-users',
    component: UsersPage,
    meta: { requiresAdmin: true }
  },  
  {
    path: '/admin-statistics/subscriptions',
    name: 'admin-statistics-subscriptions',
    component: SubscriptionsPage,
    meta: { requiresAdmin: true }
  },  
  {
    path: '/admin-statistics/google-api',
    name: 'admin-statistics-google-api',
    component: GoogleApiPage,
    meta: { requiresAdmin: true }
  },  
  {
    path: '/admin-statistics/space',
    name: 'admin-statistics-space',
    component: SpacePage,
    meta: { requiresAdmin: true }
  },  
]
