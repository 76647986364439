var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-vertical__grow l-vertical"},[_c('page-actions',[_c('sort-menu',{staticClass:"mr-1",on:{"change":function($event){_vm.sortBy = $event}}}),_c('help-menu',[_vm._v(_vm._s(_vm.$t('shared.texts.help.episodeDetails')))])],1),_c('episode-tabs',{attrs:{"episode":_vm.episode}}),_c('div',{ref:"statusTap",staticClass:"l-vertical__scroll"},[_c('episode-header',{attrs:{"episode":_vm.episode}}),_c('v-container',{staticClass:"container--narrow",class:{ 'px-3': _vm.$vuetify.breakpoint.smAndDown }},[(!_vm.episode)?_c('loading'):_vm._e(),(_vm.episode && _vm.episode.description)?_c('collapse-text',{attrs:{"center-button":""}},[_vm._v(" "+_vm._s(_vm.episode.description)+" ")]):_vm._e()],1),(_vm.episode)?_c('v-container',{class:{ 'px-3': _vm.$vuetify.breakpoint.smAndDown }},[(_vm.loadingActivities)?_c('loading'):_vm._e(),_c('v-row',{staticClass:"activities-list",attrs:{"no-gutters":""}},_vm._l((_vm.activitiesSorted),function(activity){return _c('v-col',{key:activity.id,staticClass:"pr-3",attrs:{"cols":"6","sm":"4","md":"3"}},[_c('activity-list-item',{staticClass:"activities-list__item",attrs:{"item":activity,"show-date":"","shared":"","to":{ 
              name: 'shared-activity-details', 
              params: { 
                episodeId: _vm.$route.params.episodeId, 
                activityId: activity.id
              },
              query: { hash: _vm.$route.query.hash } 
            }}})],1)}),1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }