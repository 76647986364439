<template>
  <div class="generic-list-item">
    <div class="generic-list-item__main">
      <div class="d-flex mb-2">

        <div class="flex-grow-0 mr-2">
          <user-avatar
            :src="item.userDeleted ? null : item.user.urlAvatar"
            :size="35"
          />
        </div>

        <div class="flex-grow-1">
          <div class="small mb-1">
            {{ item.userDeleted ? $t('user.titles.userDeleted') : item.user.alias }}
          </div>
          <div class="overline small">
            {{ $moment(item.date).format($t('locale.formatDate')) }} {{ $helperFunctions.fixApiTime(item.time) }}
          </div>
        </div>

        <div class="flex-grow-0 d-flex align-start">
          <v-icon
            v-if="item.privacy == NotePrivacy.PRIVATE"
            small
            class="mx-1"
            :title="$t('activities.model.privacy.private')"
          >mdi-lock-outline</v-icon>
          <v-icon
            small
            class="ml-1"
          >{{ item.urlAudioFile ? 'mdi-volume-high' : 'mdi-format-text' }}</v-icon>
        </div>
      </div>

      <div 
        v-if="showActivityTitle"
        class="height-lines-1 small"
      >
        <span class="overline small">{{ $t('activities.titles.activity') }}:</span> {{ item.activity.name }}
      </div>

      <div>{{ item.title }}</div>

      <div 
        v-if="item.text.trim().length"
        class="note-list-item__text"
        :class="{ 'note-list-item__text--open': expandText }"
      >
        <div class="pt-2">{{ item.text }}</div>
      </div>
      
    </div>
    <div class="generic-list-item__buttons">
      
      <template v-if="!selectionEnabled">

        <v-btn
          v-if="showActivityLink"
          icon
          small
          dark
          color="primary"
          class="mb-1"
          :to="{ name: 'activity-notes', params: { 
            episodeId: item.activity.episode.id,
            activityId: item.activity.id
          }}"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>

        <actions-menu 
          v-if="item.canEdit && !showActivityLink"
          :item="item" 
          class="mb-1"
          @delete="$emit('delete')"
        />

        <div 
          v-if="!item.canEdit && !showActivityLink"
          class="generic-list-item__button-placeholder mb-1"
        ></div>

      </template>
      <template v-else>

        <v-simple-checkbox
          v-if="item.canEdit"
          v-model="item.selected" 
          v-ripple
          color="primary"
          class="mb-1 mr-n1"
        />

        <div 
          v-else
          class="generic-list-item__button-placeholder mb-1"
        ></div>
      </template>

      <audio-player
        v-if="item.urlAudioFile"
        :src="item.urlAudioFile"
        class="mb-1"
      />

      <v-btn
        v-else
        icon
        small
        color="primary"
        class="mb-1"
        @click="toggleText"
      >
        <v-icon size="20">mdi-book-open-variant</v-icon>
      </v-btn>

      <v-btn
        v-if="!selectionEnabled"
        icon
        dark
        small
        class="primary--text"
        :title="item.isFavorite ? $t('actions.removeFavorite') : $t('actions.addFavorite')"
        :loading="savingFavorite"
        @click.prevent="onFavoriteClick"
      >
        <v-icon>{{ item.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
      </v-btn>

    </div>    
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';
import AudioPlayer from '@/components/AudioPlayer.vue';
import CollapseText from '@/components/util/CollapseText';
import NotePrivacy from '@/modules/notes/model/NotePrivacy.js';
import UserAvatar from '@/modules/user/components/UserAvatar.vue';

export default {
  components:{
    ActionsMenu,
    AudioPlayer,
    CollapseText,
    UserAvatar
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    selectionEnabled: {
      type: Boolean,
      default: false
    },
    showActivityLink: {
      type: Boolean,
      default: false
    },
    showActivityTitle: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      expandText: false,
      NotePrivacy: NotePrivacy,
      savingFavorite: false,
    }
  },
  methods: {
    ...mapActions({
      setFavoriteNote: 'notes/setFavoriteNote',
    }),
    toggleText() {
      this.expandText = !this.expandText;
    },
    onFavoriteClick() {
      this.savingFavorite = true;
      this.setFavoriteNote(this.item.id)
      .then(response => {
        this.item.isFavorite = !this.item.isFavorite;
        this.$emit('modified');
      })
      .finally(() => {
        this.savingFavorite = false;
      })
    },
  }
}
</script>

<style scoped>
.note-list-item__text {
  max-height: 0;
  overflow: hidden;
	transition: max-height 0.15s ease-out;
}
.note-list-item__text--open {
  max-height: 1600px;
}
</style>
