<template>
  <div 
    v-if="episode"
    class="text-center"
  >
    <v-btn
      v-if="episode.rol && (episode.rol != roles.CREATOR)"
      icon
      :title="episode.rol == roles.CREATOR ? $t('collaborators.dialogs.exitEpisode.infoCreator') : ''"
      color="error"
      @click="open = true"
    >
      <v-icon>mdi-exit-run</v-icon>
    </v-btn>

    <v-dialog 
      v-model="open" 
      width="450"
    >
      <v-container  
        v-if="loading"
        class="py-4 px-6"
      >
        <loading />
      </v-container>

      <template v-if="!loading && !hasContent">
        <v-container class="py-4 px-6">
          <div class="subtitle-1 text-center mt-2 mb-6">{{ $t('collaborators.actions.exitEpisode') }}</div>
          <p v-html="$t('collaborators.dialogs.exitEpisode.info', { episode: episode.title })"></p>

          <v-checkbox
            v-model="removeMessages"
            :label="$t('collaborators.dialogs.exitEpisode.removeMessages')"
            hide-details
          />
        </v-container>

        <hr>

        <v-container class="py-1">
          <v-row>
            <v-col cols="6">
              <v-btn
                outlined
                block
                small
                @click="open = false"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                color="error"
                :loading="saving"
                block
                small
                @click="submit"
              >{{ $t('collaborators.actions.exit') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>


      <template v-if="!loading && hasContent">
        <v-container class="py-4 px-6">

          <div class="subtitle-1 text-center mt-2 mb-6">{{ $t('collaborators.actions.exitEpisode') }}</div>
          <p v-html="$t('collaborators.dialogs.exitEpisode.infoUsersHasContent')"></p>
        </v-container>

        <hr>

        <v-container class="py-4 px-6">
          <v-btn
            outlined
            block
            small
            @click="open = false"
          >{{ $t('actions.close') }}</v-btn>
        </v-container>
      </template>

    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Loading from '@/components/util/Loading';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    Loading,
  },
  props: {
    episodeId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      hasContent: false,
      loading: false,
      open: false,
      removeMessages: false,
      removeFiles: false,
      removeNotes: false,
      roles: roles,
      saving: false
    };
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
      user: 'user/user'
    })
  },
  created() {
    this.loading = true;
    this.userHasContent(this.$route.params.episodeId)
    .then(response => {
      this.hasContent = response.data['user-has-content'];
      this.loading = false;
    })
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      userHasContent: 'collaborators/userHasContent',
      exitEpisode: 'collaborators/exitEpisode'
    }),
    submit() {
      this.saving = true;

      const data = {
        removeMessages: this.removeMessages,
        removeFiles: this.removeFiles,
        removeNotes: this.removeNotes
      }

      this.exitEpisode({
        episodeId: this.episodeId,
        data: data
      })
      .then(() => {
        this.addFlash({
          text: this.$t('collaborators.messages.success.exitEpisode'),
          type: 'success'
        });
        this.$router.push({ name: 'episodes-list' });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>

<style scoped>
.l-vertical__full-height{
  height: auto;
}
</style>
