<template>
  <div class="l-vertical__scroll l-vertical">

    <v-container class="container--narrow mb-14">
     
      <div class="subtitle-1 text-center mt-6 mb-6">
        {{ $t('episodes.welcomeMessage.screen1Title', { alias: user.alias }) }}
      </div>

      <div class="subtitle-1 text-center mb-7">
        <img 
          src="img/welcome-image.png" 
          alt=""
          width="120"
        >
      </div>

      <div 
        class="text-center"
        v-html="$t('episodes.welcomeMessage.screen1Text')"
      ></div>
      
    </v-container>
    
  </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
}
</script>
