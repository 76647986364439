<template>
  <div>

    <upload-image
      v-model="item.imageFile"
      input-id="activityImage"
      class="mt-2 mb-6"
      :url-image="item.urlImageThumbnails ? item.urlImageThumbnails.medium_ + '?bearer=' + apiToken : ''"
      @error="onFileError"
    />
    
    <v-select
      v-model="item.privacy"
      :label="$t('form.label.privacy') + '*'"
      :items="privacyOptions"
      :disabled="item.user && (user.id !== item.user.id)"
    />

    <v-text-field
      v-model="item.name"
      :label="$t('activities.form.label.title') + '*'"
      :rules="[$validationRules.required]"
      type="text"
      maxlength="128"
    />

    <v-select
      v-model="item.activityGroup"
      :label="$t('activities.form.label.group') + '*'"
      :items="groups"
      item-text="name"
      item-value="id"
      return-object
      :loading="loading"
      :rules="[$validationRules.required]"
      @change="onGroupChange"
    />

    <v-combobox
      v-model="item.activityType"
      :label="$t('activities.form.label.type') + '*'"
      :items="typesOptions"
      item-text="name"
      item-value="id"
      return-object
      :loading="loading"
      :rules="[$validationRules.required]"
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
    />

    <v-row>
      <v-col cols="8">
        <date-input
          v-model="item.date"
          :label="$t('form.label.date')"
          :rules="[$validationRules.required]"
        />
      </v-col>

      <v-col cols="4">
        <time-input
          v-model="item.time"
          :label="$t('form.label.hour')"
          required
        />
      </v-col>
    </v-row>

    <v-textarea
      v-model="item.description"
      :label="$t('activities.form.label.description')"
      maxlength="250"
      rows="2"
      auto-grow
      @keyup.stop
    />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy';
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import DateInput from '@/components/form/DateInput';
import TimeInput from '@/components/form/TimeInput';
import UploadImage from "@/components/form/UploadImage/UploadImage.vue";

export default {
  components: {
    DateInput,
    TimeInput,
    UploadImage
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      groups: [],
      loading: false,
      loadingGroups: false,
      loadingTypes: false,
      privacyOptions: [
        { value: ActivityPrivacy.PRIVATE, text: this.$t('activities.model.privacy.private') },
        { value: ActivityPrivacy.VISIBLE, text: this.$t('activities.model.privacy.visible') }
      ],
      roles: roles,
      typesData: [],
      typesOptions: []
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken',
      user: 'user/user',
    })
  },
  watch: {
    'item.activityGroup.id': function(value) {
      this.setTypesOptions();
    }
  },
  created() {
    this.loading = true;

    Promise.all([
      this.getActivityGroups(),
      this.getActivityTypes()
    ])
    .then(response => {
      this.loading = false;

      // groups
      let data = response[0].data;

      // find default group
      let defaultGroup = data.find(item => item.slug == 'none');
      data = data.filter(item => item.id != defaultGroup.id);

      // default group goes first
      this.groups = [];
      this.groups.push(defaultGroup);
      this.groups.push({ divider: true });

      // recent groups go first
      let recentGroups = data.filter(item => item.recently);
      let otherGroups = data.filter(item => !item.recently);
      if (recentGroups.length) {
        this.groups = this.groups.concat(recentGroups);
        this.groups.push({ divider: true });
      }
      this.groups = this.groups.concat(otherGroups);


      // types
      this.typesData = response[1].data;

      // set default group
      if (!this.item.id) {
        this.item.activityGroup = defaultGroup;
      }
      this.setTypesOptions();
        
      // set default type
      if (!this.item.id) {
        let types = this.typesData.find(item => item.id == defaultGroup.id);
        this.item.activityType = types.activityTypes[0];
      }
      
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getActivityGroups: 'activities/getActivityGroups',
      getActivityTypes: 'activities/getActivityTypes',
    }),
    onFileError(error) {
      this.addFlash({
        text: error,
        type: 'error'
      });
    },
    onGroupChange() {
      this.item.activityType = null;
      this.setTypesOptions();
    },
    setTypesOptions() {
      let group;
      if (this.typesData.length && this.item && this.item.activityGroup) {
        group = this.typesData.find(group => group.id == this.item.activityGroup.id)
      }
      if (group) {
        // recent types go first
        const recentTypes = group.activityTypes.filter(item => item.recently);
        const otherTypes = group.activityTypes.filter(item => !item.recently);
        this.typesOptions = recentTypes;
        if (recentTypes.length) {
          this.typesOptions.push({ divider: true });
        }
        this.typesOptions = this.typesOptions.concat(otherTypes);
      }
      else {
        this.typesOptions = [];
      }
    },
  }
}
</script>