<template>
  <v-select
    :value="value"
    label="Año"
    :items="options"
    hide-details
    v-bind="$attrs"
    @change="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      options: []
    }
  },
  created() {
    let now = Number(this.$moment().format('YYYY'));
    for (let i = 2020; i <= now; i++) {
      this.options.push(i);
    }
  }
};
</script>