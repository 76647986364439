<template>
  <div class="login-page">
    <v-container class="container--narrow">

      <div 
        class="logo"
        @click="onLogoClick"
      >Timlapp</div>
      
      <div class="title login__title mb-8 text-center">{{ $t('user.titles.slogan') }}</div>

      <template v-if="!loadingConfirmation && (!success || (success && !isMobileBrowser) || error)">
        <div class="mb-6 text-center">{{ $t('user.texts.loginDescription') }}</div>

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submitForm"
        >
          <v-text-field
            v-model="email"
            :label="$t('user.form.label.email')"
            :rules="[$validationRules.required, $validationRules.email]"
            type="email"
            validate-on-blur
            autocomplete="on"
          />
          <v-text-field
            v-model="password"
            :label="$t('user.form.label.password')"
            :rules="[$validationRules.required]"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            class="mb-1"
            autocomplete="on"
            @click:append="showPassword = !showPassword"
          />

          <v-alert 
            :value="!!error" 
            type="error"
            transition="fade-transition"
          >{{ error }}</v-alert>

          <v-btn
            block
            large
            class="primary secondary--text mt-4 mb-6"
            :disabled="!valid"
            :loading="loading"
            @click="submitForm"
          >{{ $t('user.actions.login') }}</v-btn>
        </v-form>

        <v-alert 
          :value="!!success"
          prominent 
          type="success" 
          class="mb-4"
        >
          {{ $t('user.texts.completeRegistrationSuccess') }}
        </v-alert>

        <div class="text-center mt-8 mb-4">
          <router-link 
            :class="error ? '' : 'link--no-color'"
            :to="{ name: 'user-reset-password' }" 
          >
            {{ $t('user.actions.resetPassword') }}
          </router-link>
        </div>

        <div class="text-center mb-8">
          <lang-switch /> 
        </div>

        <v-btn 
          block
          large
          outlined 
          :to="{ name: 'user-register' }" 
        >
          {{ $t('user.actions.createAccount') }}
        </v-btn>

      </template>

      <loading v-if="loadingConfirmation" />


      <div 
        v-if="!loadingConfirmation && success && !error && isMobileBrowser"
        class="text-center body-2 pt-8"
        v-html="$t('user.texts.completeRegistrationSuccessMobile')"
      ></div>


    </v-container>

  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import LangSwitch from './LangSwitch.vue';
import Loading from '@/components/util/Loading';

export default {
  components: {
    LangSwitch,
    Loading
  },
  data() {
    return {
      email: null,
      error: null,
      highlight: null,
      isMobileBrowser: this.$helperFunctions.isMobileBrowser(),
      password: null,
      loading: false,
      loadingConfirmation: false,
      openingApp: false,
      showPassword: false,
      valid: false,
      success: false
    };
  },
  created() {
    // confirm registration
    if (this.$route.params.token) {
      this.loadingConfirmation = true;
      this.completeRegistration(this.$route.params.token)
      .then(response => {
        if (response.data.user) {
          this.email = response.data.user.email;
          this.success = true;
        }
      })
      .catch(error => {
        this.error = this.$helperFunctions.errorToString(error);
      })
      .finally(() => {
        this.loadingConfirmation = false;
      });
    }
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      completeRegistration: 'user/completeRegistration',
      updateDeviceInformation: 'user/updateDeviceInformation',
    }),
    submitForm() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.loading = true;
      this.error = null;

      this.login({
        email: this.email,
        password: this.password
      })
      .then(response => {
        this.updateDeviceInformation();
        this.$router.push({ name: 'episodes-list' });
      })
      .catch(error => {
        this.success = false;
        this.error = this.$helperFunctions.errorToString(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openApp() {
      this.openingApp = true;
      window.location.href = "timlapp://";
      setTimeout(() => {
        this.openingApp = false;
        // redirect to Apple App store
        if (this.$helperFunctions.isBrowserIos()) {
          window.location.href = process.env.VUE_APP_APPLE_STORE_URL;
        }
        // redirect to Google Play store
        else if (this.$helperFunctions.isBrowserAndroid()) {
          window.location.href = process.env.VUE_APP_GOOGLE_STORE_URL;
        }
      }, 2000);
    },
    onLogoClick() {
      // show BUILD number in console
      console.log(
        `%c BUILD ${process.env.VUE_APP_BUILD} `,
        'background: #000000; color: #ffb300'
      );
    }
  }
}
</script>

<style scoped>
.login__title.title {
  font-size: 27px !important ;
}
.logo {
  color: var(--v-primary-base);
  font-size: 28px;
  letter-spacing: 0;
  text-align: center;
  font-size: 42px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 30px;
}
.register-section {
  text-align: center;
  padding: 4px 0 24px 0;
}
.register-section--highlighted {
  background-color: var(--v-primary-base);
}
.register-section__link {
  color: inherit;
}
/deep/ .v-input input{
  max-height: none;
}
</style>