import FileEditPage from '@/modules/files/pages/FileEditPage/FileEditPage.vue';
import FileUploadPage from '@/modules/files/pages/FileUploadPage/FileUploadPage.vue';
import FileUploadStep2Page from '@/modules/files/pages/FileUploadStep2Page.vue';
import FileUploadStep3Page from '@/modules/files/pages/FileUploadStep3Page.vue';
import MultipleFilesEditPage from '@/modules/files/pages/MultipleFilesEditPage/MultipleFilesEditPage.vue';

export default [
  {
    path: '/file-upload/:episodeId?/:activityId?',
    name: 'file-upload',
    component: FileUploadPage,
    meta: { requiresAuth: true }
  },  
  {
    path: '/file-upload-2/:episodeId?',
    name: 'file-upload-2',
    component: FileUploadStep2Page,
    meta: { requiresAuth: true }
  },  
  {
    path: '/file-upload-3/:episodeId/:activityId',
    name: 'file-upload-3',
    component: FileUploadStep3Page,
    meta: { requiresAuth: true }
  },
  {
    path: '/activity/:activityId/file/:fileId/edit',
    name: 'activity-file-edit',
    component: FileEditPage,
    meta: { requiresAuth: true }
  }, 
  {
    path: '/multiple-files-edit',
    name: 'multiple-files-edit',
    component: MultipleFilesEditPage,
    meta: { requiresAuth: true }
  }
];
