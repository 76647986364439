var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-vertical__grow l-vertical"},[_c('page-title',{attrs:{"back-to":{ name: 'episodes-list'},"enable-back-to-menu":""}},[_vm._v(" "+_vm._s(_vm.$t('timeline.titles.your-history'))+" "),_c('template',{slot:"buttons"},[_c('v-btn',{attrs:{"icon":"","title":_vm.expanded ? _vm.$t('actions.collapse') : _vm.$t('actions.expand')},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('v-icon',[_vm._v(_vm._s(_vm.expanded ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal'))])],1)],1)],2),_c('page-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{on:{"click":function($event){_vm.tab = 0}}},[_vm._v(_vm._s(_vm.$t('timeline.titles.myEpisodesTab')))]),_c('v-tab',{on:{"click":function($event){_vm.tab = 1}}},[_vm._v(_vm._s(_vm.$t('timeline.titles.wishListTab')))])],1),_c('div',{ref:"statusTap",staticClass:"l-vertical__scroll text-center"},[_c('v-container',[(_vm.loading || _vm.loadingHistoryActivities || _vm.loadingHistoryActivitiesWish)?_c('loading'):_c('div',{staticClass:"timeline"},[_vm._l((_vm.years),function(year){return [(_vm.yearsWithActivities.includes(year))?_c('div',{key:year,staticClass:"timeline__item timeline__item--active"},[_c('v-btn',{staticClass:"primary",attrs:{"small":"","to":{ 
                name: 'search-activities-results', 
                query: { 
                  year: year,
                  wishlist: _vm.tab ? 1 : undefined
                },
              }}},[_vm._v(" "+_vm._s(year)+" ")])],1):_c('transition',{key:year,attrs:{"name":"scale"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:((year >= _vm.birth) && (year <= _vm.yearNow) && _vm.expanded),expression:"(year >= birth) && (year <= yearNow) && expanded"}],staticClass:"timeline__item timeline__item--disabled"},[_c('v-btn',{attrs:{"small":"","disabled":""}},[_vm._v(" "+_vm._s(year)+" ")])],1)]),(_vm.birth && (year == _vm.birth))?_c('div',{key:year + 'birth',staticClass:"timeline__item timeline__item--birth"},[_c('strong',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t('timeline.titles.birth')))])]):_vm._e()]})],2)],1)],1),_c('menu-bottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }