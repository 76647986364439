export default {
  state: {
    flashBag: null
  },
  getters: {
    flashBag: state => {
      return state.flashBag;
    }
  },
  mutations: {
    addFlash: (state, { text, type='info', timeout }) => {
      state.flashBag = { text, type, timeout };
    },
    flushFlashBag: state => {
      state.flashBag = null;
    }
  }
}
