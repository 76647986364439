<template>
  <div>
    <div class="mb-4">{{ $t('collaborators.texts.addCollaborator') }}</div>

    <v-combobox
      v-model="item.email"
      :items="usersFiltered"
      :label="$t('collaborators.form.label.user')"
      item-text="email"
      item-value="email"
      :return-object="false"
      :rules="[$validationRules.required, $validationRules.email]"
      :search-input.sync="search"
      :loading="loading"
      autocomplete="off"
      no-filter
      clearable
      validate-on-blur
      outlined
    >
      <template v-slot:item="data">
        <user-item
          :item="data.item"
          class="my-1"
        />
      </template>
    </v-combobox>
  
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    UserItem
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      search: null,
      users: []
    }
  },
  computed: {
    usersFiltered() {
      if (!this.search || this.search.length < 3) {
        return [];
      }
      let search = this.$helperFunctions.normalizeString(this.search);
      return this.users.filter(user => {
        if (this.$helperFunctions.normalizeString(user.alias).indexOf(search) != -1) {
          return true;
        }
        if (this.$helperFunctions.normalizeString(user.email).indexOf(search) != -1) {
          return true;
        }
        if (this.$helperFunctions.normalizeString(user.name).indexOf(search) != -1) {
          return true;
        }
        if (this.$helperFunctions.normalizeString(user.lastname).indexOf(search) != -1) {
          return true;
        }
        return false;
      })
    }
  },
  created() {
    // load contacts
    this.loading = true;
    this.getContacts()
    .then(response => {
      // filter out users participating in 0 episodes
      this.users = response.data.users.filter(user => user.count > 0);
      this.loading = false;
    }) 
  },
  methods: {
    ...mapActions({
      getContacts: 'contacts/getContacts'
    })
  }
}
</script>