<template>
  <v-radio-group
    class="search-for-field"
    hide-details
    :value="value"
    @change="$emit('change', $event)"
  >
    <v-radio
      :label="$t('episodes.form.label.locationUseGps')"
      :value="true"
      on-icon="mdi-checkbox-marked-outline"
      off-icon="mdi-checkbox-blank-outline"
      class="mb-3"
    ></v-radio>
    <v-radio
      :label="$t('episodes.form.label.locationUseDefaults')"
      :value="false"
      on-icon="mdi-checkbox-marked-outline"
      off-icon="mdi-checkbox-blank-outline"
    ></v-radio>
  </v-radio-group>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
}
</script>