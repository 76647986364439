<template>
  <div 
    class="data-upload__list-item"
    :class="{ 'data-upload__list-item--current': current }"
  >
    <local-item 
      :item="item" 
      :nested="nested"
      :uploading="uploading"
    />
    <remote-item 
      :item="item" 
      :nested="nested"
    />
    <item-status 
      :item="item" 
      :uploading="uploading"
      :current="current"
    />
  </div>
</template>

<script>
import ItemStatus from './ItemStatus.vue';
import LocalItem from './LocalItem.vue';
import RemoteItem from './RemoteItem.vue';

export default {
  components: {
    ItemStatus,
    LocalItem,
    RemoteItem
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.data-upload__list-item {
  display: flex;
  padding: 6px;
  font-size: 14px;
  letter-spacing: 0;
}
.data-upload__list-item:nth-child(even) {
  background-color: var(--color-background);
}
.data-upload__list-item--current {
  background-color: var(--color-background--darker) !important;
}
</style>