<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('transfers.titles.transfers') }}
    </page-title>

    <page-tabs v-model="tab">
      <v-tab @click="tab = 0">{{ $t('transfers.titles.tabReceived') }}</v-tab>
      <v-tab @click="tab = 1">{{ $t('transfers.titles.tabSent') }}</v-tab>
    </page-tabs>


    <div class="l-vertical__scroll"> 
      <v-container class="container--narrow pt-5">

        <space-used 
          ref="spaceUsed"
          class="mb-6" 
        />


        <v-tabs-items 
          v-model="tab"
          class="l-vertical__grow l-vertical"
        >
          <v-tab-item 
            eager
            class="l-vertical__grow l-vertical"
          >
            <tab-received @saved="onSaved" />
          </v-tab-item>
          <v-tab-item 
            eager
            class="l-vertical__grow l-vertical"
          >
            <tab-sent />
          </v-tab-item>
        </v-tabs-items>

      </v-container>

    </div>

    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import SpaceUsed from "@/modules/transfers/pages/TransferListPage/SpaceUsed.vue";
import TabReceived from './TabReceived/TabReceived.vue';
import TabSent from './TabSent/TabSent.vue';

export default {
  components: {
    MenuBottom,
    PageTabs,
    PageTitle,
    SpaceUsed,
    TabReceived,
    TabSent
  },
  data() {
    return {
      tab: this.$route.params.tab == 'sent' ? 1 : 0
    }
  },
  methods: {
    onSaved() {
      this.$refs.spaceUsed.loadStatistics();
    }
  }
}
</script>
