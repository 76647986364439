<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('subscription.titles.subscription') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('subscription.titles.subscription-state') }}
      </v-tab>
    </page-tabs>

    <progress-linear 
      :loading="processing || loading" 
    />

    <div class="l-vertical__scroll l-vertical">

      <v-container 
        v-if="loaded"
        class="container--narrow pt-4"
      >

        <!-- valid subscription -->
        <template v-if="subscription && subscriptionIsValid">
          <div class="mb-6">
            {{ $t('subscription.texts.subscribed') }}<br><br>
            <small>{{ $t('subscription.texts.space-calculation') }}</small>
          </div>

          <v-row class="mb-4">
            <v-col>{{ $t('subscription.model.subscription-date') }}:</v-col>
            <v-col class="text-right">
              {{ $moment(subscription.createdAt).format('DD-MM-YYYY') }}
            </v-col>
          </v-row>

          <v-row class="mb-5">
            <v-col>{{ $t('subscription.model.subscription-renewal-date') }}:</v-col>
            <v-col class="text-right">
              {{ $moment(subscription.expiresAt).format('DD-MM-YYYY') }}
            </v-col>
          </v-row>

        </template>


        <!-- invalid subscription -->
        <template v-if="subscription && !subscriptionIsValid">

          <v-row class="mb-4">
            <v-col>{{ $t('subscription.model.subscription-date') }}:</v-col>
            <v-col class="text-right">
              {{ $moment(subscription.expiresAt).subtract(1, 'years').format('DD-MM-YYYY') }}
            </v-col>
          </v-row>

          <v-row class="mb-5">
            <v-col>{{ $t('subscription.model.subscription-renewal-date') }}:</v-col>
            <v-col class="text-right error--text">
              {{ $moment(subscription.expiresAt).format('DD-MM-YYYY') }}
            </v-col>
          </v-row>

          <div class="mb-6 error--text">
            {{ $t('subscription.texts.invalid-subscription') }}
            <div class="text-center mt-6">
              <v-icon size="80">mdi-alert-outline</v-icon>
            </div>
          </div>

        </template>


        <!-- no subscription -->
        <template v-if="!subscription">
          <div class="mb-5">
            {{ $t('subscription.texts.no-subscription-1') }}<br>
            {{ $t('subscription.texts.no-subscription-2') }}<br><br>
            <small>{{ $t('subscription.texts.space-calculation') }}</small><br><br>
            {{ $t('subscription.texts.no-subscription-4') }}<br><br>
            {{ $t('subscription.texts.no-subscription-5') }}<br>
          </div>

          <div 
            v-if="isMobile"
            class="l-vertical__sticky-footer--mobile"
          >
            <v-container class="container--narrow pt-0">
              <v-btn 
                block
                class="primary"
                :loading="processing"
                :disabled="!product"
                @click="onSubscribeClick" 
              >{{ $t('subscription.actions.subscribe') }}</v-btn>
            </v-container>
          </div>
        </template>


      </v-container>

      <!--       
      <v-container 
        v-if="isMobile"
        class="container--narrow pt-4"
      >
        <div>{{ $t('subscription.model.subscription-id') }}:     {{ product ? product.id : '' }}</div>
        <div>{{ $t('subscription.model.subscription-title') }}:  {{ product ? product.title : '' }}</div>
        <div>{{ $t('subscription.model.subscription-state') }}:  {{ product ? product.state : '' }}</div>
        <div>{{ $t('subscription.model.subscription-price') }}:  {{ product ? product.price : '' }}</div>
        <div>Expirydate: {{ product ? product.expiryDate : '' }}</div>
        <div>{{ $t('subscription.model.subscription-owned') }}: {{ product ? $t(product.owned ? 'texts.yes' : 'texts.no') : '' }}</div>
      </v-container> 
-->


    </div>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ProgressLinear from '@/components/util/ProgressLinear';
import statusTap from '@/mixins/statusTap.js';
import SubscritpionModel from '@/modules/subscription/model/SubscriptionModel.js';


export default {
  components: {
    Loading,
    PageTabs,
    PageTitle,
    ProgressLinear,
  },
  mixins: [statusTap],
  data() {
    return {
      buttonClicked: false,
      loading: false,
      loaded: false,
      platform: null,
      product: null,
      showSuccessMessage: false,
      subscription: null,
      subscriptionIdApple: 'timlapp_subscription_3',
      subscriptionIdGoogle: 'timlapp_subscription',
      processingInProgress: false,
      isDestroyed: false
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    subscriptionIsValid() {
      return SubscritpionModel.subscriptionIsValid(this.subscription);
    },
    isMobile() {
      return this.$helperFunctions.isCordova();
    },
    processing() {
      if (!this.isMobile) {
        return false;
      }

      if (this.subscriptionIsValid) {
        return false;
      }

      if (this.processingInProgress) {
        return true;
      }
 
      if (!this.product) {
        return true;
      }

      if (this.product.owned) {
        return false;
      }

      switch (this.product.state) {
        case 'requested':
        case 'initiated':
        case 'approved':
        case 'finished':
          return true;
        default:
          return false;
      } 
    },
    subscriptionId() {
      if (this.platform == 'ios') {
        return this.subscriptionIdApple;
      }
      if (this.platform == 'android') {
        return this.subscriptionIdGoogle;
      }
      return '';
    }
  },
  created() {
    this.platform = this.$helperFunctions.platform();
    this.loadSubscription();
  },
  beforeDestroy() {
    console.log('beforeDestroy');
    this.isDestroyed = true;
    this.storeOff();
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getSubscription: 'subscription/getSubscription',
    }),
    loadSubscription() {
      if (this.isDestroyed) {
        return;
      }

      console.log('loadSubscription');
      this.loading = true;
      // load subscription record from DB
      this.getSubscription()
      .then(response => {
        this.subscription = response.data.subscription;
        console.log('load subscription record from DB', this.subscription);
        if (this.isMobile && !this.subscription) {
          this.initializeStore();
        }
        this.loading = false;
        this.loaded = true;

        // on success
        if (this.subscription && this.buttonClicked && this.showSuccessMessage) {
          // show success message
          this.addFlash({
            text: this.$t("subscription.messages.success.subscribed"),
            type: 'success'
          }); 
          this.showSuccessMessage = false;

          this.storeOff();
        }
      })
      .finally(() => {
        if (!this.subscription) {
          setTimeout(this.loadSubscription, 3000);
        }
        else {
          this.processingInProgress = false;
        }
      })
    },
    initializeStore() {
      if (!this.isMobile) {
        return;
      }

      console.log('store', store);
      store.verbosity = store.DEBUG;

      // skip initialization
      if (this.product = store.get(this.subscriptionId)) {
        console.log('skip initialization')
        store.refresh();
        console.log('store.products', store.products);
        return;
      }

      // We should first register all our products or we cannot use them in the app.
      store.register([{
        id:    this.subscriptionId,
        type:   store.PAID_SUBSCRIPTION,
      }]);
      console.log('store.products', store.products);

      // Setup the receipt validator service.
      store.validator = process.env.VUE_APP_API_URL + '/api/subscription/subscribe';
      cordova.plugin.http.setHeader('Authorization', 'Bearer ' + this.apiToken);
      cordova.plugin.http.setHeader('Accept', 'application/json, text/plain, */*');
      cordova.plugin.http.setHeader('Accept-Language', this.$i18n.locale);


      store.error(error => {
        console.error(error);
        this.onError('Store error ' + error.code + ': ' + error.message);
      });

      // Called when any subscription product is updated
      store.when(this.subscriptionId).updated(this.onProductUpdated);

      store.when()
      .approved(this.onProductApproved)
      .verified(this.onProductVerified)
      .owned(this.onProductOwned);


      // Load informations about products and purchases
      store.refresh();
    },
    onProductUpdated() {
      this.product = store.get(this.subscriptionId) || null;
      console.log('product updated', this.product.owned, this.product.state, this.product);
    },
    onProductApproved(p) {
      console.log('ON approved');
      if (!p.owned) {
        p.verify();
      }
    },
    onProductVerified(p) {
      console.log('ON verified');
      if (!p.owned) {
        p.finish();
      }
    },
    onProductOwned(p) {
      console.log('ON owned');
      if (this.buttonClicked) {
        this.showSuccessMessage = true;
      }
      store.refresh();
      this.processingInProgress = false;
    },
    onSubscribeClick() {
      this.buttonClicked = true;
      this.processingInProgress = true;
      store.order(this.subscriptionId);
    },
    onError(text) {
      this.addFlash({
        text: text,
        type: 'error'
      });
      this.processingInProgress = false;
    },
    storeOff() {
      console.log('storeOff');
      if (this.isMobile && store) {
        store.off(this.onProductUpdated);
        store.off(this.onProductApproved);
        store.off(this.onProductVerified);
        store.off(this.onProductOwned);
      }
    },
  },
}
</script>
