<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      :back-to-routes-names="['favorites', 'episodes-wishlist', 'search', 'search-episodes-results', 'contact-details']"
    >
      {{ episode ? episode.title : '' }}
      <template slot="buttons">
        <filter-menu @change="filters = $event" />

        <sort-menu @change="sortBy = $event" />
        
        <app-fab 
          v-if="episode && (episode.rol != roles.COMMENTATOR)"
          add-activity
          add-files
          add-notes
        />
      </template>
    </page-title>

    <episode-tabs
      :episode="episode"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <episode-header 
        :episode="episode" 
      />

      <v-container 
        :class="{ 'px-3': $vuetify.breakpoint.smAndDown }"
        class="container--narrow"
      >

        <loading v-if="!episode" />

        <template v-if="episode">

          <div class="mb-3 episode__details">
            <collapse-text
              :collapse-all="!episode.description"
              force-collapse
              center-button
              button-class="mt-1"
            >

              <div class="text-center episode__description">{{ episode.description }}</div>

              <user-item
                :item="episode.user"
                :title="$t('episodes.titles.owner')"
                no-ellipsis
                class="mt-7"
              />

              <v-row 
                v-if="hasCost"
                class="mt-6"
              >
                <v-col class="flex-grow-0 d-flex">
                  <v-icon class="grey--text">mdi-cash-multiple</v-icon>
                </v-col>

                <v-col class="pa-0">{{ $helperFunctions.formatMoney(cost) }} {{ currency }}</v-col>
              </v-row>

              <v-row 
                v-if="episode.melody"
                class="mt-6"
              >
                <v-col class="flex-grow-0 d-flex align-center">
                  <v-icon class="grey--text">mdi-music-note</v-icon>
                </v-col>

                <v-col class="pa-0 d-flex justify-center flex-column">
                  <div>{{ episode.melody.name }}</div>
                  <div>{{ episode.melody.author }}</div>
                  <div>{{ episode.melody.type }}</div>
                </v-col>
              </v-row>

            </collapse-text>
          </div>

        </template>

      </v-container>

      <v-container 
        v-if="episode"
        :class="{ 'px-3': $vuetify.breakpoint.smAndDown }"
      >

        <loading v-if="loadingActivities" />

        <v-alert 
          v-if="!loadingActivities && activities && !activitiesFilteredSorted.length"
          type="info"
        >
          {{ $t('texts.noElementsMatching') }}
        </v-alert>

        <v-row 
          no-gutters
          class="activities-list"
        >
          <v-col
            v-for="activity in activitiesFilteredSorted"
            :key="activity.id"
            cols="6"
            sm="4"
            md="3"
            class="pr-3"
          >
            <activity-list-item
              :item="activity"
              show-date
              class="activities-list__item"
            />
          </v-col>
        </v-row>

      </v-container>

    </div>
    
    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ActivityListItem from '@/modules/activities/components/ActivityListItem/ActivityListItem.vue';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy.js';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import CollapseText from '@/components/util/CollapseText';
import EpisodeHeader from './EpisodeHeader';
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs';
import FilterMenu from './FilterMenu';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import SortMenu from './SortMenu';
import statusTap from '@/mixins/statusTap.js';
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    ActivityListItem,
    AppFab,
    CollapseText,
    EpisodeHeader,
    EpisodeTabs,
    FilterMenu,
    Loading,
    MenuBottom,
    PageTitle,
    SortMenu,
    UserItem
  },
  mixins: [statusTap],
  data() {
    return {
      activities: null,
      filters: { 
        attended: 'all',
        groups: {},
        groupsAll: true,
        privacy: 'all' 
      },
      loading: false,
      loadingActivities: false,
      roles: roles,
      sortBy: 'dateDesc'
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
      user: 'user/user',
    }),
    activitiesFiltered() {
      if (!this.activities) {
        return [];
      }
      return this.activities.filter(item => {

        // filter by privacy
        switch (this.filters.privacy) {
          case 'all':
            break;
          case 'myPublic':
            if (!item.isOwner || (item.privacy != ActivityPrivacy.VISIBLE)) {
              return false;
            }
            break;
          case 'myPrivate':
            if (!item.isOwner || (item.privacy != ActivityPrivacy.PRIVATE)) {
              return false;
            }
            break;
          case 'others':
            if (item.isOwner) {
              return false;
            }
            break;
        }

        // filter by group
        if (!this.filters.groupsAll) {
          if (!this.filters.groups[item.activityGroup.id]) {
            return false;
          }
        }

        // filter by attended
        switch (this.filters.attended) {
          case 'all':
            break;
          case 'attended':
            if (item.notAttended) {
              return false;
            }
            break;
          case 'notAttended':
            if (!item.notAttended) {
              return false;
            }
            break;
        }

        return true;
      });
    },
    activitiesFilteredSorted() {
      if (!this.activitiesFiltered) {
        return [];
      }
      let activities = [...this.activitiesFiltered];
      switch (this.sortBy) {
        case 'recent':
          activities.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'dateAsc':
          activities.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? -1 : 1);       
          break;       
        case 'dateDesc':
          activities.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? 1 : -1);      
          break; 
        case 'name':
          activities.sort((a, b) => (a.name || '').localeCompare(b.name || ''));        
          break;
      }
      return activities;
    },
    hasCost() {
      return !!this.cost && !!this.currency;
    },
    cost() {
      let cost = 0;
      if (this.activities) for (let i in this.activities) {
        cost += this.activities[i].cost;
      }
      return cost;
    },
    currency() {
      let currency;
      if (this.activities) for (let i in this.activities) {
        if (this.activities[i].currency && this.activities[i].cost) {
          if (currency && (currency != this.activities[i].currency)) {
            // problem: multiple currencies found in activities
            return false;
          }
          else {
            currency = this.activities[i].currency;
          }
        }
      }
      return currency;
    }
  },
  created() {
    // pre-select filters
    if (this.$route.query.group) {
      this.filters.groupsAll = false;
      this.filters.groups[this.$route.query.group] = true;
    }

    // load episode
    this.loading = true;
    this.loadEpisodeToCache(this.$route.params.episodeId)
    .finally(() => {
      this.loading = false;
    });

    // load activities
    this.loadingActivities = true;
    this.getActivities(this.$route.params.episodeId)
    .then(response => {
      this.activities = response.data.activities;
      this.addIsOwnerToActivities(this.activities);
      this.loadingActivities = false;
    });
  },
  methods: {
    ...mapActions({
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
      getActivities: 'activities/getActivities'
    }),
    addIsOwnerToActivities(activities) {
      for (let activity of activities) {
        activity.isOwner = activity.user.id == this.user.id;
      }
    }
  }
}
</script>

<style scoped>
.episode__header {
  height: 0;
  padding-bottom: 273px;
  position: relative;
  color: #fff;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.episode__image {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  object-fit: cover;
  top: 0;
}
.episode__actions {
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  padding: 10px 6px 0 6px;
  justify-content: space-between;
}
.episode-header__content {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.episode__title {
  padding: 0 16px;
  text-align: center;
}
.episode__details {
  text-indent: 0;
}
.activities-list {
  margin-right: -12px;
}
.activities-list__item {
  margin: 0 0 12px 0;
}
</style>
