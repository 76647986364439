import Vue from 'vue';
import i18n from '@/plugins/i18n';

const validationRules = {
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || i18n.t('messages.error.email');
  },
  prefix: value=>{
    const pattern = /^\d{1,4}$/
    return pattern.test(value) || i18n.t('messages.error.prefix');
  },
  tel: value=>{
    const pattern = /^\d{9}$/
    return pattern.test(value) || i18n.t('messages.error.tel');
  },
  time: value=>{
    const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
    return pattern.test(value) || i18n.t('messages.error.time');
  },
  password: value => {
    /*
      /^                  : Start
      .{12,}               : Minimum length
      $/                  : End
    */
    if (value) {
      const exp = /^.{12,}$/;
      return exp.test(value) || i18n.t('messages.error.password');
    }
    return true;
  },
  required: value => {
    return !!value || i18n.t('messages.error.empty');
  },
  requiredMultiple: value => {
    return (!!value && !!value.length) || i18n.t('messages.error.empty')
  },
}



const plugin = {
  install () {
    Vue.validationRules = validationRules
    Vue.prototype.$validationRules = validationRules
  }
}

Vue.use(plugin);
