<template>
  <span>
    <a @click.stop="dialog=true">{{ $t('dataPolicy.titles.title') }}</a>

    <v-dialog
      v-model="dialog"
      scrollable
      fullscreen
    >

      <div class="l-vertical">
          
        <app-header only-logo />

        <v-container class="py-0">
      
          <h1 class="subtitle-1 mb-1">
            <v-btn
              icon
              class="mt-n1 ml-n2"
              @click="dialog = false"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ $t('dataPolicy.titles.title') }}
          </h1>
        </v-container>

        <page-tabs v-model="tab">
          <v-tab @click="tab = 0">{{ $t('dataPolicy.titles.legal') }}</v-tab>
          <v-tab @click="tab = 1">{{ $t('dataPolicy.titles.cookies') }}</v-tab>
          <v-tab @click="tab = 2">{{ $t('dataPolicy.titles.policies') }}</v-tab>
          <v-tab @click="tab = 3">{{ $t('dataPolicy.titles.privacy') }}</v-tab>
        </page-tabs>

        <v-tabs-items 
          ref="statusTap"
          v-model="tab"
          class="l-vertical__grow l-vertical"
        >
        
          <v-tab-item class="l-vertical__grow l-vertical">
            <div class="l-vertical__scroll">
              <v-container v-html="$t('dataPolicyLegal')"></v-container>
            </div>
          </v-tab-item>

          <v-tab-item class="l-vertical__grow l-vertical">
            <div class="l-vertical__scroll">
              <v-container v-html="$t('dataPolicyCookies')"></v-container>
            </div>
          </v-tab-item>

          <v-tab-item class="l-vertical__grow l-vertical">
            <div class="l-vertical__scroll">
              <v-container v-html="$t('dataPolicyPolicies')"></v-container>
            </div>
          </v-tab-item>

          <v-tab-item class="l-vertical__grow l-vertical">
            <div class="l-vertical__scroll">
              <v-container v-html="$t('dataPolicyPrivacy')"></v-container>
            </div>
          </v-tab-item>

        </v-tabs-items>

      </div>

    </v-dialog>
  </span>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader/AppHeader';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';

export default {
  components: {
    AppHeader,
    PageTabs,
  },
  data () {
    return {
      dialog: false,
      tab: 0
    }
  }
}
</script>

