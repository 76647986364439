import UploadParameters from '@/modules/files/model/UploadParameters.js';

export default {
  async shareFile(item, tokenParameter) {
    const url = (item.type === 'video') 
      ? item.urlFilename + '?' + tokenParameter
      : item.urlImageThumbnails.large_ + '?' + tokenParameter;

    let options = {
      files: [url], // an array of filenames either locally or remotely
    };

    let onSuccess = function(result) {
      console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
      console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    };

    let onError = function(msg) {
      console.log("Sharing failed with message: " + msg);
    };

    // window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
    window.plugins.socialsharing.share(null, null, url, null);
  },
};