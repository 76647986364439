import axios from 'axios';

export default {
  namespaced: true,
  state: {
    cachedActivity: null,
    cachedEpisode: null,
  },
  getters: {
    cachedActivity(state) {
      return state.cachedActivity;
    },
    cachedEpisode(state) {
      return state.cachedEpisode;
    },
  },
  mutations: {
    setCachedActivity: (state, activity) => {
      state.cachedActivity = activity;
    },
    setCachedEpisode: (state, episode) => {
      state.cachedEpisode = episode;
    }
  },
  actions: {
    verifyHash(context, { episodeId, hash }) {
      return axios.get('/api/preview/' + episodeId + '/verify', { params: { hash }});
    },
    getEpisode(context, { episodeId, hash }) {
      return axios.get('/api/preview/' + episodeId, { params: { hash }});
    },
    loadEpisodeToCache({ state, dispatch }, { episodeId, hash }) {
      if (state.cachedEpisode && state.cachedEpisode.id != episodeId) {
        state.cachedEpisode = null;
      }
      // return promise
      return new Promise((resolve, reject) => {
        dispatch('getEpisode', { episodeId, hash })
        .then(response => {
          // save data to cache
          state.cachedEpisode = response.data.episode;
          resolve(response);
        })
        .catch(error => {
          state.cachedEpisode = null;
          reject(error);
        }) ;
      });
    },
    getActivities(context, { episodeId, hash }) {
      return axios.get('/api/preview/' + episodeId + '/activities', { params: { hash }});
    },
    getActivity(context, { episodeId, activityId, hash }) {
      return axios.get('/api/preview/' + episodeId + '/activity/' + activityId, { params: { hash }});
    },
    loadActivityToCache({ state, dispatch }, { episodeId, activityId, hash }) {
      if (state.cachedActivity && state.cachedActivity.id != activityId) {
        state.cachedActivity = null;
      }
      // return promise and save data to cache
      return new Promise((resolve, reject) => {
        dispatch('getActivity', { episodeId, activityId, hash })
        .then(response => {
          state.cachedActivity = response.data.activity;
          resolve(response);
        })
        .catch(error => {
          state.cachedActivity = null;
          reject(error);
        });
      });
    },
    getEpisodeFiles(context, { episodeId, hash }) {
      return axios.get('/api/preview/' + episodeId + '/files', { params: { hash }});
    },
    getActivityFiles(context, { episodeId, activityId, hash }) {
      return axios.get('/api/preview/' + episodeId + '/activity/' + activityId + '/files', { params: { hash }});
    },
  }
}
