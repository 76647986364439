<template>
  <div class="transfer-list-item mb-4">
    <router-link 
      class="transfer-list-item__header px-3"
      :style="'background-image: url(' + imageUrl + ')'"
      :to="{ name: 'transfer-sent-details', params: { transferId: item.id } }"
    >
      <div class="image-overlay"></div>

      <v-row class="mb-2">
        <v-col>
          <v-chip
            :color="item.status == TransferStatus.REJECTED ? 'error' : 'primary'"
            label
            small
          >
            {{ $t('transfers.model.status.' + (item.status == TransferStatus.REJECTED ? 'rejected' : 'pending')) }}
          </v-chip>
        </v-col>
        <v-col class="text-right py-1 px-2">
          <v-icon class="mt-2">mdi-arrow-right</v-icon>
        </v-col>
      </v-row>
      <div class="subtitle-1 height-lines-1">{{ item.episode.title }}</div>
    </router-link>

    <div class="d-flex pt-3 px-3">
      <div class="small flex-grow-1">
        {{ $t('transfers.texts.transferRequest') }}
        <template v-if="item.photosCount">
          {{ $tc('transfers.texts.transferRequestImages', item.photosCount, { number: item.photosCount }) }}
        </template>
        <template v-if="item.photosCount && item.moviesCount">{{ $t('and') }}</template>
        <template v-if="item.moviesCount">
          {{ $tc('transfers.texts.transferRequestVideos', item.moviesCount, { number: item.moviesCount }) }}
        </template>
      </div>
      <div class="flex-shrink-0">
        <strong>{{ $helperFunctions.formatFileSize(item.totalSizeInBytes, 1) }}</strong>
      </div>
    </div>

    <div class="d-flex pt-2 pb-3 px-3 align-end">
      <user-item
        :item="item.toUser"
        small-text
        :height="36"
        class="flex-grow-1 small pt-0"
      />
      <actions-menu 
        :transfer-id="item.id"
        @deleted="$emit('deleted')" 
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ActionsMenu from './ActionsMenu.vue';
import TransferStatus from '@/modules/transfers/model/TransferStatus.js';
import UserItem from '@/modules/user/components/UserItem';

export default {
  components: {
    ActionsMenu,
    UserItem
  },
  props: {
    item: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      TransferStatus: TransferStatus
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageUrl() {
      return (this.item && this.item.episode.urlImageThumbnails) 
        ? this.item.episode.urlImageThumbnails.small_ + '?bearer=' + this.apiToken 
        : 'img/placeholder/episode-list.png';
    },
  },
}
</script>

<style scoped>
.transfer-list-item {
  border-radius: 5px;
  background-color: var(--color-background--light);
  color: var(--color-text--dark);
  overflow: hidden;
}
.transfer-list-item__header {
  display: block;
  position: relative;
  background-color: var(--color-image-background);
  background-position: center;
  background-size: cover;
  height: 91px;
  color: white;
}
.transfer-list-item__header > * {
  position: relative;
  z-index: 1;
}
</style>