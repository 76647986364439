<template>
  <div
    v-if="show" 
    class="version overline small"
  >
    {{ build }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      build: process.env.VUE_APP_BUILD,
      show: false
    }
  },
  created() {
    const keySequence = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'b',
      'a'
    ];
    let userInput = new Array(keySequence.length);
    window.addEventListener('keydown', ({ key }) => {
      userInput = [...userInput.slice(1), key];
      if (keySequence.every((v, k) => v === userInput[k])) {
        this.show = true;
        this.$router.push({ name: 'ui-explorer' });
      }
    });
  }
}
</script>

<style>
.version {
  position: absolute;
  color: var(--v-primary-base);
}
</style>