<template>
  <div class="l-vertical__scroll l-vertical">
    <v-container class="container--narrow">

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >
        <form-fields-2
          :item="item"
        />
      </v-form>  

    </v-container>

    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn 
              outlined
              block
              @click="$router.back()"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :disabled="!valid"
              :loading="saving"
              @click="submit"
            >{{ $t('actions.add') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import FormFields2 from '@/modules/notes/components/FormFields2.vue';

export default {
  components: {
    FormFields2
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: true
    }
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate() || !this.item.episode || !this.item.activity) {
        return false;
      }

      this.$emit('submit');
    }
  }
}
</script>

