<template>
  <v-container
    class="py-0"
    :class="{ 'px-0' : $vuetify.breakpoint.smAndDown }"
  >
    <header
      class="episode-header"
      :style="'background-image:url(' + imageBackground + ')'"
    >
      <router-link  
        class="image-overlay"
        :to="to"
      />

      <div 
        v-if="episode"
        class="header__top"
      >
        <router-link  
          class="header__title white--text"
          :to="to"
        >
          <h2 class="subtitle-1">
            {{ episode ? episode.title : '' }}
          </h2>
        </router-link>

      </div><!-- /.header__top -->

      <div class="header__bottom">
        <div class="overline small">{{ episode && episode.date | moment($t('locale.formatDate')) }}</div>
      </div><!-- /.header__bottom -->

    </header>
  </v-container>
</template>

<script>
export default {
  props: {
    episode: {
      type: Object,
      default: null
    }
  },
  computed: {
    imageBackground() {
      return this.episode
        ? this.episode.urlImageThumbnails
          ? this.episode.urlImageThumbnails.large_ + '?hash=' + this.$route.query.hash 
          : 'img/placeholder/episode-details.png'
        : null;
    },
    to() {
      return { 
        name: 'shared-episode-files', 
        params: { episodeId: this.$route.params.episodeId },
        query: { hash: this.$route.query.hash } 
      }
    }
  }
}
</script>

<style scoped>
.episode-header {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 273px;
  background-size: cover;
  background-position: center;
  padding: 11px 18px 14px 18px;
}
.episode-header > * {
  position: relative;
  z-index: 1;
  flex: 0 1 auto;
}
.header__title {
  margin-top: 3px;
}
.header__top {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.header__bottom {
  display: flex;
  width: 100%;
  align-self: flex-end;
}


/* mobile */
@media (max-width: 1024px) {
  .episode-header {
    height: 154px;
    padding: 11px 16px 14px 16px;
  }
}
</style>
