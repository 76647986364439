import ContactDetailsPage from '@/modules/contacts/pages/ContactDetailsPage/ContactDetailsPage.vue';
import ContactsListPage from '@/modules/contacts/pages/ContactsListPage/ContactsListPage.vue';
import GroupAddPage from '@/modules/contacts/pages/GroupAddPage/GroupAddPage.vue';
import GroupDetailsPage from '@/modules/contacts/pages/GroupDetailsPage/GroupDetailsPage.vue';
import GroupEditPage from '@/modules/contacts/pages/GroupEditPage/GroupEditPage.vue';
import GroupsListPage from '@/modules/contacts/pages/GroupsListPage/GroupsListPage.vue';

export default [
  {
    path: '/contacts',
    name: 'contact-list',
    component: ContactsListPage,
    meta: { requiresAuth: true }
  },  
  {
    path: '/contacts/:id',
    name: 'contact-details',
    component: ContactDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/contact-groups',
    name: 'contact-groups-list',
    component: GroupsListPage,
    meta: { requiresAuth: true }
  },  
  {
    path: '/contact-groups/add',
    name: 'contact-group-add',
    component: GroupAddPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/contact-groups/:id',
    name: 'contact-group-details',
    component: GroupDetailsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/contact-groups/:id/edit',
    name: 'contact-group-edit',
    component: GroupEditPage,
    meta: { requiresAuth: true }
  },
];
