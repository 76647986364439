var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-img",class:_vm.inline ? 'm-img--inline' : '',style:({ 
    width: _vm.width ? _vm.width + 'px' : 'auto',
    height: _vm.height ? _vm.height + 'px' : 'auto',
    maxHeight: _vm.maxHeight ? _vm.maxHeight + 'px' : 'none',
    minHeight: _vm.minHeight + 'px',
    minWidth: _vm.minWidth + 'px',
    backgroundColor: _vm.backgroundColor,
    backgroundImage: _vm.cover ? 'url(' + _vm.src + ')' : '',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  })},[(_vm.aspectRatio && !_vm.height)?[(!_vm.cover)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.src),expression:"src"}],staticClass:"m-img__img--aspect-ratio",style:({ 
        maxHeight: _vm.maxHeight ? _vm.maxHeight + 'px' : '100%'
      }),attrs:{"alt":_vm.alt}}):_vm._e(),_c('div',{staticClass:"m-img__spacer",style:('padding-bottom: ' + 1 / _vm.aspectRatio * 100 + '%')})]:(!_vm.cover)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.src),expression:"src"}],staticClass:"m-img__img",style:({ 
      maxHeight: _vm.maxHeight ? _vm.maxHeight + 'px' : '100%'
    }),attrs:{"alt":_vm.alt}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }