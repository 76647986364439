<template>
  <div
    v-ripple="clickIsDisabled ? null : {}"
    v-hammer:press="onPress"
    class="file-thumbnail"
    :class="{
      'file-thumbnail--selected': selectionEnabled && file.selected,
      'file-thumbnail--disable-click': clickIsDisabled,
    }"
    @click="onClick"
  >
    <v-icon
      v-if="file.type == 'video'"
      class="file-thumbnail__video-icon white--text"
      :small="small"
    >mdi-play-outline</v-icon>
    <v-icon
      v-if="file.privacy == FilePrivacy.PRIVATE"
      class="file-thumbnail__private-icon white--text"
      :title="$t('files.model.privacy.private')"
      small
    >mdi-lock-outline</v-icon>
    <v-simple-checkbox
      v-if="!small && selectionEnabled && file.canEdit"
      v-model="file.selected" 
      color="primary"
      class="file-thumbnail__checkbox"
      @click.stop
    />

    <v-btn
      v-if="favoriteButton && !selectionEnabled"
      icon
      dark
      small
      class="primary--text file-thumbnail__favorite"
      :title="file.isFavorite ? $t('actions.removeFavorite') : $t('actions.addFavorite')"
      :loading="savingFavorite"
      @click.stop="onFavoriteClick"
    >
      <v-icon>{{ file.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
    </v-btn>

    <img
      v-lazy="imageUrl"
      alt="" 
      class="file-thumbnail__image"  
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { eventBus } from '@/eventBus.js';
import EpisodeRoles from '@/modules/episodes/model/EpisodeRoles.js';
import fileFunctions from '@/modules/files/functions/fileFunctions.js';
import FilePrivacy from '@/modules/files/model/FilePrivacy.js';

export default {
  props: {
    file: {
      type: Object,
      default: Object
    },
    selectionEnabled: {
      type: Boolean,
      default: false
    },
    disableClick: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    shared: {
      type: Boolean,
      default: false
    },
    favoriteButton: {
      type: Boolean,
      default: false
    },
    episodeRole: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      FilePrivacy: FilePrivacy,
      savingFavorite: false,
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken',
      user: 'user/user',
    }),
    tokenParameter() {
      return this.shared 
        ? 'hash=' + this.$route.query.hash 
        : 'bearer=' + this.apiToken;
    },
    imageUrl() {
      return this.file.urlImageThumbnails 
        ? this.file.urlImageThumbnails.medium_ + '?' + this.tokenParameter 
        : '';
    },
    clickIsDisabled() {
      return this.disableClick || (this.selectionEnabled && !this.file.canEdit);
    },
    canUseImage() {
      return this.file.canEdit || (((this.episodeRole === EpisodeRoles.CREATOR) || (this.episodeRole === EpisodeRoles.EDITOR)) && this.file.user.allowOthersUseMyPhotos);
    },
  },
  watch: {
    selectionEnabled() {
      this.file.selected = false;
    }
  },
  methods: {
    ...mapActions({
      setFavoriteFile: 'files/setFavoriteFile',
    }),
    toggleSelection() {
      this.file.selected = !this.file.selected;
    },
    onFavoriteClick($event) {
      console.log('onFavoriteClick');
      // $event.stopPropagation();
      this.savingFavorite = true;
      this.setFavoriteFile({
        activityId: this.file.activity.id,
        fileId: this.file.id
      })
      .then(response => {
        this.file.isFavorite = !this.file.isFavorite;
        this.$emit('modified');
      })
      .finally(() => {
        this.savingFavorite = false;
      })
    },
    onClick() {
      console.log('click');
      if (this.selectionEnabled) {
        if (this.file.canEdit) {
          this.toggleSelection();
        }
      }
      else {
        this.$emit('click');
      }
    },
    kuba() {
      console.log('kuba');
    },
    async onPress() {
      console.log('press');

      if (this.canUseImage) {
        fileFunctions.shareFile(this.file, this.tokenParameter);
        return;
      }
      // disable context menu if user is not image owner and is episode commentator
      if (this.episodeRole === EpisodeRoles.COMMENTATOR) {
        return;
      }
      // show "no permission" message if user is not image owner and is episode creator or editor
      if (!this.file.canEdit 
        && ((this.episodeRole === EpisodeRoles.CREATOR) || (this.episodeRole === EpisodeRoles.EDITOR))
        && !this.file.user.allowOthersUseMyPhotos
      ) {
        eventBus.$emit('no-permisison-dialog-open');
      }
    },
  }
}
</script>

<style scoped>
.file-thumbnail {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  background: var(--color-image-background);
}
.file-thumbnail:not(.file-thumbnail--disable-click) {
  cursor: pointer;
}
.file-thumbnail__image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.image-overlay {
  z-index: 1;
}
.file-thumbnail__private-icon {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
}
.file-thumbnail__video-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px !important;
}
.video-progress-text {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50%), calc(-50%));
  padding: 10px;
}
.video-progress-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50%), calc(-50% - 17px));
}
.file-thumbnail--selected::before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: var(--v-primary-base);
  opacity: 0.2;
}
.file-thumbnail__checkbox {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background-color: var(--color-background);
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-thumbnail__checkbox /deep/ .v-input--selection-controls__input {
  margin-right: 0;
}
.file-thumbnail__favorite {
  position: absolute;
  z-index: 1;
  top: 2px;
  right: 2px;
}


/* mobile */
@media (max-width: 1024px) {
  .file-thumbnail__checkbox {
    top: 8px;
    right: 8px;
  }
}
</style>
