<template>
  <div>
    <v-list-item @click="onButtonClick">
      <v-icon class="mr-3 primary--text">mdi-delete-restore</v-icon> 
      {{ $t('trash.actions.restore') }}
    </v-list-item>

    <v-dialog
      v-model="open"
      width="450"
    >
      <v-container class="py-3 px-6">
        <div class="subtitle-1 text-center mt-2 mb-6">{{ $t('trash.dialogs.restore.text') }}</div>
        <p class="text-center mb-8">{{ $t('trash.dialogs.restore.text2') }}</p>
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="primary"
              :loading="saving"
              block
              small
              @click="submit"
            >{{ $t('trash.actions.restore') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    selectedItems: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      open: false,
      saving: false,
    };
  },
  computed: {
    selectedEpisodesIds() {
      return this.selectedItems.filter(item => item.itemType == 'episode').map(item => item.id);
    },
    selectedActivitiesIds() {
      return this.selectedItems.filter(item => item.itemType == 'activity').map(item => item.id);
    },
    selectedFilesIds() {
      return this.selectedItems.filter(item => item.itemType == 'file').map(item => item.id);
    },
    selectedNotesIds() {
      return this.selectedItems.filter(item => item.itemType == 'note').map(item => item.id);
    },
    selectedMelodiesIds() {
      return this.selectedItems.filter(item => item.itemType == 'melody').map(item => item.id);
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      restoreActivities: 'trash/restoreActivities',
      restoreEpisodes: 'trash/restoreEpisodes',
      restoreFiles: 'trash/restoreFiles',
      restoreNotes: 'trash/restoreNotes',
      restoreMelodies: 'trash/restoreMelodies',
    }),
    async submit() {
      this.saving = true;

      try {
        // restore episodes
        if (this.selectedEpisodesIds.length) {
          await this.restoreEpisodes(this.selectedEpisodesIds);
        }

        // restore activities
        if (this.selectedActivitiesIds.length) {
          await this.restoreActivities(this.selectedActivitiesIds);
        }

        // restore files
        if (this.selectedFilesIds.length) {
          await this.restoreFiles(this.selectedFilesIds);
        }

        // restore notes
        if (this.selectedNotesIds.length) {
          await this.restoreNotes(this.selectedNotesIds);
        }

        // restore melodies
        if (this.selectedMelodiesIds.length) {
          await this.restoreMelodies(this.selectedMelodiesIds);
        }

        this.addFlash({
          text: this.$t("trash.messages.restored"),
          type: 'success'
        });
        this.$emit('success');

      } 
      catch(error) {
        this.addFlash({
          text: this.selectedItems.length > 1
            ? this.$t('trash.messages.error.notAllItemsRecovered')
            : this.$helperFunctions.errorToString(error),
          type: 'error'
        });
        if (this.selectedItems.length > 1) {
          this.$emit('success');
        }
      }

      this.saving = false;
      this.open = false;
    },
    onButtonClick() {
      setTimeout(() => {
        this.open = true;
      }, 0);
    }
  }
};
</script>
