<template>
  <v-autocomplete
    :value="value"
    :label="$t('form.label.currency') + (required ? ' *' : '')"
    :items="currencyOptions"
    item-value="iso"
    item-text="name"
    :rules="required ? [$validationRules.required] : []"
    v-bind="$attrs"
    @change="$emit('input', $event)"
  />
</template>

<script>
import Currency from '@/model/Currency.js';

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currencyOptions: Currency
    };
  }
};
</script>
