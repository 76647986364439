import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getEpisodes() {
      return axios.get('/api/episodes-deleted');
    },
    getActivities() {
      return axios.get('/api/activities-deleted');
    },  
    getFiles() {
      return axios.get('/api/files-deleted');
    },  
    getNotes() {
      return axios.get('/api/notes-deleted');
    },
    getMelodies() {
      return axios.get('/api/melodies/deleted');
    },
    restoreEpisodes(context, ids) {
      return axios.post('/api/episodes-deleted', { ids: ids });
    }, 
    restoreActivities(context, ids) {
      return axios.post('/api/activities-deleted', { ids: ids });
    }, 
    restoreFiles(context, ids) {
      return axios.post('/api/files-deleted', { ids: ids });
    }, 
    restoreNotes(context, ids) {
      return axios.post('/api/notes-deleted', { ids: ids });
    }, 
    restoreMelodies(context, ids) {
      return axios.post('/api/melodies/deleted/recover', { ids: ids });
    }, 
    deleteEpisodes(context, ids) {
      return axios.delete('/api/episodes-deleted', { data: { ids: ids }});
    }, 
    deleteActivities(context, ids) {
      return axios.delete('/api/activities-deleted', { data: { ids: ids }});
    }, 
    deleteFiles(context, ids) {
      return axios.delete('/api/files-deleted', { data: { ids: ids }});
    },
    deleteNotes(context, ids) {
      return axios.delete('/api/notes-deleted', { data: { ids: ids }});
    },
    deleteMelodies(context, ids) {
      return axios.post('/api/melodies/deleted/remove', { ids: ids });
    },
  }
};
