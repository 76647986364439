<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'melodies-list' }">
      {{ $t('melodies.titles.melodies') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('melodies.titles.editMelody') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">

      <v-container class="container--narrow">

        <loading v-if="loading" />

        <v-form
          v-if="item"
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <form-fields
            :item="item"
          />

          <div class="mt-2 mb-2">
            <v-icon class="mr-1">mdi-music-note</v-icon>
            {{ item.originalAudioFilename }}
          </div>

        </v-form>

      </v-container>

      <div 
        v-if="item"
        class="l-vertical__sticky-footer--mobile"
      >
        <v-container class="container--narrow">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'melodies-list' }"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from '../components/FormFields.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    Loading,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: null,
      loading: false,
      saving: false,
      valid: false
    }
  },
  created() {
    this.loading = true;

    this.getMelody(this.$route.params.id)
    .then(response => {
      this.item = response.data.melody;
      this.loading = false;
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getMelody: 'melodies/getMelody',
      updateMelody: 'melodies/updateMelody',
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        name: this.item.name,
        type: this.item.type,
        author: this.item.author
      }

      this.updateMelody({
        id: this.item.id,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.$router.push({ name: 'melodies-list' });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
