<template>
  <router-link
    v-if="item"
    v-ripple
    class="activity"
    :style="'background-image:url(' + imageBackground + ')'"
    :to="to ? to : { name: 'activity-details', params: { 
      episodeId: item.episode ? item.episode.id : $route.params.episodeId, 
      activityId: item.id
    } }"
    @click.native="setCachedActivity(item)"
  >
    <div class="image-overlay"></div>

    <div class="activity__content">
      <div class="activity__top-icons">

        <v-icon
          v-if="item.privacy == ActivityPrivacy.PRIVATE"
          :title="$t('activities.model.privacy.private')"
          class="activity__top-icon"
        >mdi-lock-outline</v-icon>   
        <span 
          v-else
          class="activity__icon-placeholder"
        ></span>

        <span 
          v-if="!showActionsMenu"
          class="activity__icon-placeholder"
        ></span>

        <v-btn
          v-if="favoriteButton"
          icon
          dark
          class="primary--text mt-n2"
          :title="item.isFavorite ? $t('actions.removeFavorite') : $t('actions.addFavorite')"
          :loading="savingFavorite"
          @click.prevent="onFavoriteClick"
        >
          <v-icon>{{ item.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
        </v-btn>

        <v-icon 
          v-else
          class="activity__top-icon"
        >{{ item.activityGroup.icon }}</v-icon>

        <actions-menu 
          v-if="showActionsMenu"
          class="activity__actions-menu"
          :activity="item" 
          @modified="$emit('modified')"
        />

      </div>
      <div class="activity__title body-2 max-lines-5">{{ item.name }}</div>
      <div
        v-if="showDate && item.date"
        class="overline small"
      >{{ $moment($helperFunctions.fixApiDate(item.date)).format($t('locale.formatDate')) }}</div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy';

export default {
  components: {
    ActionsMenu
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    showDate: {
      type: Boolean,
      default: false
    },
    showActionsMenu: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
      default: null
    },
    shared: {
      type: Boolean,
      default: false
    },
    favoriteButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ActivityPrivacy: ActivityPrivacy,
      savingFavorite: false,
    }
  },
   computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageBackground() {
      let auth = this.shared 
        ? '?hash=' + this.$route.query.hash 
        : '?bearer=' + this.apiToken;
        
      return this.item && this.item.urlImageThumbnails
        ? this.item.urlImageThumbnails.medium_ + auth
        : 'img/placeholder/activity-list.png';
    }
  },
  methods: {
    ...mapMutations({
      setCachedActivity: 'activities/setCachedActivity'
    }),
    ...mapActions({
      setFavoriteActivity: 'activities/setFavoriteActivity',
    }),
    onFavoriteClick() {
      this.savingFavorite = true;
      this.setFavoriteActivity(this.item.id)
      .then(response => {
        this.item.isFavorite = !this.item.isFavorite;
        this.$emit('modified');
      })
      .finally(() => {
        this.savingFavorite = false;
      })
    },
  }
}
</script>

<style scoped>
.activity {
  --gutter: 10px;
  display: block;
  position: relative;
  color: inherit;
  border-radius: 6px;
  color: white !important;
  padding-bottom: calc(100% / var(--activity-aspect-ratio));
  overflow: hidden;
  background-color: rgba(43, 46, 54, 0.3);
  background-size: cover;
  background-position: center;
}
.activity:hover,
.activity:focus,
.activity:active {
  color: var(--v-primary-darken1);
  opacity: 0.85;
}
.activity__content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: var(--gutter);
  display: flex;
  flex-direction: column;
}
.activity__title {
  width: 100%;
  margin-top: auto;
  margin-bottom: 10px;
}
.activity__top-icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.activity__top-icon {
  font-size: 20px;
}
.activity__icon-placeholder {
  width: 16px;
  height: 16px;
}
.activity__actions-menu {
  margin: -4px -4px 0 -8px;
}

@media (max-width: 370px) {
  .activity__top-icon {
    font-size: 16px;
  }
}
</style>