<template>
  <div class="text-center">
    <div class="mb-2">
      <small>{{ $t('activities.form.captureLocation') }}</small>
    </div>
    <v-btn
      icon
      color="primary"
      large
      :loading="loading"
      @click="onClick"
    >
      <v-icon large>mdi-map-marker-outline</v-icon>
    </v-btn>

    <reverse-geocode-service
      ref="reverseGeocodeService"
      @gotCoordinates="onGotCoordinates"
      @reverseGeocoded="onReverseGeocoded" 
      @error="onError"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';  
import ReverseGeocodeService from '@/modules/activities/components/ReverseGeocodeService.vue';

export default {
  components: {
    ReverseGeocodeService
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    onClick() {
      this.$emit('click');
      this.loading = true;
      this.$refs.reverseGeocodeService.start();
    },
    onGotCoordinates(result) {
      this.$emit('gotCoordinates', result);
    },
    onReverseGeocoded(result) {
      this.loading = false;
      this.$emit('reverseGeocoded', result);
    },
    onError(error) {
      this.loading = false;
      this.addFlash({
        text: this.$helperFunctions.errorToString(error),
        type: 'error'
      });
    }
  }
}
</script>