<template>
  <span></span>
</template>

<script>
/**
 * REVERSE GEOCODE
 * get user coordinates & convert to address
 */ 
import { mapActions, mapGetters, mapMutations } from 'vuex';
import GoogleMapsApiLoader from "google-maps-api-loader";

export default { 
  data() {
    return {
      latitude: null,
      longitude: null,
      started: false,
    }
  },
  created() {
    console.log('start reverseGeocodeService');
    // get user coordinates
    navigator.geolocation.getCurrentPosition((position) => {
      console.log('navigator gotCoordinates', position.coords.latitude, position.coords.longitude);
      this.$emit('gotCoordinates', position.coords);
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      if (this.started) {
        this.reverseGeocode();
      }
    },
    error => {
      console.log('onGetPositionError', error);
    }, { timeout: 15000, enableHighAccuracy: true, maximumAge: Infinity });
  },
  methods: {
    ...mapActions({
      incrementGoogleApiCounter: 'user/incrementGoogleApiCounter'
    }),
    start() {
      this.started = true;
      this.reverseGeocode();
    },
    async reverseGeocode() {
      if (!this.latitude || !this.longitude) {
        return;
      }
      console.log('reverseGeocode');

      // load Google Maps API
      const google = await GoogleMapsApiLoader({ 
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        language: this.$i18n.locale
      });

      // Reverse Geocoding: coordinates >> address
      const geocoder = new google.maps.Geocoder();
      const params = {
        location: {
          lat: parseFloat(this.latitude),
          lng: parseFloat(this.longitude)
        },
        language: this.$i18n.locale
      };
      console.log('geocoder.geocode');
      geocoder.geocode(params)
      .then(response => {
        if (response.results[0] && response.results[0].address_components) {
          let result = {
            latitude: this.latitude,
            longitude: this.longitude
          };
          const address_components = response.results[0].address_components;
          result.country = this.getCountry(address_components);
          result.province = this.getProvince(address_components);
          result.city = this.getCity(address_components);
          result.address = this.getAddress(address_components);
          this.$emit('reverseGeocoded', result);
        } 
        else {
          this.$emit('error', "Geocoder: No results found");
        }
      })
      .catch(error => {
        this.$emit('error', error);
      });

      this.incrementGoogleApiCounter();
    },
    getCountry(addrComponents) {
      for (let i in addrComponents) {
        if (addrComponents[i].types[0] == "country") {
          return addrComponents[i].long_name;
        }
      }
    },
    getProvince(addrComponents) {
      for (let i in addrComponents) {
        if (addrComponents[i].types[0] == "administrative_area_level_1") {
          return addrComponents[i].short_name;
        }
      }
    },
    getCity(addrComponents) {
      for (let i in addrComponents) {
        if (addrComponents[i].types[0] == "locality") {
          return addrComponents[i].short_name;
        }
      }
    },
    getAddress(addrComponents) {
      let street = '';
      let streetNumber = '';
      for (let i in addrComponents) {
        if (addrComponents[i].types[0] == "route") {
          street = addrComponents[i].short_name;
        }
        else if (addrComponents[i].types[0] == "street_number") {
          streetNumber = addrComponents[i].short_name;
        }
      }
      return street + (street && streetNumber ? ' ' : '') + streetNumber;
    }
  }
}
</script>