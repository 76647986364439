<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ $t('user.titles.statistics') }}
    </page-title>

    <statistics-tabs />
    

    <loading v-if="loading" />

    <div 
      v-else
      class="l-vertical__scroll"
    > 
      <v-container class="container--narrow">

        <v-row class="small mb-1">
          <v-col 
            cols="3"
            offset="9"
            class="text-right pl-0"
          >Importe</v-col>
        </v-row>

        <v-row class="small mb-3">
          <v-col cols="6">Anterior a 13-01-2022</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots(before2022) }}</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots($helperFunctions.round(before2022 * GoogleApiCost, 2)) }} €</v-col>
        </v-row>

        <v-row class="subtitle-1 mb-1">
          <v-col cols="6">Total</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots(summary.total) }}</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots($helperFunctions.round(summary.total * GoogleApiCost, 2)) }} €</v-col>
        </v-row>

        <v-row class="small mb-3">
          <v-col cols="6">Promedio por usuario</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ totalUsersCount ? $helperFunctions.numberWithDots($helperFunctions.round(summary.total / totalUsersCount, 1)) : '' }}</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ totalUsersCount ? $helperFunctions.numberWithDots($helperFunctions.round(summary.total / totalUsersCount * GoogleApiCost, 2)) : '' }} €</v-col>
        </v-row>

        <v-row>
          <v-col cols="6">- Año en curso</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots(summary['current-year']) }}</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots($helperFunctions.round(summary['current-year'] * GoogleApiCost, 2)) }} €</v-col>
        </v-row>
                
        <v-row class="small">
          <v-col cols="6 pl-6">Valores esperados</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots(expectedCalls) }}</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots($helperFunctions.round(expectedCalls * GoogleApiCost, 2)) }} €</v-col>
        </v-row>
                
        <v-row class="small mb-2">
          <v-col cols="5 pl-6">Desviación</v-col>
          <v-col 
            cols="1"
            :class="(deviation > 0) ? 'error--text' : 'success--text'"
          >{{ deviationPercents }}%</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
            :class="(deviation > 0) ? 'error--text' : 'success--text'"
          >{{ $helperFunctions.numberWithDots(deviation) }}</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
            :class="(deviation > 0) ? 'error--text' : 'success--text'"
          >{{ $helperFunctions.numberWithDots($helperFunctions.round(deviation * GoogleApiCost, 2)) }} €</v-col>
        </v-row>

        <v-row>
          <v-col cols="6">- Mes en curso</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots(summary['current-month']) }}</v-col>
          <v-col 
            cols="3"
            class="text-right pl-0"
          >{{ $helperFunctions.numberWithDots($helperFunctions.round(summary['current-month'] * GoogleApiCost, 2)) }} €</v-col>
        </v-row>

        
        <div class="subtitle-1 mb-2 mt-4">Evolución</div>

        <v-row class="mb-5">
          <v-col cols="3">
            <year-select
              v-model="selectedYear"
            />
          </v-col>
        </v-row>

        <months-graph
          :value="selectedMonth"
          :months-data="monthsData"
          :loading="loadingMonths"
          class="mb-5"
          @input="selectedMonth = $event"
        />

        <days-graph
          :value="selectedDay"
          :days-data="daysData"
          :loading="loadingDays"
          class="mb-2"
          @input="selectedDay = $event"
        />

        <div 
          v-if="daysData && selectedDay"
          class="text-center"
        >
          <small>En el día:</small> &nbsp;
          {{ $helperFunctions.numberWithDots(daysData[selectedDay]) }}
          -
          {{ $helperFunctions.numberWithDots(daysData[selectedDay] * GoogleApiCost) }} €
        </div>

      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GoogleApiCost from '@/modules/adminStatistics/model/GoogleApiCost';
import DaysGraph from '@/modules/adminStatistics/components/DaysGraph/DaysGraph.vue';
import Loading from '@/components/util/Loading';
import MonthsGraph from '@/modules/adminStatistics/components/MonthsGraph/MonthsGraph.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import StatisticsTabs from '@/modules/adminStatistics/components/StatisticsTabs.vue';
import YearSelect from '@/modules/adminStatistics/components/YearSelect.vue';

export default {
  components: {
    Loading,
    DaysGraph,
    MonthsGraph,
    PageTitle,
    StatisticsTabs,
    YearSelect
  },
  data () {
    return {
      before2022: 1021,
      loading: false,
      loadingMonths: false,
      loadingDays: false,
      daysData: null,
      GoogleApiCost: GoogleApiCost,
      monthsData: null,
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      summary: {},
      usersSummary: {}
    }
  },
  computed: {
    ...mapGetters({
      totalUsersCount: 'adminStatistics/totalUsersCount'
    }),
    expectedCalls() {
      return this.$moment().dayOfYear() * this.usersSummary['total'];
    },
    deviation() {
      return this.summary['current-year'] - this.expectedCalls;
    },
    deviationPercents() {
      return this.expectedCalls ? this.$helperFunctions.round(this.deviation / this.expectedCalls * 100, 1) : 0;
    }
  },
  watch: {
    selectedYear() {
      this.loadMonthsData();
      // select current month
      if (this.selectedYear == Number(this.$moment().format('YYYY'))) {
        this.selectedMonth = Number(this.$moment().format('MM'));
      }
    },
    selectedMonth() {
      this.loadDaysData();
    },
  },
  created() {
    this.loading = true;

    Promise.all([
      this.getGoogleApiCallsSummary(),
      this.getUsersCountSummary()
    ])
    .then(response => {
      // Google API summary
      this.summary = response[0].data.count;
      this.summary.total = Number(this.summary.total) + this.before2022;

      // Users summary
      this.usersSummary = response[1].data.count;

      this.loading = false;
    });

    // select current year
    this.selectedYear = Number(this.$moment().format('YYYY'));
  },
  methods: {
    ...mapActions({
      getGoogleApiCallsSummary: 'adminStatistics/getGoogleApiCallsSummary',
      getGoogleApiCallsByMonth: 'adminStatistics/getGoogleApiCallsByMonth',
      getGoogleApiCallsByDay: 'adminStatistics/getGoogleApiCallsByDay',
      getUsersCountSummary: 'adminStatistics/getUsersCountSummary',
    }),
    loadMonthsData() {
      if (!this.selectedYear) {
        return;
      }
      this.loadingMonths = true;
      this.getGoogleApiCallsByMonth(this.selectedYear)
      .then(response => {
        this.monthsData = response.data.count;
        this.loadingMonths = false;
      });
      this.selectedMonth = null;
      this.daysData = null;
    },
    loadDaysData() {
      if (!this.selectedYear || !this.selectedMonth) {
        return;
      }
      this.loadingDays = true;
      this.getGoogleApiCallsByDay({
        year: this.selectedYear,
        month: this.selectedMonth
      })
      .then(response => {
        this.daysData = response.data.count;
        this.loadingDays = false;
      });
      this.selectedDay = null;
    }
  }
}
</script>
