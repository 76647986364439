<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('timeline.titles.your-history') }} 
      <template slot="buttons">
        <v-btn 
          icon
          :title="expanded ? $t('actions.collapse') : $t('actions.expand')"
          @click="expanded = !expanded"
        >
          <v-icon>{{ expanded ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal' }}</v-icon>
        </v-btn>
      </template>
    </page-title>

    <page-tabs v-model="tab">
      
      <v-tab @click="tab = 0">{{ $t('timeline.titles.myEpisodesTab') }}</v-tab> 
      <v-tab @click="tab = 1">{{ $t('timeline.titles.wishListTab') }}</v-tab> 

    </page-tabs>

    <div 
      ref="statusTap"
      class="l-vertical__scroll text-center"
    >
      
      <v-container>

        <loading v-if="loading || loadingHistoryActivities || loadingHistoryActivitiesWish" />

        <div 
          v-else
          class="timeline"
        >

          <template
            v-for="year in years"
          >
            <div
              v-if="yearsWithActivities.includes(year)"
              :key="year"
              class="timeline__item timeline__item--active"
            >
              <v-btn 
                small
                class="primary"
                :to="{ 
                  name: 'search-activities-results', 
                  query: { 
                    year: year,
                    wishlist: tab ? 1 : undefined
                  },
                }"
              >
                {{ year }}
              </v-btn>
            </div>

            <transition
              v-else
              :key="year"
              name="scale" 
            >
              <div 
                v-show="(year >= birth) && (year <= yearNow) && expanded"
                class="timeline__item timeline__item--disabled"
              >
                <v-btn 
                  small
                  disabled
                >
                  {{ year }}
                </v-btn>
              </div>
            </transition>

            <div 
              v-if="birth && (year == birth)"
              :key="year + 'birth'"
              class="timeline__item timeline__item--birth"
            >
              <strong class="small">{{ $t('timeline.titles.birth') }}</strong>
            </div>

          </template>

        </div>

      </v-container>

    </div>

    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from '@/components/layout/PageTitle.vue'
import statusTap from '@/mixins/statusTap.js';

export default {
  name: 'TimelinePage',
  components: {
    Loading,
    MenuBottom,
    PageTabs,
    PageTitle,
  },
  mixins: [statusTap],
  data() {
    return {
      expanded: true,
      historyActivities: null,
      historyActivitiesWish: null,
      loading: false,
      loadingHistoryActivities: false,
      loadingHistoryActivitiesWish: false,
      tab: 0,
      userData: null,
      yearNow: this.$moment().format('YYYY'),
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    birth() {
      return this.userData && this.userData.birth ? this.userData.birth.substr(0,4) : null;
    },
    yearMax() {
      let max = Math.max(...this.yearsWithActivities);
      return max > this.yearNow   
          ? max 
          : this.yearNow
    },
    yearMin() {
      let min = Math.min(...this.yearsWithActivities);
      return this.birth 
        ? min < this.birth 
          ? min 
          : this.birth
        : min;
    },
    years() {
      let years = [];
      for (let i = this.yearMax; i >= this.yearMin; i--) {
        years.push(i.toString());
      }
      return years;
    },
    yearsWithActivities() {
      if (this.tab) {
        return this.historyActivitiesWish === null ? [] : this.historyActivitiesWish;
      } else {
        return this.historyActivities === null ? [] : this.historyActivities;
      }
    }
  },
  watch: {
    tab() {
      if (this.tab && (this.historyActivitiesWish === null)) {
        this.loadHistoryActivitiesWish();
      }
    }
  },
  created() {
    // load user data
    this.loading = true;
    this.getUser(this.user.id).then(
      response => {
        this.userData = response.data.user;

        // fix date format from API
        this.userData.date = this.$helperFunctions.fixApiDate(this.userData.date);

        this.loading = false;
      }
    );

    this.loadHistoryActivities();
  },
  methods: {
    ...mapActions({
      getHistoryActivities: 'timeline/getHistoryActivities',
      getHistoryActivitiesWish: 'timeline/getHistoryActivitiesWish',
      getUser: 'user/getUser',
    }),
    loadHistoryActivities() {
      this.loadingHistoryActivities = true;
      this.getHistoryActivities()
      .then(response => {
        this.historyActivities = response.data.years;
        this.loadingHistoryActivities = false;
      })
    },
    loadHistoryActivitiesWish() {
      this.loadingHistoryActivitiesWish = true;
      this.getHistoryActivitiesWish()
      .then(response => {
        this.historyActivitiesWish = response.data.years;
        this.loadingHistoryActivitiesWish = false;
      })
    },
  }
}
</script>

<style scoped>
.timeline {
  margin: 0 auto;
  position: relative;
}
.timeline::before {
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  width: 3px;
  height: calc(100% - 20px);
  margin-top: 15px;
  background-color: #A6A7AB;
}
.timeline__item {
  width: 50%;
  left: 50%;
  margin-bottom: 10px;
  position: relative;
  text-align: left;
  padding: 0 0 0 30px;
}
.timeline__item::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  top: 6px;
  transform: translateX(-50%);
  background-color: var(--color-background--darker);
  border-radius: 50%;
  border: 3px solid white;
}
.timeline__item--active::before {
  background-color: var(--v-primary-base);
}
.timeline__item--disabled {
  left: 0;
  text-align: right;
  padding: 0 25px 0 0;
}
.timeline__item--disabled .v-btn{
  color: white !important;
}
.timeline__item--disabled::before {
  left: 100%;
}
.timeline__item--birth {
  padding-top: 5px;
}
.timeline__item--birth::before {
  background-color: var(--color-grey);
}


.scale-enter-active {
  animation: scale-animation .25s;
}
.scale-leave-active {
  animation: scale-animation .25s reverse;
}
@keyframes scale-animation {
  0% {
    max-height: 0;
    opacity: 0;
  }  
  75% {
    opacity: 0;
  }
  100% {
    max-height: 30px;
    opacity: 1;
  }
}
</style>
