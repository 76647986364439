import axios from 'axios';

export default {
  namespaced: true,
  state: {
    selectedContacts: null,
  },
  getters: {
    getSelectedContacts: state => {
      return state.selectedContacts;
    },
  },
  mutations: {
    setSelectedContacts: (state, files) => {
      state.selectedContacts = files;
    },
  },
  actions: {
    changeAlias(context, { userId, alias }) {
      return axios.post('/api/contact/user/' + userId + '/set-alias', { alias: alias });
    },
    getContacts(context) {
      return axios.get('/api/contacts');
    },    
    getContact(context, userId) {
      return axios.get('/api/contact/' + userId + '/episodes');
    },
    getGroups(context) {
      return axios.get('/api/contact-group');
    }, 
    getGroup(context, id) {
      return axios.get('/api/contact-group/' + id);
    }, 
    createGroup(context, data) {
      let formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
      
      return axios.post('/api/contact-group', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    }, 
    updateGroup(context, { groupId, data }) {
      let formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
      
      return axios.post('/api/contact-group/' + groupId, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    },
    addContactsToGroup(context, { groupId, contacts }) {
      return axios.post('/api/contact-group/' + groupId + '/contacts', { contacts: contacts });
    },
    deleteGroups(context, groupsIds) {
      return axios.post('/api/contact-group/delete', { groups: groupsIds });
    },
    removeContactsFromGroup(context, { groupId, contactsIds }) {
      return axios.post('/api/contact-group/' + groupId + '/contacts/delete', { contacts: contactsIds });
    }
  }
};
