import axios from 'axios';

export default {
  namespaced: true,
  state: {
    cancelToken: null
  },
  actions: {
    getCollaborators(context, episodeId) {
      return axios.get('/api/episode/' + episodeId + '/collaborators');
    },
    getCollaborator(context, { episodeId, userId }) {
      return axios.get('/api/episode/' + episodeId + '/collaborator/' + userId);
    },
    createCollaborator(context, { episodeId, data }) {
      return axios.post('/api/episode/' + episodeId + '/collaborator', data);
    },
    updateCollaborator(context, { episodeId, data }) {
      return axios.post('/api/episode/' + episodeId + '/collaborator', data);
    },
    deleteCollaborator(context, { episodeId, userId }) {
      return axios.delete('/api/episode/' + episodeId + '/collaborator/' + userId);
    },
    deleteCollaborators(context, { episodeId, userIds }) {
      return axios.post('/api/episode/' + episodeId + '/collaborator/delete', { users: userIds });
    },
    exitEpisode(context, { episodeId, data }) {
      return axios.post('/api/episode/' + episodeId + '/exit', data);
    },
    changeCreator(context, { episodeId, collaboratorId }) {
      return axios.post('/api/episode/' + episodeId + '/creator/' + collaboratorId);
    },
    userHasContent({ state }, episodeId) {
      return axios.get('/api/episode/' + episodeId + '/user-has-content');
    },
    changeRole(context, { episodeId, userIds, role }) {
      return axios.post('/api/episode/' + episodeId + '/collaborator/add-multi', { 
        users: userIds,
        role: role
       });
    },
    addGroup(context, { episodeId, groupId, role }) {
      return axios.post('/api/episode/' + episodeId + '/collaborator/by-group', { 
        group: groupId,
        role: role
       });
    },
  }
};
