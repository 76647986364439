<template>
  <div>
    
    <v-select
      v-model="item.privacy"
      :label="$t('form.label.privacy')"
      :items="privacyOptions"
    />

    <v-row>
      <v-col cols="8">
        <date-input
          v-model="item.date"
          :label="$t('form.label.date')"
          :rules="[$validationRules.required]"
        />
      </v-col>

      <v-col cols="4">
        <time-input
          v-model="item.time"
          :label="$t('form.label.hour')"
          required
        />
      </v-col>
    </v-row>

    <v-textarea
      v-model="item.description"
      :label="$t('files.form.label.description')"
      auto-grow
      maxlength="128"
      rows="2"
      class="mt-0"
      @keyup.stop
    />

  </div>
</template>

<script>
import FilePrivacy from '../model/FilePrivacy';
import DateInput from '@/components/form/DateInput';
import TimeInput from '@/components/form/TimeInput';

export default {
  components: {
    DateInput,
    TimeInput
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      privacyOptions: [
        { value: FilePrivacy.PRIVATE, text: this.$t('files.model.privacy.private') },
        { value: FilePrivacy.VISIBLE, text: this.$t('files.model.privacy.visible') }
      ]
    }
  }
}
</script>