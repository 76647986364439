<template>
  <page-tabs v-bind="$attrs">

    <v-tab 
      :to="{ 
        name: 'shared-episode', 
        params: { episodeId: $route.params.episodeId },
        query: { hash: $route.query.hash } 
      }"
      exact
    >{{ $t('actions.back') }}</v-tab>  

    <v-tab 
      :to="{ 
        name: 'shared-activity-details', 
        params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId },
        query: { hash: $route.query.hash } 
      }"
      exact
    >{{ $t('activities.titles.nav.description') }}</v-tab>    
    
    <v-tab 
      :to="{ 
        name: 'shared-activity-files', 
        params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId },
        query: { hash: $route.query.hash } 
      }"
    >{{ $t('activities.titles.nav.gallery') }}</v-tab>

  </page-tabs>
</template>

<script>
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';

export default {
  components: {
    PageTabs
  }
}
</script>
