import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';
import en from 'vuetify/es5/locale/en';
import i18n from '@/plugins/i18n';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: '#FFB300',
        secondary: '#272A2F',
        accent: '#ffaa1e',
        info: '#54575D',
        success: '#01b446',
        error: '#FF5252',
        warning: '#FC8C00'
      }
    }
  },
  /* Responsive Breakpoints

    Extra small < 640px               mobile
    Small	      640px > < 1024px      tablet
    Medium	    1024px > < 1400px     laptop
    Large     	1400px > < 1904px     desktop
    Extra       > 1904px              TV

  */
  breakpoint: {
    thresholds: {
      xs: 640,  
      sm: 1024, 
      md: 1400, 
      lg: 1904  
    }
  },
  lang: {
    locales: { es, en },
    current: i18n.locale
  }
});
