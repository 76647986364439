<template>
  <div class="tab-details">

    <v-alert 
      v-if="expenses && !expenses.length"
      type="info"
    >
      {{ $t('texts.noElements') }}
    </v-alert>

    <expense-list-item
      v-for="item in expensesSorted"
      :key="item.id"
      :item="item"
      :show-menu="canEdit"
      @delete="deleteId = item.id"
    />
    
    <delete-dialog
      :item-id="deleteId"
      :episode-id="$route.params.episodeId"
      @close="deleteId = null"
      @success="$emit('deleted')"
    />

  </div>
</template>

<script>
import DeleteDialog from './DeleteDialog';
import ExpenseListItem from './ExpenseListItem/ExpenseListItem.vue';


export default {
  components: {
    DeleteDialog,
    ExpenseListItem,
  },
  props: {
    expenses: {
      type: Array,
      default: null
    },
    sortBy: {
      type: String,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      deleteId: null
    }
  },
  computed: {
    expensesSorted() {
      if (!this.expenses) {
        return [];
      }
      let expenses = [...this.expenses];
      switch (this.sortBy) {
        case 'activityGroup':
          expenses.sort((a, b) => (a.activityGroup.name || '').toString().localeCompare(b.activityGroup.name || ''));      
          break;     
        case 'date':
          expenses.sort((a, b) => a.date > b.date ? 1 : -1);      
          break;       
        case 'user':
          expenses.sort((a, b) => (a.user.alias || '').toString().localeCompare(b.user.alias || ''));      
          break;       
        case 'title':
          expenses.sort((a, b) => (a.title || '').toString().localeCompare(b.title || ''));      
          break;       
      }
      return expenses;
    },
  },
}
</script>

<style scoped>
.tab-details {
  padding-top: 10px;
}
</style>