<template>
  <div 
    class="month-item"
    :class="{
      'month-item--selected': selected
    }"
  >
    <div class="month-item__value">{{ formattedValue }}</div>
    <div 
      class="month-item__bar"
      :style="'height: ' + (percents / 100 * maxHeight) + 'px'"
    ></div>
    {{ label }}
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false
    }, 
    label: {
      type: String,
      default: null
    },
    percents: {
      type: Number,
      default: 0
    },
    monthData: {
      type: [String, Number],
      default: 0
    },
    formatFileSize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      maxHeight: 100
    }
  },
  computed: {
    formattedValue() {
      return this.formatFileSize 
        ? this.$helperFunctions.numberWithDots(this.$helperFunctions.formatFileSize(this.monthData, 1))
        : this.$helperFunctions.numberWithDots(this.monthData)
    }
  }
};
</script>

<style scoped>
.month-item {
  display: flex;
  flex-direction: column;
  width: calc((100% - 22px) / 12);
  align-items: center;
  cursor: pointer;
}
.month-item__value {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  font-size: 11px;
  font-weight: bold;
}
.month-item__bar {
  width: 100%;
  height: 20px;
  background-color: var(--v-success-base);
  margin-top: 8px;
  margin-bottom: 4px;
}
.month-item--selected .month-item__bar,
.month-item:hover .month-item__bar  {
  background-color: var(--v-primary-base);
}
</style>