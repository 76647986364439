<template>
  <div>
    <v-btn
      icon
      :label="$t('actions.select')"
      @click="selectionEnabled = true"
    >
      <v-icon>mdi-checkbox-blank-outline</v-icon>
    </v-btn>

    <div 
      v-if="selectionEnabled"
      class="selection-toolbar"
    >
      <v-container class="selection-toolbar__container">
        <div class="selection-toolbar__inner">

          <v-btn
            icon
            :title="$t('actions.close')"
            class="mr-2"
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <div class="selection-toolbar__count">
            <div class="selection-toolbar__count-text">{{ $t('texts.selected') }}</div>
            {{ selectedFiles.length }} {{ $t('texts.selectedOf') }} {{ files ? files.length : 0 }}
          </div> 

          <v-btn
            v-if="selectAllFlag"
            icon
            :title="$t('actions.selectAll')"
            class="mr-2"
            @click="selectAll(); selectAllFlag = !selectAllFlag"
          >
            <v-icon>mdi-checkbox-multiple-marked-outline</v-icon>
          </v-btn>

          <v-btn
            v-else
            icon
            :title="$t('actions.clearSelection')"
            class="mr-2"
            @click="clearSelection(); selectAllFlag = !selectAllFlag"
          >
            <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
          </v-btn>

          <actions-menu 
            :selected-files="selectedFiles"
            @close="close"
            @success="onSuccess"
          />        

        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';

export default {
  components: {
    ActionsMenu
  },
  props: {  
    files: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      selectAllFlag: true,
      selectionEnabled: false
    }
  },
  computed: {
    selectedFiles() {
      return this.files
        ? this.files.filter(file => file.selected)
        : [];
    }
  },
  watch: {
    selectedFiles() {
      this.setSelectedFiles(this.selectedFiles);
    },
    selectionEnabled() {
      this.clearSelection();
    }
  },
  methods: {
    ...mapMutations({
      setSelectedFiles: 'files/setSelectedFiles'
    }),
    clearSelection() {
      let i;
      for (i in this.files) {
        this.files[i].selected = false;
      }
    },
    selectAll() {
      let i;
      for (i in this.files) {
        if (this.files[i].canEdit) {
          this.files[i].selected = true;
        }
      }
    },
    onSuccess() {
      this.selectionEnabled = false;
      this.clearSelection();
      this.$emit('success');
    },
    close() {
      this.selectionEnabled = false;
    }
  }
}
</script>
