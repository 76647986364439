<template>
  <div>
    <v-container class="pt-5 pb-1">

      <div class="subtitle-1 mt-2 mb-2">{{ $t('episodes.dialogs.addEpisode.episodeTitle') }}</div>
      
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >

        <v-text-field
          v-model="title"
          label=""
          maxlength="32"
          outlined
          validate-on-blur
          :rules="[$validationRules.required]"
        />

      </v-form>

    </v-container>

    <progress-linear 
      :loading="loading"
      grey
    />

    <hr>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="$emit('cancel')"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            class="primary"
            block
            small
            :disabled="!title || loading"
            :loading="saving"
            @click="submit"
          >{{ $t('actions.save') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <reverse-geocode-service
      ref="reverseGeocodeService"
      @reverseGeocoded="onReverseGeocoded" 
    />

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy';
import ProgressLinear from '@/components/util/ProgressLinear';
import ReverseGeocodeService from '@/modules/activities/components/ReverseGeocodeService.vue';

export default {
  components: {
    ProgressLinear,
    ReverseGeocodeService
  },
  props: {
    addActivity: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      address: '',
      activityGroupId: null,
      activityTypeId: null,
      city: null,
      countries: null,
      country: null,
      latitude: null,
      longitude: null,
      loading: false,
      provinceId: null,
      title: '',
      saving: false,
      valid: false
    };
  },  
  computed: {
    ...mapGetters({
      user: 'user/user',
    })
  },
  created() {
    this.loading = true;

    this.city = this.user.city ? this.user.city : null;
    this.provinceId = this.user.idProvince ? this.user.idProvince : null;

    Promise.all([
      this.getActivityGroups(),
      this.getActivityTypes(),
      this.getCountries()
    ])
    .then(response => {
      this.loading = false;

      // find default group
      let defaultGroup = response[0].data.find(item => item.slug == 'none');
      this.activityGroupId = defaultGroup.id;
      // find default type
      let types = response[1].data.find(item => item.id == defaultGroup.id);
      let defaultType = types.activityTypes[0];
      this.activityTypeId = defaultType.id;

      // country
      this.countries = response[2].data;
      if (this.user.idCountry) {
        this.country = this.countries.find(item => item.id == this.user.idCountry);
      }
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapGetters({
      getUploadFiles: 'files/getUploadFiles',
    }),   
    ...mapActions({
      createActivity: 'activities/createActivity',
      createEpisode: 'episodes/createEpisode',
      getActivityGroups: 'activities/getActivityGroups',
      getActivityTypes: 'activities/getActivityTypes',
      getCountries: 'user/getCountries',
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.saving = true;
      this.$refs.reverseGeocodeService.start();
    },
    onReverseGeocoded(result) {
      console.log('onReverseGeocoded', result);
      this.latitude = result.latitude;
      this.longitude = result.longitude;
      this.address = result.address;
      this.city = result.city;
      this.provinceId = null;

      // find country by name
      this.country = this.countries.find(item => item.name == result.country);

      this.save();
    },
    async save() {
      try {
        let imageFile = this.getImageFile();

        // create episode
        const episodeData = {
          title: this.title,
          date: this.$moment().format('YYYY-MM-DD'),
          description: '',
          imageFile: imageFile || undefined
        }

        let response = await this.createEpisode(episodeData);
        const episodeId = response.data.episode.id;
          
 
        // create activity
        let activity;
        if (this.addActivity) {
          const activityData = {
            name: this.title,
            activityGroup: this.activityGroupId,
            activityType: this.activityTypeId,
            date: this.$moment().format('YYYY-MM-DD'),
            time: this.$moment().format('kk:mm'),
            cost: 0,
            currency: this.user.currency,
            description: '',
            country: this.country ? this.country.id : null,
            province: this.province,
            city: this.city,
            establishment: '',
            address: this.address,
            privacy: ActivityPrivacy.VISIBLE,
            imageFile: imageFile || undefined,
            latitude: this.latitude,
            longitude: this.longitude
          }

          response = await this.createActivity({ 
            episodeId: episodeId,
            data: activityData
          });
          activity = response.data.activity;
        }

        this.$emit('episode-created', { episodeId: episodeId, activityId: activity ? activity.id : null });
      }
      catch(error) {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      };

      this.saving = false;
    },
    getImageFile() {
      let files = this.getUploadFiles();
      if (!files) {
        return null;
      }
      let file = files[0];
      if (!file) {
        return null;
      }
      // if video
      if (file.type.indexOf('video') !== -1) {
        return null;
      }
      return file.file;
    }
  }
};
</script>
