<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('user.titles.configuration') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('user.titles.defaultValues') }}
      </v-tab>
    </page-tabs>

    <loading v-if="loading" />

    <div 
      v-else
      class="l-vertical__scroll l-vertical"
    >
      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >
          <form-fields
            :user-data="userData"
          />
        </v-form>
      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'episodes-list'}"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from './FormFields.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    Loading,
    PageTabs,
    PageTitle,
  },
  data() {
    return {
      loading: false,
      saving: false,
      userData: null,
      valid: true
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  created() {
    // load user data
    this.loading = true;
    this.getUser(this.user.id)
    .then(response => {
      this.userData = response.data.user;
      this.loading = false;
    });
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      mutateUser: 'user/mutateUser'
    }),
    ...mapActions({
      getUser: 'user/getUser',
      updateConfiguration: 'user/updateConfiguration',
    }),
    submit() {
      this.saving = true;

      let data = {        
        birth: this.userData.birth,
        city: this.userData.city ? this.userData.city : '',
        country: this.userData.idCountry ? this.userData.idCountry : '',
        currency: this.userData.currency,
        province : this.userData.idProvince ? this.userData.idProvince : '',
        locale: this.userData.locale,
        allowOthersUseMyPhotos: this.userData.allowOthersUseMyPhotos,
      }

      this.updateConfiguration(data)
      .then(response => {
        this.mutateUser(response.data.user);
        this.$i18n.locale = this.userData.locale;
        localStorage.lang = this.userData.locale;
        this.addFlash({
          text: response.data.message,
          type: 'success'
        });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
