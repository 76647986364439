<template>
  <div>
    <v-container class="pb-0">

      <v-form
        ref="form"
        @submit.prevent
        @keyup.native.enter="submit"
      >

        <v-radio-group
          v-model="choice"
        >
          <div class="d-flex mb-3">
            <div class="flex-grow-1 text-right pr-3">
              <strong>{{ $t('episodes.dialogs.addEpisode.addToExistingEpisode') }}</strong>
            </div>
            <div class="flex-grow-0">
              <v-radio
                value="existing"
              />
            </div>
          </div>

          <div class="d-flex">
            <div class="flex-grow-1 text-right pr-3">
              <strong>{{ $t('episodes.dialogs.addEpisode.createNewEpisode') }}</strong>
            </div>
            <div class="flex-grow-0">
              <v-radio
                value="new"
              />
            </div>
          </div>

        </v-radio-group>

      </v-form>

    </v-container>

    <hr>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="$emit('cancel')"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            class="primary"
            block
            small
            @click="submit"
          >{{ $t('actions.continue') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  data() {
    return {
      choice: 'existing'
    }
  },
  methods: {
    submit() {
      if (this.choice == 'new') {
        this.$emit('choose-new');
      }
      else {
        this.$emit('choose-existing');
      }
    }
  }
}
</script>