<template>
  <div class="mb-5">
    
    <div class="d-flex">
      <v-checkbox 
        v-model="selectedFields.privacy"
        hide-details 
        class="mr-2" 
      />
      <v-select
        v-model="item.privacy"
        :label="$t('form.label.privacy')"
        :items="privacyOptions"
        :rules="selectedFields.privacy ? [$validationRules.required] : []"
        :disabled="!selectedFields.privacy"
      />
    </div>

    <v-row>
      <v-col 
        cols="7" 
        class="py-0"
      >
        <div class="d-flex">
          <v-checkbox 
            v-model="selectedFields.date"
            hide-details 
            class="mr-2" 
          />
          <date-input
            v-model="item.date"
            :label="$t('form.label.date')"
            :rules="selectedFields.date ? [$validationRules.required] : []"
            :disabled="!selectedFields.date"
          />
        </div>
      </v-col>

      <v-col 
        cols="5" 
        class="py-0"
      >
        <div class="d-flex">
          <v-checkbox 
            v-model="selectedFields.time"
            hide-details 
            class="mr-2" 
          />
          <time-input
            v-model="item.time"
            :label="$t('form.label.hour')"
            :required="selectedFields.time"
            :disabled="!selectedFields.time"
          />
        </div>
      </v-col>
    </v-row>

    <div class="d-flex">
      <v-checkbox 
        v-model="selectedFields.description"
        hide-details 
        class="mr-2" 
      />
      <v-textarea
        v-model="item.description"
        :label="$t('files.form.label.description')"
        auto-grow
        maxlength="128"
        rows="2"
        class="mt-0"
        :disabled="!selectedFields.description"
        hide-details 
        @keyup.stop
      />
    </div>
    <div 
      v-if="selectedFields.description && !item.description"
      class="small warning--text mt-2 ml-10 dense--text"
    >{{ $t('files.texts.emptyDescriptionWarning') }}</div>
    
  </div>
</template>

<script>
import FilePrivacy from '@/modules/files/model/FilePrivacy.js';
import DateInput from '@/components/form/DateInput';
import TimeInput from '@/components/form/TimeInput';

export default {
  components: {
    DateInput,
    TimeInput
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    selectedFields: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      privacyOptions: [
        { value: FilePrivacy.PRIVATE, text: this.$t('files.model.privacy.private') },
        { value: FilePrivacy.VISIBLE, text: this.$t('files.model.privacy.visible') }
      ]
    }
  }
}
</script>