import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getSubscription() {
      return axios.get('api/subscription');
    },
  }
}
