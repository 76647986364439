<template>
  <div class="l-vertical__scroll l-vertical">
    <v-container class="container--narrow">

      <v-form
        ref="form"
        v-model="item.tab2Valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >

        <div class="mb-4">
          {{ $t('activities.form.noteTitle') }}:<br>
          {{ $t('activities.form.noteText') }}
        </div>

        <form-fields-2
          :item="item"
        />

      </v-form>
    </v-container>

    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn  
              outlined
              block
              @click="$emit('back')"
            >{{ $t('actions.back') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :disabled="!item.tab2Valid"
              :loading="saving"
              @click="submit"
            >{{ $t('actions.next') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <episode-add-modal 
      ref="episodeAddModal"
      :add-activity="false"
      @choose-existing="$emit('next')"
      @episode-created="onEpisodeCreated($event)"
    />
    
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EpisodeAddModal from '@/modules/episodes/components/EpisodeAddModal/EpisodeAddModal.vue';
import FormFields2 from '../../components/FormFields/FormFields2.vue';

export default { 
  components: {
    EpisodeAddModal,
    FormFields2
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    saving: {
      type: Boolean,
      default: false
    }
  }, 
  computed: {
    ...mapGetters({
      user: 'user/user',
    })
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.$refs.episodeAddModal.open();
    },
    onEpisodeCreated($event) {
      this.item.episode = { id: $event.episodeId };
      this.$emit('submit');
    }
  }
}
</script>