import { render, staticRenderFns } from "./ActivityHeader.vue?vue&type=template&id=7526faf0&scoped=true"
import script from "./ActivityHeader.vue?vue&type=script&lang=js"
export * from "./ActivityHeader.vue?vue&type=script&lang=js"
import style0 from "./ActivityHeader.vue?vue&type=style&index=0&id=7526faf0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7526faf0",
  null
  
)

export default component.exports