import axios from 'axios';

export default {
  namespaced: true,
  state: {
    selectedItems: null
  },
  getters: {
    getSelectedItems: state => {
      return state.selectedItems;
    }
  },
  mutations: {
    setSelectedItems: (state, items) => {
      state.selectedItems = items;
    }
  },
  actions: {
    getMelodies(){
      return axios.get('/api/melodies');
    },
    createMelody(context, data){
      let formData = new FormData();
      for(const key in data){
          if(data[key]){
              formData.append(key, data[key]);
          }
      }

      return axios.post('/api/melody', formData, {
          headers: { 'Content-Type': 'multipart/form-data'}
      });
    },  
    getMelody(context, id){
      return axios.get('/api/melody/' + id);
    },
    updateMelody(context, { id, data }){
      return axios.post('/api/melody/' + id, data);
    },
    updateMelodies(context, { ids, type, author }){
      return axios.post('/api/melodies/update-multiple', { ids, type, author });
    },
    deleteMelodies(context, ids){
      return axios.post('/api/melodies/delete-multiple', { ids });
    },
    deleteMelody(context, id){
      return axios.delete('/api/melody/' + id);
    },
    assignToEpisode(context, { melodyId, episodeId }) {
      return axios.post('/api/melody/' + melodyId + '/assign/episode/' + episodeId);
    },
    assignToActivity(context, { melodyId, activityId }) {
      return axios.post('/api/melody/' + melodyId + '/assign/activity/' + activityId);
    },
    downloadMelodies(context, ids) {
      return axios.post('/api/download-melodies', { ids })
    }
  }
}
