import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getMapActivities() {
      return axios.get('/api/map-activities');
    },  
  }
};
