<template>
  <v-select
    v-model="item.group"
    :items="groups"
    item-text="title"
    item-value="id"
    :loading="loading"
    :rules="[$validationRules.required]"
    height="80"
  >
    <template v-slot:item="data">
      <group-dropdown-item :item="data.item" />
    </template>
    <template v-slot:selection="data">
      <group-dropdown-item 
        :key="data.item.id"
        :item="data.item" 
      />
    </template>
  </v-select>
</template>

<script>
import { mapActions } from 'vuex';
import GroupDropdownItem from './GroupDropdownItem';
import Loading from '@/components/util/Loading';

export default {
  components: {
    GroupDropdownItem,
    Loading
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      groups: [],
      loading: false,
    }
  },
  created() {
    // load groups
    this.loading = true;
    this.getGroups()
    .then(response => {
      response.data.groups.sort((a, b) => (a.title || '').localeCompare(b.title || ''));  
      this.groups = response.data.groups;
      this.loading = false;
      // select first item
      if (this.groups[0]) {
        this.item.group = this.groups[0].id;
      }
    }) 
  },  
  methods: {
    ...mapActions({
      getGroups: 'contacts/getGroups'
    })
  }
}
</script>