<template>
  <v-btn 
    icon 
    :title="$t('actions.notificationsOpen')"
    :large="$vuetify.breakpoint.mdAndUp"
    :height="$vuetify.breakpoint.smAndDown ? 30 : null"
    :width="$vuetify.breakpoint.smAndDown ? 30 : null"
    @click="onClick"
  >
    <v-badge
      :value="notificationsCount > 0"
      :content="notificationsCount"
      overlap
    >
      <v-icon 
        class="notifications__icon"
      >mdi-bell-outline</v-icon>
    </v-badge>
  </v-btn> 
</template>



<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      notificationsCount: 'notifications/notificationsCount',
      user: 'user/user'
    })  
  },
  created() {
    this.getNotificationsCount();

    // update counter every x seconds
    setInterval(() => {
      if (this.user) {
        this.getNotificationsCount();
      }
    }, 30000);
  },
  methods: {
    ...mapActions({
      getNotificationsCount: 'notifications/getNotificationsCount',
    }),
    onClick() {
      this.$router.push({ name: 'notifications-list', params: { refresh: this.$moment().unix() } });
    }
  }
}
</script>
