<template>
  <v-dialog
    v-model="open"
    scrollable
    fullscreen
  >

    <div 
      v-if="notices && notices.length"
      class="l-vertical"
    >

      <app-header only-logo /> 

      <div 
        ref="statusTap"
        class="l-vertical__scroll"
      >
        <v-container class="container--narrow">
          <div class="text-center primary--text mb-6">
            <v-icon size="110">mdi-bell-outline</v-icon>
          </div>
          <div class="body-2 mb-4">
            {{ notices[index].title }}
          </div>
          {{ notices[index].description }}
        </v-container>
      </div>

      <v-container class="container--narrow">
        <v-checkbox 
          v-model="checked"
          :label="$t('notices.texts.doNotShowAgain')"
        />

        <v-btn 
          class="primary"
          block
          @click="next"
        >{{ $t('actions.continue') }}</v-btn>
      </v-container>

    </div>

  </v-dialog>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AppHeader from '@/components/layout/AppHeader/AppHeader';

export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      checked: false,
      index: 0,
      notices: null,
      open: false
    };
  },
  created() {
    this.showNotices();
  },
  methods: {
    ...mapActions({
      getUserNotices: 'notices/getUserNotices',
      disableUserNotice: 'notices/disableUserNotice'
    }),
    showNotices() {
      this.getUserNotices({
        lang: this.$i18n.locale,
        platform: this.$helperFunctions.platform()
      })
      .then(response => {
        this.notices = response.data.notices;
        if (this.notices.length) {
          this.open = true;
        }
      });
    },
    next() {
      // save checkbox
      if (this.checked) {
        this.disableUserNotice(this.notices[this.index].id);
      }

      // show next notice
      if (this.notices[this.index + 1]) {
        ++this.index;
        this.checked = false;
      }

      // close
      else {
        this.open = false;
      }
    },
  }
};
</script>
