<template>
  <v-autocomplete
    v-model="filters.country"
    :label="$t('form.label.country')"
    :items="countries"
    item-text="name"
    item-value="id"
    return-object
    clearable
    outlined
    hide-details
    :loading="loadingCountries"
    dense
    :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      countries: [],
      loadingCountries: false
    }
  },
  created() {
    // load countries
    this.loadingCountries = true;
    this.getCountries()
    .then(response => {
      this.countries = response.data;
      this.loadingCountries = false;
    })
  },
  methods: {
    ...mapActions({
      getCountries: 'user/getCountries',
    })
  }
}
</script>