var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-vertical__grow l-vertical"},[_c('page-actions',[_c('help-menu',[_vm._v(_vm._s(_vm.$t('shared.texts.help.episodeDetails')))])],1),_c('activity-tabs'),_c('div',{staticClass:"l-vertical__scroll"},[_c('activity-header',{attrs:{"episode":_vm.episode,"activity":_vm.activity,"shared":""}}),(!_vm.activity)?_c('loading'):_vm._e(),(_vm.activity)?_c('v-container',{staticClass:"container--narrow"},[(_vm.activity.description)?_c('div',{staticClass:"mb-6 text-center"},[_c('collapse-text',{attrs:{"center-button":""}},[_vm._v(" "+_vm._s(_vm.activity.description)+" ")])],1):_vm._e(),(_vm.activity.establishment || _vm.activity.address || _vm.activity.province || _vm.activity.country)?_c('v-row',{staticClass:"mb-6"},[_c('v-col',{staticClass:"flex-grow-0 d-flex align-center"},[_c('v-btn',{attrs:{"icon":"","to":{ 
              name: 'shared-episode-map', 
              params: { episodeId: _vm.$route.params.episodeId },
              query: { 
                activity: _vm.activity.id,
                hash: this.$route.query.hash 
              } 
            },"color":"primary"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-map-marker-outline")])],1)],1),_c('v-col',{staticClass:"pa-0 d-flex justify-center flex-column"},[_c('div',[_vm._v(_vm._s(_vm.activity.establishment))]),_c('div',[_vm._v(_vm._s(_vm.activity.address))]),_c('div',[(_vm.activity.city)?[_vm._v(_vm._s(_vm.activity.city)+", ")]:_vm._e(),(_vm.activity.province)?[_vm._v(_vm._s(_vm.activity.province.name)+", ")]:_vm._e(),(_vm.activity.country)?[_vm._v(_vm._s(_vm.activity.country.name))]:_vm._e()],2)])],1):_vm._e(),(_vm.activity.cost)?_c('v-row',{staticClass:"mb-6"},[_c('v-col',{staticClass:"flex-grow-0 d-flex align-center"},[_c('v-icon',{staticClass:"grey--text",attrs:{"large":""}},[_vm._v("mdi-cash-multiple")])],1),_c('v-col',{staticClass:"pa-0 d-flex align-center"},[_vm._v(_vm._s(_vm.$helperFunctions.formatMoney(_vm.activity.cost))+" "+_vm._s(_vm.activity.currency))])],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }