import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

/* modules */
import activities from '@/modules/activities/store/activities';
import adminStatistics from '@/modules/adminStatistics/store/adminStatistics';
import chat from '@/modules/chat/store/chat';
import collaborators from '@/modules/collaborators/store/collaborators';
import contacts from '@/modules/contacts/store/contacts';
import dataUpload from '@/modules/dataUpload/store/dataUpload';
import episodeAccesses from '@/modules/episodeAccesses/store/episodeAccesses';
import episodes from '@/modules/episodes/store/episodes';
import expenses from '@/modules/expenses/store/expenses';
import favorites from '@/modules/favorites/store/favorites';
import files from '@/modules/files/store/files';
import history from './history.js';
import melodies from '@/modules/melodies/store/melodies';
import notes from '@/modules/notes/store/notes';
import notices from '@/modules/notices/store/notices';
import notifications from '@/modules/notifications/store/notifications';
import pageTabs from '@/components/layout/PageTabs/store.js';
import procedures from '@/modules/procedures/store/procedures';
import search from '@/modules/search/store/search';
import shared from '@/modules/shared/store/shared';
import subscription from '@/modules/subscription/store/subscription';
import timeline from '@/modules/timeline/store/timeline';
import transfers from '@/modules/transfers/store/transfers';
import trash from '@/modules/trash/store/trash';
import user from '@/modules/user/store/user';
import worldmap from '@/modules/worldmap/store/worldmap';

/* components */
import FlashBag from '@/components/util/FlashBag/store.js';
import OfflineBadge from '@/components/util/OfflineBadge/store.js';


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    activities,
    adminStatistics,
    chat,
    collaborators,
    contacts,
    dataUpload,
    episodeAccesses,
    episodes,
    expenses,
    favorites,
    files,
    history,
    melodies,
    notes,
    notices,
    notifications,
    pageTabs,
    procedures,
    search,
    shared,
    subscription,
    timeline,
    transfers,
    trash,
    user,
    worldmap,
    
    FlashBag,
    OfflineBadge
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: [
        'user.apiToken',
        'user.refreshToken',
        'user.user',
      ]
    })
  ],
})
