import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getAccesses(context, episodeId) {
      return axios.get('/api/episode/' + episodeId + '/accesses');
    },
    createAccess(context, { episodeId, email }){
      return axios.post('/api/episode/' + episodeId + '/access', { email });
    },
    updateAccess(context, { episodeId, accessId, alias }){
      return axios.post('/api/episode/' + episodeId + '/access/' + accessId, { alias });
    },
    resendAccess(context, { episodeId, accessId }){
      return axios.post('/api/episode/' + episodeId + '/resend-access/' + accessId);
    },
    deleteAccesses(context, ids) {
      return axios.post('/api/delete-accesses', { accesses: ids });
    },
    deleteEpisodeAccesses(context, episodeId) {
      return axios.delete('/api/episode/' + episodeId + '/delete-all-access');
    }
  }
}
