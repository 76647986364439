<template>
  <div class="l-vertical__grow l-vertical">
    <page-title
      :back-to="{ 
        name: 'activity-files', 
        params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId } 
      }"
    >
      {{ $t('files.actions.addPhotosVideos') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('files.titles.information') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll">
      <v-container class="container--narrow pb-0">

        <v-alert
          v-if="uploading"
          type="info"
          class="mb-6"
        >
          <div class="mb-4">{{ $t('files.texts.uploadingFiles', { count: uploadedCount, total: files.length }) }}</div>
          <div><strong>{{ $t('files.texts.uploadingFilesMore') }}</strong></div>
        </v-alert>

        <v-alert 
          v-if="!uploading && uploadedCount != files.length"
          type="error"
          class="mb-6"
        >
          {{ $tc('files.texts.uploadErrorsCount', errorCount) }}
          <br>
          {{ $tc('files.texts.uploadSuccessCount', uploadedCount, { total: files.length }) }}
        </v-alert>

      </v-container>

      <v-container class="py-0">
        <div
          v-if="files && files.length" 
          class="small mb-1"
        >
          {{ $tc('texts.numberOfFilesSelected', files.length, { number: files.length }) }}
        </div>
      </v-container>

      <v-container 
        class="py-0"
        :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
      >
        <v-row 
          v-if="files && files.length" 
          no-gutters
          class="file-list__row"
        >
          <v-col
            v-for="file in filesCue" 
            :key="file.id"
            :cols="cols"
          >
            <upload-file-list-item 
              :file="file"
            />
          </v-col>
        </v-row>

      </v-container>
    </div>

    <div class="fixed-bottom-buttons">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn 
              :to="{ name: 'activity-files', params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId } }" 
              outlined
              block
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :loading="uploading"
              :disabled="!!errorCount"
              @click="submit"
            >
              {{ $t('actions.add') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
   
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UploadFileListItem from '@/modules/files/components/UploadFileListItem';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    UploadFileListItem,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      data: null,
      errorCount: 0,
      files: [],
      uploadedCount: 0
    }
  },
  computed: {
    cols() {    
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 2;
      }
      if (this.$vuetify.breakpoint.sm) {
        return 3;
      }        
      if (this.files && this.files.length) {
        switch (this.files.length) {
          case 1:
          case 2:
            return 6;
        }
      }
      return 4;
    },
    filesCue() {
      return this.files.filter(item => !item.uploaded);
    },
    uploading() {
      for (let i in this.files) {
        if (this.files[i].uploading) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    // get selected files
    this.files = this.getUploadFiles();
    this.setUploadFiles(null);

    // get upload data
    this.data = this.getUploadData();
    this.setUploadData(null);
       
    this.submit();
  },
  methods: {
    ...mapGetters({
      getUploadData: 'files/getUploadData',
      getUploadFiles: 'files/getUploadFiles',
    }),    
    ...mapMutations({
      addFlash: 'addFlash',
      setUploadData: 'files/setUploadData',
      setUploadFiles: 'files/setUploadFiles',
    }),
    ...mapActions({
      uploadFile: 'files/uploadFile',
      createNotificationContentUploadFile: 'notifications/createNotificationContentUploadFile',
    }),
    submit() {
      let notificationSent = false;

      for (let i in this.filesCue) {
        let file = this.files[i];
        file.uploading = true;

        this.uploadFile({
          activityId: this.$route.params.activityId,
          data: {
            ...this.data,
            activity: this.$route.params.activityId,
            filenameFile: file.file,
            type: (file.type.substr(0, 5) === 'image') ? 'photo' : 'video',
            duration: file.duration ? file.duration : null
          }
        })
        .then(response => {
          file.uploaded = true;
          this.uploadedCount++;

          // create notification
          if (!notificationSent && (this.data.privacy != 'private')) {
            this.createNotificationContentUploadFile({ fileId: response.data.activity_file.id });
            notificationSent = true;
          }

          if (this.uploadedCount == this.files.length) {
            this.onAllSent();
          }
        })
        .catch(error => {
          this.addFlash({
            text: this.$helperFunctions.errorToString(error),
            type: 'error'
          });
          file.error = true;
          this.errorCount++;
        })
        .finally(() => {
          file.uploading = false;
        });
      }
    },
    onAllSent() {
      this.addFlash({
        text: this.$t('files.texts.uploadSuccess', { count: this.uploadedCount, total: this.files.length }),
        type: 'success'
      });
      this.$router.push( {name: 'activity-files', params: { episodeId: this.$route.params.episodeId, activityId: this.$route.params.activityId }} );
    }
  }
}
</script>

<style scoped>
.file-list__row {
  margin-left: -1px;
  margin-right: -1px;
}
</style>
