<template>
  <div class="item-status">

    <span 
      v-if="!loading && !item.finished"
      class="item-status__empty"
    ></span>

    <v-progress-circular
      v-if="loading"
      indeterminate
      size="20"
      width="2"
    />

    <v-icon 
      v-if="!loading && item.finished"
      class="item-status__success"
    >
      mdi-check-circle
    </v-icon>
    
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    loading() {
      return this.current && this.uploading;
    }
  }
}
</script>


<style scoped>
.item-status {
  flex: 0 0 auto;
  padding: 4px 2px 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}
.item-status__progress {
  font-size: 10px;
  margin-top: 3px;
}
.item-status__empty {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #353535;
}
.item-status__success {
  font-size: 24px;
  color: var(--v-success-base) !important;
}
</style>
