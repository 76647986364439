<template>
  <div 
    v-if="$vuetify.breakpoint.smAndDown"
    class="menu-bottom"
    :class="{ 'menu-bottom--ios': $helperFunctions.isIos() }"
  >

    <router-link 
      v-ripple
      class="menu-bottom__item"
      :to="{ name: 'episode-add' }"
    >
      <v-icon>mdi-folder-plus-outline</v-icon>
      <div class="menu-bottom__item-label">{{ $t('menu-fab.episode') }}</div>
    </router-link>

    <router-link 
      v-ripple
      class="menu-bottom__item"
      :to="{ name: 'activity-add', params: { episodeId: $route.params.episodeId } }"
    >
      <v-icon>mdi-folder-multiple-plus-outline</v-icon>
      <div class="menu-bottom__item-label">{{ $t('menu-fab.activity') }}</div>
    </router-link>

    <v-menu 
      v-if="$helperFunctions.isAndroid()"
      offset-y
      :top="$vuetify.breakpoint.smAndDown"
      content-class="fab-audio-menu menu-bottom__add-photo-android"
    >
      <template v-slot:activator="{ on }">
        <button 
          v-ripple
          class="menu-bottom__item menu-bottom__item--bigger"
          v-on="on"
        >
          <v-icon 
            size="32"
          >mdi-camera-plus</v-icon>
          <div class="menu-bottom__item-label">{{ $t('menu-fab.upload') }}</div>
        </button>
      </template>
      <v-list
        class="py-0"
        style="overflow-y: hidden;"
      >
        <camera-button-android 
          menu-bottom
        />
        <upload-button 
          menu-bottom
        >
          <template v-slot:button="{ loading }">
            <v-list-item
              class="primary--text pt-2 pb-1 ma-0"
            >
              {{ $t('menu-fab.fototeca') }}
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                size="24"
                width="2"
                class="ml-2"
              />
              <v-icon 
                v-else
                class="ml-2"
              >mdi-image-plus</v-icon>
            </v-list-item>
          </template>
        </upload-button>
      </v-list>
    </v-menu>
    
    <upload-button 
      v-else 
      v-ripple
      class="menu-bottom__item menu-bottom__item--bigger"
      menu-bottom
    >
      <template v-slot:button="{ loading }">
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          size="24"
          width="2"
        />
        <v-icon 
          v-else
          size="32"
        >mdi-camera-plus</v-icon>
        <div class="menu-bottom__item-label">{{ $t('menu-fab.upload') }}</div>
      </template>
    </upload-button>
          
    <fab-audio-menu 
      menu-bottom
      @open-recorder="$refs.audioRecorder.open()"
    />       
    
    <audio-recorder-cordova
      v-if="$helperFunctions.isCordova()"
      ref="audioRecorder"
    />

    <router-link 
      v-ripple
      class="menu-bottom__item"
      :to="{ name: 'add-note', params: { episodeId: $route.params.episodeId, activityId: $route.params.activityId } }"
    >
      <v-icon>mdi-format-annotation-plus</v-icon>
      <div class="menu-bottom__item-label">{{ $t('menu-fab.note') }}</div>
    </router-link>

  </div>
</template>

<script>
import AudioRecorderCordova from '@/modules/notes/components/AudioRecorder/AudioRecorderCordova.vue';
import CameraButtonAndroid from '@/modules/files/components/UploadButton/CameraButtonAndroid.vue';
import FabAudioMenu from '@/modules/notes/components/FabAudioMenu/FabAudioMenu.vue';
import UploadButton from '@/modules/files/components/UploadButton/UploadButton.vue';

export default {
  components: {
    AudioRecorderCordova,
    CameraButtonAndroid,
    FabAudioMenu,
    UploadButton,
  },
}
</script>


<style scoped>
.menu-bottom {
  height: 62px;
  display: flex;
  justify-content: space-around;
  z-index: 6;
  background-color: #353535;
}
.menu-bottom--ios {
  height: 74px;
}
/deep/ .menu-bottom__item {
  display: flex;
  height: 100%;
  width: 19%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4px 6px 4px;
  color: var(--v-primary-base);
}
.menu-bottom--ios /deep/ .menu-bottom__item {
  padding-bottom: 20px;
}
.menu-bottom__item--bigger {
  width: 24%;
  position: relative !important;
  overflow-x: hidden;
  height: 72px;
  margin-top: -10px;
}
.menu-bottom__item--bigger::before {
  content: "";
  position: absolute;
  width: 90px;
  height: 22px;
  top: 1px;
  z-index: 0;
  background-image: url('../../../public/img/camera-button-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;
}
/deep/ .menu-bottom__item-label {
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
}
.menu-bottom__add-photo-android {
  overflow-y: hidden;
}
.menu-bottom--ios /deep/ .upload-button {
  height: 84px;
}
</style>

