<template>
  <file-upload
    ref="uploadWidget"
    class="sort-menu__item v-list-item v-list-item--link theme--dark primary--text"
    :accept="acceptedMimeTypes"
    :multiple="false"
    :drop="false"
    ripple
    input-id="upload-audio-input"
    @input="onFileSelected"
    @input-filter="inputFilter"
    @click.native.stop="onOpen"
  >
    <template>
      {{ $t('notes.actions.explore') }}
      <v-icon 
        class="ml-2"
      >mdi-dots-horizontal</v-icon>
    </template>

  </file-upload>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FileUpload from 'vue-upload-component';
import UploadParameters from '@/modules/notes/model/UploadParameters.js';

export default {
  components: {
    FileUpload
  },
  data() {
    return {
      acceptedMimeTypes: UploadParameters.ACCEPTED_MIME_TYPES.join(','),
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      uploadFile: 'notes/getUploadFile',
    }),
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      setUploadFile: 'notes/setUploadFile',
    }),
    inputFilter(file, oldFile, prevent) {
      this.loading = true;

      // validate max audio duration
      var objectURL = URL.createObjectURL(file.file);
      var audio = new Audio([objectURL]);
      audio.oncanplaythrough = () => {
        URL.revokeObjectURL(objectURL);
        if (audio.duration > UploadParameters.MAX_AUDIO_DURATION) {
          this.addFlash({
            text: this.$t('notes.messages.error.upload.audioTooLong', {
              max: UploadParameters.MAX_AUDIO_DURATION,
              file: file.name
            }),
            type: 'error'
          });
          this.removeFile(file);
        }
      };
    },
    onFileSelected(files) {
      if (!files || !files.length) {
        return;
      }
      this.loading = true;
      this.setUploadFile(files[0]);
      this.$refs.uploadWidget.clear();
      this.$router.push({ 
        name: 'add-audio-note',
        params: {
          episodeId: this.$route.params.episodeId, 
          activityId: this.$route.params.activityId
        }
      });
    },
    removeFile(file) {
      this.setUploadFile(null);
    },
    onOpen() {
      this.setUploadFile(null);
      this.loading = true;
      document.body.onfocus = this.onReturn; 
    },
    onReturn() { 
      document.body.onfocus = null; 

      // fix statusBar on iOS after returning from the camera
      this.$statusBarHelper.hide();
      this.$statusBarHelper.show();

      setTimeout(() => {
        if (!this.uploadFile) {
          this.loading = false;
        }
      }, 500);
    }     
  }

};
</script>

<style scoped>
.sort-menu__item {
  display: flex;
}
.sort-menu__item /deep/ label {
  cursor: pointer;
}
</style>
