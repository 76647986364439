<template>
  <div class="l-vertical__grow l-vertical">
    <page-actions>
      <sort-menu 
        class="mr-1"
        @change="sortBy = $event" 
      />
      <help-menu>{{ $t('shared.texts.help.episodeDetails') }}</help-menu>
    </page-actions>

    <activity-tabs
      :keyboard-navigation="!(openFile && openFile.id)"
    />

    <progress-linear :loading="loading" />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
        
      <v-container v-if="!loading && files && !files.length">
        <v-alert type="info">
          {{ $t('files.texts.noElements') }}
        </v-alert>
      </v-container>

      <file-list>
        <file-list-col
          v-for="file in filesSorted"
          :key="file.id"
        >
          <file-list-item
            :file="file"
            shared
            @click="openFile = file"
          />
        </file-list-col>
      </file-list>

    </div>

    <file-details-modal
      :open="!!(openFile && openFile.id)"
      :file="openFile"
      :files="filesSorted"
      @close="openFile = null"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ActivityTabs from '@/modules/shared/components/ActivityTabs.vue';
import FileDetailsModal from '@/modules/files/components/FileDetailsModal/FileDetailsModal.vue';
import FileList from '@/modules/files/components/FileList/FileList.vue';
import FileListCol from '@/modules/files/components/FileListCol.vue';
import FileListItem from '@/modules/files/components/FileListItem/FileListItem.vue';
import HelpMenu from '@/modules/shared/components/HelpMenu.vue';
import PageActions from '@/modules/shared/components/PageActions.vue';
import ProgressLinear from '@/components/util/ProgressLinear';
import SortMenu from './SortMenu.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    ActivityTabs,
    FileDetailsModal,
    FileList,
    FileListCol,
    FileListItem,
    HelpMenu,
    PageActions,
    ProgressLinear,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      files: null,
      loading: false,
      openFile: null,
      sortBy: 'recent'
    }
  },
  computed: {
    filesSorted() {
      if (!this.files) {
        return [];
      }
      let files = [...this.files];
      switch (this.sortBy) {
        case 'recent':
          files.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'date':
          files.sort((a, b) => (a.date + a.time + a.id) < (b.date + b.time + b.id) ? -1 : 1);       
          break;       
        case 'title':
          files.sort((a, b) => (a.description || '').toString().localeCompare(b.description || ''));      
          break;
      }
      return files;
    }
  },
  created() {
    this.loadFiles();
    
    // load episode
    this.loadEpisodeToCache({
      episodeId: this.$route.params.episodeId,
      hash: this.$route.query.hash
    });

    // load activity
    this.loadActivityToCache({
      episodeId: this.$route.params.episodeId,
      activityId: this.$route.params.activityId,
      hash: this.$route.query.hash
    });
  },
  methods: {
    ...mapActions({
      getActivityFiles: 'shared/getActivityFiles',
      loadActivityToCache: 'shared/loadActivityToCache',
      loadEpisodeToCache: 'shared/loadEpisodeToCache'
    }),
    loadFiles() {
      this.loading = true;
      this.getActivityFiles({ 
        episodeId: this.$route.params.episodeId,
        activityId: this.$route.params.activityId,
        hash: this.$route.query.hash
      })
      .then(response => {
        this.files = response.data.activity_files;
        this.loading = false;
      });
    }
  }
}
</script>
