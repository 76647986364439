<template>
  <div class="generic-list-item">

    <div class="generic-list-item__main">  

      <div class="d-flex align-start">
        <v-icon 
          size="30"
          class="mr-2 mb-1"
        >{{ ProcedureTypes[item.type].icon }}</v-icon>
        <div class="flex-grow-1">
          <collapse-text 
            overflow-button
            class="mb-3"
          >{{ item.text }}</collapse-text>
          <div 
            v-if="item.cost"
            class="small mt-2"
          >{{ $helperFunctions.formatMoney(item.cost) }} {{ item.currency }}</div>
        </div>
      </div>

      <div 
        v-if="item.urlFilename"
        class="mt-1"
      >
        <a 
          :href="item.urlFilename + '?bearer=' + apiToken"
          class="link--no-color"
        >
          <v-icon 
            size="22"
            class="mr-2 ml-1 mb-1"
          >mdi-paperclip</v-icon>
          <strong class="small">
            {{ item.originalFilename }}
          </strong>
        </a>
      </div>

      <div 
        v-if="!item.cost && !item.urlFilename"
        class="mb-6"
      ></div>
    </div>

    <div class="generic-list-item__buttons">
      <actions-menu 
        v-if="showMenu"
        :item="item" 
        @delete="$emit('delete')"
      />
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';
import CollapseText from '@/components/util/CollapseText';
import ProcedureTypes from '@/modules/procedures/model/ProcedureTypes.js';

export default {
  components:{
    ActionsMenu,
    CollapseText
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ProcedureTypes: ProcedureTypes
    }
  },
  computed: {
  ...mapGetters({
    apiToken: 'user/apiToken'
  }),
  } 
}
</script>

<style scoped>
.procedure-list-item {
  border-radius: 5px;
  background-color: var(--color-background--light);
  color: var(--color-text--dark);
  overflow: hidden;
}
</style>
