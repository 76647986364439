<template>
  <div>
    <v-container class="py-3 px-6">
      <div class="subtitle-1 mt-2">
        {{ $t('contacts.titles.enterNewAlias') }}
      </div>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >
        <v-text-field
          v-model="alias"
          type="text"
          maxlength="12"
          :hint="$t('contacts.form.aliasHint')"
          persistent-hint
        />
      </v-form>



    </v-container>

    <hr>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="$emit('close')"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            color="primary"
            :loading="saving"
            block
            small
            @click="submit"
          >{{ $t('actions.save') }}</v-btn>
        </v-col>

      </v-row>
    </v-container>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      alias: this.user.alias,
      saving: false,
      valid: true
    };
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      changeAlias: 'contacts/changeAlias'
    }),
    submit() {
      this.saving = true;

      this.changeAlias({
        userId: this.user.id,
        alias: this.alias
      })
      .then(() => {
        this.addFlash({
          text: this.$t("contacts.messages.success.aliasChanged"),
          type: 'success'
        });
        this.$emit('close');
        this.$emit('saved');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>
