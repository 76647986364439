<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'notice-list' }">
      {{ $t('notices.titles.notices') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('actions.add') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">

      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <form-fields
            :item="item"
            context="add"
          />
        </v-form>

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'notice-list' }"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.add') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from '../components/FormFields.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {
        lang: 'es'
      },
      saving: false,
      valid: false
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      createNotice: 'notices/createNotice',
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        title: this.item.title,
        description: this.item.description,
        ios: !!this.item.ios,
        android: !!this.item.android,
        web: !!this.item.web,
        lang: this.item.lang,
        published: false,
        finished: false
      }

      this.createNotice(data)
      .then(response => {
        this.addFlash({
          text: this.$t("notices.messages.success.added"),
          type: 'success'
        });
        this.$router.push({ name: 'notice-list' });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
