<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :title="$t('actions.filter')"
        :class="{
          'ml-1': $vuetify.breakpoint.mdAndUp
        }"
        v-on="on"
      >
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">

      <v-list-item 
        dense
        @click.stop.prevent="filter = 'episodes'"
      >
        <v-checkbox
          v-model="filter"
          value="episodes"
          :label="$t('favorites.filter.episodes')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="filter = 'activities'"
      >
        <v-checkbox
          v-model="filter"
          value="activities"
          :label="$t('favorites.filter.activities')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="filter = 'files'"
      >
        <v-checkbox
          v-model="filter"
          value="files"
          :label="$t('favorites.filter.files')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="filter = 'notes'"
      >
        <v-checkbox
          v-model="filter"
          value="notes"
          :label="$t('favorites.filter.notes')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <!-- ------------------------------- -->

      <v-row class="mx-0 my-2">
        <v-col 
          cols="6" 
          class="pr-2"
        >
          <v-btn
            outlined
            block
            small
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>
        <v-col 
          cols="6" 
          class="pl-2"
        >
          <v-btn 
            block
            small
            color="primary"
            @click="$emit('change', filter)"
          >{{ $t('actions.apply') }}</v-btn>
        </v-col>
      </v-row>


    </v-list>
  </v-menu>
</template>


<script>
export default {
  data() {
    return {
      filter: 'episodes'
    }
  }
}
</script>