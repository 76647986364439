<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :title="$t('actions.filter')"
        :class="{
          'ml-1': $vuetify.breakpoint.mdAndUp
        }"
        v-on="on"
      >
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">


      <!-- favorites -->
      
      <v-list-item 
        dense
        @click.stop.prevent="favorites = !favorites"
      >
        <v-checkbox
          v-model="favorites"
          :value="true"
          :label="$t('titles.favorites')"
          class="pl-0 mt-0"
          hide-details
          @click.stop
        />
      </v-list-item>

      <!-- type -->

      <v-list-item 
        dense
        @click.stop.prevent="type = 'all'"
      >
        <v-checkbox
          v-model="type"
          value="all"
          :label="$t('files.model.galleryFilter.photosAndVideos')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="type = 'photo'"
      >
        <v-checkbox
          v-model="type"
          value="photo"
          :label="$t('files.model.galleryFilter.photos')"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="type = 'video'"
      >
        <v-checkbox
          v-model="type"
          value="video"
          :label="$t('files.model.galleryFilter.videos')"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        />
      </v-list-item>


      <!-- privacy -->

      <v-list-item 
        dense
        @click.stop.prevent="privacy = 'all'"
      >
        <v-checkbox
          v-model="privacy"
          value="all"
          :label="$t('files.model.galleryFilter.all')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="privacy = 'mine'"
      >
        <v-checkbox
          v-model="privacy"
          value="mine"
          :label="$t('files.model.galleryFilter.mine')"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="privacy = 'minePublic'"
      >
        <v-checkbox
          v-model="privacy"
          value="minePublic"
          :label="$t('files.model.galleryFilter.minePublic')"
          class="pl-0 mt-0 ml-12"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="privacy = 'minePrivate'"
      >
        <v-checkbox
          v-model="privacy"
          value="minePrivate"
          :label="$t('files.model.galleryFilter.minePrivate')"
          class="pl-0 mt-0 ml-12"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="privacy = 'others'"
      >
        <v-checkbox
          v-model="privacy"
          value="others"
          :label="$t('files.model.galleryFilter.others')"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-row class="mx-0 my-2">
        <v-col 
          cols="6" 
          class="pr-2"
        >
          <v-btn
            outlined
            block
            small
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>
        <v-col 
          cols="6" 
          class="pl-2"
        >
          <v-btn 
            block
            small
            color="primary"
            @click="$emit('change', { favorites, type, privacy })"
          >{{ $t('actions.apply') }}</v-btn>
        </v-col>
      </v-row>

    </v-list>
  </v-menu>
</template>


<script>
export default {
  data() {
    return {
      favorites: false,
      type: 'all',
      privacy: 'all'
    }
  }
}
</script>