<template>
  <div class="upload-footer__controls">
    <v-btn 
      class="primary upload-footer__submit"
      :disabled="loading || !enoughSpace || finished"
      :loading="uploading"
      @click="$emit('upload')"
    >
      <v-icon left>mdi-upload</v-icon>
      {{ paused ? $t('dataUpload.actions.resume') : $t('dataUpload.actions.upload') }}
    </v-btn>
    <!-- <v-btn 
      outlined
      icon
      class="ml-2"
      :disabled="!uploading"
      :loading="pausing"
      @click="$emit('pause')"
    >
      <v-icon>mdi-pause</v-icon> 
    </v-btn> -->
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    pausing: {
      type: Boolean,
      default: false,
    },
    paused: {
      type: Boolean,
      default: false,
    },
    enoughSpace: {
      type: Boolean,
      default: false,
    },
    finished: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.upload-footer__controls {
  flex: 0 0 auto;
  margin-top: 6px;
  text-align: right;
}
.upload-footer__submit {
  width: 200px;
}
</style>