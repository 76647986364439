import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getNotices(context) {
      return axios.get('api/notices');
    },
    createNotice(context, data) {
      return axios.post('api/notice', data);
    },
    getNotice(context, noticeId) {
      return axios.get('api/notice/' + noticeId);
    },
    updateNotice(context, { noticeId, data }) {
      return axios.post('api/notice/' + noticeId, data);
    },
    deleteNotice(context, noticeId) {
      return axios.delete('api/notice/' + noticeId);
    },
    republishNotice(context, noticeId) {
      return axios.post('api/notice/' + noticeId + '/reemit');
    },
    getUserNotices(context, { lang, platform }) {
      return axios.get('api/notices-user', { params: { lang, platform }});
    },
    disableUserNotice(context, noticeId) {
      return axios.post('api/notice/' + noticeId + '/disable');
    },
  }
}
