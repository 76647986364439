<template>
  <v-badge 
    :value="offline"
    color="transparent"
    icon="mdi-cloud-off-outline"
    overlap
  >
    <slot></slot>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      offline: 'offline'
    }),
  }
}
</script>