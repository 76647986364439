export default {
  namespaced: true,
  state: {
    pageTabsScroll: {
      key: null,
      position: 0
    }
  },
  getters: {
    pageTabsScroll(state) {
      return state.pageTabsScroll;
    },
  },
  mutations: {
    setPageTabsScroll: (state, value) => {
      state.pageTabsScroll = value;
    }
  }
}