const currencies = [
  {iso:'EUR',name:'Euro'},
  {iso:'USD',name:'US Dollar'},
  {iso:'GBP',name:'Pound Sterling'},
  {iso:'AFN',name:'Afghani'},
  {iso:'DZD',name:'Algerian Dinar'},
  {iso:'ARS',name:'Argentine Peso'},
  {iso:'AMD',name:'Armenian Dram'},
  {iso:'AWG',name:'Aruban Guilder'},
  {iso:'AUD',name:'Australian Dollar'},
  {iso:'AZN',name:'Azerbaijanian Manat'},
  {iso:'BSD',name:'Bahamian Dollar'},
  {iso:'BHD',name:'Bahraini Dinar'},
  {iso:'THB',name:'Baht'},
  {iso:'PAB',name:'Balboa'},
  {iso:'BBD',name:'Barbados Dollar'},
  {iso:'BYR',name:'Belarussian Ruble'},
  {iso:'BZD',name:'Belize Dollar'},
  {iso:'BMD',name:'Bermudian Dollar'},
  {iso:'VEF',name:'Bolivar Fuerte'},
  {iso:'BOB',name:'Boliviano'},
  {iso:'BRL',name:'Brazilian Real'},
  {iso:'BND',name:'Brunei Dollar'},
  {iso:'BGN',name:'Bulgarian Lev'},
  {iso:'BIF',name:'Burundi Franc'},
  {iso:'CAD',name:'Canadian Dollar'},
  {iso:'CVE',name:'Cape Verde Escudo'},
  {iso:'KYD',name:'Cayman Islands Dollar'},
  {iso:'GHS',name:'Cedi'},
  {iso:'XAF',name:'CFA Franc'},
  {iso:'XOF',name:'CFA Franc'},
  {iso:'XPF',name:'CFP Franc'},
  {iso:'CLP',name:'Chilean Peso'},
  {iso:'CNY',name:'Chinese Yuan'},
  {iso:'COP',name:'Colombian Peso'},
  {iso:'KMF',name:'Comoro Franc'},
  {iso:'CDF',name:'Congolese Franc'},
  {iso:'BAM',name:'Convertible Marks'},
  {iso:'NIO',name:'Cordoba Oro'},
  {iso:'CRC',name:'Costa Rican Colon'},
  {iso:'HRK',name:'Croatian Kuna'},
  {iso:'CUP',name:'Cuban Peso'},
  {iso:'CZK',name:'Czech Koruna'},
  {iso:'GMD',name:'Dalasi'},
  {iso:'DKK',name:'Danish Krone'},
  {iso:'MKD',name:'Denar'},
  {iso:'DJF',name:'Djibouti Franc'},
  {iso:'STD',name:'Dobra'},
  {iso:'DOP',name:'Dominican Peso'},
  {iso:'VND',name:'Dong'},
  {iso:'XCD',name:'East Caribbean Dollar'},
  {iso:'EGP',name:'Egyptian Pound'},
  {iso:'ETB',name:'Ethiopian Birr'},
  {iso:'FKP',name:'Falkland Islands Pound'},
  {iso:'FJD',name:'Fiji Dollar'},
  {iso:'GIP',name:'Gibraltar Pound'},
  {iso:'HTG',name:'Gourde'},
  {iso:'PYG',name:'Guarani'},
  {iso:'GNF',name:'Guinea Franc'},
  {iso:'GWP',name:'Guinea-Bissau Peso'},
  {iso:'GYD',name:'Guyana Dollar'},
  {iso:'HKD',name:'Hong Kong Dollar'},
  {iso:'UAH',name:'Hryvnia'},
  {iso:'HUF',name:'Hungary Forint'},
  {iso:'ISK',name:'Iceland Krona'},
  {iso:'INR',name:'Indian Rupee'},
  {iso:'IRR',name:'Iranian Rial'},
  {iso:'IQD',name:'Iraqi Dinar'},
  {iso:'ILS',name:'Israeli Sheqel'},
  {iso:'JMD',name:'Jamaican Dollar'},
  {iso:'JPY',name:'Japan Yen'},
  {iso:'JOD',name:'Jordanian Dinar'},
  {iso:'KES',name:'Kenyan Shilling'},
  {iso:'PGK',name:'Kina'},
  {iso:'LAK',name:'Kip'},
  {iso:'EEK',name:'Kroon'},
  {iso:'KWD',name:'Kuwaiti Dinar'},
  {iso:'MWK',name:'Kwacha'},
  {iso:'AOA',name:'Kwanza'},
  {iso:'MMK',name:'Kyat'},
  {iso:'GEL',name:'Lari'},
  {iso:'LVL',name:'Latvian Lats'},
  {iso:'LBP',name:'Lebanese Pound'},
  {iso:'ALL',name:'Lek'},
  {iso:'HNL',name:'Lempira'},
  {iso:'SLL',name:'Leone'},
  {iso:'LRD',name:'Liberian Dollar'},
  {iso:'LYD',name:'Libyan Dinar'},
  {iso:'SZL',name:'Lilangeni'},
  {iso:'LTL',name:'Lithuanian Litas'},
  {iso:'LSL',name:'Loti'},
  {iso:'MGA',name:'Malagasy Ariary'},
  {iso:'MYR',name:'Malaysian Ringgit'},
  {iso:'TMT',name:'Manat'},
  {iso:'MUR',name:'Mauritius Rupee'},
  {iso:'MZN',name:'Metical'},
  {iso:'MXN',name:'Mexican Peso'},
  {iso:'MDL',name:'Moldovan Leu'},
  {iso:'MAD',name:'Moroccan Dirham'},
  {iso:'NGN',name:'Naira'},
  {iso:'ERN',name:'Nakfa'},
  {iso:'NAD',name:'Namibia Dollar'},
  {iso:'NPR',name:'Nepalese Rupee'},
  {iso:'ANG',name:'Netherlands Antillian Guilder'},
  {iso:'RON',name:'New Leu'},
  {iso:'NZD',name:'New Zealand Dollar'},
  {iso:'BTN',name:'Ngultrum'},
  {iso:'KPW',name:'North Korean Won'},
  {iso:'NOK',name:'Norwegian Krone'},
  {iso:'PEN',name:'Nuevo Sol'},
  {iso:'MRO',name:'Ouguiya'},
  {iso:'TOP',name:'Pa\'anga'},
  {iso:'PKR',name:'Pakistan Rupee'},
  {iso:'MOP',name:'Pataca'},
  {iso:'UYU',name:'Peso Uruguayo'},
  {iso:'PHP',name:'Philippine Peso'},
  {iso:'PLN',name:'Polish Zloty'},
  {iso:'BWP',name:'Pula'},
  {iso:'QAR',name:'Qatari Rial'},
  {iso:'GTQ',name:'Quetzal'},
  {iso:'ZAR',name:'Rand'},
  {iso:'OMR',name:'Rial Omani'},
  {iso:'KHR',name:'Riel'},
  {iso:'MVR',name:'Rufiyaa'},
  {iso:'IDR',name:'Rupiah'},
  {iso:'RUB',name:'Russian Ruble'},
  {iso:'RWF',name:'Rwanda Franc'},
  {iso:'SHP',name:'Saint Helena Pound'},
  {iso:'SVC',name:'Salvadoran Colon'},
  {iso:'SAR',name:'Saudi Riyal'},
  {iso:'RSD',name:'Serbian Dinar'},
  {iso:'SCR',name:'Seychelles Rupee'},
  {iso:'SGD',name:'Singapore Dollar'},
  {iso:'SBD',name:'Solomon Islands Dollar'},
  {iso:'KGS',name:'Som'},
  {iso:'SOS',name:'Somali Shilling'},
  {iso:'TJS',name:'Somoni'},
  {iso:'LKR',name:'Sri Lanka Rupee'},
  {iso:'SDG',name:'Sudanese Pound'},
  {iso:'SRD',name:'Surinam Dollar'},
  {iso:'SEK',name:'Swedish Krona'},
  {iso:'CHF',name:'Swiss Franc'},
  {iso:'SYP',name:'Syrian Pound'},
  {iso:'TWD',name:'Taiwan Dollar'},
  {iso:'BDT',name:'Taka'},
  {iso:'WST',name:'Tala'},
  {iso:'TZS',name:'Tanzanian Shilling'},
  {iso:'KZT',name:'Tenge'},
  {iso:'TTD',name:'Trinidad and Tobago Dollar'},
  {iso:'MNT',name:'Tugrik'},
  {iso:'TND',name:'Tunisian Dinar'},
  {iso:'TRY',name:'Turkish Lira'},
  {iso:'AED',name:'UAE Dirham'},
  {iso:'UGX',name:'Uganda Shilling'},
  {iso:'UZS',name:'Uzbekistan Sum'},
  {iso:'VUV',name:'Vatu'},
  {iso:'KRW',name:'Won'},
  {iso:'YER',name:'Yemeni Rial'},
  {iso:'ZMK',name:'Zambian Kwacha'},
  {iso:'ZWL',name:'Zimbabwe Dollar'}
];

export default currencies;
