import CollaboratorAddPage from '@/modules/collaborators/pages/CollaboratorAddPage/CollaboratorAddPage.vue';
import CollaboratorGroupAddPage from '@/modules/collaborators/pages/CollaboratorGroupAddPage/CollaboratorGroupAddPage.vue';
import CollaboratorEditPage from '@/modules/collaborators/pages/CollaboratorEditPage/CollaboratorEditPage.vue';
import CollaboratorsListPage from '@/modules/collaborators/pages/CollaboratorsListPage/CollaboratorsListPage.vue';
import CollaboratorsChangeRolePage from '@/modules/collaborators/pages/CollaboratorsChangeRolePage/CollaboratorsChangeRolePage.vue';

export default [
  {
    path: '/episode/:episodeId/collaborators',
    name: 'collaborators-list',
    component: CollaboratorsListPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/add-collaborator',
    name: 'collaborator-add',
    component: CollaboratorAddPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/collaborator/:collaboratorId',
    name: 'collaborator-edit',
    component: CollaboratorEditPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/collaborators/change-role',
    name: 'collaborators-change-role',
    component: CollaboratorsChangeRolePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/add-collaborator-group',
    name: 'add-collaborator-group',
    component: CollaboratorGroupAddPage,
    meta: { requiresAuth: true }
  },
];
