export default {
  namespaced: true,
  state: {
    backToMenu: false,
    lastRouteName: null,
  },
  getters: {
    backToMenu(state) {
      return state.backToMenu;
    },
    lastRouteName(state) {
      return state.lastRouteName;
    },
  },
  mutations: {
    setBackToMenu: (state, backToMenu) => {
      state.backToMenu = backToMenu;
    },
    setLastRouteName: (state, lastRouteName) => {
      state.lastRouteName = lastRouteName;
    }
  },
};
