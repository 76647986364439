<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('worldmap.titles.worldmap') }}
      <template
        slot="buttons"
      >
        <filter-menu 
          :activity-groups="activityGroups"
          @change="filters = $event; showMarkers()" 
        />

        <map-legend :activity-groups="activityGroups" />
      </template>
    </page-title>

    <page-tabs v-model="tab">
      
      <v-tab @click="tab = 0">{{ $t('worldmap.titles.visitedPlaces') }}</v-tab> 
      <v-tab @click="tab = 1">{{ $t('timeline.titles.wishListTab') }}</v-tab> 

    </page-tabs>

    <progress-linear :loading="loading" />

    <div class="l-vertical__grow l-vertical">
      <div 
        ref="googleMap" 
        class="google-map"
      ></div>
    </div>
    
    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FilterMenu from './FilterMenu';
import GoogleMapsApiLoader from "google-maps-api-loader";
import MapLegend from "@/modules/worldmap/components/MapLegend.vue";
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ProgressLinear from '@/components/util/ProgressLinear';

export default {
  components: {
    FilterMenu,
    MapLegend,
    MenuBottom,
    PageTabs,
    PageTitle,
    ProgressLinear
  },
  data() {
    return {
      activities: null,
      activityGroups: null,
      filters: { 
        groups: {},
        groupsAll: true,
      },
      loading: false,
      map: null,
      mapConfig: {
        center: { lat: 0, lng: 0 },
        zoom: 2,
        disableDefaultUI: true
      },
      markers: [],
      tab: 0,
    };
  },
  computed: {
    activitiesFiltered() {
      if (!this.activities) {
        return [];
      }
      return this.activities.filter(item => {
        // not wishlist
        if (this.tab == 0) {
          // activity date is not in the future
          if (this.$moment(item.date).isAfter()) {
            return false;
          }
        }
        // wishlist
        else {
          // episode is on wish list
          if (!item.episode.isWish) {
            return false;
          }
          // episode date is in the future
          if (!this.$moment(item.episode.date).isAfter()) {
            return false;
          }
        }

        // filter by group
        if (!this.filters.groupsAll) {
          if (!this.filters.groups[item.activityGroup.id]) {
            return false;
          }
        }

        return true;
      });
    },
  },
  watch: {
    tab() {
      this.showMarkers();
    }
  },
  created() {
    // load activities
    this.loading = true;
    this.getMapActivities()
    .then(response => {
      this.activities = response.data.activity;
      this.loading = false;
      this.showMarkers();
    });

    // load activity groups
    this.getActivityGroups()
    .then(response => {
      let groups = response.data;
      groups.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
      let firstItemIndex = groups.findIndex(item => item.slug == 'none');
      let firstItem = groups.splice(firstItemIndex, 1)[0];
      groups.unshift(firstItem);
      this.activityGroups = groups;
    });
  },
  async mounted() {
    // load Google Maps API
    const google = await GoogleMapsApiLoader({ 
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      language: this.$i18n.locale
    });

    // create map
    this.map = new google.maps.Map(this.$refs.googleMap, this.mapConfig);

    this.showMarkers();
  },
  methods: {
    ...mapActions({
      getMapActivities: 'worldmap/getMapActivities',
      getActivityGroups: 'activities/getActivityGroups',
    }),
    showMarkers() {
      if (!this.activities || !this.map) {
        return;
      }

      this.clearMarkers();

      for (const activity of this.activitiesFiltered) {
        if (activity.latitude && activity.longitude) {
          const marker = new google.maps.Marker({
            position: { 
              lat: parseFloat(activity.latitude), 
              lng: parseFloat(activity.longitude) 
            },
            map: this.map,
            title: activity.name,
            icon: {
              url: "img/markers/" + activity.activityGroup.slug + ".png",
              scaledSize: new google.maps.Size(32, 32)
            } 
          });
          marker.addListener("click", () => {
            this.$router.push({ 
              name: 'activity-details', 
              params: { 
                episodeId: activity.episode.id, 
                activityId: activity.id
              } 
            });
          });
          this.markers.push(marker);
        }
      }
    },
    clearMarkers() {
      for (const marker of this.markers) {
        marker.setMap(null);
      }
      this.markers = [];
    }
  }
};
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
</style>
