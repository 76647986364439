<template>
  <div class="upload-footer">
    <loading 
      v-if="loading" 
      height="48"
    />

    <v-container 
      v-else
      class="upload-footer__container"
    > 
      <items-total
        :available-space="availableSpace"
        :total="total"
        :total-size="totalSize"
        :enough-space="enoughSpace"
        :loading="loading"
        :activities="activities"
      />
      <upload-status
        :current-file="currentFile"
        :current-file-number="currentFileNumber"
        :total="total"
        :uploading="uploading"
      />
      <upload-controls
        :loading="loading"
        :uploading="uploading"
        :pausing="pausing"
        :paused="paused"
        :finished="finished"
        :enough-space="enoughSpace"
        @upload="$emit('upload')"
        @pause="$emit('pause')"
      />
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ItemsTotal from './ItemsTotal.vue';
import Loading from '@/components/util/Loading.vue';
import UploadControls from './UploadControls.vue';
import UploadStatus from './UploadStatus.vue';

export default {
  components: {
    ItemsTotal,
    Loading,
    UploadControls,
    UploadStatus,
  },
  props: {
    availableSpace: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    currentFile: {
      type: Object,
      default: null,
    },
    currentFileNumber: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    pausing: {
      type: Boolean,
      default: false,
    },
    paused: {
      type: Boolean,
      default: false,
    },
    activities: {
      type: Array,
      default: null,
    },
    finished: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    enoughSpace() {
      if ((this.availableSpace === null) || (this.totalSize === null)) {
        return true;
      }
      return this.availableSpace >= this.totalSize;
    },
    totalSize() {
      return this.activities.reduce((acc, activity) => {
        return activity.selected ? acc + activity.estimatedSize : acc;
      }, 0);
    },
  },
  watch: {
    enoughSpace() {
      if (!this.enoughSpace) {
        this.addFlash({
          text: this.$t('dataUpload.messages.error.notEnoughSpace'),
          type: 'error',
        });
      }
    },
  },
  methods: {    
    ...mapMutations({
      addFlash: 'addFlash',
    }),
  }
}
</script>

<style scoped>
.upload-footer {
  background-color: var(--color-background--footer);
}
.upload-footer__container {
  display: flex;
  gap: 20px;
  padding-top: 3px;
  padding-bottom: 6px;
}
</style>