import { render, staticRenderFns } from "./StatisticsTabs.vue?vue&type=template&id=2a890109"
import script from "./StatisticsTabs.vue?vue&type=script&lang=js"
export * from "./StatisticsTabs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports