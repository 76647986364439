<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'advanced-search' }"
      :back-to-routes-names="['timeline']"
    >
      {{ $t('search.titles.search') }}
      <template slot="buttons">
        <sort-menu 
          v-if="activities && activities.length"
          @change="sortBy = $event" 
        />
      </template>
    </page-title>
    
    <page-tabs class="mb-2">
      <v-tab>
        {{ $t('search.titles.searchResults') }}
      </v-tab>
    </page-tabs>

    <filters-chips 
      search-for="activities" 
      @change="submit"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <v-container 
        class="py-0"
        :class="{ 'px-3': $vuetify.breakpoint.smAndDown }"
      >

        <v-alert
          v-if="!loading && activities && !activities.length"
          type="info"
        >{{ $t('messages.results.notFound') }}</v-alert>

        <loading v-if="loading" />

        <v-row 
          v-if="activities && activities.length"
          no-gutters
          class="activities-list"
        >
          <v-col
            v-for="activity in activitiesFilteredSorted"
            :key="activity.id"
            cols="6"
            sm="3"
            md="2"
            lg="2"
            class="pr-3"
          >
            <activity-list-item
              :item="activity"
              show-date
              class="activities-list__item"
            />
          </v-col>
        </v-row>

      </v-container>
    </div>


  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActivityListItem from '@/modules/activities/components/ActivityListItem/ActivityListItem.vue';
import FiltersChips from '@/modules/search/components/FiltersChips.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle";
import SortMenu from './SortMenu';
import statusTap from '@/mixins/statusTap.js';

export default {
  name: 'ActivitiesResultsPage',
  components: {
    ActivityListItem,
    FiltersChips,
    Loading,
    PageTabs,
    PageTitle,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      activities: null,
      loading: false,
      sortBy: 'recent'
    }
  },
  computed: {
    ...mapGetters({
      filters: 'search/getFilters',
      searchCached: 'search/getSearchCached',
    }),  
    activitiesFiltered() {
      if (!this.activities) {
        return [];
      }
      // filter by 'wishlist' parameter from timeline page
      if (this.$route.query.wishlist) {
        return this.activities.filter(item => item.episode.isWish);
      }
      else {
        return this.activities;
      }
    },
    activitiesFilteredSorted() {
      let activities = [...this.activitiesFiltered];
      switch (this.sortBy) {
        case 'recent':
          activities.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'date':
          activities.sort((a, b) => (a.date + a.time + a.id) < (b.date + b.time + b.id) ? -1 : 1);       
          break;       
        case 'title':
          activities.sort((a, b) => (a.name || '').localeCompare(b.name || ''));      
          break;
      }
      return activities;
    }
  },

  activated() {
    // handle 'year' parameter from timeline page
    if (this.$route.query.year) {
      this.setFilters({
        dateFrom: this.$route.query.year + "-01-01",
        dateTo: this.$route.query.year + "-12-31"
      });
      this.setSearchCached(false);
    }

    if (!this.searchCached) {
      this.submit();
    }
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      setFilters: 'search/setFilters',
      setSearchCached: 'search/setSearchCached',
    }),
    ...mapActions({
      searchActivities: 'search/searchActivities'
    }),
    submit() {
      this.activities = null;
      
      this.loading = true;

      let params = {
        activity_title: this.filters.title,
        activity_group: this.filters.activityGroup ? this.filters.activityGroup.id : null,
        activity_type: this.filters.activityType ? this.filters.activityType.id : null,
        address: this.filters.address,
        city: this.filters.city,
        country: this.filters.country ? this.filters.country.id : null,
        date_from: this.filters.dateFrom,
        date_to: this.filters.dateTo,
        establishment: this.filters.establishment,
        province: this.filters.province ? this.filters.province.id : null,
        user: this.filters.user ? this.filters.user.id : null
      };

      this.searchActivities(params)
      .then(response => {
        if (response) {
          this.activities = response.data.activities;
          this.loading = false;
          this.setSearchCached(true);
        }
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      });
    }
  }
}
</script>

<style scoped>
.activities-list {
  margin-right: -12px;
}
.activities-list__item {
  margin: 0 0 12px 0;
}
</style>