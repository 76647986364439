<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'collaborators-list', params: { episodeId: $route.params.episodeId } }">
      {{ $t('collaborators.titles.collaborators') }}
      <template slot="buttons">
        <delete-dialog 
          :episode-id="$route.params.episodeId"
          :collaborator-id="$route.params.collaboratorId"
          :collaborator="item"
        />
      </template>
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('collaborators.titles.changeRole') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">
      
      <v-container class="container--narrow pb-0">
        <loading v-if="loading" />

        <v-form
          v-if="item"
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="onSubmit"
        >

          <user-item
            :item="item"
            class="mb-2"
          />
    
          <div class="mb-4"><strong>{{ $t('collaborators.texts.selectRole') }}</strong></div>
          <role-field
            :item="item"
          />

        </v-form> 
      </v-container>

      <div
        v-if="item"
        class="l-vertical__sticky-footer--mobile"
      >
        <v-container class="container--narrow pt-0">

          <v-row>
            <v-col cols="6">
              <v-btn
                outlined
                block
                :to="{ name: 'collaborators-list', params: { episodeId: $route.params.episodeId } }"
                exact
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                class="primary"
                :disabled="!valid"
                :loading="saving"
                block
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>  

        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import DeleteDialog from './DeleteDialog';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import RoleField from '@/modules/collaborators/components/formFields/RoleField.vue';
import UserItem from '@/modules/user/components/UserItem';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    DeleteDialog,
    Loading,
    PageTabs,
    PageTitle,
    RoleField,
    UserItem
  },
  data() {
    return {
      item: null,
      loading: false,
      saving: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode'
    })
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    // load collaborator
    this.loading = true;
    this.getCollaborator({
      episodeId: this.$route.params.episodeId,
      userId: this.$route.params.collaboratorId
    })
    .then(response => {
      this.item = response.data.collaborator;
      this.loading = false;
    }) 
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getCollaborator: 'collaborators/getCollaborator',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
      updateCollaborator: 'collaborators/updateCollaborator',
      userHasContent: 'collaborators/userHasContent',
    }),
    async submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        email: this.item.email,
        role: this.item.rol
      }

      this.updateCollaborator({ 
        episodeId: this.episode.id,
        collaboratorId: this.item.id,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.$router.push({ name: 'collaborators-list', params: { 
          episodeId: this.episode.id
        } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>