<template>
  <div class="l-vertical__grow l-vertical">
    <page-title history-back>
      {{ $t('notifications.titles.notifications') }} 
      <template slot="buttons">
        <delete-dialog 
          v-if="notifications && notifications.length"
          :on-deleted="onDeleted"
        />
        
        <app-fab 
          add-episode
          add-activity
          add-files
          add-notes
        />
      </template>
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('notifications.titles.yourNotifications') }}
      </v-tab>
    </page-tabs>

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
      
      <v-container>

        <loading v-if="loading" />

        <div v-if="!loading && (!notifications || (notifications && !notifications.length))">
          <v-alert type="info">{{ $t('notifications.texts.noData') }}</v-alert>
        </div>

        <div v-if="!loading && notifications && notifications.length">

          <div
            v-for="item in notifications"
            :key="item.id"
          >
            <invited-to-episode
              v-if="item.type == NotificationTypes.invited_to_episode"
              :item="item"
              class="notifications__item"
            />
            <invited-to-episode-collaborators
              v-else-if="item.type == NotificationTypes.invited_to_episode_collaborators"
              :item="item"
              class="notifications__item"
            />
            <role-changed
              v-else-if="item.type == NotificationTypes.role_changed"
              :item="item"
              class="notifications__item"
            />
            <role-changed-collaborators
              v-else-if="item.type == NotificationTypes.role_changed_collaborators"
              :item="item"
              class="notifications__item"
            />
            <removed-from-episode
              v-else-if="item.type == NotificationTypes.removed_from_episode"
              :item="item"
              class="notifications__item"
            />
            <removed-from-episode-collaborators
              v-else-if="item.type == NotificationTypes.removed_from_episode_collaborators"
              :item="item"
              class="notifications__item"
            />
            <user-content-upload
              v-else-if="item.type == NotificationTypes.user_content_upload"
              :item="item"
              class="notifications__item"
            />
            <user-exit-episode
              v-else-if="item.type == NotificationTypes.user_exit_episode"
              :item="item"
              class="notifications__item"
            />
            <user-exit-app
              v-else-if="item.type == NotificationTypes.user_exit_app"
              :item="item"
              class="notifications__item"
            />
            <messages-in-chat
              v-else-if="item.type == NotificationTypes.messages_in_chat"
              :item="item"
              class="notifications__item"
            />
            <user-new-creator
              v-else-if="item.type == NotificationTypes.user_new_creator"
              :item="item"
              class="notifications__item"
            />
            
            <created-transfer
              v-else-if="item.type == NotificationTypes.created_transfer"
              :item="item"
              class="notifications__item"
            />
            <accepted-transfer
              v-else-if="item.type == NotificationTypes.accepted_transfer"
              :item="item"
              class="notifications__item"
            />
            <rejected-transfer
              v-else-if="item.type == NotificationTypes.rejected_transfer"
              :item="item"
              class="notifications__item"
            />
            <deleted-transfer
              v-else-if="item.type == NotificationTypes.deleted_transfer"
              :item="item"
              class="notifications__item"
            />

            <created-procedure
              v-else-if="item.type == NotificationTypes.created_procedure"
              :item="item"
              class="notifications__item"
            />
            <updated-procedure
              v-else-if="item.type == NotificationTypes.updated_procedure"
              :item="item"
              class="notifications__item"
            />
            <deleted-procedure
              v-else-if="item.type == NotificationTypes.deleted_procedure"
              :item="item"
              class="notifications__item"
            />
          </div>

        </div>

      </v-container>

    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AcceptedTransfer from './listItems/AcceptedTransfer.vue';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import CreatedProcedure from './listItems/CreatedProcedure.vue';
import CreatedTransfer from './listItems/CreatedTransfer.vue';
import DeleteDialog from './DeleteDialog.vue';
import DeletedProcedure from './listItems/DeletedProcedure.vue';
import DeletedTransfer from './listItems/DeletedTransfer.vue';
import InvitedToEpisode from './listItems/InvitedToEpisode.vue';
import InvitedToEpisodeCollaborators from './listItems/InvitedToEpisodeCollaborators.vue';
import Loading from '@/components/util/Loading';
import MessagesInChat from './listItems/MessagesInChat.vue';
import NotificationTypes from '@/modules/notifications/model/NotificationTypes.js';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from '@/components/layout/PageTitle.vue';
import RejectedTransfer from './listItems/RejectedTransfer.vue';
import RoleChanged from './listItems/RoleChanged.vue';
import RoleChangedCollaborators from './listItems/RoleChangedCollaborators.vue';
import RemovedFromEpisode from './listItems/RemovedFromEpisode.vue';
import RemovedFromEpisodeCollaborators from './listItems/RemovedFromEpisodeCollaborators.vue';
import statusTap from '@/mixins/statusTap.js';
import UserContentUpload from './listItems/UserContentUpload.vue';
import UpdatedProcedure from './listItems/UpdatedProcedure.vue';
import UserExitApp from './listItems/UserExitApp.vue';
import UserExitEpisode from './listItems/UserExitEpisode.vue';
import UserNewCreator from './listItems/UserNewCreator.vue';

export default {
  components: {
    AcceptedTransfer,
    AppFab,
    CreatedProcedure,
    CreatedTransfer,
    DeleteDialog,
    DeletedProcedure,
    DeletedTransfer,
    InvitedToEpisode,
    InvitedToEpisodeCollaborators,
    Loading,
    MessagesInChat,
    PageTabs,
    PageTitle,
    RejectedTransfer,
    RemovedFromEpisode,
    RemovedFromEpisodeCollaborators,
    RoleChanged,
    RoleChangedCollaborators,
    UpdatedProcedure,
    UserContentUpload,
    UserExitApp,
    UserExitEpisode,
    UserNewCreator
  },
  mixins: [statusTap],
  data() {
    return {
      loading: false,
      notifications: null,
      NotificationTypes: NotificationTypes
    };
  },
  watch: {
    '$route.params.refresh': function() {
      this.loadNotifications();
    }
  },
  created() {
    this.loadNotifications();
  },
  methods: {
    ...mapActions({
      getNotifications: 'notifications/getNotifications',
      getNotificationsCount: 'notifications/getNotificationsCount',
      markAsViewed: 'notifications/markAsViewed'
    }),
    loadNotifications() {
      this.loading = true;
      this.getNotifications(this.$route.params.id).then(
        async response => {
          this.notifications = response.data.notifications;
          this.loading = false;

          // mark notifications as read
          for (let i in this.notifications) {
            if (!this.notifications[i].viewed) {
              await this.markAsViewed(this.notifications[i].id);
            }
          }

          // reload counter
          this.getNotificationsCount();

          // clear ios application badge
          if (this.$helperFunctions.isIos()) {
            window.FirebasePlugin.setBadgeNumber(0);
          }
        }
      );
    },
    onDeleted() {
      this.notifications = null;
    }
  }
}
</script>

<style scoped>
.notifications__item {
  margin-bottom: 30px;
}
</style>
