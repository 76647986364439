<template>
  <div class="upload-data__list-header overline">
    <div 
      v-if="nested"
      class="upload-data__list-header-local"
    >{{ $t('dataUpload.titles.localFolder') }}</div>
    <div class="upload-data__list-header-remote">{{ $t('dataUpload.titles.episodeAndActivity') }}</div>
    <div class="upload-data__list-header-space">{{ $t('dataUpload.titles.estimatedSpace') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.upload-data__list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 6px;
}
.upload-data__list-header-local {
  width: calc(50%);
}
.upload-data__list-header-remote {
  padding-left: 8px;
  margin-right: auto;
}
.upload-data__list-header-space {
  margin-right: 32px;
}
</style>