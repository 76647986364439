import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    createProcedure(context, { episodeId, data }) {
      let formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      return axios.post(
        'api/episode/' + episodeId + '/procedure', 
        formData, 
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
    },
    deleteProcedure(context, {episodeId, procedureId}){
      return axios.delete('api/episode/' + episodeId + '/procedure/' + procedureId);
    },
    getProcedure(context, {episodeId, procedureId}){
      return axios.get('api/episode/' + episodeId + '/procedure/' + procedureId);
    },
    getProcedures(context, episodeId){
      return axios.get('api/episode/' + episodeId + '/procedures');
    },
    updateProcedure(context, { episodeId, procedureId, data }) {
      let formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      return axios.post(
        'api/episode/' + episodeId + '/procedure/' + procedureId, 
        formData, 
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
    },
  }
}
