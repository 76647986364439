<template>
  <v-dialog
    v-model="isOpen"
    max-width="450"
  >
    <v-container class="py-3 px-6">
      {{ item ? item.text : '' }}
    </v-container>

  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    }
  }
};
</script>
