<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="backTo">
      {{ $t('files.actions.addPhotosVideosTo') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('files.titles.selectEpisodeActivity') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll">
      <v-container class="container--narrow pb-0">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="next"
        >
          <form-fields-2
            :item="item"
          />
        </v-form>  

      </v-container>
    </div>

    <div class="fixed-bottom-buttons">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn 
              :to="backTo" 
              outlined
              block
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              :disabled="!valid"
              block
              @click="next"
            >
              {{ $t('actions.add') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
   
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields2 from '@/modules/files/components/FormFields2.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields2,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {
        activity: null,
        episode: null,
      },
      valid: true
    }
  },
  computed: {
    backTo() {
      return this.$route.params.episodeId 
        ? { 
          name: 'episode-files', 
          params: { episodeId: this.$route.params.episodeId } 
        }
      : { name: 'episodes-list' }
    }
  },
  methods: {
    ...mapGetters({
      getUploadData: 'files/getUploadData',
    }),    
    ...mapMutations({
      setUploadData: 'files/setUploadData',
    }),
    next() {
      if (!this.$refs.form.validate() || !this.item.episode || !this.item.activity) {
        return false;
      }

      this.$router.push({ name: 'file-upload-3', params: { 
        episodeId: this.item.episode.id,
        activityId: this.item.activity.id
      }} );
    }
  }
}
</script>

