<template>
  <div 
    v-if="item"
    class="activity-item py-2"
  >
    <img 
      v-if="item.id"
      v-lazy="imageUrl"
      class="activity-item__img"
      alt=""
    >
    <div class="activity-item__name">
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    imageUrl() {
      return (this.item && this.item.urlImageThumbnails) 
        ? this.item.urlImageThumbnails.small_ + '?bearer=' + this.apiToken 
        : 'img/placeholder/activity-list.png';
    }
  }
};
</script>

<style scoped>
.activity-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-item__img {
  width: 30px;
  height: calc(30px / var(--activity-aspect-ratio));
  margin: 0 12px 0 0;
  object-fit: cover;
  object-position: center;
  border-radius: 3px;
  background-color: var(--color-image-background);
}
.activity-item__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
}
</style>  