<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('contacts.titles.contacts') }}
      <template slot="buttons">
        <selection-toolbar
          ref="itemSelectionToolbar"
          :items="contacts"
        />

        <contacts-sort-menu @change="sortBy = $event" />
      </template>
    </page-title>
    
    <contacts-tabs />
    
    <progress-linear :loading="loading" />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <v-container class="container--narrow">

        <template v-if="contacts && contacts.length">
          <contact-list-item
            v-for="item in contactsSorted"
            :key="item.id"
            :item="item"
            :selection-enabled="$refs.itemSelectionToolbar && $refs.itemSelectionToolbar.selectionEnabled"
          />
        </template>

        <v-alert
          v-if="contacts && !contacts.length"
          text
          type="info"
        >
          {{ $t('contacts.texts.noElements') }}
        </v-alert>

      </v-container>

    </div>

    <menu-bottom />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContactListItem from '@/modules/contacts/components/ContactListItem.vue';
import ContactsSortMenu from '@/modules/contacts/components/ContactsSortMenu.vue';
import ContactsTabs from '@/modules/contacts/components/ContactsTabs.vue';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ProgressLinear from '@/components/util/ProgressLinear';
import SelectionToolbar from './SelectionToolbar/SelectionToolbar.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  name: 'ContactsListPage',
  components: {
    ContactListItem,
    ContactsSortMenu,
    ContactsTabs,
    MenuBottom,
    PageTitle,
    ProgressLinear,
    SelectionToolbar,
  },
  mixins: [statusTap],
  data() {
    return {
      contacts: null,
      loading: false,
      sortBy: 'alias'
    }
  }, 
  computed: {
    contactsSorted() {
      if (!this.contacts) {
        return [];
      }
      let contacts = [...this.contacts];
      switch (this.sortBy) {
        case 'alias':
          contacts.sort((a, b) => (a.alias || '').localeCompare(b.alias || ''));     
          break;        
        case 'name':
          contacts.sort((a, b) => (a.name + ' ' + a.lastname).localeCompare(b.name + ' ' + b.lastname));        
          break;       
        case 'email':
          contacts.sort((a, b) => (a.email || '').localeCompare(b.email || ''));       
          break;
      }
      return contacts;
    }
  },
  activated() {
    this.loadContacts();
  },
  methods: {
    ...mapActions({
      getContacts: 'contacts/getContacts'
    }),
    loadContacts() {
      this.loading = true;
      this.getContacts()
      .then(response => {
        // add reactive field "selected"
        for (let i in response.data.users) {
          response.data.users[i].selected = false;
        }
        this.contacts = response.data.users;
        this.loading = false;
      }) 
    }
  }
}
</script>