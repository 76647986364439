import axios from 'axios';

export default {
  namespaced: true,
  state: {
    cachedEpisode: null,
    cancelToken: null,
    newFilesCount: 0,
    newMessagesCount: 0
  },
  getters: {
    cachedEpisode(state) {
      return state.cachedEpisode;
    },
    newFilesCount(state) {
      return state.newFilesCount;
    },
    newMessagesCount(state) {
      return state.newMessagesCount;
    }
  },
  mutations: {
    setCachedEpisode: (state, episode) => {
      state.cachedEpisode = episode;
    }
  },
  actions: {
    getEpisodes() {
      return axios.get('/api/episodes');
    },
    getEpisode(context, id) {
      return axios.get('/api/episode/' + id);
    },
    loadEpisodeToCache({ state, dispatch }, id) {
      if (state.cachedEpisode && state.cachedEpisode.id != id) {
        state.cachedEpisode = null;
      }
      // return promise
      return new Promise((resolve, reject) => {
        dispatch('getEpisode', id)
        .then(response => {
          // save data to cache
          state.cachedEpisode = response.data.episode;
          resolve(response);
        })
        .catch(error => {
          state.cachedEpisode = null;
          reject(error);
        }) ;
      });
    },
    createEpisode(context, data) {
      let formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }

      return axios.post('/api/episode', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    },
    updateEpisode(context, { id, data }) {
      let formData = new FormData();
      for (const key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
      return axios.post('/api/episode/' + id, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    },
    deleteEpisode(context, id) {
      return axios.delete('/api/episode/' + id);
    },
    setWishList(context, id) {
      return axios.post('/api/episode/' + id + '/wish-list');
    },
    getWishListEpisodes() {
      return axios.get('/api/wish-list-episodes');
    },
    getFiles(context, { episodeId }) {
      return axios.get('/api/episode/' + episodeId + '/files');
    },
    setNewFilesCount({ state }, episodeId) {
      axios.get('/api/episode-access/' + episodeId + '/gallery').then(response => {
        state.newFilesCount = response.data;
      });
    },
    updateAccessFilesEpisode({ state }, episodeId) {
      state.newFilesCount = 0;
      axios.post('/api/episode-access/' + episodeId + '/gallery');
    },
    setNewMessagesCount({ state }, episodeId) {
      axios.get('/api/episode-access/' + episodeId + '/chat').then(response => {
        state.newMessagesCount = response.data;
      });
    },
    updateAccessMessagesEpisode({ state }, episodeId) {
      state.newMessagesCount = 0;
      axios.post('/api/episode-access/' + episodeId + '/chat');
    },
    getEpisodeStatistics(context, episodeId) {
      return axios.get('/api/statistics-episode/' + episodeId);
    },
    downloadZip(context, episodeId) {
      return axios.get('/api/episode/' + episodeId + '/download');
    },
    getFavoriteEpisodes() {
      return axios.get('/api/favorite-episodes');
    },
    setFavoriteEpisode(context, id) {
      return axios.post('/api/episode/' + id + '/favorite');
    },
    transferEpisode(context, { toUserId, episodeId, includeActivities }) {
      return axios.post('/api/transfer-episode', { toUserId, episodeId, includeActivities });
    },
  }
};
