<template>
  <v-select
    :value="value"
    :label="$t('form.label.language')"
    :items="languageOptions"
    item-value="id"
    item-text="name"
    hide-details
    v-bind="$attrs"
    @change="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    languageOptions() {
      let options = [];
      for (let i in this.$i18n.availableLocales) {
        let lang = this.$i18n.availableLocales[i];
        options.push({
          id: lang,
          name: this.$t('languages.' + lang)
        });
      }
      return options;
    }
  }
};
</script>