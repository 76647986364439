<template>
  <div class="l-vertical__scroll l-vertical"> 
    <v-container class="container--narrow">

      <v-form
        ref="form"
        :value="valid"
        class="mt-2"
        @input="$emit('update:valid', $event)"
        @submit.prevent
        @keyup.native.enter="submit"
      >

        <upload-image
          v-model="userData.avatarFile"
          class="mb-4"
          :circle="true"
          :text="$t('user.form.label.avatarFile')"
          icon="mdi-account-outline"
          :max-width="1024"
          :max-height="1024"
          :url-image="userData.urlAvatar ? ( userData.urlAvatar + '?bearer=' + apiToken ) : null"
        />

        <v-text-field
          v-model="userData.email"
          :label="$t('user.form.label.email')"
          type="text"
          disabled
        />
        <v-text-field
          v-model="userData.name"
          :label="$t('user.form.label.name') + '*'"
          type="text"
          :rules="[$validationRules.required]"
          maxlength="64"
        />
        <v-text-field
          v-model="userData.lastname"
          :label="$t('user.form.label.lastname') + '*'"
          type="text"
          :rules="[$validationRules.required]"
          maxlength="64"
        />
        <v-text-field
          v-model="userData.alias"
          :label="$t('user.form.label.alias') + '*'"
          type="text"
          :rules="[$validationRules.required]"
          maxlength="10"
        />

      </v-form>
    </v-container>

    <div class="l-vertical__sticky-footer--mobile">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn  
              outlined
              block
              :to="'/'"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              :disabled="!valid"
              :loading="saving"
              @click="submit"
            >{{ $t('actions.save') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UploadImage from "@/components/form/UploadImage/UploadImage.vue";

export default { 
  components: {
    UploadImage
  },
  props: {
    userData: {
      type: Object,
      default: null
    },
    valid: {
      type: Boolean,
      default: true
    },
    saving: {
      type: Boolean,
      default: false
    }
  }, 
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.$emit('submit');
    }
  }
}
</script>