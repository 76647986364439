<template>
  <div>
    <v-dialog
      v-model="isOpen"
      content-class="fullscreen-audio-recorder__dialog"
    >
      <p></p>
      <v-btn
        icon
        x-large
        :loading="loading"
        @click="close"
      >
        <v-icon
          size="70"
        >mdi-stop</v-icon>
      </v-btn>
      <p></p>
    </v-dialog>

    <audio 
      ref="player"
      :src="src"
      :autoplay="autoplay"
    />
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    playlist: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      playlistIndex: 0
    };
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    src() {
      return (this.playlist && this.playlist[this.playlistIndex])
        ? this.playlist[this.playlistIndex] + '?bearer=' + this.apiToken
        : null
    },
    autoplay() {
      return this.isOpen;
    }
  },
  watch: {
    isOpen(value) {
      if (!value) {
        this.$refs.player.pause();
      }
      else {
        this.loading = true;
      }
    }
  },
  mounted() {
    this.$refs.player.addEventListener("canplay", this.onCanplay);
    this.$refs.player.addEventListener("playing", this.onPlaying);
    this.$refs.player.addEventListener("ended", this.onEnded);
  },
  methods: {
    open() {
      this.isOpen = true;
      this.playlistIndex = 0;
      this.$refs.player.currentTime = 0;
      this.$refs.player.play();
    },
    close() {
      this.isOpen = false;
    },
    onCanplay() {
      if (this.isOpen) {
        this.$refs.player.play();       
      }
    },
    onPlaying() {
      this.loading = false;
    },
    onEnded() {
      // play next
      if (this.playlist.length > (this.playlistIndex + 1)) {
        ++this.playlistIndex;
      }
      // close
      else {
        this.close();
      }
    }
  }
}
</script>


<style>
.fullscreen-audio-recorder__dialog {
  background-color: rgba(0,0,0,0) !important;
  box-shadow: none;
  text-align: center;
}
</style>