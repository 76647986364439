import NotificationsListPage from '@/modules/notifications/pages/NotificationsListPage/NotificationsListPage.vue';

export default [
  {
    path: '/notifications/:refresh?',
    name: 'notifications-list',
    component: NotificationsListPage,
    meta: { requiresAuth: true }
  }
];
