<template>
  <nav 
    class="menu-items" 
    :class="{'menu-items--open': open}"
    @click="onMenuItemClick"
  >

    <router-link 
      :to="{ name: 'episodes-list' }"
      class="menu-items__logo-link"
    >Timlapp</router-link>
    
    <v-btn 
      v-if="open"
      class="menu-items__close" 
      icon 
      :title="$t('actions.menuClose')" 
      @click="onMenuItemClick"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <router-link  
      v-ripple
      :to="{ name: 'episodes-list' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-home-outline</v-icon>
      {{ $t('menu.home') }}
    </router-link>

    <router-link  
      v-ripple
      :to="{ name: 'user-configuration' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-cog-outline</v-icon>
      {{ $t('user.menu.configuration') }}
    </router-link>

    <router-link  
      v-ripple
      :to="{ name: 'user-profile' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-account-outline</v-icon>
      {{ $t('user.menu.profile') }}
    </router-link>

    <router-link  
      v-ripple
      :to="{ name: 'timeline' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-history</v-icon>
      {{ $t('timeline.menu.timeline') }}
    </router-link>

    <router-link  
      v-ripple
      :to="{ name: 'worldmap' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-map</v-icon>
      {{ $t('worldmap.menu.worldmap') }}
    </router-link>  

    <router-link  
      v-ripple
      :to="{ name: 'trash' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon error--text">mdi-trash-can-outline</v-icon>
      {{ $t('trash.menu.trash') }}
    </router-link> 

    <router-link  
      v-ripple
      :to="{ name: 'contact-list' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-account-group-outline</v-icon>
      {{ $t('contacts.menu.contacts') }}
    </router-link>

    <router-link  
      v-ripple
      :to="{ name: 'user-statistics' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-chart-bar</v-icon>
      {{ $t('user.titles.statistics') }}
    </router-link>   

    <router-link  
      v-ripple
      :to="{ name: 'transfers-list' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-account-arrow-left-outline</v-icon>
      {{ $t('transfers.menu.transfers') }}
    </router-link> 

    <router-link  
      v-ripple
      :to="{ name: 'subscription' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-currency-eur</v-icon>
      {{ $t('subscription.titles.subscription') }}
    </router-link>

    <router-link  
      v-ripple
      :to="{ name: 'melodies-list' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-music-note</v-icon>
      {{ $t('melodies.menu.melodies') }}
    </router-link>

    <router-link  
      v-if="user.isAdmin"
      v-ripple
      :to="{ name: 'data-upload-step-1' }" 
      class="menu-items__item"
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-folder-upload-outline</v-icon>
      {{ $t('dataUpload.menu.dataUpload') }}
    </router-link> 
    
    <router-link  
      v-ripple
      :to="{ name: 'faq' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-comment-question-outline</v-icon>
      {{ $t('menu.faq') }}
    </router-link> 

    <router-link  
      v-ripple
      :to="{ name: 'data-policy' }" 
      class="menu-items__item"
      @click="onMenuItemClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-script-text-outline</v-icon>
      {{ $t('dataPolicy.titles.title') }}
    </router-link>

    <a 
      v-ripple
      text 
      block 
      class="menu-items__item mb-2" 
      @click="logoutClick" 
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-location-exit</v-icon>
      {{ $t('user.menu.logout') }}
    </a>  

    <router-link  
      v-if="user.isAdmin"
      v-ripple
      :to="{ name: 'notice-list' }" 
      class="menu-items__item"
    >
      <v-icon class="menu-items__item-icon primary--text">mdi-message-reply-text-outline</v-icon>
      {{ $t('notices.menu.notices') }}
    </router-link> 

    <div class="menu-items__spacer"></div>

    <div class="overline small pt-2 grey--text text-center">© Timlapp 2019 · build {{ build }}</div>

  </nav>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      build: process.env.VUE_APP_BUILD
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    ...mapMutations({
      logout: 'user/logout',
      setBackToMenu: 'history/setBackToMenu',
    }),
    logoutClick() {
      this.logout();
      this.$router.push({ name: 'user-login' });
      this.close();
    },
    close() {
      this.$emit('update:open', false);
    },
    onMenuItemClick() {
      console.log('setBackToMenu true');
      this.setBackToMenu(true);
      this.close();
    }
  }
}
</script>

<style scoped>
.menu-items {
  position: absolute;  
  display: flex;
  flex-direction: column;
  align-items: center;  
  z-index: 100;
  width: 295px;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  visibility: hidden;
  transition: visibility 0s linear 0.25s, transform 0.25s ease-out;
  background: var(--color-background);
  padding: 50px 0 15px 0;
  overflow-y: auto;
}
.menu-items--open {
  visibility: visible;
  transform: translateX(0);
  transition-delay:0s;
}
.menu-items__item {
  display: block;
  width: 100%;
  padding: 10px 22px 10px 60px;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-text);
  transition: all ease-out 0.3s;
}
.menu-items__item-icon {
  margin-right: 12px;
  margin-top: -3px;
  margin-left: -41px  ;
}
/* on hover, select */
.menu-items__item:hover,
.menu-items__item:focus {
  background-color: var(--color-background--darker);
}
/* on press */
.menu-items__item:active {
	opacity: 0.8;
}
.menu-items__item--small {
  font-size: 16px;
  font-weight: 400;
}
.menu-items__spacer {
  flex-grow: 1;
}
.menu-items__item /deep/ a {
  color: inherit;
}
.menu-items__logo-link {
  position: absolute;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0;
}
.menu-items__logo-img {
  height: 24px;
}
.menu-items__close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 101;
}
</style>
