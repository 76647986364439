<template>
  <v-container class="page-title">

    <v-btn
      v-if="backTo || historyBack"
      class="page-title__back-button"
      icon
      :title="backTitle ? backTitle : $t('actions.back')"
      :to="to"
      @click="onClick"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <h1 class="page-title__h1 title height-lines-1">
      <slot></slot>
    </h1>
    <div 
      v-if="user"
      class="page-title__buttons"
    >
      <slot name="buttons"></slot>
    </div>

  </v-container>
</template>

<script>
import { eventBus } from '@/eventBus.js';
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    backTo: {
      type: Object,
      default: null
    },    
    backTitle: {
      type: String,
      default: ''
    },
    historyBack: {
      type: Boolean,
      default: false
    },
    backToRoutesNames: {
      type: Array,
      default: null
    },
    enableBackToMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      goBackToMenu: false
    }
  },
  computed: {
    ...mapGetters({
      backToMenu: 'history/backToMenu',
      lastRouteName: 'history/lastRouteName',
      user: 'user/user',
    }),
    to() {
      return (this.useHistory || this.goBackToMenu) ? null : this.backTo;
    },
    useHistory() {
      if (this.historyBack) {
        return true;
      }
      if (this.backToRoutesNames && this.backToRoutesNames.includes(this.lastRouteName)) {
        return true;
      }
      return false
    }
  },
  watch: {
    backToMenu() {
      if (!this._inactive) {
        this.onBackToMenuChange();
      }
    }
  },
  activated() {
    this.onBackToMenuChange();
  },
  methods: {
    ...mapMutations({
      setBackToMenu: 'history/setBackToMenu',
    }),
    onClick() {
      if (this.goBackToMenu) {
        eventBus.$emit('openMenu');
        return;
      }
      if (this.useHistory) {
        this.$router.back();
      }
    },
    onBackToMenuChange() {
      if (this.enableBackToMenu && this.backToMenu) {
        this.goBackToMenu = true;
        this.setBackToMenu(false);
      }
    }
  }
}
</script>

<style scoped>
.page-title {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1px;
  padding-right: 34px;
  transition: all 0.35s ease-out;
  height: 56px;
}
.page-title__back-button {
  margin-right: 6px;
  margin-bottom: -2px;
  margin-left: -8px;
}
.v-application .title {
  margin-bottom: 0;
  font-weight: normal !important;
}
.page-title__add-button {
  padding-left: 0 !important;
}
.page-title__buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

/* mobile */
@media (max-width: 1024px) {
  .page-title {
    height: 51px;
    padding-right: 1px;
  }
  .page-title__back-button {
    margin-right: 1px;
  }
  .v-application .title {
    font-size: 20px !important;
  }
  .page-title__add-button {
    display: none;
  }
}
</style>