<template>
  <v-checkbox
    v-model="filters.sharedEpisodes"
    :label="$t('search.titles.sharedEpisodes')"
    hide-details
    class="shared-episodes"
  />
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>
.shared-episodes {
  background-color: var(--color-background);
  padding: 9px 15px;
  border-radius: 21px;
  margin-top: 12px;
}
</style>