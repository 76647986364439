<template>
  <div 
    class="search-panel"
    :class="{ 'search-panel--selected': filters.title }"
  >
    <div class="body-2 search-panel__header">
      {{ $t('search.titles.filtersGroup3') }}
    </div>  

    <v-row>
      <v-col
        cols="6"
        class="py-0 pr-0"
      >
        <small>{{ $t('episodes.form.label.from') }}</small>

        <date-input
          v-model="filters.dateFrom"
          hide-details
          dense
          :class="$vuetify.breakpoint.xs ? 'mb-3' : ''"
          prepend
        />
      </v-col>
      <v-col
        cols="6"
        class="py-0 pl-0 date-to-column"
      >
        <small>{{ $t('episodes.form.label.to') }}</small>

        <date-input
          v-model="filters.dateTo"
          hide-details
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateInput from '@/components/form/DateInput';

export default {
  components: {
    DateInput
  },
  props: {
    filters: {
      type: Object,
      default: null
    }
  },
}
</script>

<style scoped>
/deep/ .v-text-field input {
  font-size: 14px;
}
/deep/ .v-input__slot:before {
  display: none !important;
}
.date-to-column {
  text-align: right;
}
.date-to-column /deep/ input {
  text-align: right;
}
</style>