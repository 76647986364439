<template>
  <audio 
    ref="player"
    :src="shared ? sharedSrc : src"
    preload="none"
    loop
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {  
  props: {
    melodyUrl: {
      type: String,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken',
      activity: 'activities/cachedActivity',
      episode: 'episodes/cachedEpisode',
      sharedActivity: 'shared/cachedActivity',
      sharedEpisode: 'shared/cachedEpisode'
    }),
    shared() {
      return this.$route.meta.shared;
    },
    src() {
      // play favorite files melody
      if (this.melodyUrl) {
        return this.melodyUrl + '?bearer=' + this.apiToken;
      }
      // play episode melody
      else if (this.$route.params.episodeId 
        && !this.$route.params.activityId 
        && this.episode 
        && this.episode.melody
      ) {
        return this.episode.melody.urlAudioFile + '?bearer=' + this.apiToken;
      }
      // play episode melody
      else if (this.$route.params.episodeId 
        && this.$route.params.activityId 
        && this.activity 
        && !this.activity.melody 
        && this.episode 
        && this.episode.melody
      ) {
        return this.episode.melody.urlAudioFile + '?bearer=' + this.apiToken;
      }
      // play activity melody
      else if (this.$route.params.activityId 
        && this.activity 
        && this.activity.melody
      ) {
        return this.activity.melody.urlAudioFile + '?bearer=' + this.apiToken;
      }
      return null;
    },
    sharedSrc() {
      // play episode melody
      if (this.$route.params.episodeId 
        && !this.$route.params.activityId
        && this.sharedEpisode 
        && this.sharedEpisode.melody
      ) {
        return this.sharedEpisode.melody.urlAudioFile + '?hash=' + this.$route.query.hash;
      }
      // play episode melody
      else if (this.$route.params.episodeId 
        && this.$route.params.activityId
        && this.sharedActivity 
        && !this.sharedActivity.melody 
        && this.sharedEpisode 
        && this.sharedEpisode.melody
      ) {
        return this.sharedEpisode.melody.urlAudioFile + '?hash=' + this.$route.query.hash;
      }
      // play activity melody
      else if (this.$route.params.activityId
        && this.sharedActivity 
        && this.sharedActivity.melody
      ) {
        return this.sharedActivity.melody.urlAudioFile + '?hash=' + this.$route.query.hash;
      }
      return null;
    }
  },
  methods: {
    play() {
      try {
        this.$refs.player.play();
      } catch (error) {
        console.log(error);
      }
    },
    pause() {
      this.$refs.player.pause();
    }
  }
}
</script>