<template>
  <div>
    <v-list-item @click="onButtonClick">
      <v-icon class="mr-3 primary--text">mdi-pencil-outline</v-icon> 
      {{ $t('actions.edit') }}
    </v-list-item>

    <v-dialog
      v-model="open"
      width="450"
    >
      <v-container class="px-6">
        <div class="mb-4 subtitle-1">{{ $t('melodies.dialogs.editMultiple.title') }}</div>

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >
          <v-text-field
            v-model="item.author"
            :label="$t('melodies.form.label.author')"
            :placeholder="$t('melodies.form.placeholder.author')"
            persistent-placeholder
            type="text"
            maxlength="255"
          />

          <v-text-field
            v-model="item.type"
            :label="$t('melodies.form.label.type')"
            :placeholder="$t('melodies.form.placeholder.type')"
            persistent-placeholder
            type="text"
            maxlength="255"
          />
          
          
        </v-form> 
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn 
              class="primary"
              :disabled="!item.author && !item.type"
              block
              small
              :loading="saving"
              @click="submit"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {  
    selectedItems: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      item: {},
      loading: false,
      open: false,
      saving: false,
      valid: false
    };
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash'
    }),
    ...mapActions({
      updateMelodies: 'melodies/updateMelodies'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      this.updateMelodies({
        ids: this.selectedItems.map(item => item.id),
        type: this.item.type ? this.item.type : undefined,
        author: this.item.author ? this.item.author : undefined
      })
      .then(response => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.open = false;
        this.$emit('success');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
    },
    onButtonClick() {
      setTimeout(() => {
        this.open = true;
      }, 0);
    }
  }
};
</script>
