<template>
  <page-tabs>
    <v-tab 
      :to="{ name: 'episodes-list'}"
      exact
    >
      {{ $t('episodes.titles.tabMyEpisodes') }}
    </v-tab>

    <v-tab 
      :to="{ name: 'favorites'}"
      exact
    >
      {{ $t('episodes.titles.tabFavorites') }}
    </v-tab>

    <v-tab 
      :to="{ name: 'episodes-wishlist' }"
      exact
    >
      {{ $t('episodes.titles.tabWishList') }}
    </v-tab>   
  </page-tabs>
</template>

<script>
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';

export default {
  components: {
    PageTabs
  }
}
</script>