<template>
  <v-expansion-panel 
    class="expansion-panel"
    :class="{ 'expansion-panel--selected': filters.activityGroup }"
  >
    <v-expansion-panel-header class="body-2 ">
      {{ $t('search.titles.filtersGroup2') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content eager>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="py-0"
        >
          <activity-group-field 
            :filters="filters" 
            :class="$vuetify.breakpoint.xs ? 'mb-3' : ''"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="py-0"
        >
          <activity-type-field 
            :filters="filters" 
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ActivityGroupField from './formFields/ActivityGroupField.vue';
import ActivityTypeField from './formFields/ActivityTypeField.vue';

export default {
  components: {
    ActivityGroupField,
    ActivityTypeField,
  },
  props: {
    filters: {
      type: Object,
      default: null
    }
  }
}
</script>