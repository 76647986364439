<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="backTo">
      {{ $t('files.actions.addPhotosVideos') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('files.titles.information') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll">
      <v-container class="container--narrow pb-0">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="next"
        >
          <form-fields
            :item="item"
          />
        </v-form>  

      </v-container>

      <v-container class="py-0">
        <div
          v-if="files && files.length" 
          class="small mb-1"
        >
          {{ $tc('texts.numberOfFilesSelected', files.length, { number: files.length }) }}
        </div>
      </v-container>

      <v-container 
        class="py-0"
        :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
      > 
        <v-row 
          v-if="files && files.length" 
          no-gutters
          class="file-list__row"
        >
          <v-col
            v-for="file in files" 
            :key="file.id"
            :cols="cols"
          >
            <upload-file-list-item 
              :file="file"
              @remove="removeFile(file)"
            />
          </v-col>
        </v-row>

      </v-container>
    </div>

    <div class="fixed-bottom-buttons">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn 
              outlined
              block
              class="v-btn--outlined--background"
              @click="onCancel"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              :disabled="!valid || !files || !files.length || loadingSpace || errorTooManyFiles"
              :loading="loadingSpace"
              block
              @click="next"
            >
              {{ $route.params.activityId ? $t('actions.add') : $t('actions.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <episode-add-modal 
      ref="episodeAddModal"
      @choose-existing="goToStep2"
      @episode-created="onEpisodeCreated($event)"
    />

    <too-many-files-dialog
      ref="tooManyFilesDialog"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EpisodeAddModal from '@/modules/episodes/components/EpisodeAddModal/EpisodeAddModal.vue';
import FilePrivacy from '@/modules/files/model/FilePrivacy';
import FormFields from '@/modules/files/components/FormFields.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import SpaceLimit from '@/modules/user/model/SpaceLimit';
import TooManyFilesDialog from './TooManyFilesDialog.vue';
import UploadFileListItem from '@/modules/files/components/UploadFileListItem';
import UploadParameters from '@/modules/files/model/UploadParameters.js';

export default {
  components: {
    EpisodeAddModal,
    FormFields,
    PageTabs,
    PageTitle,
    TooManyFilesDialog,
    UploadFileListItem,
  },
  data() {
    return {
      errorTooManyFiles: false,
      item: {
        privacy: FilePrivacy.VISIBLE,
        date: this.$moment().format('YYYY-MM-DD'),
        time: this.$moment().format('kk:mm'),
      },
      loading: false,
      loadingSpace: false,
      statistics: {},
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      files: 'files/getUploadFiles',
    }), 
    cols() {    
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 2;
      }
      if (this.$vuetify.breakpoint.sm) {
        return 3;
      }        
      if (this.files && this.files.length) {
        switch (this.files.length) {
          case 1:
          case 2:
            return 6;
        }
      }
      return 4;
    },
    backTo() {
      return this.$route.params.episodeId 
        ? this.$route.params.activityId 
          ? { 
            name: 'activity-files', 
            params: { episodeId: this.$route.params.episodeId, activityId: this.$route.params.activityId } 
          }
        : { 
            name: 'episode-files', 
            params: { episodeId: this.$route.params.episodeId } 
          }
      : { name: 'episodes-list' }
    },
    freeSpace() {
      let totalSpace = SpaceLimit * 1000 * 1000 * 1000; // convert from GB to B
      return totalSpace 
        - this.statistics.space_pictures 
        - this.statistics.space_videos
        - this.statistics.space_soft_deleted;
    },
  },  created() {
    // load activity
    if (this.$route.params.episodeId && this.$route.params.activityId) {
      this.loading = true;
      this.getActivity({
        episodeId: this.$route.params.episodeId,
        activityId: this.$route.params.activityId
      })
      .then(response => {
        // if (response.data.activity.date) {
        //   this.item.date = this.$helperFunctions.fixApiDate(response.data.activity.date);
        //   this.item.time = this.$helperFunctions.fixApiTime(response.data.activity.time);
        // }
        this.loading = false;
      })
    }

    // load available space
    this.loadingSpace = true;
    this.getStatistics(this.user.id)
    .then(response => {
      this.statistics = response.data.statistics;
      this.loadingSpace = false;
    });
  },
  methods: {  
    ...mapMutations({
      addFlash: 'addFlash',
      setUploadData: 'files/setUploadData',
      setUploadFiles: 'files/setUploadFiles',
    }),
    ...mapActions({
      getActivity: 'activities/getActivity',
      uploadFile: 'files/uploadFile',
      getStatistics: 'user/getStatistics',
    }),
    removeFile(file) {
      let files = this.files;
      let index = files.findIndex(item => item.id == file.id);
      files.splice(index, 1);
      this.setUploadFiles(files);
    },
    next() {
      if (!this.$refs.form.validate()) {
        return;
      }

      // validate max number of files
      if (this.files.length > UploadParameters.MAX_FILES_NUMBER) {
        this.$refs.tooManyFilesDialog.open();
        return;
      }

      // verify if user has enough space to upload the files
      let totalFilesSize = 0;
      this.files.forEach(file => {
        totalFilesSize += file.size;
      });
      if (this.freeSpace < totalFilesSize) {
        this.addFlash({
          text: this.$t('files.messages.error.upload.notEnoughSpace'),
          type: 'error'
        });
        return;
      }

      this.setUploadFiles(this.files);

      const data = {
        privacy: this.item.privacy,
        date: this.item.date,
        time: this.item.time,
        description: this.item.description
      }

      this.setUploadData(data);

      this.$refs.episodeAddModal.open();
    },
    goToStep2() {
      this.$router.push({ name: 'file-upload-2', params: { episodeId: this.$route.params.episodeId, activityId: this.$route.params.activityId }} );
    },
    onEpisodeCreated($event) {
      this.$router.push({ name: 'file-upload-3', params: { 
        episodeId: $event.episodeId,
        activityId: $event.activityId
      }} );
    },
    onCancel() {
      this.setUploadFiles(null);
      this.$router.push(this.backTo);
    }
  }
}
</script>

<style scoped>
.file-list__row {
  margin-left: -1px;
  margin-right: -1px;
}
</style>
