<template>
  <v-dialog 
    v-model="open" 
    width="450"
  >
    <v-container class="py-3 px-6">
      <div class="subtitle-1 text-center mt-3 mb-4">{{ $t('melodies.dialogs.assign.title') }}</div>    

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >
        <form-fields
          :item="item"
        />
      </v-form> 
    
    </v-container>

    <hr>


    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="$emit('close')"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            class="primary"
            :loading="saving"
            :disabled="!valid"
            block
            small
            @click="submit"
          >{{ $t('actions.assign') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from './FormFields.vue';

export default {
  components: {
    FormFields
  },
  props: {
    itemId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      item: {},
      open: false,
      saving: false,
      valid: false
    };
  },
  watch: {
    itemId(itemId) {
      this.open = !!itemId;
    },
    open(open) {
      if (!open) {
        this.$emit('close');
      }
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      assignToActivity: 'melodies/assignToActivity',
      assignToEpisode: 'melodies/assignToEpisode'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      // assign to activity
      if (this.item.activity.id) {
        this.assignToActivity({
          melodyId: this.itemId,
          activityId: this.item.activity.id
        })
        .then(this.onSuccess)
        .catch(this.onError);
      }

      // assign to episode
      else {
        this.assignToEpisode({
          melodyId: this.itemId,
          episodeId: this.item.episode.id
        })
        .then(this.onSuccess)
        .catch(this.onError);
      }

    },
    onSuccess() {
      this.addFlash({
        text: this.$t("melodies.messages.success.assigned"),
        type: 'success'
      });
      this.$emit('close');
      this.saving = false;
    },
    onError(error) {
      this.addFlash({
        text: this.$helperFunctions.errorToString(error),
        type: 'error'
      });
      this.saving = false;
    }
  }
};
</script>
