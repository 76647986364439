<template>
  <div class="l-vertical__grow l-vertical">
    <page-title history-back>
      {{ $t('transfers.titles.transfer') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('transfers.titles.indicateProtagonist') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll">
      <v-container class="container--narrow pb-0">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >
          <to-user-field
            :item="item"
            :episode-id="$route.params.episodeId"
          />
        </v-form>  

      </v-container>

      <v-container class="py-0">
        <div
          v-if="files && files.length" 
          class="small mb-1"
        >
          {{ $tc('texts.numberOfFilesSelected', files.length, { number: files.length }) }}
        </div>
      </v-container>

      <v-container 
        class="py-0"
        :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
      > 
        <v-row 
          v-if="files && files.length" 
          no-gutters
          class="file-list__row"
        >
          <v-col
            v-for="file in files" 
            :key="file.id"
            :cols="cols"
          >
            <file-list-item 
              :file="file" 
              disable-click
            />
          </v-col>
        </v-row>

      </v-container>
    </div>

    <div class="fixed-bottom-buttons">
      <v-container class="container--narrow pt-0">
        <v-row>
          <v-col cols="6">
            <v-btn 
              outlined
              block
              @click="$router.back()"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn 
              class="primary"
              :disabled="!valid || !files || !files.length"
              block
              :loading="saving"
              @click="submit"
            >
              {{ $t('actions.send') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
   
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FileListItem from '@/modules/files/components/FileListItem/FileListItem.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import ToUserField from '@/modules/transfers/components/form/ToUserField.vue';

export default {
  components: {
    FileListItem,
    PageTabs,
    PageTitle,
    ToUserField
  },
  data() {
    return {
      item: {},
      loading: false,
      saving: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      files: 'files/getSelectedFiles'
    }),  
    cols() {    
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 2;
      }
      if (this.$vuetify.breakpoint.sm) {
        return 3;
      }        
      if (this.files && this.files.length) {
        switch (this.files.length) {
          case 1:
          case 2:
            return 6;
        }
      }
      return 4;
    },
    filesIds() {
      return this.files.map(file => file.id);
    },
  },
  methods: {  
    ...mapMutations({
      addFlash: 'addFlash',
      setSelectedFiles: 'files/setSelectedFiles'
    }),
    ...mapActions({
      createTransfer: 'transfers/createTransfer'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      this.createTransfer({
        toUser: this.item.toUser,
        files: this.filesIds
      })
      .then(response => {
        this.addFlash({
          text: this.$t("transfers.messages.success.sent"),
          type: 'success'
        });
        this.setSelectedFiles(null);
        this.$router.back();
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
    }
  }
}
</script>

<style scoped>
.file-list__row {
  margin-left: -1px;
  margin-right: -1px;
}
</style>
