import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getHistoryActivities() {
      return axios.get('/api/history-activities');
    },
    getHistoryActivitiesWish() {
      return axios.get('/api/history-activities/wish');
    },
  },
}
