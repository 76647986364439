<template>
  <div v-if="isOwner">
    <v-list-item @click="onButtonClick">
      <v-icon class="mr-3 primary--text">mdi-send</v-icon> 
      {{ $t('actions.transfer') }}
    </v-list-item>

    <v-dialog
      v-model="open"
      width="450"
    >

      <v-container class="px-6">

        <loading 
          v-if="loading && !errorMessage" 
          height="100"
        />

        <template v-if="errorMessage">

          <v-alert
            type="info"
            class="mt-3 mb-5"
          >
            {{ errorMessage }}
          </v-alert>

          <v-btn
            class="primary mb-2"
            block
            small
            @click="close"
          >{{ $t('actions.ok') }}</v-btn>

        </template>


        <template v-if="!loading && !errorMessage">
          <div class="mb-1"><strong>{{ $t('episodes.dialogs.transfer.title') }}</strong></div>

          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
            @keyup.native.enter="submit"
          >
            <user-field
              :item="item"
              :collaborators="collaborators"
              :rules="[$validationRules.required]"
            />

            <v-checkbox
              v-model="item.includeActivities"
              :label="$t('episodes.dialogs.transfer.includeActivities')"
              hide-details
              class="include-activities mb-4"
            />
          </v-form> 

          <hr class="mb-4">

          <v-row>
            <v-col cols="6">
              <v-btn
                outlined
                block
                small
                @click="open = false"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn 
                class="primary"
                :disabled="!valid"
                block
                small
                :loading="saving"
                @click="submit"
              >
                {{ $t('actions.save') }}
              </v-btn>
            </v-col>
          </v-row>

        </template>

      </v-container>

    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Loading from '@/components/util/Loading';
import UserField from './UserField.vue';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    Loading,
    UserField,
  },
  props: {
    episode: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      collaborators: null,
      errorMessage: null,
      item: {
        includeActivities: true
      },
      loading: false,
      open: false,
      saving: false,
      valid: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    isOwner() {
      return this.episode && this.episode.user && (this.episode.user.id == this.user.id);
    },
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash'
    }),
    ...mapActions({
      getCollaborators: 'collaborators/getCollaborators',
      transferEpisode: 'episodes/transferEpisode',
    }),
    onButtonClick() {
      setTimeout(() => {
        this.open = true;
        if (!this.errorMessage) {
          this.loadCollaborators();
        }
      }, 0);
    },
    loadCollaborators() {
      this.loading = true;
      this.getCollaborators(this.episode.id)
      .then(response => {
        this.collaborators = response.data.collaborators.filter(item => {
          return (item.id != this.user.id) && (item.rol != roles.COMMENTATOR);
        });
        if (this.collaborators.length == 0) {
          this.errorMessage = this.$t('episodes.messages.info.noCollaborators');
        }
        this.loading = false;
      });
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      this.transferEpisode({
        toUserId: this.item.user.id,
        episodeId: this.episode.id,
        includeActivities: this.item.includeActivities
      })
      .then(response => {
        this.addFlash({
          text: this.$t("episodes.messages.success.transferred"),
          type: 'success'
        });
        this.$emit('success');
        this.open = false;
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
    },
    close() {
      this.open = false;
    },
  }
};
</script>

<style scoped>
.include-activities /deep/ .v-input__slot {
  flex-direction: row-reverse;
}
.include-activities /deep/ .v-input--selection-controls__input {
  margin-left: 8px;
  margin-right: 0;
}
</style>