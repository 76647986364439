<template>
  <div class="l-vertical__grow l-vertical">
    <page-title history-back>
      {{ $t('notes.titles.blog') }}
    </page-title>
    
    <page-tabs>
      <v-tab>{{ $t('notes.titles.createAudio') }}</v-tab>
    </page-tabs> 

    <step-1
      v-if="step == 1"
      :item="item"
      :file-url="fileUrl"
      :saving="saving"
      @submit="submit"
      @go-to-step-2="step = 2"
    />

    <step-2
      v-if="step == 2"
      :item="item"
      :saving="saving"
      @submit="submit"
    />   

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import NotePrivacy from '@/modules/notes/model/NotePrivacy.js';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';

export default {
  components: {
    PageTabs,
    PageTitle,
    Step1,
    Step2
  },
  data() {
    return {
      file: null,
      fileUrl: null,
      item: {
        date: this.$moment().format('YYYY-MM-DD'),
        time: this.$moment().format('kk:mm'),
        privacy: NotePrivacy.VISIBLE,
        text: null,
        activity: this.$route.params.activityId ? { id: this.$route.params.activityId } : null,
        episode: this.$route.params.episodeId ? { id: this.$route.params.episodeId } : null,
      },
      saving: false,
      step: 1,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      uploadFile: 'notes/getUploadFile',
    }),
  },
  created() {
    // get uploaded file from vuex
    if (this.uploadFile) {
      this.file = this.uploadFile.file;
      this.fileUrl = URL.createObjectURL(this.uploadFile.file)
    }
    // read recorded local file
    else if (this.$helperFunctions.isCordova()) {
      window.requestFileSystem(LocalFileSystem.TEMPORARY, 0, fs => {
        console.log('file system open: ' + fs.name);
        fs.root.getFile(this.$route.query.filename, { create: false, exclusive: false }, fileEntry => {
          fileEntry.file(file => {
            var reader = new FileReader();
            reader.onloadend = evt => {
              // Create a blob based on the FileReader "result", which we asked to be retrieved as an ArrayBuffer
              this.file = new Blob([new Uint8Array(evt.target.result)], { type: "audio/m4a" });
              this.file.name = this.filename;
              this.file.lastModifiedDate = new Date();
            };
            // Read the file as an ArrayBuffer
            reader.readAsArrayBuffer(file);
          }, function (err) { console.error('error getting fileentry file!', err); });
          this.fileUrl = fileEntry.toURL();
        }, function (err) { console.error('error getting file! ', err); });
      }, function (err) { console.error('error getting persistent fs! ', err); });
    }
    // clear uploaded images
    this.setUploadFiles(null);
  },
  methods: {
    ...mapMutations({
      addFlash: 'addFlash',
      setUploadFile: 'notes/setUploadFile',
      setUploadFiles: 'files/setUploadFiles',
    }),
    ...mapActions({
      createNote: 'notes/createNote',
      createNotificationContentUploadNote: 'notifications/createNotificationContentUploadNote',
    }),
    submit() {
      if (!this.item.episode.id || !this.item.activity.id) {
        return;
      }
      
      this.saving = true;

      const data = {
        title: this.item.title,
        text: '.',
        date: this.item.date,
        time: this.item.time,
        privacy: this.item.privacy,
        audioFile: this.file
      }

      this.createNote({
        activityId: this.item.activity.id,
        data: data
      })
      .then(response => {
        this.setUploadFile(null);
        this.addFlash({
          text: this.$t("notes.messages.success.added"),
          type: 'success'
        });

        // create notification
        if (this.item.privacy == NotePrivacy.VISIBLE) {
          this.createNotificationContentUploadNote({ noteId: response.data.note.id });
        }

        this.$router.push({ name: 'activity-notes', params: {
          episodeId: this.item.episode.id,
          activityId: this.item.activity.id
        } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
