import axios from 'axios';

export default {
  namespaced: true,
  state: {
    selectedNotes: null,
    uploadFile: null
  },
  getters: {
    getSelectedNotes: state => {
      return state.selectedNotes;
    },
    getUploadFile: state => {
      return state.uploadFile;
    }
  },
  mutations: {
    setSelectedNotes: (state, notes) => {
      state.selectedNotes = notes;
    },
    setUploadFile: (state, uploadFile) => {
      state.uploadFile = uploadFile;
    }
  },
  actions: {
    deleteNote(context, {activityId, noteId}){
      return axios.delete('api/activity/' + activityId + '/note/' + noteId);
    },
    deleteNotes(context, notesIds) {
      return axios.delete('/api/multiple-notes', { data: { notes: notesIds } });
    },
    getNote(context, {activityId, noteId}){
      return axios.get('api/activity/' + activityId + '/note/' + noteId);
    },
    getActivityNotes(context, activityId){
      return axios.get('api/activity/' + activityId + '/notes');
    },
    getEpisodeNotes(context, episodeId){
      return axios.get('api/episode/' + episodeId + '/notes');
    },
    createNote(context, { activityId, data }){
      let formData = new FormData();
      for(const key in data){
          if(data[key]){
              formData.append(key, data[key]);
          }
      }

      return axios.post('/api/activity/' + activityId + '/note', formData, {
          headers: { 'Content-Type': 'multipart/form-data'}
      });
    },
    updateNote(context, { activityId, noteId, data }){
      let formData = new FormData();
      for(const key in data){
          if(data[key]){
              formData.append(key, data[key]);
          }
      }

      return axios.post('/api/activity/' + activityId + '/note/' + noteId, formData, {
          headers: { 'Content-Type': 'multipart/form-data'}
      });
    },
    updateNotes(context, { notesIds, data }) {
      return axios.post('api/multiple-notes', { notes: notesIds, data });
    },
    downloadNotes(context, notesIds) {
      return axios.post('/api/download-notes', { ids: notesIds });
    },   
    getFavoriteNotes() {
      return axios.get('/api/favorite-notes');
    }, 
    setFavoriteNote(context, id) {
      return axios.post('/api/note/' + id + '/favorite');
    },
  }
}
