<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'contact-groups-list'}">
      {{ $t('contacts.titles.contacts') }}
      <template slot="buttons">
        <contact-selection-toolbar
          ref="itemSelectionToolbar"
          :items="group ? group.contacts : null"
          @success="loadGroup"
        />

        <contacts-sort-menu @change="sortBy = $event" />
      </template>
    </page-title>
    
    <contacts-tabs />

    <loading v-if="loading" />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
      <v-container 
        v-if="!loading && group"
        class="container--narrow pb-0"
      >

        <div class="d-flex mb-4">
          <div class="flex-grow-0 mr-3">
            <group-thumbnail 
              :src="group.urlFilename" 
              class="group-item__thumbnail" 
            />
          </div>

          <div class="flex-grow-1 mr-2">
            <div class="mb-1">{{ group.title }}</div>      
            <div class="group-item__description small">
              <collapse-text>{{ group.description }}</collapse-text>   
            </div>
          </div>

          <actions-menu 
            :id="group.id"
            class="flex-grow-0"
          />
        </div>
    

        <div class="overline mb-2">{{ $t('contacts.titles.contacts') }}</div>

        <contact-list-item
          v-for="item in contactsSorted"
          :key="item.id"
          :item="item"
          :selection-enabled="$refs.itemSelectionToolbar && $refs.itemSelectionToolbar.selectionEnabled"
        />

        <v-alert
          v-if="!group.contacts.length"
          text
          type="info"
        >
          {{ $t('contacts.texts.noContactsInGroup') }}
        </v-alert>

      </v-container>

    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';
import CollapseText from '@/components/util/CollapseText';
import ContactListItem from '@/modules/contacts/components/ContactListItem.vue';
import ContactSelectionToolbar from './ContactSelectionToolbar/ContactSelectionToolbar.vue';
import ContactsSortMenu from '@/modules/contacts/components/ContactsSortMenu.vue';
import ContactsTabs from '@/modules/contacts/components/ContactsTabs.vue';
import GroupItem from '@/modules/contacts/components/GroupItem/GroupItem.vue';
import GroupThumbnail from '@/modules/contacts/components/GroupThumbnail.vue';
import Loading from '@/components/util/Loading';
import PageTitle from "@/components/layout/PageTitle.vue";
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    ActionsMenu,
    CollapseText,
    ContactListItem,
    ContactSelectionToolbar,
    ContactsSortMenu,
    ContactsTabs,
    GroupItem,
    GroupThumbnail,
    Loading,
    PageTitle
  },
  mixins: [statusTap],
  data() {
    return {
      group: null,
      loading: false,
      sortBy: 'alias'
    }
  }, 
  computed: {
    contactsSorted() {
      if (!this.group || !this.group.contacts) {
        return [];
      }
      let contacts = [...this.group.contacts];
      switch (this.sortBy) {
        case 'alias':
          contacts.sort((a, b) => (a.alias || '').localeCompare(b.alias || ''));     
          break;        
        case 'name':
          contacts.sort((a, b) => (a.name + ' ' + a.lastname).localeCompare(b.name + ' ' + b.lastname));        
          break;       
        case 'email':
          contacts.sort((a, b) => (a.email || '').localeCompare(b.email || ''));       
          break;
      }
      return contacts;
    }
  },
  created() {
    this.loadGroup();
  },
  methods: {
    ...mapActions({
      getGroup: 'contacts/getGroup'
    }),
    loadGroup() {
      this.loading = true;
      this.getGroup(this.$route.params.id)
      .then(response => {
        // add reactive field "selected"
        for (let i in response.data.group.contacts) {
          response.data.group.contacts[i].selected = false;
        }
        this.group = response.data.group;
        this.loading = false;
      })
    }
  }
}
</script>
