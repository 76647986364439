<template>
  <div 
    class="loading"
    :style="{ height: height + 'px' }"
  >
    <v-progress-circular
      indeterminate
      :color="color"
      :title="alt ? alt : $t('messages.loading')"
    />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    height: {
      type: String,
      default: '210'
    },
    alt: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
  },
};
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
}
.loading__image {
  min-height: 60px;
  max-height: 180px;
}
  
/* mobile */
@media (max-width: 1024px), (max-height: 1024px) {
  .loading__image {
    max-height: 120px;
  }
}
</style>