<template>
  <v-dialog
    v-model="isOpen"
    width="450"
  >
    <v-container class="py-3 px-6">
      <div class="subtitle-1 mt-2 mb-6">{{ $t('episodeAccesses.dialogs.add.title') }}</div>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
        @keyup.native.enter="submit"
      >

        <v-text-field
          v-model="email"
          :label="$t('user.form.label.email')"
          :rules="[$validationRules.required, $validationRules.email]"
          type="email"
          maxlength="128"
          outlined
          dense
          class="v-input--darker"
          validate-on-blur
        />

      </v-form>

    </v-container>

    <hr>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn
            outlined
            block
            small
            @click="isOpen = false"
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            class="primary"
            block
            small
            :disabled="!email"
            :loading="saving"
            @click="submit"
          >{{ $t('actions.share') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      email: null,
      isOpen: false,
      saving: false,
      valid: false
    };
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      createAccess: 'episodeAccesses/createAccess',
    }),
    open() {
      this.isOpen = true;
      this.email = null;
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      this.createAccess({ 
        episodeId: this.$route.params.episodeId,
        email: this.email
      })
      .then(response => {
        this.addFlash({
          text: this.$t("episodeAccesses.messages.success.created"),
          type: 'success'
        });
        this.$emit('saved');
        this.isOpen = false;
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>
