<template>
  <div class="expenses-summary">
    <list-item
      v-for="item in groupsFiltered"
      :key="item.id"
      :item="item"
    />

    <list-item
      :item="proceduresItem"
    />

    <hr class="mb-5">

    <list-item
      :item="totalItem"
      class="expenses-summary__total"
    />   

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ListItem from './ListItem.vue';

export default {
  components: {
    ListItem,
  },
  props: {
    expenses: {
      type: Array,
      default: null
    },
    groups: {
      type: Array,
      default: null
    },
    procedures: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      groupsFiltered: null,
      proceduresItem: {
        name: this.$t('procedures.titles.procedures'),
        icon: 'mdi-calendar-check',
        amount: 0,
        errorVariousCurrencies: false,
      },
      totalItem: {
        name: this.$t('expenses.titles.totalExpenses'),
        amount: 0,
        errorVariousCurrencies: false,
      },
    }
  },
  created() {

    // filter out default group
    let defaultGroup = this.groups.find(item => item.slug == 'none');
    let groups = this.groups.filter(item => item.id != defaultGroup.id);
    // sort groups by name
    groups.sort((a, b) => (a.name || '').toString().localeCompare(b.name || '')); 
    this.groupsFiltered = groups;

    // calculate group total
    this.groupsFiltered.forEach(group => {
      group.amount = 0;
      let expenses = this.expenses.filter(item => item.activityGroup.id == group.id);
      for (let i in expenses) {
        let expense = expenses[i];
        if (i == 0) {
          group.currency = expense.currency;
        }
        if (expense.currency == group.currency) {
          group.amount += Number(expense.amount);
        }
        else {
          group.errorVariousCurrencies = true;
          group.amount = 0;
          break;
        }
      }
    });

    // calculate procedures total
    for (let i in this.procedures) {
      let procedure = this.procedures[i];
      if (i == 0) {
        this.proceduresItem.currency = procedure.currency;
      }
      if (procedure.currency == this.proceduresItem.currency) {
        this.proceduresItem.amount += Number(procedure.cost);
      }
      else {
        this.proceduresItem.errorVariousCurrencies = true;
        this.proceduresItem.amount = 0;
      }
    }  
    
    
    // calculate total
    for (let group of this.groupsFiltered) {
      if (group.errorVariousCurrencies) {
        this.totalItem.errorVariousCurrencies = true;
        this.totalItem.amount = 0;
        break;
      }
      else if (group.amount) {
        if (!this.totalItem.currency) {
          this.totalItem.currency = group.currency;
        } 
        if (group.currency == this.totalItem.currency) {
          this.totalItem.amount += group.amount;
        }
        else {
          this.totalItem.errorVariousCurrencies = true;
          this.totalItem.amount = 0;
          break;
        }
      } 
    }
    if (this.proceduresItem.errorVariousCurrencies) {
      this.totalItem.errorVariousCurrencies = true;
      this.totalItem.amount = 0;
    }
    else if (this.proceduresItem.amount) {
      if (!this.totalItem.currency) {
        this.totalItem.currency = this.proceduresItem.currency;
      } 
      if (this.proceduresItem.currency == this.totalItem.currency) {
        this.totalItem.amount += this.proceduresItem.amount;
      }
      else {
        this.totalItem.errorVariousCurrencies = true;
        this.totalItem.amount = 0;
      }
    }
  },
}
</script>

<style scoped>
.expenses-summary {
  max-width: 300px;
  margin: 20px auto 0 auto;
}
.expenses-summary__total {
  text-transform: uppercase;
}
</style>
```