<template>
  <div>
    <v-menu 
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          small
          class="primary"
          v-on="on"
          @click.stop.prevent
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">

        <v-list-item
          :to="{ name: 'transfer-forward', params: {
            transferId: transferId
          } }"
        >
          <v-icon class="mr-3 primary--text">mdi-file-send-outline</v-icon>
          {{ $t('transfers.actions.forward') }}
        </v-list-item> 

        <delete-dialog 
          :transfer-id="transferId"  
          @success="$emit('deleted')"
        />

      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DeleteDialog from './DeleteDialog';

export default {
  components: {
    DeleteDialog
  },
  props: {
    transferId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      deleting: false,
    };
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      deleteTransfer: 'transfers/deleteTransfer',
    }),
    onDeleteClick() {
      this.deleting = true;

      this.deleteTransfer(this.transferId)
      .then(() => {
        this.addFlash({
          text: this.$t("transfers.messages.success.deleted"),
          type: 'success'
        });
        this.$emit('deleted');
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.deleting = false;
      });
    }
  }
}
</script>