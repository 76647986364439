var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.layout == 'thumbnail')?_c('file-thumbnail',{staticClass:"file-thumbnail",attrs:{"file":_vm.file,"selection-enabled":_vm.selectionEnabled,"disable-click":_vm.disableClick,"shared":_vm.shared,"favorite-button":_vm.favoriteButton,"episode-role":_vm.episodeRole},on:{"click":function($event){return _vm.$emit('click')},"modified":function($event){return _vm.$emit('modified')}}}):_c('div',{staticClass:"file-item",class:{
    'file-item--selected': _vm.file.selected,
    'file-item--not-clickable': _vm.notClickable
  },attrs:{"v-ripple":!_vm.notClickable},on:{"click":function($event){_vm.selectionEnabled ? (_vm.file.canEdit ? _vm.file.selected = !_vm.file.selected : null) : _vm.$emit('click')}}},[_c('div',{staticClass:"file-item__thumbnail"},[_c('file-thumbnail',{attrs:{"file":_vm.file,"small":"","disable-click":"","shared":_vm.shared,"favorite-button":_vm.favoriteButton,"episode-role":_vm.episodeRole}})],1),_c('div',{staticClass:"file-item__details"},[_c('div',{staticClass:"file-item__user-row"},[_c('div',{staticClass:"file-item__user-alias"},[_c('div',{staticClass:"small height-lines-1"},[_c('span',{staticClass:"overline small"},[_vm._v(_vm._s(_vm.$t('files.titles.owner')))]),_vm._v(": "+_vm._s(_vm.file.user.alias)+" ")]),_c('div',{staticClass:"body-2 height-lines-1"},[_vm._v(_vm._s(_vm.file.activity.episode ? _vm.file.activity.episode.title : _vm.episodeTitle))]),_c('div',{staticClass:"height-lines-1 mb-1"},[_vm._v(_vm._s(_vm.file.activity.name))])]),_c('div',{staticClass:"file-item__user-image"},[_c('user-avatar',{staticClass:"user-item__avatar",attrs:{"src":_vm.file.user.urlAvatar,"disable-modal":""}})],1)]),(_vm.file.description)?_c('div',{staticClass:"small height-lines-1 mb-1"},[_vm._v(_vm._s(_vm.file.description))]):_vm._e(),_c('div',{staticClass:"small overline d-flex"},[_vm._v(" "+_vm._s(_vm.file.date ? _vm.$moment(_vm.file.date).format(_vm.$t('locale.formatDate')) : '')+" "+_vm._s(_vm.file.time ? _vm.$helperFunctions.fixApiTime(_vm.file.time) : '')+" "),(_vm.file.duration)?_c('div',{staticClass:"ml-auto mr-2"},[_vm._v(_vm._s(_vm.$helperFunctions.formatSeconds(_vm.file.duration)))]):_vm._e(),_c('div',{class:{ 'ml-auto': !_vm.file.duration }},[_vm._v(" "+_vm._s(_vm.$helperFunctions.formatFileSize(_vm.file.size, 1, 3))+" ")])])]),(_vm.showActivityLink && !_vm.selectionEnabled)?_c('v-btn',{staticClass:"file-item__link",attrs:{"icon":"","small":"","to":{ name: 'activity-files', params: { 
      episodeId: _vm.file.activity.episode.id,
      activityId: _vm.file.activity.id
    }}}},[_c('v-icon',{staticClass:"file-item__link secondary--text"},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.selectionEnabled && _vm.file.canEdit)?_c('v-simple-checkbox',{staticClass:"file-item__checkbox",attrs:{"color":"primary"},model:{value:(_vm.file.selected),callback:function ($$v) {_vm.$set(_vm.file, "selected", $$v)},expression:"file.selected"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }