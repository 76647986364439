<template>
  <v-dialog
    v-model="isOpen"
    content-class="audio-recorder__dialog"
    transition="dialog-bottom-transition"
  >

    <p>{{ recording ? '&nbsp;' : $t('notes.texts.pressToRecord') }} </p>

    <v-btn 
      v-if="recording"
      icon 
      class="ma-1 mb-4"
      outlined
      large
      title="large"
      @click="stopRecording"
    >
      <v-icon 
        x-large
        class="error--text"
      >mdi-stop</v-icon>
    </v-btn>

    <v-btn 
      v-else
      icon 
      class="ma-1 mb-4"
      outlined
      large
      title="large"
      @click="startRecording"
    >
      <v-icon 
        x-large
        class="error--text"
      >mdi-record</v-icon>
    </v-btn>

    <div class="small">{{ formattedTime }}</div>

  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UploadParameters from '@/modules/notes/model/UploadParameters.js';

export default {
  data() {
    return {
      filename: null,
      filePath: null,
      media: null,
      interval: null,
      isOpen: false,
      recording: false,
      src: null,
      time: null,
      startTime: null
    }
  },
  computed: {
    formattedTime() {
      let minutes = Math.floor(this.time / 60);
      let seconds = this.time - minutes * 60;
      return this.$helperFunctions.strPadLeft(minutes, '0', 2) + ':' + this.$helperFunctions.strPadLeft(seconds, '0', 2);
    }
  },
  methods: {
    ...mapActions({
      createNote: 'notes/createNote',
    }),
    open() {
      this.isOpen = true;
    },
    async startRecording() {

      // Save the recorded fileName
      this.filename = Date.now() + ".m4a";
      this.filePath = 'cdvfile://localhost/temporary/' + this.filename;
      console.log('filePath', this.filePath)

      console.log('Media', Media);

      if (this.media == null) {
        this.media = new Media(this.filePath,
            // success callback
            function () {
                console.log("on Success");
            },

            // error callback
            function (err) {
                console.log("on Error: " + err.code);
                console.log(err);
            });
      }
      // Record MPEG compressed audio, single channel at 16kHz
      var options = {
          SampleRate: 16000,
          NumberOfChannels: 1
      }

      this.media.startRecordWithCompression(options);

      console.log("Recording Started");
      this.recording = true;

      // start timer
      if (!this.interval) {
        this.interval = setInterval(this.timer, 1000);
      } 
    },
    stopRecording() {
      this.recording = false;
      console.log("Recording Stopped");
      this.media.stopRecord();
      this.media.release();
      this.$router.push({ 
        name: 'add-audio-note',
        params: {
          episodeId: this.$route.params.episodeId, 
          activityId: this.$route.params.activityId
        },
        query: {
          filename: this.filename
        }
      });
    },
    timer() {
      if (!this.startTime) {
        this.startTime = Math.floor(Date.now() / 1000);
      }

      // update time
      this.time = Math.floor(Date.now() / 1000) - this.startTime;

      // verify duration limit
      if (this.time >= UploadParameters.MAX_AUDIO_DURATION) {
        clearInterval(this.interval);
        this.interval = null;
        this.stopRecording();
      }
    }
  }
}
</script>

<style>
.audio-recorder__dialog {
  align-self: flex-end;
  margin: 0;
  max-width: none !important;
  border-radius: 4px 4px 0 0;
  text-align: center;
  padding: 30px 12px;
}
</style>