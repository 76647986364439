<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'contact-groups-list' }">
      {{ $t('contacts.titles.contacts') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('contacts.actions.editGroup') }}
      </v-tab>
    </page-tabs>

    <loading v-if="loading" />

    <div 
      v-if="item && !loading"
      class="l-vertical__scroll l-vertical"
    >

      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <group-form-fields
            :item="item"
          />
        </v-form>

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'contact-groups-list' }"
                exact
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import GroupFormFields from '@/modules/contacts/components/GroupFormFields.vue';
import Loading from '@/components/util/Loading';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    GroupFormFields,
    Loading,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: null,
      saving: false,
      valid: false
    }
  },
  created() {
    this.loading = true;
    this.getGroup(this.$route.params.id)
    .then(response => {
      this.item = response.data.group;
      this.loading = false;
    })
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      getGroup: 'contacts/getGroup',
      updateGroup: 'contacts/updateGroup'
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        title: this.item.title,
        description: this.item.description,
        imageFile: this.item.imageFile || undefined,
      }

      this.updateGroup({
        data,
        groupId: this.$route.params.id
      })
      .then(response => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.$router.push({ name: 'contact-groups-list' });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      })
    }
  }
}
</script>
