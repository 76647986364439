<template>
  <div class="l-vertical__grow l-vertical">

    <progress-linear :loading="loading" />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
      <v-container 
        v-if="activities.length"
        :class="{ 'px-3': $vuetify.breakpoint.smAndDown }"
      >
        <v-row 
          no-gutters
          class="activities-list"
        >
          <v-col
            v-for="activity in activitiesSorted"
            :key="activity.id"
            cols="6"
            sm="4"
            md="3"
            class="pr-3"
          >
            <activity-list-item
              :item="activity"
              show-date
              favorite-button
              class="activities-list__item"
              @modified="loadActivities"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container
        v-if="!activities.length && !loading" 
      >
        <v-alert
          text
          type="info"
        >
          {{ $t('favorites.texts.noData') }}
          <v-icon class="primary--text mt-n1">mdi-heart</v-icon>
          {{ $t('favorites.texts.noData2') }}
        </v-alert>
      </v-container>


    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActivityListItem from '@/modules/activities/components/ActivityListItem/ActivityListItem.vue';
import ProgressLinear from '@/components/util/ProgressLinear';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    ActivityListItem,
    ProgressLinear,
  },
  mixins: [statusTap],
  props: {   
    sortBy: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      activities: [],
      loading: false,
    }
  },  
  computed: {
    activitiesSorted() {
      if (!this.activities) {
        return [];
      }
      let activities = [...this.activities];
      switch (this.sortBy) {
        case 'recent':
          activities.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'dateAsc':
          activities.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? -1 : 1);       
          break;       
        case 'dateDesc':
          activities.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? 1 : -1);       
          break;       
        case 'name':
          activities.sort((a, b) => (a.name || '').localeCompare(b.name || ''));        
          break;
      }
      return activities;
    }
  },
  created() {
    this.loadActivities();
  },
  methods: {
    ...mapActions({
      getFavoriteActivities: 'activities/getFavoriteActivities'
    }),
    loadActivities() {
      this.loading = true;

      this.getFavoriteActivities()
      .then(response => {
        if (response) {
          this.activities = response.data.activities;
          this.loading = false;
        }
      })
    }
  }
}
</script>

<style scoped>
.activities-list {
  margin-right: -12px;
}
.activities-list__item {
  margin: 0 0 12px 0;
}
</style>