import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    setFavoriteFilesMelody(context, melodyId) {
      return axios.post('/api/favorite-files-melody/' + melodyId);
    },
    getFavoriteFilesMelody() {
      return axios.get('/api/favorite-files-melody');
    },
  },
};
