<template>
  <div class="file-item">
    <v-icon 
      v-if="file.error"
      class="error--text error-icon"
    >mdi-cancel</v-icon>
    <v-progress-circular 
      v-if="file.uploading"
      class="file-progress"
      :size="64"
      :width="5"
      color="white"
      indeterminate
    />
    <v-icon 
      v-if="isVideo"
      class="video-icon white--text"
    >mdi-file-video-outline</v-icon>
    <v-btn
      v-if="!file.uploading && !file.error"
      icon
      small
      class="remove-btn btn--active"
      :title="$t('actions.removeFile')"
      @click="$emit('remove')"
    >
      <v-icon 
        class="primary--text"
      >mdi-delete-forever</v-icon>
    </v-btn>
    <video 
      v-if="isVideo"
      ref="videoPlayer"
      :src="fileUrl" 
      class="file-thumb" 
      :autoplay="$helperFunctions.isCordova()"
      muted
      playsinline
    />
    <img 
      v-else
      :src="fileUrl" 
      class="file-thumb" 
      :alt="file.name"
    />
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fileUrl: null,
      isVideo: false
    }
  },
  created() {
    this.fileUrl = URL.createObjectURL(this.file.file);
    this.isVideo = this.file.type.indexOf('video') !== -1;
  },
  mounted() {
    // iphone bug fix: show first frame
    // https://stackoverflow.com/questions/47580647/html-video-first-frame-poster-on-ipad
    if (this.isVideo && this.$helperFunctions.isCordova()) {
      this.$refs.videoPlayer.onloadedmetadata = () => {
        this.$refs.videoPlayer.pause();
      };
    }
  }
}
</script>

<style scoped>
.file-item {
  position: relative;
  background: var(--color-image-background);
  padding-bottom: 100%;
  height: 0;
  margin: 0 1px 2px 1px;
}
.remove-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
}
.video-icon {
  position: absolute;
  top: 6px;
  left: 2px;
  font-size: 40px !important;
  z-index: 2;
}
.error-icon {
  position: absolute;
  top: 6px;
  right: 2px;
  z-index: 2;
}
.file-thumb {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
}
.file-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
</style>