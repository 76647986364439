<template>
  <div class="l-vertical__grow l-vertical">

    <progress-linear :loading="loading" />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >
      <episodes-list 
        v-if="episodes.length"
        :items="episodesSorted"
        favorite-button
        @modified="loadEpisodes"
      />

      <v-container
        v-if="!episodes.length && !loading" 
      >
        <v-alert
          text
          type="info"
        >
          {{ $t('favorites.texts.noData') }}
          <v-icon class="primary--text mt-n1">mdi-heart</v-icon>
          {{ $t('favorites.texts.noData2') }}
        </v-alert>
      </v-container>


    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EpisodesList from '@/modules/episodes/components/EpisodesList/EpisodesList';
import ProgressLinear from '@/components/util/ProgressLinear';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    EpisodesList,
    ProgressLinear,
  },
  mixins: [statusTap],
  props: {   
    sortBy: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      episodes: [],
      loading: false,
    }
  },  
  computed: {
    episodesSorted() {
      if (!this.episodes) {
        return [];
      }
      let episodes = [...this.episodes];
      switch (this.sortBy) {
        case 'recent':
          episodes.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'dateAsc':
          episodes.sort((a, b) => a.date < b.date ? -1 : 1);      
          break;       
        case 'dateDesc':
          episodes.sort((a, b) => a.date < b.date ? 1 : -1);      
          break;       
        case 'title':
          episodes.sort((a, b) => (a.title || '').toString().localeCompare(b.title || ''));      
          break;
      }
      return episodes;
    }
  },
  activated() {
    this.loadEpisodes();
  },
  methods: {
    ...mapActions({
      getFavoriteEpisodes: 'episodes/getFavoriteEpisodes'
    }),
    loadEpisodes() {
      this.loading = true;

      this.getFavoriteEpisodes()
      .then(response => {
        if (response) {
          this.episodes = response.data.episodes;
          this.loading = false;
        }
      })
    }
  }
}
</script>