<template>
  <v-menu 
    v-model="open"
    offset-y
    left
    eager
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :disabled="disabled"
        :class="disabled ? 'actions-menu__button-disabled' : 'primary'"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">  

      <v-list-item 
        :to="{ 
          name: 'collaborators-change-role',
          query: { users: selectedItemsIds }
        }"
      >
        <v-icon class="mr-3 primary--text">mdi-account-edit</v-icon> 
        {{ $t('collaborators.actions.changeRole') }}
      </v-list-item>  

      <delete-dialog 
        :selected-items="selectedItems"
        @success="$emit('success')" 
      />

    </v-list>
  </v-menu>
</template>

<script>
import DeleteDialog from './DeleteDialog';

export default {
  components: {
    DeleteDialog,
  },
  props: {  
    selectedItems: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    disabled() {
      return !this.selectedItems.length;
    },
    selectedItemsIds() {
      return this.selectedItems.map(item => item.id);
    }
  },
}
</script>

<style scoped>
.actions-menu__button-disabled {
  visibility: hidden;
}
</style>