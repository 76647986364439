<template>
  <v-menu 
    offset-y
    :top="$vuetify.breakpoint.smAndDown"
    content-class="fab-audio-menu"
  >
    <template v-slot:activator="{ on }">
      <template v-if="menuBottom">
        <button 
          v-ripple
          class="menu-bottom__item"
          v-on="on"
        >
          <v-icon>mdi-microphone-plus</v-icon>
          <div class="menu-bottom__item-label">{{ $t('menu-fab.audios') }}</div>
        </button>
      </template>

      <template v-else>
        <div 
          class="app-fab__menu-item"
          v-on="on"
        >
          <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
            {{ $t('menu-fab.audios') }}
          </v-btn>
          <v-btn
            fab
            small
            class="primary"
          >
            <v-icon>mdi-microphone-plus</v-icon>
          </v-btn>
        </div>
      </template>
    </template>
    <v-list
      class="py-0"
    >
      
      <v-list-item
        v-if="$helperFunctions.isCordova()"
        class="sort-menu__item primary--text"
        @click="$emit('open-recorder')"
      >
        {{ $t('notes.actions.record') }}
        <v-icon 
          class="ml-2"
        >mdi-microphone</v-icon>
      </v-list-item>

      <audio-upload-button />

    </v-list>
  </v-menu>
</template>

<script>
import AudioUploadButton from './AudioUploadButton.vue';

export default {
  components: {
    AudioUploadButton
  },
  props: {
    menuBottom: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style>
.fab-audio-menu {
  margin-top: 12px;
}

/* mobile & tablet */
@media (max-width: 1024px) {
  .fab-audio-menu {
    margin-top: -12px;
  }
}
</style>