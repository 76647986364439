<template>
  <div 
    class="upload-image"
    :class="circle ? 'upload-image--circle' : ''"
  >
    <div 
      v-show="$refs.upload && $refs.upload.dropActive" 
      class="drop-overlay"
    >
      <h3>{{ $t('actions.dropFilesToUpload') }}</h3>
    </div>

    <div class="buttons-row">
      <file-upload
        ref="upload"
        v-model="files"
        v-ripple
        :input-id="inputId"
        :accept="acceptedMimeTypes"
        name="image"
        class="file-upload btn btn-primary"
        :drop="true"
        @input-filter="inputFilter"
      >
        <div class="image-row">
          
          <div
            v-if="files.length"
            class="image-img"
            :style="'background-image: url(' + files[0].url + ')'"
          ></div>
          <div
            v-else-if="urlImage"
            class="image-img"
            :style="'background-image: url(' + (files.length ?files[0].url : urlImage) + ')'"
          ></div>
          <div 
            v-else 
            class="image-placeholder"
          >
            <v-icon color="primary">{{ icon }}</v-icon>
          </div>
          <div class="ml-6 body-2">{{ text ? text : $t('form.label.uploadImage') }}</div>
        </div>
      </file-upload>

      <camera-button 
        :max-width="maxWidth"
        :max-height="maxHeight"
        :quality="JPEG_QUALITY"
        @input="onCameraInput"
      />
    </div>

  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import CameraButton from './CameraButton.vue';

const JPEG_QUALITY = 85;

const ACCEPTED_MIME_TYPES = [
  'image/jpeg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/x-ms-bmp',
  'image/vnd.wap.wbmp',
  'image/webp',
  'image/x-adobe-dng',
  'image/x-canon-cr2',
  'image/x-nikon-nef',
  'image/x-nikon-nrw',
  'image/x-sony-arw',
  'image/x-panasonic-rw2',
  'image/x-olympus-orf',
  'image/x-fuji-raf',
  'image/x-pentax-pef',
  'image/x-samsung-srw',
];
const MIME_TYPES_MOBILE = 'image/*'

export default {
  components: {
    CameraButton,
    FileUpload
  },
  props: {
    value: {
      type: File,
      default: null
    },
    urlImage: {
      type: String,
      default: null
    },
    maxWidth: {
      type: Number,
      default: 2048
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'mdi-file-image'
    },
    circle: {
      type: Boolean,
      default: null
    },    
    inputId: {
      type: String,
      default: 'image-input'
    },
    scaleImage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      acceptedMimeTypes: process.env.NODE_ENV == 'cordova' ? MIME_TYPES_MOBILE : ACCEPTED_MIME_TYPES.join(','),
      files: [],
      JPEG_QUALITY: JPEG_QUALITY
    };
  },
  created() {
    if(this.value) {
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
          this.value.url = URL.createObjectURL(this.value);
      }

      this.files.push(this.value);
    }
  },
  methods: {
    async inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }

        // Filter non-image file
        // Will not be added to files
        if (!/\.(jpg|jpeg|jpe|gif|png|bmp|wbmp|webmp|dng|cr2|nef|nrw|arw|rw2|orf|raf|pef|srw|webp)$/i.test(newFile.name)) {
          this.$emit('error', this.$t('files.messages.error.upload.incorrectFileType', {
            file: newFile.name
          }));
          return prevent();
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {

        // Preloader
        newFile.url = "";

        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {

          if (this.scaleImage) {
            this.$imageFunctions.scale(newFile.file, this.maxWidth, this.maxHeight, JPEG_QUALITY)
            .then(file => {
              newFile.file = file;
              newFile.url = URL.createObjectURL(newFile.file);
              this.$emit("input", newFile.file);
            });
          }
          else {
            newFile.url = URL.createObjectURL(newFile.file);
            this.$emit("input", newFile.file);
          }
        }
      }
    },
    onCameraInput(newFile) {
      this.files = [newFile];
      this.$emit("input", newFile.file);
    }
  }
};
</script>

<style scoped>
.buttons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-image .drop-overlay {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.upload-image .drop-overlay h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.image-placeholder {
  width: 90px;
  height: 90px;
  border: 2px dashed var(--color-text);
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-image--circle .image-placeholder {
  border-radius: 50%;
}
.image-placeholder i {
  font-size: 50px !important;
}
.image-row {
  display: flex;
  align-items: center;
}
.image-img {
  width: 90px;
  height: 90px;
  background-size: contain;
  background-position: center;
}
.upload-image--circle .image-img {
  border-radius: 50%;
  background-size: cover;
  background-color: var(--color-image-background);
}
.file-uploads /deep/ label {
  cursor: pointer;
}
.file-progress {
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
