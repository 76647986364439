<template>
  <div class="list-item">
    <v-icon 
      v-if="item.icon"
      size="30"
      class="list-item__icon"
      :title="item.name"
    >{{ item.icon }}</v-icon>
    <div class="list-item__name">{{ item.name }}</div>

    
    <div class="list-item__price">
      <various-currencies v-if="item.errorVariousCurrencies" />
      <template v-else>{{ $helperFunctions.formatMoney(item.amount) }} {{ item.currency }}</template>
    </div>
  </div>
</template>

<script>
import VariousCurrencies from '@/components/util/VariousCurrencies.vue';

export default {
  components: {
    VariousCurrencies,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
  },
}
</script>

<style scoped>
.list-item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.list-item__icon {
  margin-top: -4px;
  margin-right: 10px;
}
.list-item__price {
  margin-left: auto;
}
</style>
