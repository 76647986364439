<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ episode ? episode.title : '' }}
    </page-title>

    <episode-tabs
      :episode="episode"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll d-flex flex-column"
    >
      <v-container class="flex-grow-1 py-0 container--narrow">

        <loading v-if="loading" />

        <v-alert
          v-if="!loading && messages && !messages.length"
          type="info"
          class="mt-4"
        >
          {{ $t('chat.texts.noElements') }}
        </v-alert>


        <template v-for="(message, i) in messages">
          <div 
            v-if="!i || (message.date != messages[i - 1].date)"
            :key="i + 'date'"
            class="message-list__date"
          >{{ $moment(message.createdAt).format($t('locale.formatDate')) }}</div>

          <chat-message
            :key="i"
            :item="message"
            :right="(message.user.id == user.id) && !message.userDeleted"
            :hide-user="!!i && (message.user.id == messages[i - 1].user.id) && (message.date == messages[i - 1].date)"
            :last-message="!messages[i + 1] || (message.user.id != messages[i + 1].user.id) || (message.date != messages[i + 1].date)"
          />
        </template>

      </v-container>

      <v-container 
        v-if="!loading" 
        class="pb-3 container--narrow"
      >
        <message-form id="messageForm" />
      </v-container>

    </div>
    
    <menu-bottom />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EventSourcePolyfill } from 'event-source-polyfill';
import ChatMessage from './ChatMessage';
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs.vue';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import MessageForm from './MessageForm';
import PageTitle from "@/components/layout/PageTitle.vue";
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    ChatMessage,
    EpisodeTabs,
    Loading,
    MenuBottom,
    MessageForm,
    PageTitle
  },
  mixins: [statusTap],
  data() {
    return {
      eventSource: null,
      jwt: null,
      loading: false,
      messages: []
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
      user: 'user/user'
    })
  },
  async created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    // load messages
    this.loading = true;
    this.getMessages(this.$route.params.episodeId)
    .then(response => {
      this.messages = response.data.map(item => {
        item.date = this.$helperFunctions.fixApiDate(item.createdAt);
        return item;
      })
      this.loading = false;
      this.$nextTick(() => {
        this.scrollToBottom(true);
      });
    });


    //load jwt
    let response = await this.getJwt(this.$route.params.episodeId);
    this.jwt = response.data;

    // subscribe to mercure events
    const subscribeURL = new URL(process.env.VUE_APP_MERCURE_URL);
    subscribeURL.searchParams.append('topic', 'episode/' + this.$route.params.episodeId);

    this.eventSource = new EventSourcePolyfill(subscribeURL, {
        headers: {
            'Authorization': 'Bearer '+this.jwt,
        }
    });
    this.eventSource.onmessage = ({data}) => {
        let message = JSON.parse(data);
        message = {
          ...message,
          date: this.$helperFunctions.fixApiDate(message.createdAt)
        }
        this.messages.push(message);
        this.updateAccessMessagesEpisode(this.$route.params.episodeId);

        this.$nextTick(() => {
          this.scrollToBottom();
        });
    };
  },
  mounted() {
    this.updateAccessMessagesEpisode(this.$route.params.episodeId);

    window.addEventListener('keyboardDidShow', () => {
      this.scrollToBottom(true);
    });
  },
  beforeDestroy() {
    this.eventSource.onmessage = null;
    this.eventSource.close();
  },
  methods: {
    ...mapActions({
      getMessages: 'chat/getMessages',
      getJwt: 'chat/getJwt',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
      updateAccessMessagesEpisode: 'episodes/updateAccessMessagesEpisode'
    }),
    scrollToBottom(animate = false) {
      const el = document.getElementById('messageForm');
      if (el) {
        el.scrollIntoView({ behavior: animate ? 'smooth' : 'instant' });
      }
    }
  }
}
</script>

<style scoped>
.message-list__date {
  text-align: center;
  font-size: 10px;
  color: var(--color-text--darker);
  margin: 12px 0 0 0;
}
</style>