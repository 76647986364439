<template>
  <div class="note-item">
    <div 
      v-ripple
      class="note-item__thumbnail"
      @click="onClick"
    >
      <v-icon
        v-if="item.privacy == NotePrivacy.PRIVATE"
        class="note-item__private-icon"
        :title="$t('notes.model.privacy.private')"
        small
      >mdi-lock-outline</v-icon>
      
      <v-icon large>{{ isAudio ? 'mdi-volume-high' : 'mdi-format-text' }}</v-icon>
    </div>

    <div class="note-item__details">
      <div class="body-2 height-lines-1">{{ item.activity.episode.title }}</div>
      <div class="height-lines-1">{{ item.activity.name }}</div>
      <div class="small height-lines-1">{{ item.title }}</div>
      <div class="small overline height-lines-1">
        {{ item.date ? $moment(item.date).format($t('locale.formatDate')) : '' }}
        {{ item.time ? $helperFunctions.fixApiTime(item.time) : '' }}
      </div>
    </div>

    <v-checkbox
      v-model="item.selected" 
      color="primary"
      class="note-item__checkbox"
    />
  </div>
</template>

<script>
import NotePrivacy from '@/modules/notes/model/NotePrivacy.js';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      NotePrivacy: NotePrivacy
    }
  },
  computed: {
    isAudio() {
      return this.item && !!this.item.urlAudioFile;
    }
  },
  methods: {
    onClick() {
      if (this.isAudio) {
        this.$emit('play', this.item.urlAudioFile);
      }
      else {
        this.$emit('view', this.item);
      }
    }
  }
};
</script>

<style scoped>
.note-item {
  display: flex;
  align-items: center;
  position: relative;
}
.note-item__thumbnail {
  position: relative;
  width: 76px;
  height: 76px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: var(--color-background--light);
  color: var(--color-text--dark);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.note-item__private-icon {
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 6px;
}
.note-item__details {
  flex-grow: 1;
  padding: 0 4px 0 16px;
  text-align: left;
}
</style>