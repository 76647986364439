<template>
  <div>

    <v-combobox
      v-model="item.episode"
      :label="$t('files.form.label.episode')"
      :items="episodes"
      item-text="title"
      item-value="id"
      :rules="[$validationRules.required]"
      :loading="loadingEpisodes"
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
      @change="loadActivities"
    >
      <template v-slot:item="data">
        <episodes-dropdown-item :item="data.item" />
      </template>
      <template v-slot:selection="data">
        <episodes-dropdown-item 
          :key="data.item.id"
          :item="data.item" 
        />
      </template>
    </v-combobox>

    <v-combobox
      v-model="item.activity"
      :label="$t('files.form.label.activity')"
      :items="activities"
      item-text="name"
      item-value="id"
      :loading="loadingEpisodes || loadingActivities"
      :validate-on-blur="false"
      :filter="(item, queryText, itemText) => $helperFunctions.autocompleteFilter(itemText, queryText)"
    >
      <template v-slot:item="data">
        <activities-dropdown-item :item="data.item" />
      </template>
      <template v-slot:selection="data">
        <activities-dropdown-item 
          :key="data.item.id"
          :item="data.item" 
        />
      </template>
    </v-combobox>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ActivitiesDropdownItem from '@/modules/activities/components/ActivitiesDropdownItem';
import EpisodesDropdownItem from '@/modules/episodes/components/EpisodesDropdownItem';
import ProgressLinear from '@/components/util/ProgressLinear';

export default {
  components: {
    ActivitiesDropdownItem,
    EpisodesDropdownItem,
    ProgressLinear
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activities: [],
      episodes: [],
      loadingActivities: false,
      loadingEpisodes: false,
    }
  },
  created() {
    // load episodes
    this.loadingEpisodes = true;
    this.getEpisodes()
    .then(response => {
      this.episodes = response.data.episodes;
      this.loadingEpisodes = false;
      if (this.episodes) {
        this.item.episode = this.episodes[0];
        this.loadActivities();
      }
    });
  },  
  methods: {
    ...mapActions({
      getActivities: 'activities/getActivities',
      getEpisodes: 'episodes/getEpisodes'
    }),
    loadActivities() {
      this.activities = [];
      this.item.activity = null; 

      if (this.item.episode) {
        this.loadingActivities = true;
        this.getActivities(this.item.episode.id)
        .then(response => {
          this.activities = response.data.activities;
          // add first item
          this.activities.unshift({
            id: null,
            name: this.$t('melodies.form.allActivities')
          });
          this.loadingActivities = false;
          if (this.activities) {
            this.item.activity = this.activities[0];
          }
        });
      }
    },
  }
}
</script>