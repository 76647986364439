import SubscriptionPage from '@/modules/subscription/pages/SubscriptionPage/SubscriptionPage.vue';

export default [
  {
    path: '/subscription',
    name: 'subscription',
    component: SubscriptionPage,
    meta: { requiresAuth: true }
  }
]
