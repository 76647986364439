<template>
  <div class="actions-menu">
    <v-menu 
      v-if="activity && activity.episode && ((activity.episode.rol == roles.CREATOR) || (activity.episode.rol == roles.EDITOR))"
      v-model="open"
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          small
          class="primary"
          :title="$t('activities.actions.editActivity')"
          v-on="on"
          @click.native.stop.prevent
          @mousedown.native.stop.prevent
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">

        <v-list-item
          :to="{ name: 'activity-edit', params: { 
            episodeId: $route.params.episodeId,
            activityId: $route.params.activityId
          } }"
        >
          <v-icon class="mr-3 primary--text">mdi-pencil-outline</v-icon> 
          {{ $t('actions.edit') }}
        </v-list-item>

        <download-item 
          :episode-id="$route.params.episodeId"
          :activity-id="$route.params.activityId"
          @close="open = false"
        />   

        <transfer-item 
          :activity="activity"
          @success="open = false; $emit('modified')"
        /> 

        <v-list-item 
          v-if="canDelete"
          class="error--text"
          @click="deleteDialogOpen = true"
        >
          <v-icon class="mr-3">mdi-trash-can-outline</v-icon> 
          {{ $t('actions.delete') }}
        </v-list-item>

      </v-list>
    </v-menu>

    <v-dialog
      v-if="canDelete"
      v-model="deleteDialogOpen"
      width="450"
    >
      <delete-dialog 
        :item="activity"
        @close="deleteDialogOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeleteDialog from './DeleteDialog';
import DownloadItem from './DownloadItem.vue';
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import TransferItem from './TransferItem/TransferItem.vue';

export default {
  components: {
    DeleteDialog,
    DownloadItem,
    TransferItem,
  },
  props: {
    activity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      deleteDialogOpen: false,
      open: false,
      roles: roles
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    canDelete() {
      return this.activity.user.id == this.user.id;
    }
  }
}
</script>

<style scoped>
.actions-menu {
  width: 28px;
}
</style>