<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :title="$t('actions.filter')"
        :class="{
          'ml-1': $vuetify.breakpoint.mdAndUp
        }"
        :disabled="loading"
        v-on="on"
      >
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">

      <!-- filter by privacy ------------------------------- -->

      <v-list-item 
        dense
        @click.stop.prevent="privacy = 'all'"
      >
        <v-checkbox
          v-model="privacy"
          value="all"
          :label="$t('activities.texts.filter.all')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="privacy = 'myPrivate'"
      >
        <v-checkbox
          v-model="privacy"
          value="myPrivate"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        >
          <template v-slot:label>
            <v-icon class="mr-2">mdi-lock-outline</v-icon> 
            {{ $t('activities.texts.filter.myPrivate') }}
          </template>
        </v-checkbox>
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="privacy = 'myPublic'"
      >
        <v-checkbox
          v-model="privacy"
          value="myPublic"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        >
          <template v-slot:label>
            <v-icon class="mr-2">mdi-lock-open-variant-outline</v-icon> 
            {{ $t('activities.texts.filter.myPublic') }}
          </template>
        </v-checkbox>
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="privacy = 'others'"
      >
        <v-checkbox
          v-model="privacy"
          value="others"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        >
          <template v-slot:label>
            <v-icon class="mr-2">mdi-lock-open-variant-outline</v-icon> 
            {{ $t('activities.texts.filter.others') }}
          </template>
        </v-checkbox>
      </v-list-item>

        

      <!-- filter by attended ------------------------------- -->


      <v-list-item 
        dense
        @click.stop.prevent="attended = 'all'"
      >
        <v-checkbox
          v-model="attended"
          value="all"
          :label="$t('activities.texts.filter.all')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="attended = 'attended'"
      >
        <v-checkbox
          v-model="attended"
          value="attended"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        >
          <template v-slot:label>
            <v-icon class="mr-2">mdi-map-marker-check-outline</v-icon> 
            {{ $t('activities.texts.filter.attended') }}
          </template>
        </v-checkbox>
      </v-list-item>

      <v-list-item 
        dense
        @click.stop.prevent="attended = 'notAttended'"
      >
        <v-checkbox
          v-model="attended"
          value="notAttended"
          class="pl-0 mt-0 ml-6"
          hide-details
          @click.prevent
        >
          <template v-slot:label>
            <v-icon class="mr-2">mdi-map-marker-off-outline</v-icon> 
            {{ $t('activities.texts.filter.notAttended') }}
          </template>
        </v-checkbox>
      </v-list-item>

        

      <!-- filter by group ------------------------------- -->

      <v-list-item 
        dense
        @click.stop.prevent="selectAllGroups()"
      >
        <v-checkbox
          v-model="groupsAll"
          :value="true"
          :label="$t('activities.texts.filter.all')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        v-for="group in groupsData"
        :key="group.id"
        dense
        @click.stop.prevent="onGroupClick(group.id)"
      >
        <v-icon class="ml-6 mr-2 primary--text">{{ groups[group.id] ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon> 
        <v-icon class="mr-2">{{ group.icon }}</v-icon> 
        {{ group.name }}
      </v-list-item>



      <v-row class="mx-0 my-2">
        <v-col 
          cols="6" 
          class="pr-2"
        >
          <v-btn
            outlined
            block
            small
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>
        <v-col 
          cols="6" 
          class="pl-2"
        >
          <v-btn 
            block
            small
            color="primary"
            @click="submit"
          >{{ $t('actions.apply') }}</v-btn>
        </v-col>
      </v-row>

    </v-list>
  </v-menu>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      attended: 'all',
      groups: {},
      groupsAll: true,
      groupsData: [],
      loading: false,
      privacy: 'all',
    }
  },
  created() {
    // load groups
    this.loading = true;
    this.getActivityGroups()
    .then(response => {
      this.groupsData = response.data;
      this.groupsData.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
      this.loading = false;
      for (let i in this.groupsData) {
        this.$set(this.groups, this.groupsData[i].id, false);
      }

      // pre-select filters
      if (this.$route.query.group) {
        this.groupsAll = false;
        this.groups[this.$route.query.group] = true;
      }

    });
  },
  methods: {
    ...mapActions({
      getActivityGroups: 'activities/getActivityGroups',
    }),
    selectAllGroups() {
      this.groupsAll = true;
      for (let i in this.groups) {
        this.groups[i] = false;
      }
    },
    onGroupClick(id) {
      this.groups[id] = !this.groups[id]; 
      this.groupsAll = false
    },
    submit() {
      this.$emit('change', { 
        attended: this.attended, 
        groups: { ...this.groups }, 
        groupsAll: this.groupsAll,
        privacy: this.privacy,
      });
    }   
  }
}
</script>