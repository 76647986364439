import ActivityNotesPage from '@/modules/notes/pages/ActivityNotesPage.vue';
import AudioNoteAddPage from '@/modules/notes/pages/AudioNoteAddPage/AudioNoteAddPage.vue';
import EpisodeNotesPage from '@/modules/notes/pages/EpisodeNotesPage.vue';
import NoteAddPage from '@/modules/notes/pages/NoteAddPage/NoteAddPage.vue';
import NoteEditPage from '@/modules/notes/pages/NoteEditPage.vue';
import MultipleNotesEditPage from '@/modules/notes/pages/MultipleNotesEditPage/MultipleNotesEditPage.vue';
import MultipleNotesMovePage from '@/modules/notes/pages/MultipleNotesMovePage/MultipleNotesMovePage.vue';

export default [
  {
    path: '/add-note/:episodeId?/:activityId?',
    name: 'add-note',
    component: NoteAddPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-audio-note/:episodeId?/:activityId?',
    name: 'add-audio-note',
    component: AudioNoteAddPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/activity/:activityId/note/:noteId',
    name: 'note-edit',
    component: NoteEditPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/activity/:activityId/notes',
    name: 'activity-notes',
    component: ActivityNotesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/notes',
    name: 'episode-notes',
    component: EpisodeNotesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/multiple-notes-edit',
    name: 'multiple-notes-edit',
    component: MultipleNotesEditPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/multiple-notes-move',
    name: 'multiple-notes-move',
    component: MultipleNotesMovePage,
    meta: { requiresAuth: true }
  }
]
