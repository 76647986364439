<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ $t('contacts.titles.contacts') }}
      <template slot="buttons">
        <selection-toolbar
          ref="itemSelectionToolbar"
          :items="groups"
          @success="loadGroups"
        />

        <app-fab 
          v-if="!$refs.itemSelectionToolbar || !$refs.itemSelectionToolbar.selectionEnabled"
        >
          <template slot="list">
            <router-link 
              class="app-fab__menu-item"
              :to="{ name: 'contact-group-add' }"
            >
              <v-btn class="app-fab__item-label primary v-btn--raised mr-2">
                {{ $t('contacts.actions.addGroup') }}
              </v-btn>
              <v-btn
                fab
                small
                class="primary"
              >
                <v-icon>mdi-account-multiple-plus-outline</v-icon>
              </v-btn>
            </router-link>

          </template>  
        </app-fab>
      </template>
    </page-title>
    
    <contacts-tabs />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <v-container class="container--narrow pb-0">

        <loading v-if="loading" />

        <group-item
          v-for="item in groups"
          v-else
          :key="item.id"
          :item="item"
          :selection-enabled="$refs.itemSelectionToolbar && $refs.itemSelectionToolbar.selectionEnabled"
        />

        <v-alert
          v-if="!loading && groups && !groups.length"
          text
          type="info"
        >
          {{ $t('contacts.texts.noGroups') }}
        </v-alert>

      </v-container>

    </div>

    <menu-bottom />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import ContactsTabs from '@/modules/contacts/components/ContactsTabs.vue';
import GroupItem from '@/modules/contacts/components/GroupItem/GroupItem.vue';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import SelectionToolbar from './SelectionToolbar/SelectionToolbar.vue';
import statusTap from '@/mixins/statusTap.js';

export default {
  components: {
    AppFab,
    ContactsTabs,
    GroupItem,
    Loading,
    MenuBottom,
    PageTitle,
    SelectionToolbar,
  },
  mixins: [statusTap],
  data() {
    return {
      groups: null,
      loading: false
    }
  }, 
  created() {
    this.loadGroups();
  },
  methods: {
    ...mapActions({
      getGroups: 'contacts/getGroups'
    }),
    loadGroups() {
      this.loading = true;
      this.getGroups()
      .then(response => {
        // add reactive field "selected"
        for (let i in response.data.groups) {
          response.data.groups[i].selected = false;
        }
        this.groups = response.data.groups;
        this.groups.sort((a, b) => (a.title || '').localeCompare(b.title || ''));  
        this.loading = false;
      }) 
    }
  }
}
</script>