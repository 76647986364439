<template>
  <div class="header">
    <v-container class="header__container">
      <div class="header__logo">
        <div class="header__logo-text-big">Timlapp</div>
        <div class="header__logo-text-small">
          <strong>T</strong>his 
          <strong>i</strong>s 
          <strong>m</strong>y 
          <strong>l</strong>ife 
          <strong>app</strong>lication
        </div>
      </div>

      <div class="header__lang mr-5">
        <lang-switch />
      </div>

      <div class="header__user">
        <v-btn 
          class="primary mb-2"
          :class="{
            'mt-1': $vuetify.breakpoint.smAndDown
          }"
          small
          :to="{ name: 'user-login' }"
        >
          {{ $t('shared.actions.login') }}
        </v-btn>
      </div>
    </v-container>
  </div>
</template>


<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import LangSwitch from './LangSwitch.vue';

export default {
  components: {
    LangSwitch
  },
  data() {
    return {
      email: null
    }
  },
  created() {
    this.verifyHash({
      episodeId: this.$route.params.episodeId,
      hash: this.$route.query.hash
    })
    .then(response => {
      if (response.data.access && response.data.access.email) {
        this.email = response.data.access.email;
      }
    })
    .catch(error => {
      this.addFlash({
        text: this.$helperFunctions.errorToString(error),
        type: 'error'
      });
    });
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      verifyHash: 'shared/verifyHash'
    })
  }
}
</script>



<style scoped>
.header {
  height: 80px;
  transition: all 0.35s ease-out;
}
.header__container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 0;
  height: 100%;
  z-index: 5;
  display: flex;
}
.header__lang {
  margin-left: auto;
}
.header__logo {
  letter-spacing: normal;
  font-weight: bold;
}
.header__logo-text-big {
  color: var(--v-primary-base);
  margin-bottom: 2px;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
}
.header__logo-text-small {
  font-size: 12px;
}
.header__logo-text-small strong {
  color: var(--v-primary-base);
  font-weight: bold;
}
.header__user {
  text-align: right;
}

/* mobile */
@media (max-width: 1024px) {
  .header {
    height: 60px;
  }
  .header__container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .header__lang {
    display: none;
  }
  .header__logo-text-big {
    font-size: 21px;
  line-height: 1;
  }
  .header__user {
    margin-left: auto;
  }
}
</style>
