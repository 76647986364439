<template>
  <div>
    <v-container 
      class="py-0"
      :class="{ 'px-0' : $vuetify.breakpoint.smAndDown }"
    >
      <div 
        class="episode-header"
      >
        <div 
          class="episode__image"
          :style="'background-image:url(' + episodeImage + ')'"
        ></div>
        <div class="image-overlay"></div>

        <div class="episode__title subtitle-1 max-lines-1">
          {{ episode ? episode.title : '' }}
        </div>

      </div>
    </v-container>
    <v-container 
      class="activity-header"
      :class="{ 
        'activity-header--md': $vuetify.breakpoint.mdAndUp
      }"
    >
      <v-row 
        no-gutters
      >
        <v-col
          cols="6"
          sm="4"
          md="3"
          class="pr-3"
        >
          <activity-list-item
            :item="activity"
            :show-actions-menu="!shared"
            favorite-button
            :to="to"
            :shared="shared"
            class="activity-header__image"
            @modified="$emit('modified')"
          />
        </v-col>
        <v-col
          v-if="activity"
          cols="6"
          sm="8"
          md="9"
          class="activity-header__details pl-4"
        >
          <div class="overline mb-4">
            {{ activity.activityGroup.name }} - {{ activity.activityType.name }}
          </div>
          <div class="overline">
            {{ activity.date ? $moment(activity.date).format($t('locale.formatDate')) : '' }} 
            {{ activity.date && activity.time ? '-' : '' }}
            {{ activity.time ? $helperFunctions.fixApiTime(activity.time) : '' }} 
          </div>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActivityListItem from '@/modules/activities/components/ActivityListItem/ActivityListItem.vue';

export default {
  components: {
    ActivityListItem
  },
  props: {
    episode: {
      type: Object,
      default: null
    },
    activity: {
      type: Object,
      default: null
    },
    shared: {
      type: Boolean,
      default: false
    }
  },
   computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
    activityImage() {
      let auth = this.shared 
        ? '?hash=' + this.$route.query.hash 
        : '?bearer=' + this.apiToken;

      return this.activity 
        ? this.activity.urlImageThumbnails
          ? this.activity.urlImageThumbnails.medium_ + auth 
          : 'img/placeholder/activity-details.png'
        : null
    },    
    episodeImage() {
      let auth = this.shared 
        ? '?hash=' + this.$route.query.hash 
        : '?bearer=' + this.apiToken;

      return this.episode 
        ? this.episode.urlImageThumbnails
          ? this.episode.urlImageThumbnails.medium_ + auth 
          : 'img/placeholder/episode-details.png'
        : null
    },
    to() {
      return this.shared 
        ? { 
          name: 'shared-activity-files', 
          params: { 
            episodeId: this.$route.params.episodeId, 
            activityId: this.$route.params.activityId
          },
          query: { hash: this.$route.query.hash }
        }
        : { 
          name: 'activity-files', 
          params: { 
            episodeId: this.$route.params.episodeId, 
            activityId: this.$route.params.activityId
          }
        }
    }
  },
}
</script>

<style scoped>
.episode-header {
  position: relative;
  height: 273px;
  color: white;
}
.episode__image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-image-background);
  background-size: cover;
  background-position: center;
}
.episode__title {
  position: absolute;
  top: 14px;
  left: 18px;
  width: calc(100% - 32px);
}

.activity-header {
  position: relative;
  margin-top: -223px;
  padding: 0 12px;
}

.activity-header--md {
  padding-left: 56px;
  padding-right: 56px;
}

.activity-header__image {
  background-color: var(--color-image-background);
}

.activity-header__image:hover {
  opacity: 1;
}



.activity-header__details {
  padding-top: 236px !important;
}

/* mobile */
@media (max-width: 1024px) {
  .episode-header {
    height: 154px;
  }
  .episode__title {
    left: 16px;
    width: calc(100% - 32px);
  }
  .activity-header {
    margin-top: -104px;
  }

  .activity-header__details {
    padding-top: 120px !important;
  }
}
</style>