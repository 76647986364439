<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ episode ? episode.title : '' }}
      <template 
        v-if="episode"
        slot="buttons"
      >
        <note-selection-toolbar
          v-if="(episode.rol != roles.COMMENTATOR)"
          ref="itemSelectionToolbar"
          :notes="notes"
          @success="loadNotes"
        />

        <filter-menu @change="filters = $event" />

        <sort-menu @change="sortBy = $event" />

        <app-fab 
          v-if="(episode.rol != roles.COMMENTATOR) && (!$refs.itemSelectionToolbar || !$refs.itemSelectionToolbar.selectionEnabled)"
          add-notes
        />

      </template>
    </page-title>

    <episode-tabs
      :episode="episode"
    />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <loading v-if="loading" />

      <v-container 
        v-else
        class="container--narrow"
      >
        
        <v-alert 
          v-if="notes && !notes.length"
          type="info"
        >
          {{ $t('notes.texts.noElements') }}
        </v-alert>

        <v-alert 
          v-if="notes && notes.length && !notesFilteredSorted.length"
          type="info"
        >
          {{ $t('texts.noElementsMatching') }}
        </v-alert>

        <template v-if="notesFilteredSorted">
          <note-list-item
            v-for="note in notesFilteredSorted"
            :key="note.id"
            :item="note"
            :selection-enabled="$refs.itemSelectionToolbar && $refs.itemSelectionToolbar.selectionEnabled"
            show-activity-title
            @delete="deleteNote = note"
          />
        </template>
      </v-container>

    </div>
    
    <delete-dialog
      :note="deleteNote"
      @close="deleteNote = null"
      @success="loadNotes()"
    />
    
    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import DeleteDialog from '@/modules/notes/components/DeleteDialog';
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs';
import FilterMenu from '@/modules/notes/components/FilterMenu';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import NoteListItem from '@/modules/notes/components/NoteListItem/NoteListItem.vue';
import NotePrivacy from '@/modules/notes/model/NotePrivacy.js';
import NoteSelectionToolbar from '@/modules/notes/components/NoteSelectionToolbar/NoteSelectionToolbar.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import SortMenu from '@/modules/notes/components/SortMenu.vue';
import statusTap from '@/mixins/statusTap.js';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    AppFab,
    DeleteDialog,
    EpisodeTabs,
    FilterMenu,
    Loading,
    MenuBottom,
    NoteListItem,
    NoteSelectionToolbar,
    PageTitle,
    SortMenu
  },
  mixins: [statusTap],
  data() {
    return {
      deleteNote: null,
      filters: { 
        favorites: false,
        type: 'all',
        privacy: 'all'
      },
      notes: null,
      loading: false,
      roles: roles,
      sortBy: 'recent'
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
      user: 'user/user'
    }),
    notesFiltered() {
      if (!this.notes) {
        return [];
      }
      return this.notes.filter(item => {
        // filter by type
        switch (this.filters.type) {
          case 'all':
            break;
          case 'text':
            if (item.urlAudioFile) {
              return false;
            }
            break;
          case 'audio':
            if (!item.urlAudioFile) {
              return false;
            }
            break;
        }

        // filter by privacy
        switch (this.filters.privacy) {
          case 'all':
            break;
          case 'mine':
            if (item.user.id != this.user.id) {
              return false;
            }
            break;
          case 'minePublic':
            if ((item.user.id != this.user.id) || (item.privacy != NotePrivacy.VISIBLE)) {
              return false;
            }
            break;
          case 'minePrivate':
            if ((item.user.id != this.user.id) || (item.privacy != NotePrivacy.PRIVATE)) {
              return false;
            }
            break;
          case 'others':
            if (item.user.id == this.user.id) {
              return false;
            }
            break;
        }

        // filter by favorites
        switch (this.filters.favorites) {
          case true:
            if (!item.isFavorite) {
              return false;
            }
            break;
        }

        return true;
      });
    },
    notesFilteredSorted() {
      if (!this.notesFiltered) {
        return [];
      }
      let notes = [...this.notesFiltered];
      switch (this.sortBy) {
        case 'recent':
          notes.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);      
          break;        
        case 'dateAsc':
          notes.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? -1 : 1);       
          break;        
        case 'dateDesc':
          notes.sort((a, b) => (a.date + a.time) < (b.date + b.time) ? 1 : -1);       
          break;       
        case 'title':
          notes.sort((a, b) => (a.title || '').localeCompare(b.title || ''));      
          break;
      }
      return notes;
    }
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    this.loadNotes();
  },
  methods: {
    ...mapActions({
      getEpisodeNotes: 'notes/getEpisodeNotes',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache'
    }),
    loadNotes() {
      this.loading = true;
      this.getEpisodeNotes(this.$route.params.episodeId)
      .then(response => {
        // add reactive field "selected"
        for (let i in response.data.notes) {
          response.data.notes[i].selected = false;
        }
        this.notes = response.data.notes;
        this.loading = false;
      });
    }
  }
}
</script>
