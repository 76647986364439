<template>
  <div class="mb-5">
    
    <div class="d-flex">
      <v-checkbox 
        v-model="selectedFields.privacy"
        hide-details 
        class="mr-2" 
      />
      <v-select
        v-model="item.privacy"
        :label="$t('form.label.privacy')"
        :items="privacyOptions"
        :rules="selectedFields.privacy ? [$validationRules.required] : []"
        :disabled="!selectedFields.privacy"
      />
    </div>

    <v-row>
      <v-col 
        cols="7" 
        class="py-0"
      >
        <div class="d-flex">
          <v-checkbox 
            v-model="selectedFields.date"
            hide-details 
            class="mr-2" 
          />
          <date-input
            v-model="item.date"
            :label="$t('form.label.date')"
            :rules="selectedFields.date ? [$validationRules.required] : []"
            :disabled="!selectedFields.date"
          />
        </div>
      </v-col>

      <v-col 
        cols="5" 
        class="py-0"
      >
        <div class="d-flex">
          <v-checkbox 
            v-model="selectedFields.time"
            hide-details 
            class="mr-2" 
          />
          <time-input
            v-model="item.time"
            :label="$t('form.label.hour')"
            :required="selectedFields.time"
            :disabled="!selectedFields.time"
          />
        </div>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import NotePrivacy from '@/modules/notes/model/NotePrivacy.js';
import DateInput from '@/components/form/DateInput';
import TimeInput from '@/components/form/TimeInput';

export default {
  components: {
    DateInput,
    TimeInput
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    selectedFields: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      privacyOptions: [
        { value: NotePrivacy.PRIVATE, text: this.$t('notes.model.privacy.private') },
        { value: NotePrivacy.VISIBLE, text: this.$t('notes.model.privacy.visible') }
      ]
    }
  }
}
</script>