import ExpenseAddPage from '@/modules/expenses/pages/ExpenseAddPage.vue';
import ExpenseEditPage from '@/modules/expenses/pages/ExpenseEditPage.vue';
import ExpensesListPage from '@/modules/expenses/pages/ExpensesListPage/ExpensesListPage.vue';


export default [
  {
    path: '/episode/:episodeId/expenses',
    name: 'expenses-list',
    component: ExpensesListPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/add-expense',
    name: 'expense-add',
    component: ExpenseAddPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/episode/:episodeId/expense/:expenseId',
    name: 'expense-edit',
    component: ExpenseEditPage,
    meta: { requiresAuth: true }
  },
]
