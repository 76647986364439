import axios from 'axios';

export default {
  namespaced: true,
  state: {
    count: 0
  },
  getters: {
    notificationsCount(state) {
      return state.count;
    }
  },
  actions: {
    getNotifications() {
      return axios.get('/api/notifications');
    },
    deleteNotifications() {
      return axios.delete('/api/notifications-delete');
    },
    getNotificationsCount({ state }) {
      axios.get('/api/notifications-number')
      .then(response => {
        state.count = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },    
    markAsViewed(context, notificationId) {
      return axios.post('/api/notification-view/' + notificationId);
    },
    createNotificationContentUploadFile(context, { fileId }) {
      return axios.post('/api/notification/content-upload/activity-file/' + fileId);
    },
    createNotificationContentUploadNote(context, { noteId }) {
      return axios.post('/api/notification/content-upload/note/' + noteId);
    },
  }
};
