const types = {
  documentation: {
    id: 'documentation',
    icon: 'mdi-passport',
    sort: 1
  },
  reservations: {
    id: 'reservations',
    icon: 'mdi-hail',
    sort: 2
  },
  health: {
    id: 'health',
    icon: 'mdi-heart-pulse',
    sort: 3
  },
  money: {
    id: 'money',
    icon: 'mdi-credit-card-sync-outline',
    sort: 4
  }
};

export default types;