<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'expenses-list', params: { episodeId: $route.params.episodeId }, query: { details: 1 } }">
      {{ $t('expenses.titles.expenses') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('expenses.titles.create') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">

      <v-container class="container--narrow">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <form-fields
            :item="item"
            context="add"
          />
        </v-form>

      </v-container>

      <div class="l-vertical__sticky-footer--mobile">
        <v-container class="container--narrow pt-0">
          <v-row>
            <v-col cols="6">
              <v-btn  
                outlined
                block
                :to="{ name: 'expenses-list', params: { episodeId: $route.params.episodeId }, query: { details: 1 }}"
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn 
                class="primary"
                block
                :disabled="!valid"
                :loading="saving"
                @click="submit"
              >{{ $t('actions.add') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FormFields from '../components/FormFields.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";

export default {
  components: {
    FormFields,
    PageTabs,
    PageTitle
  },
  data() {
    return {
      item: {
        date: this.$moment().format('YYYY-MM-DD'),
      },
      saving: false,
      valid: false,
    }
  },
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
      user: 'user/user'
    })
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    // default values
    this.item.currency = this.user.currency;
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      createExpense: 'expenses/createExpense',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
    }),
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      const data = {
        date: this.item.date,
        activityGroup: this.item.activityGroup.id,
        title: this.item.title,
        user: this.item.user.id,
        amount: this.item.amount.replace(',', '.'),
        currency: this.item.currency,
      }

      this.createExpense({
        episodeId: this.episode.id,
        data: data
      })
      .then(response => {
        this.addFlash({
          text: this.$t("expenses.messages.success.added"),
          type: 'success'
        });
        this.$router.push({ name: 'expenses-list', params: { episodeId: this.$route.params.episodeId }, query: { details: 1 } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
