<template>
  <v-menu 
    offset-y
    left
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :title="$t('actions.filter')"
        :class="{
          'ml-1': $vuetify.breakpoint.mdAndUp
        }"
        :disabled="!activityGroups || disabled"
        v-on="on"
      >
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">
      
        

      <!-- filter by group ------------------------------- -->

      <v-list-item 
        dense
        @click.stop.prevent="selectAllGroups()"
      >
        <v-checkbox
          v-model="groupsAll"
          :value="true"
          :label="$t('activities.texts.filter.all')"
          class="pl-0 mt-0"
          hide-details
          @click.prevent
        />
      </v-list-item>

      <v-list-item 
        v-for="group in activityGroups"
        :key="group.id"
        dense
        @click.stop.prevent="onGroupClick(group.id)"
      >
        <v-icon class="ml-6 mr-2">{{ groups[group.id] ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon> 
        <v-icon class="mr-2">{{ group.icon }}</v-icon> 
        {{ group.name }}
      </v-list-item>


      <hr class="mt-2">


      <v-row class="mx-0 my-2">
        <v-col 
          cols="6" 
          class="pr-2"
        >
          <v-btn
            outlined
            block
            small
          >{{ $t('actions.cancel') }}</v-btn>
        </v-col>
        <v-col 
          cols="6" 
          class="pl-2"
        >
          <v-btn 
            block
            small
            color="primary"
            @click="$emit('change', { groups: { ...groups }, groupsAll })"
          >{{ $t('actions.apply') }}</v-btn>
        </v-col>
      </v-row>

    </v-list>
  </v-menu>
</template>


<script>
export default {
  props: {
    activityGroups: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      groups: {},
      groupsAll: true,
    }
  },
  watch: {
    activityGroups(val) {
      if (val) {
        for (const group of this.activityGroups) {
          this.$set(this.groups, group.id, false);
        }
      }
    }
  },
  methods: {
    selectAllGroups() {
      this.groupsAll = true;
      for (let i in this.groups) {
        this.groups[i] = false;
      }
    },
    onGroupClick(id) {
      this.groups[id] = !this.groups[id]; 
      this.groupsAll = false
    }
  }
}
</script>