<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'collaborators-list', params: { episodeId: $route.params.episodeId } }">
      {{ $t('collaborators.titles.collaborators') }}
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('collaborators.titles.changeRole') }}
      </v-tab>
    </page-tabs>

    <div class="l-vertical__scroll l-vertical">
      
      <v-container class="container--narrow pb-0">

        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="onSubmit"
        >

          <div class="mb-4"><strong>{{ $t('collaborators.texts.selectRoleMultiple') }}</strong></div>

          <role-field
            :item="item"
          />

        </v-form> 
      </v-container>

      <div
        v-if="item"
        class="l-vertical__sticky-footer--mobile"
      >
        <v-container class="container--narrow pt-0">

          <v-row>
            <v-col cols="6">
              <v-btn
                outlined
                block
                :to="{ name: 'collaborators-list', params: { episodeId: $route.params.episodeId } }"
                exact
              >{{ $t('actions.cancel') }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                class="primary"
                :disabled="!valid"
                :loading="saving"
                block
                @click="submit"
              >{{ $t('actions.save') }}</v-btn>
            </v-col>
          </v-row>

        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from "@/components/layout/PageTitle.vue";
import RoleField from '@/modules/collaborators/components/formFields/RoleField.vue';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    PageTabs,
    PageTitle,
    RoleField,
  },
  data() {
    return {
      item: {
        rol: roles.EDITOR
      },
      saving: false,
      valid: false
    }
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      changeRole: 'collaborators/changeRole',
    }),
    async submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.saving = true;

      this.changeRole({ 
        episodeId: this.$route.params.episodeId,
        userIds: this.$route.query.users,
        role: this.item.rol,
      })
      .then(response => {
        this.addFlash({
          text: this.$t("messages.success.saved"),
          type: 'success'
        });
        this.$router.push({ name: 'collaborators-list', params: { 
          episodeId: this.$route.params.episodeId
        } });
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
