import Vue from 'vue';

const helperFunctions = {

  /**
   * convert API error to string
   * By default we return error.response.data.exception
   *  
   */
  errorToString(error) {
    if (!error.response) {
      return error.toString();
    }
    if (!error.response.data) {
      return error.response.toString();
    }
    let data = error.response.data;
    if (data.error) {
      data = data.error;
    }
    if (data.exception) {
      return data.exception;
    }    
    else if (data.message) {
      return data.message;
    }
    else {
      return data.toString();
    }
  },

  /* fix date format from API from '2019-11-26T00:00:00+00:00' to '2019-11-26' */
  fixApiDate(date) {
    return date ? date.substr(0, 10) : null;
  },

  /* fix time format from API from '2019-11-26T00:00:00+00:00' to '00:00' */
  fixApiTime(time) {
    return time ? time.substr(11, 5) : null;
  },

  round(value, decimals=0) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  },

  /**
   * International System of Units
   * https://en.wikipedia.org/wiki/Megabyte
   * 1MB = 1000kB = 1000000B
   * 1GB = 1000MB
   */

  bytesToMegaBytes: bytes => {
    return bytes / 1024 / 1024;
  },

  gigaBytesToBytes: gb => {
    return gb * 1024 * 1024 * 1024;
  },
  
  formatFileSize(bytes, decimalPoint = 0, decimalRound = 0) {
    // decimal round
    if (decimalRound) {
      bytes = Math.round(bytes / Math.pow(10, decimalRound)) * Math.pow(10, decimalRound);
    }
    if (bytes == 0) return '0 B';
    let k = 1024,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint)) + ' ' + sizes[i];
  },

  /* format number: coma as decimals separator, dot as thousand separator  */
  formatMoney(amount) {
    return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&#').replace('.', ',').replace(/#/g, '.');
  },

  /* format secondes to 'mm:ss' */
  formatSeconds(seconds) {
    let m = Math.floor(seconds / 60);
    let s = seconds % 60;

    return m.toString().padStart(2,'0') + ':' + s.toString().padStart(2,'0');
  },

  /* format number: change dot to coma and use dot as thousand separator  */
  numberWithDots(x) {
    return x.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  },

  stripTags(str) {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  },

  strPadLeft(string, pad, length) {
    return (new Array(length+1).join(pad)+string).slice(-length);
  },

  isAndroid() {
    return process.env.NODE_ENV == 'cordova' && window.cordova.platformId == 'android';
  },

  isIos() {
    return process.env.NODE_ENV == 'cordova' && window.cordova.platformId == 'ios';
  },

  isCordova() {
    return process.env.NODE_ENV == 'cordova';
  },

  platform() {
    if (this.isIos()) {
      return 'ios';
    }
    if (this.isAndroid()) {
      return 'android';
    }
    return 'web';
  },

  isBrowserIos() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  },

  isBrowserAndroid() {
    return /Android/i.test(navigator.userAgent);
  },

  isMobileBrowser() {
    return this.isBrowserAndroid() || this.isBrowserIos();
  },

  isMobile() {
    return this.isCordova() || this.isMobileBrowser();
  },

  downloadFile(url) {
    // mobile app
    if (this.isCordova()) {
      window.open(url);
    }
    // web browser
    else {
      let a = document.createElement('a');
      a.href = url;
      a.download = url.substr(url.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      console.log('click', a.click());
      document.body.removeChild(a); 
    }
  },

  normalizeString(s, transform = s => s.toLocaleLowerCase()) {
    let normalized = transform(s);
    if (String.prototype.normalize) {
      try {
        normalized = normalized.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } catch (e) {
        // just ignore
      }
    }
    return normalized;
  },

  autocompleteFilter(text, search) {
    return this.normalizeString(text).indexOf(this.normalizeString(search)) > -1;
  },

}

const plugin = {
  install () {
    Vue.helperFunctions = helperFunctions
    Vue.prototype.$helperFunctions = helperFunctions
  }
}

Vue.use(plugin);


export default helperFunctions;