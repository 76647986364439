<template>
  <div 
    v-if="!isCommentator"
    class="actions-menu"
  >
    <v-menu 
      v-model="open"
      offset-y
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-show="file"
          icon
          class="primary"
          small
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">

        <v-list-item
          v-if="file.canEdit"
          @click="edit"
        >
          <v-icon class="mr-3 primary--text">mdi-pencil-outline</v-icon> 
          {{ $t('actions.edit') }}
        </v-list-item> 

        <move-dialog 
          v-if="file.canEdit"
          :file="file"
          @success="$emit('deleted')" 
        />

        <download-item 
          :file="file"
          @close="open = false"
        />  

        <transfer-item 
          v-if="file.canEdit"
          :file="file"
          @close="open = false"
        /> 

        <delete-dialog
          v-if="file.canEdit"
          :activity-id="file.activity.id"
          :file-id="file.id"
          @success="$emit('deleted')"
        /> 

        <assign-as-episode-image
          v-if="showAssignAsEpisodeImage && canEditEpisode"
          :file="file"
          @saved="open = false"
        />

        <assign-as-activity-image
          v-if="showAssignAsActivityImage && canEditEpisode"
          :file="file"
          @saved="open = false"
        />  

        <share-item 
          :file="file"
          @close="open = false"
        />

      </v-list>
    </v-menu>
  </div>
</template>

<script>
import AssignAsActivityImage from './AssignAsActivityImage';
import AssignAsEpisodeImage from './AssignAsEpisodeImage';
import DeleteDialog from './DeleteDialog';
import DownloadItem from './DownloadItem.vue';
import EpisodeRoles from '@/modules/episodes/model/EpisodeRoles.js';
import MoveDialog from './MoveDialog';
import ShareItem from './ShareItem.vue';
import TransferItem from './TransferItem.vue';

export default {
  components: {
    AssignAsActivityImage,
    AssignAsEpisodeImage,
    DeleteDialog,
    DownloadItem,
    MoveDialog,
    ShareItem,
    TransferItem
  },
  props: {
    file: {
      type: Object,
      default: null
    },
    showAssignAsActivityImage: {
      type: Boolean,
      default: false
    },
    showAssignAsEpisodeImage: {
      type: Boolean,
      default: false
    },
    episodeRole: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    canEditEpisode() {
      return this.episodeRole === EpisodeRoles.EDITOR || this.episodeRole === EpisodeRoles.CREATOR;
    },
    isCommentator() {
      return this.episodeRole === EpisodeRoles.COMMENTATOR;
    }
  },
  methods: {
    edit() {
      this.$statusBarHelper.setColorDefault();
      this.$router.push({ name: 'activity-file-edit', params: {
        activityId: this.file.activity.id,
        fileId: this.file.id
      }});
    }
  }
}
</script>