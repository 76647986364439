<template>
  <div>

    <v-select
      v-model="item.privacy"
      :label="$t('form.label.privacy')"
      :items="privacyOptions"
    />

    <v-text-field
      v-model="item.title"
      :label="$t('notes.form.label.title')"
      :rules="[$validationRules.required]"
      type="text"
      maxlength="255"
    />

    <v-textarea
      v-if="!hideText"
      v-model="item.text"
      :label="$t('notes.form.label.text')"
      :rules="[$validationRules.required]"
      rows="4"
      :auto-grow="true"
      maxlength="2000"
    />

    <slot></slot>

    <v-row>
      <v-col
        cols="7"
        class="py-0"
      >
        <date-input
          v-model="item.date"
          :label="$t('form.label.date')"
          clearable
          required
        />
      </v-col>

      <v-col
        cols="5"
        class="py-0"
      >
        <time-input
          v-model="item.time"
          :label="$t('form.label.hour')"
          required
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActivityPrivacy from '@/modules/activities/model/ActivityPrivacy';
import DateInput from '@/components/form/DateInput';
import TimeInput from '@/components/form/TimeInput';

export default {
  components: {
    DateInput,
    TimeInput
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    hideText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      privacyOptions: [
        { value: ActivityPrivacy.PRIVATE, text: this.$t('activities.model.privacy.private') },
        { value: ActivityPrivacy.VISIBLE, text: this.$t('activities.model.privacy.visible') }
      ]
    }
  }
}
</script>
