import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import './directives/onClickOutside';
import './plugins/imageFunctions';
import './plugins/helperFunctions';
import './plugins/statusBarHelper';
import './plugins/validationRules';
import './plugins/videoFunctions';
import axios from './plugins/axios';
import i18n from './plugins/i18n';
import router from './router';
import store from './store';
const moment = require('moment');
require('moment/locale/es');
import VueLazyload from 'vue-lazyload';
import vuetify from './plugins/vuetify';
import '@/assets/css/custom-vuetify.css';
import '@/assets/css/custom.css';
import '@/modules/activities/css/activity-group-colors.css';
import { VueHammer } from 'vue2-hammer';
import smoothscroll from 'smoothscroll-polyfill';
import SmartBanner from 'smart-app-banner';
import 'smart-app-banner/dist/smart-app-banner.css';
import '@/scripts/cordovaOpenWith.js';

Vue.config.productionTip = false;

Vue.use(require('vue-moment'), {
  moment
});
Vue.moment.locale(i18n.locale);

Vue.use(VueLazyload, {
  error: 'img/icon/file-error.png',
  loading: 'img/loading-image.gif',
});

axios(store);

// custom directive v-visible
Vue.directive('visible', function (el, binding) {
  el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

Vue.use(VueHammer);


new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

smoothscroll.polyfill();


if (process.env.NODE_ENV != 'cordova') {
  new SmartBanner({
    daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    appStoreLanguage: i18n.locale, // language code for the App Store (defaults to user's browser language)
    title: 'Timlapp',
    author: 'KONTROL-AT Developments',
    button: 'VER',
    store: {
        ios: 'En App Store',
        android: 'En Google Play',
        windows: 'En Windows store'
    },
    price: {
        ios: 'OBTENER',
        android: 'OBTENER',
        windows: 'OBTENER'
    }
    // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
    // , icon: '' // full path to icon image if not using website icon image
    // , force: 'ios' // Uncomment for platform emulation
  });
}
