<template>
  <div class="l-vertical__grow l-vertical">
    <page-title :back-to="{ name: 'episodes-list'}">
      {{ episode ? episode.title : '' }}
      <template slot="buttons">
        <app-fab 
          v-if="episode && (episode.rol != roles.COMMENTATOR)"
          add-activity
          add-files
          add-notes
        />
      </template>
    </page-title>

    <episode-tabs
      :episode="episode"
    />

    <div class="l-vertical__scroll">
      <loading v-if="loading" />

      <v-container 
        v-if="!loading && statistics"
        class="container--narrow"
      >
        
        <bar-chart
          :statistics="statistics" 
          :labels="[
            $t('episodes.titles.statistics.photosMinePublic'),
            $t('episodes.titles.statistics.photosOthersPublic'),
            $t('episodes.titles.statistics.photosMinePrivate')
          ]"
          class="mb-8"
        />

        <section-photos 
          :statistics="statistics.photo" 
          class="mb-3"
        />
        <section-videos 
          :statistics="statistics.video" 
          class="mb-3"
        />
        <section-notes 
          :statistics="statistics.note" 
        />

      </v-container>

    </div>

    <menu-bottom />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppFab from '@/components/layout/AppFab/AppFab.vue';
import BarChart from "./BarChart";
import EpisodeTabs from '@/modules/episodes/components/EpisodeTabs.vue';
import Loading from '@/components/util/Loading';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import PageTitle from "@/components/layout/PageTitle";
import roles from '@/modules/episodes/model/EpisodeRoles.js';
import SectionNotes from "./SectionNotes";
import SectionPhotos from "./SectionPhotos";
import SectionVideos from "./SectionVideos";

export default {
  components: {
    AppFab,
    BarChart,
    EpisodeTabs,
    Loading,
    MenuBottom,
    PageTitle,
    SectionNotes,
    SectionPhotos,
    SectionVideos
  },
  data() {
    return {
      loading: false,
      statistics: null,
      roles: roles
    }
  },  
  computed: {
    ...mapGetters({
      episode: 'episodes/cachedEpisode',
    })
  },
  created() {
    // load episode
    this.loadEpisodeToCache(this.$route.params.episodeId);

    this.loadStatistics();
  },
  methods: {
    ...mapActions({
      getEpisodeStatistics: 'episodes/getEpisodeStatistics',
      loadEpisodeToCache: 'episodes/loadEpisodeToCache',
    }),
    loadStatistics() {
      this.loading = true;

      this.getEpisodeStatistics(this.$route.params.episodeId)
      .then(response => {
        if (response) {
          this.statistics = response.data.statistics;
          this.loading = false;
        }
      })
    }
  }
}
</script>