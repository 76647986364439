<template>
  <span>
    <v-btn
      icon
      class="primary ml-5"
      @click.stop="open = true"
    >
      <v-icon>mdi-tune-variant</v-icon>
    </v-btn>

    <v-dialog 
      v-model="open" 
      width="450"
    >
      <v-container class="pt-3 pb-6 px-6">
        <div class="subtitle-1 text-center mt-3 mb-4">{{ $t('files.dialogs.slideshowSettings.title') }}</div>
        
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
          @keyup.native.enter="submit"
        >

          <v-row>
            <v-col 
              cols="9" 
              class="d-flex align-center"
            >
              {{ $t('files.dialogs.slideshowSettings.interval') }}
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="settings.interval"
                class="pt-0"
                :items="intervalOptions"
                hide-details
              />
            </v-col>
          </v-row>

          <v-select
            v-model="settings.transition"
            :label="$t('files.dialogs.slideshowSettings.transition')"
            :items="transitionOptions"
            item-value="value"
            item-text="label"
            hide-details
          />
                    
        </v-form> 
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn 
              class="primary"
              block
              small
              :disabled="!valid"
              @click="submit"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-dialog>

  </span>
</template>

<script>
export default {
  data () {
    return {
      intervalOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      settings: {
        interval: 3,
        transition: 'reveal'
      },
      open: false,
      transitionOptions: [
        { value: 'reveal', label: this.$t('files.model.transitions.reveal') },
        { value: 'iris', label: this.$t('files.model.transitions.iris') },
        { value: 'flip', label: this.$t('files.model.transitions.flip') },
        { value: 'fall', label: this.$t('files.model.transitions.fall') },
        { value: 'dissolve', label: this.$t('files.model.transitions.dissolve') }
      ],
      valid: false
    }
  },
  created() {
    this.$emit('change', this.settings);
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.$emit('change', this.settings);
      this.open = false;
    },
    validateInterval() {
      if (this.settings.interval > 10) {
        this.settings.interval = 10;
      }
    }
  }
};
</script>



