<template>
  <v-select
    v-model="filters.activityGroup"
    :label="$t('activities.form.label.group')"
    :items="groups"
    item-text="name"
    item-value="id"
    return-object
    :loading="loadingGroups"
    clearable
    hide-details
    outlined
    dense
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      groups: [],
      loadingGroups: false,
    }
  },
  created() {
    // load groups
    this.loadingGroups = true;
    this.getActivityGroups()
    .then(response => {
      this.groups = response.data;
      this.loadingGroups = false;
    });
  },
  methods: {
    ...mapActions({
      getActivityGroups: 'activities/getActivityGroups',
    })
  }
}
</script>