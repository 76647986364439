<template>
  <div>

    <v-text-field
      v-model="item.name"
      :label="$t('melodies.form.label.title') + ' *'"
      :placeholder="$t('melodies.form.placeholder.title')"
      persistent-placeholder
      :rules="[$validationRules.required]"
      type="text"
      maxlength="255"
    />
    
    <v-text-field
      v-model="item.type"
      :label="$t('melodies.form.label.type')"
      :placeholder="$t('melodies.form.placeholder.type')"
      persistent-placeholder
      type="text"
      maxlength="255"
    />
    
    <v-text-field
      v-model="item.author"
      :label="$t('melodies.form.label.author')"
      :placeholder="$t('melodies.form.placeholder.author')"
      persistent-placeholder
      type="text"
      maxlength="255"
    />

  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  }
}
</script>
