<template>
  <div>
    <upload-image
      v-model="item.imageFile"
      input-id="episodeImage"
      class="mb-4"
      :url-image="item.urlImageThumbnails ? item.urlImageThumbnails.medium_ + '?bearer=' + apiToken : ''"
      :scale-image="false"
      @error="onFileError"
    />
    <v-text-field
      ref="title"
      v-model="item.title"
      :label="$t('episodes.form.label.title') + ' *'"
      :rules="[$validationRules.required]"
      type="text"
      maxlength="32"
    />
    <date-input 
      v-model="item.date" 
      :label="$t('episodes.form.label.date') + ' *'" 
      required 
    />
    <v-textarea
      v-model="item.description"
      :label="$t('episodes.form.label.description')"
      auto-grow
      hide-details
      @keyup.stop
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import DateInput from "@/components/form/DateInput";
import UploadImage from "@/components/form/UploadImage/UploadImage.vue";

export default {
  components: {
    DateInput,
    UploadImage
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
  },  
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    onFileError(error) {
      this.addFlash({
        text: error,
        type: 'error'
      });
    }
  }
};
</script>