import EpisodeAccessesListPage from '@/modules/episodeAccesses/pages/EpisodeAccessesListPage/EpisodeAccessesListPage.vue';

export default [
   {
    path: '/episode/:episodeId/accesses',
    name: 'episode-accesses',
    component: EpisodeAccessesListPage,
    meta: { requiresAuth: true }
  }
]
