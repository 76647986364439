<template>
  <div
    class="avatar"
    :class="{ 'avatar--clicable': !disableModal && src }"
    :style="{ width: size + 'px', height: size + 'px'}" 
    @click="(!disableModal && src) ? modal = true : null" 
  >
    <img 
      v-if="src"
      :src="src + '?bearer=' + apiToken"
      :alt="alt" 
      class="avatar__img"
    >
    <div 
      v-else 
      class="avatar__placeholder"
    >
      <v-icon 
        color="grey"
        :size="size * 0.65"
      >mdi-account-outline</v-icon>
    </div>

    <v-dialog
      v-if="!disableModal && src"
      v-model="modal"
      fullscreen
    >
      <div 
        class="avatar__modal"
        @click="modal = false"
      >
        <img 
          v-lazy="src + '?bearer=' + apiToken"
          :alt="alt" 
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    src: {
      type: String,
      default: null
    },   
    alt: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 56
    },
    disableModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: false
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken'
    }),
  },
  watch: {
    modal(val) {
      if (val) {
        document.addEventListener("keydown", this.keyDownHandler);
      }
      else {
        document.removeEventListener("keydown", this.keyDownHandler);
      }
    }
  },
  methods: {
    keyDownHandler(e) {
      if (e.keyCode == 27) { // esc
        this.modal = false;
      }
    }
  }
}
</script>

<style scoped>
.avatar {
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--color-image-background);
  max-width: none;
  overflow: hidden;
}
.avatar--clicable {
  cursor: pointer;
}
.avatar__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.avatar__placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar__modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.95);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>