<template>
  <div class="user-field">
    <progress-linear :loading="loading" />

    <v-select
      v-if="!loading"
      v-model="item.user"
      :label="$t('expenses.form.label.user')"
      :items="collaborators"
      item-text="title"
      item-value="id"
      return-object
      :rules="rules"
    >
      <template v-slot:item="data">
        <user-item 
          :item="data.item" 
          class="my-1"
          disable-modal
        />
      </template>
      <template v-slot:selection="data">
        <user-item 
          :key="data.item.id"
          :item="data.item"  
          class="my-2"
          disable-modal
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserItem from '@/modules/user/components/UserItem';
import ProgressLinear from '@/components/util/ProgressLinear';
import roles from '@/modules/episodes/model/EpisodeRoles.js';

export default {
  components: {
    ProgressLinear,
    UserItem
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    episodeId: {
      type: [Number, String],
      default: null
    },
    rules: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      collaborators: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    noData() {
      return !this.loading && !this.collaborators;
    }
  },
  created() {
    // load collaborators
    this.loading = true;
    this.getCollaborators(this.episodeId)
    .then(response => {
      this.collaborators = response.data.collaborators.filter(item => {
        return item.rol != roles.COMMENTATOR;
      });
      this.loading = false;
    });
  },  
  methods: {
    ...mapActions({
      getCollaborators: 'collaborators/getCollaborators'
    })
  }
} 
</script>

<style scoped>
.user-field {
  min-height: 70px;
}
</style>