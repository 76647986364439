<template>
  <v-dialog
    v-model="open"
    fullscreen
  >
    <div 
      v-if="currentFile"
      ref="fileModal"
      class="file-details"
      @fullscreenchange="onFullscreenChange"
    >
      <div 
        v-if="!fullscreen"
        class="file-details__header"
        :class="showControls ? 'file-details__header--visible' : ''"
      >
        <v-btn
          icon
          class="primary"
          :class="{ 'mr-2': !shared }"
          small
          :title="$t('actions.close')"
          @click="$emit('close')"
        >
          <v-icon>mdi-arrow-left</v-icon> 
        </v-btn>
        <v-btn
          v-if="shared"
          text
          small
          dark
          class="primary--text mr-2"
          @click="$emit('close')"
        >
          {{ $t('actions.back') }} 
        </v-btn>
        
        <template v-if="currentFile">          
          <v-icon
            v-if="currentFile.privacy == FilePrivacy.PRIVATE"
            class="mr-2"
            small
          >mdi-lock-outline</v-icon>
          <div 
            class="overline small file-details__date"
            :class="{ 'file-details__date--shared': shared }"
          >
            {{ currentFile.date ? $moment(currentFile.date).format($t('locale.formatDate')) : '' }}
            {{ currentFile.time ? $helperFunctions.fixApiTime(currentFile.time) : '' }}
          </div>
        </template>

        <v-spacer />

        <actions-menu 
          v-if="!hideMenu"
          ref="actionsMenu"
          :file="currentFile" 
          :show-assign-as-episode-image="showAssignAsEpisodeImage"
          :show-assign-as-activity-image="showAssignAsActivityImage"
          :episode-role="episodeRole"
          @deleted="$emit('deleted')"
        />
      </div>


      <div 
        class="file-details__center"
        @click="onClick"
      >

        <slick
          v-if="files && files.length"
          v-show="!slideshowPlaying"
          ref="slick"
          :options="{
            arrows: false,
            infinite: true,
            initialSlide: fileIndex,
            lazyLoad: 'ondemand'
          }"
          @beforeChange="handleBeforeChange"
          @afterChange="handleAfterChange"
          @swipe="slideshowStop"
        >
          <template
            v-if="files && files.length"
          >
            <div
              v-for="item in files"
              :key="item.id"
              class="img-wrap"
            >
              <pinch-zoom
                v-if="item.type == 'photo'"
                :ref="'zoom-' + item.id"
                :min-scale="1"
                :auto-zoom-out="true"
              >
                <img
                  :ref="'img-' + item.id"
                  v-hammer:swipe.down="onSwipeDown"
                  :data-lazy="item.urlImageThumbnails ? item.urlImageThumbnails.large_ + '?' + tokenParameter : ''"
                  :alt="item.name"
                  class="file-details__image"
                />
              </pinch-zoom>
              <video
                v-if="item.type == 'video'"
                :id="'video-' + item.id"
                :ref="'video-' + item.id"
                class="file-details__video"
                :src="item.urlFilename + '?' + tokenParameter"
                controls
              ></video>
            </div>
          </template>
        </slick>

        <slide-show
          v-if="files && files.length && slideshowPlaying"
          :files="files"
          :start-index="fileIndex"
          :settings="slideshowSettings"
          @change="fileIndex = $event"
        />

      </div>

      <div 
        class="file-details__footer container--fluid"
        :class="{ 
          'file-details__footer--visible': showControls,
          'file-details__footer--fullscreen': fullscreen
        }"
      >
        <div
          v-if="currentFile && shared && this.$route.params.episodeId && sharedEpisode"
          class="mt-1 mb-1 text-center small"
        >{{ sharedEpisode.title }}</div>
        <div
          v-if="currentFile && shared"
          class="mt-1 mb-1 text-center small"
        >{{ currentFile.activity.name }}</div>
        <div
          v-if="$vuetify.breakpoint.smAndDown && currentFile"
          class="mt-1 mb-2 text-center small"
        >{{ currentFile.description }}</div>
        <div class="file-details__footer-buttons">
          <v-btn
            v-if="!hideArrows && $vuetify.breakpoint.mdAndUp"
            icon
            dark
            outlined
            class="primary--text mr-5"
            @click="prev"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-btn
            v-if="!hideSlideshow"
            text
            small
            dark
            class="file-details__slideshow-button"
            @click="slideshowPlaying ? slideshowStop() : slideshowPlay()"
          >
            <v-icon left>{{ slideshowPlaying ? 'mdi-pause' : 'mdi-image-move' }}</v-icon>
            <span class="small">{{ slideshowPlaying ? $t('files.actions.stopSlideshow') : $t('files.actions.showSlideshow') }}</span>           
          </v-btn>

          <div 
            v-if="$vuetify.breakpoint.mdAndUp"
            class="mb-1 mx-auto text-center small"
          >
            {{ currentFile ? currentFile.description : '' }}
          </div>

          <slideshow-settings-dialog 
            @change="slideshowSettings = $event"
          />

          <v-btn
            v-if="!hideFullscreen"
            text
            small
            dark
            class="file-details__fullscreen-button ml-5"
            @click="toggleFullScreen"
          >
            <v-icon left>{{ fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
            <span class="small">{{ fullscreen ? $t('files.actions.exitFullscreen') : $t('files.actions.fullscreen') }}</span>           
          </v-btn>

          <v-btn
            v-if="!hideArrows && $vuetify.breakpoint.mdAndUp"
            icon
            outlined
            dark
            class="primary--text ml-5"
            @click="next"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>

    </div>

    <melody-player 
      ref="melodyPlayer" 
      :melody-url="melodyUrl"
    />

  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActionsMenu from './ActionsMenu/ActionsMenu';
import FilePrivacy from '@/modules/files/model/FilePrivacy.js';
import MelodyPlayer from './MelodyPlayer.vue';
import PinchZoom from 'vue-pinch-zoom';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import SlideShow from './SlideShow/SlideShow.vue';
import SlideshowSettingsDialog from './SlideshowSettingsDialog.vue';

export default {
  components: {
    MelodyPlayer,
    ActionsMenu,
    PinchZoom,
    Slick,
    SlideShow,
    SlideshowSettingsDialog
  },
  props: {
    open: {
      type: Boolean,
      default: null
    },
    file: {
      type: Object,
      default: null
    },
    files: {
      type: Array,
      default: Array
    },
    showAssignAsActivityImage: {
      type: Boolean,
      default: false
    },
    showAssignAsEpisodeImage: {
      type: Boolean,
      default: false
    },
    episodeRole: {
      type: String,
      default: null
    },
    hideMenu: {
      type: Boolean,
      default: false
    },
    hideSlideshow: {
      type: Boolean,
      default: false
    },
    hideFullscreen: {
      type: Boolean,
      default: false
    },
    hideArrows: {
      type: Boolean,
      default: false
    },
    melodyUrl: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      FilePrivacy: FilePrivacy,
      fileIndex: 0,
      fullscreen: false,
      preloadedFiles: {},
      showControls: true,
      slideshowPlaying: false,
      slideshowSettings: null,
      keyFlag: false
    }
  },
  computed: {
    ...mapGetters({
      apiToken: 'user/apiToken',
      sharedEpisode: 'shared/cachedEpisode'
    }),
    currentFile() {
      return this.files ? this.files[this.fileIndex] : null;
    },
    nextFile() {
      return this.files[this.$refs.slick.currentSlide() + 1];
    },
    shared() {
      return this.$route.meta.shared;
    },
    tokenParameter() {
      return this.$route.meta.shared 
        ? 'hash=' + this.$route.query.hash 
        : 'bearer=' + this.apiToken;
    },
  },
  watch: {
    open(val) {
      // on open, find index of current file and move slider
      if (val && this.file && this.files) {
        this.fileIndex = this.files.findIndex(item => item.id == this.file.id);
        if (this.$refs.slick) {
          this.$refs.slick.goTo(this.fileIndex);
        }
        // preload next 3 files
        this.preloadFile(this.fileIndex + 1);
        this.preloadFile(this.fileIndex + 2);
        this.preloadFile(this.fileIndex + 3);
        // play video
        if (this.isValidVideo(this.currentFile)) {
          this.$nextTick(() => {
            document.getElementById('video-' + this.currentFile.id).play();
          });
        }

        this.$statusBarHelper.setColorBlack();
        document.addEventListener("keydown", this.keyDownHandler);
      }
      // on close
      else {
        this.pauseAllVideos();
        this.showControls = true;
        this.$statusBarHelper.setColorDefault();
        document.removeEventListener("keydown", this.keyDownHandler);
        this.$emit('close');
      }
      this.slideshowStop();
    },
  },
  methods: {
    handleBeforeChange() {
      this.pauseAllVideos();

      // reset zoom
      let el = this.$refs['zoom-' + this.currentFile.id] ? this.$refs['zoom-' + this.currentFile.id][0] : null;
      if (el && el.isZoomedIn) {
        el.toggleZoom();
      }
    },
    handleAfterChange() {
      this.fileIndex = this.$refs.slick.currentSlide();
      // preload next 3 files
      this.preloadFile(this.$refs.slick.currentSlide() + 1);
      this.preloadFile(this.$refs.slick.currentSlide() + 2);
      this.preloadFile(this.$refs.slick.currentSlide() + 3);

      this.keyFlag = !this.keyFlag;
    },
    pauseAllVideos() {
      this.files.map(file => {
        if (this.isValidVideo(file)) {
          const player = document.getElementById('video-' + file.id);
          player.pause();
          player.currentTime = 0;
        }
      });
    },
    isValidVideo(file) {
      return file && file.type == 'video';
    },
    preloadFile(fileIndex) {
      if (!this.preloadedFiles[fileIndex]) {
        const file = this.files[fileIndex];
        if (file && file.urlImageThumbnails) {
          const img = new Image();
          img.src = file.urlImageThumbnails.large_ + '?' + this.tokenParameter;
        }
        this.preloadedFiles[fileIndex] = true;
      }
    },
    next() {
      this.$refs.slick.next();
      this.slideshowStop();
    },
    prev() {
      this.$refs.slick.prev();
      this.slideshowStop();
    },
    slideshowPlay() {
      this.slideshowPlaying = true;
      this.$refs.melodyPlayer.play();
    },
    slideshowStop() {
      this.slideshowPlaying = false;
      if (this.$refs.slick) {
        this.$refs.slick.goTo(this.fileIndex);
      }
      if (this.$refs.melodyPlayer) {
        this.$refs.melodyPlayer.pause();
      }
    },
    onSlideshowStopOnVideo() {
      this.slideshowStop(true);

      // play video
      const player = document.getElementById('video-' + this.currentFile.id);
      player.currentTime = 0;
      player.play();
    },
    toggleFullScreen() {
      const elem = document.documentElement;
      if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } 
      else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    onFullscreenChange() {
      // This becomes important when the user doesn't use the button to exit
      // fullscreen but hits ESC on desktop, pushes a physical back button on
      // mobile etc.

      this.fullscreen = document.fullscreenElement !== null;
    },
    toggleControls() {
      this.showControls = !this.showControls;
      if (this.showControls) {
        this.$statusBarHelper.show();
      }
      else {
        this.$statusBarHelper.hide();
      }
      this.fixIosImage();
    },
    fixIosImage() {
      // fix image on ios
      if (this.$helperFunctions.isIos()) {
        let el = this.$refs['img-' + this.currentFile.id][0];
        if (!el) {
          return;
        }
        
        setTimeout(() => {
          el.style.display = 'none';
          setTimeout(() => {
            el.style.display = 'block';
          }, 1);
        }, 150);

        setTimeout(() => {
          el.style.display = 'none';
          setTimeout(() => {
            el.style.display = 'block';
          }, 1);
        }, 300);
      }
    },
    keyDownHandler(e) {
      switch (e.keyCode) {
        case 27: // esc
          this.$emit('close');
          break;
        case 37: // left
          this.prev();
          break;
        case 39: // right
          this.next();
          break;
      }
    },
    onSwipeDown() {
      this.$emit('close');
    },
    onClick() {
      this.toggleControls();
    },
  }
};
</script>

<style scoped>
.file-details {
  background-color: rgba(0,0,0,0.95);
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.file-details__header {
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}
.file-details__center {
  position: relative;
  z-index: 0;
  flex-grow: 1;
  overflow-y: hidden;
  height: 100%;
}
.file-details__footer {
  flex-grow: 0;
  padding: 12px 18px;
}
.file-details__footer--fullscreen {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: transparent;
}
.file-details__footer--fullscreen /deep/ .theme--dark.v-btn {
  background-color: rgba(0, 0, 0, 0.3);
}
.file-details__footer-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.file-details__slideshow-button {
  color: var(--v-primary-base);
}
.file-details__fullscreen-button {
  color: var(--v-primary-base);
}
.img-wrap {
  position: relative;
  background-image: url('/img/loading-image.gif');
  background-position: center center;
  min-height: 200px;
  height: 100%;
  display: flex !important;
  justify-content: center;
}
.file-details__image,
.file-details__video {
  max-height: 100%;
  flex: 0 0;
}
.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-size: 60px !important;
}
.video-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(calc(-50%), calc(-50% - 17px));
}
.video-progress-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(calc(-50%), calc(-50% - 37px));
}
.file-details__video {
  width: 100%;
}

.file-details__date--shared {
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
}

/* Slick Slider */
.slick-slider,
/deep/ .slick-track,
/deep/ .slick-list,
/deep/ .slick-slide > div {
  height: 100%;
}

/* Pinch Zoom */
/deep/ .pinch-zoom-content {
  height: 100%;
}
/deep/ .pz-zoom-button {
  display: none;
}


/* mobile */
@media (max-width: 1024px) {
  .file-details {
    background-color: rgba(0,0,0,0.99);
  }
  .file-details__header {
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: rgba(0,0,0,0.75);
    padding: 6px 6px 0 6px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.35s, opacity 0.35s ease-out;
  }
  .file-details__header--visible {
    visibility: visible;
    opacity: 1;
    transition-delay:0s;
  }
  .file-details__center {
    cursor: pointer;
  }
  .file-details__footer {
    position: absolute;
    width: 100%;
    z-index: 1;
    bottom: 0;
    background-color: rgba(0,0,0,0.75);
    padding: 6px 8px 10px 6px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.35s, opacity 0.35s ease-out;
  }
  .file-details__footer--visible {
    visibility: visible;
    opacity: 1;
    transition-delay:0s;
  }
  .file-details__slideshow-button {
    margin-right: auto;
  }
  .file-details__fullscreen-button {
    display: none;
  }
}
</style>
