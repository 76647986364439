<template>
  <span class="text-center">
    <v-btn 
      icon
      class="notifications__button-delete"
      color="error"
      :title="$t('notifications.texts.delete')"
      @click="open = true"
    >
      <v-icon>mdi-trash-can-outline</v-icon>
    </v-btn>

    <v-dialog 
      v-model="open" 
      max-width="450"
    >
      <v-container class="py-4 px-6">
        <div class="subtitle-1 text-center mt-2 mb-6">{{ $t('notifications.texts.deleteConfirm') }}</div>
      </v-container>

      <hr>

      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              outlined
              block
              small
              @click="open = false"
            >{{ $t('actions.cancel') }}</v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="error"
              :loading="saving"
              block
              small
              @click="submit"
            >{{ $t('actions.delete') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
      
    </v-dialog>
  </span>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  props: {
    onDeleted: {
      type: Function,
      default: null
    } 
  },
  data() {
    return {
      open: false,
      saving: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    ...mapMutations([
      'addFlash'
    ]),
    ...mapActions({
      deleteNotifications: 'notifications/deleteNotifications'
    }),
    submit() {
      this.saving = true;

      this.deleteNotifications()
      .then(response => {
        this.addFlash({
          text: this.$t("notifications.messages.success.deleted"),
          type: 'success'
        });
        this.onDeleted();
      })
      .catch(error => {
        this.addFlash({
          text: this.$helperFunctions.errorToString(error),
          type: 'error'
        });
      })
      .finally(() => {
        this.saving = false;
        this.open = false;
      });
    }
  }
};
</script>

