<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
      enable-back-to-menu
    >
      {{ $t('trash.titles.trash') }} 
      <template slot="buttons">

        <selection-toolbar
          v-if="items.length"
          :items="items"
          @success="loadData"
        />

        <sort-menu 
          v-if="items.length"
          :sort-by.sync="sortBy"
          :sort-direction.sync="sortDirection"
        />

      </template>
    </page-title>
    
    <page-tabs>
      <v-tab>
        {{ $t('trash.titles.deletedElements') }}
      </v-tab>
    </page-tabs>
        
    <progress-linear :loading="loading" />  

    <div class="l-vertical__scroll">
      
      <v-container class="container--narrow">

        <v-alert 
          v-if="!loading && !items.length"
          type="info"
        >
          {{ $t('trash.texts.noElements') }}
        </v-alert>

        <template v-for="item in items">
          <episode-item
            v-if="item.itemType=='episode'"
            :key="item.id + item.itemType"
            :item="item"
            class="trash-item"
          />

          <activity-item
            v-else-if="item.itemType=='activity'"
            :key="item.id + item.itemType"
            :item="item"
            class="trash-item"
          />

          <file-item
            v-else-if="item.itemType=='file'"
            :key="item.id + item.itemType"
            :item="item"
            class="trash-item"
            @click="files = [item]; openFile = item"
          />

          <note-item
            v-else-if="item.itemType=='note'"
            :key="item.id + item.itemType"
            :item="item"
            class="trash-item"
            @play="onPlay"
            @view="onViewNote"
          />

          <melody-item
            v-else-if="item.itemType=='melody'"
            :key="item.id + item.itemType"
            :item="item"
            class="trash-item"
            @play="onPlay"
          />
        </template>

      </v-container>

    </div>

    <file-details-modal
      :file="openFile"
      :files="files"
      :open="!!(openFile && openFile.id)"
      hide-menu
      hide-slideshow
      hide-fullscreen
      hide-arrows
      @close="openFile = null; files = []"
    />

    <fullscreen-audio-player 
      ref="fullscreenAudioPlayer"
      :playlist="playlist"
    />

    <note-text-dialog 
      ref="noteTextDialog"
      :item="note"
    />

    <menu-bottom />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ActivityItem from './ActivityItem.vue';
import EpisodeItem from './EpisodeItem.vue';
import FileDetailsModal from '@/modules/files/components/FileDetailsModal/FileDetailsModal.vue';
import FileItem from './FileItem.vue';
import FullscreenAudioPlayer from '@/components/FullscreenAudioPlayer.vue';
import MelodyItem from './MelodyItem.vue';
import MenuBottom from '@/components/layout/MenuBottom.vue';
import NoteItem from './NoteItem.vue';
import NoteTextDialog from '@/modules/notes/components/NoteTextDialog.vue';
import PageTabs from '@/components/layout/PageTabs/PageTabs.vue';
import PageTitle from '@/components/layout/PageTitle.vue';
import ProgressLinear from '@/components/util/ProgressLinear';
import SelectionToolbar from './SelectionToolbar/SelectionToolbar.vue';
import SortMenu from './SortMenu';

export default {
  components: {
    ActivityItem,
    EpisodeItem,
    FileDetailsModal,
    FileItem,
    FullscreenAudioPlayer,
    MelodyItem,
    MenuBottom,
    NoteItem,
    NoteTextDialog,
    PageTabs,
    PageTitle,
    ProgressLinear,
    SelectionToolbar,
    SortMenu
  },
  data() {
    return {
      files: [],
      items: [],
      loading: false,
      openFile: null,
      playlist: null,
      note: null,
      sortBy: 'name',
      sortDirection: 1
    };
  },
  watch: {
    sortBy() {
      this.sortData();
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      getActivities: 'trash/getActivities',
      getEpisodes: 'trash/getEpisodes',
      getFiles: 'trash/getFiles',
      getNotes: 'trash/getNotes',
      getMelodies: 'trash/getMelodies',
    }),
    loadData() {
      this.loading = true;

      Promise.all([
        this.getEpisodes(),
        this.getActivities(),
        this.getFiles(),
        this.getNotes(),
        this.getMelodies(),
      ])
      .then(response => {
        this.items = [];
        let i;

        // episodes
        for (i in response[0].data.episodes) {
          this.items.push({
            ...response[0].data.episodes[i],
            selected: false,
            itemType: 'episode',
          })
        }

        // activities
        for (i in response[1].data.activities) {
          this.items.push({
            ...response[1].data.activities[i],
            selected: false,
            itemType: 'activity'
          })
        }

        // files
        for (i in response[2].data.activity_files) {
          this.items.push({
            ...response[2].data.activity_files[i],
            selected: false,
            itemType: 'file'
          })
        }

        // notes
        for (i in response[3].data.notes) {
          this.items.push({
            ...response[3].data.notes[i],
            selected: false,
            itemType: 'note',
          })
        }

        // melodies
        for (i in response[4].data.melodies) {
          this.items.push({
            ...response[4].data.melodies[i],
            selected: false,
            itemType: 'melody',
          })
        }

        this.sortData();
        this.loading = false;
      });
    },
    sortData() {
      this.items.sort((a, b) => {
        return (a[this.sortBy] || '').localeCompare(b[this.sortBy] || '') * this.sortDirection;
      });
    },
    clearSelection() {
      let i;
      for (i in this.items) {
        this.items[i].selected = false;
      }
    },
    selectAll() {
      let i;
      for (i in this.items) {
        this.items[i].selected = true;
      }
    },
    onPlay(src) {
      this.playlist = [src];
      this.$refs.fullscreenAudioPlayer.open();
    },
    onViewNote(item) {
      this.note = item;
      this.$refs.noteTextDialog.open();
    }
  }
}
</script>

<style scoped>
.trash-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
/deep/ .trash-item__thumb {
  width: 76px;
  margin-right: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .trash-item__img {
  background-color: var(--color-image-background);
}
/deep/ .trash-item__details {
  text-align: left;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 4px;
}
/deep/ .trash-item__name {
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/ .trash-item__type {
  margin: 2px 0;
}
/deep/ .trash-item__checkbox {
  margin-left: 10px;
}
</style>
