<template>
  <div class="l-vertical__grow l-vertical">
    <page-actions>
      <help-menu>{{ $t('shared.texts.help.episodeDetails') }}</help-menu>
    </page-actions>

    <activity-tabs />

    <div class="l-vertical__scroll">

      <activity-header
        :episode="episode"
        :activity="activity"
        shared
      />
        
      <loading v-if="!activity" />

      <v-container
        v-if="activity"
        class="container--narrow"
      >
        <div 
          v-if="activity.description"
          class="mb-6 text-center"
        >
          <collapse-text center-button>
            {{ activity.description }}
          </collapse-text>
        </div>

        <v-row 
          v-if="activity.establishment || activity.address || activity.province || activity.country"
          class="mb-6"
        >
          <v-col class="flex-grow-0 d-flex align-center">
            <v-btn
              icon
              :to="{ 
                name: 'shared-episode-map', 
                params: { episodeId: $route.params.episodeId },
                query: { 
                  activity: activity.id,
                  hash: this.$route.query.hash 
                } 
              }"
              color="primary"
            >
              <v-icon 
                large 
              >mdi-map-marker-outline</v-icon>
            </v-btn>
          </v-col>

          <v-col class="pa-0 d-flex justify-center flex-column">
            <div>{{ activity.establishment }}</div>
            <div>{{ activity.address }}</div>
            <div>
              <template v-if="activity.city">{{ activity.city }}, </template>
              <template v-if="activity.province">{{ activity.province.name }}, </template>
              <template v-if="activity.country">{{ activity.country.name }}</template>
            </div>
          </v-col>
        </v-row>

        <v-row 
          v-if="activity.cost"
          class="mb-6"
        >
          <v-col class="flex-grow-0 d-flex align-center">
            <v-icon 
              class="grey--text"
              large
            >mdi-cash-multiple</v-icon>
          </v-col>

          <v-col class="pa-0 d-flex align-center">{{ $helperFunctions.formatMoney(activity.cost) }} {{ activity.currency }}</v-col>
        </v-row>
        
      </v-container>
      
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ActivityHeader from '@/modules/activities/components/ActivityHeader.vue';
import ActivityTabs from '@/modules/shared/components/ActivityTabs.vue';
import CollapseText from '@/components/util/CollapseText';
import HelpMenu from '@/modules/shared/components/HelpMenu.vue';
import Loading from '@/components/util/Loading';
import PageActions from '@/modules/shared/components/PageActions.vue';

export default {
  components: {
    ActivityTabs,
    ActivityHeader,
    CollapseText,
    HelpMenu,
    Loading,
    PageActions
  },
  data() {
    return {
    }
  }, 
  computed: {
    ...mapGetters({
      activity: 'shared/cachedActivity',
      episode: 'shared/cachedEpisode'
    })
  },
  created() {
    // load episode
    this.loadEpisodeToCache({
      episodeId: this.$route.params.episodeId,
      hash: this.$route.query.hash
    });

    // load activity
    this.loadActivityToCache({
      episodeId: this.$route.params.episodeId,
      activityId: this.$route.params.activityId,
      hash: this.$route.query.hash
    });
  },
  methods: {
    ...mapActions({
      loadActivityToCache: 'shared/loadActivityToCache',
      loadEpisodeToCache: 'shared/loadEpisodeToCache'
    })
  }
}
</script>

<style scoped>
.activity__type {
  margin-top: 6px;
  margin-left: 300px;
  height: 80px;
}

/* mobile */
@media (max-width: 1024px) {
  .activity__type {
    margin-left: 180px;
  }
}
</style>