<template>
  <div class="generic-list-item">
    <div class="generic-list-item__main d-flex">

      <div class="flex-grow-0 mr-2">
        <v-icon class="mt-4">mdi-music-note</v-icon>
      </div>

      <div class="flex-grow-1">
        {{ item.name }}<br>
        {{ item.author }}<br>
        {{ item.type }}
      </div>
    </div>

    <div class="generic-list-item__buttons">
      <actions-menu 
        v-if="!selectionEnabled"
        :item="item" 
        class="mb-1"
        @delete="$emit('delete')"
        @assign="$emit('assign')"
      />

      <v-simple-checkbox
        v-if="selectionEnabled"
        v-model="item.selected" 
        v-ripple
        color="primary"
        class="melody-item__checkbox mb-1"
      />
        
      <audio-player
        :src="item.urlAudioFile"
      />
    </div>
  </div>
</template>

<script>
import ActionsMenu from './ActionsMenu/ActionsMenu.vue';
import AudioPlayer from '@/components/AudioPlayer.vue';

export default {
  components:{
    ActionsMenu,
    AudioPlayer
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    selectionEnabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.melody-list-item {
  border-radius: 5px;
  background-color: var(--color-background--light);
  color: var(--color-text--dark);
  line-height: 1.1;
}
.melody-item__checkbox
{ 
  align-self: flex-start;
  margin-top: 2px;
  margin-left: 4px;
}
</style>
