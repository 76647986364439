<template>
  <div>

    <div v-if="notices && noticesCurrent.length">
      <notice-list-item
        v-for="item in noticesCurrent"
        :key="item.id"
        :item="item"
        @publish="publishId = item.id"
        @republish="republishId = item.id"
        @finish="finishId = item.id"
        @delete="deleteId = item.id"
      />
    </div>

    <v-alert 
      v-if="notices && !noticesCurrent.length"
      type="info"
    >
      {{ $t('notices.texts.noCurrentNotices') }}
    </v-alert>
    
    <publish-dialog
      :item-id="publishId"
      @close="publishId = null"
      @success="$emit('success')"
    />
    
    <republish-dialog
      :item-id="republishId"
      @close="republishId = null"
      @success="$emit('success')"
    />
    
    <finish-dialog
      :item-id="finishId"
      @close="finishId = null"
      @success="$emit('success')"
    />
    
    <delete-dialog
      :item-id="deleteId"
      @close="deleteId = null"
      @success="$emit('success')"
    />

  </div>
</template>

<script>
import DeleteDialog from './DeleteDialog';
import FinishDialog from './FinishDialog';
import NoticeListItem from '@/modules/notices/pages/NoticeListPage/NoticeListItem/NoticeListItem.vue';
import PublishDialog from './PublishDialog';
import RepublishDialog from './RepublishDialog';

export default {
  components: {
    DeleteDialog,
    FinishDialog,
    NoticeListItem,
    PublishDialog,
    RepublishDialog
  },
  props: {
    notices: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      deleteId: null,
      finishId: null,
      publishId: null,
      republishId: null,
    }
  }, 
  computed: {
    noticesCurrent() {
      if (!this.notices) {
        return [];
      }
      return this.notices.filter(item => {
        // show notices that are not finished
        return !item.finished;
      });
    }
  }
}
</script>
