import FavoritesPage from '@/modules/favorites/pages/FavoritesPage/FavoritesPage.vue';

export default [
  {
    path: '/favorites',
    name: 'favorites',
    component: FavoritesPage,
    meta: { requiresAuth: true }
  }
];
