<template>
  <div class="l-vertical__grow l-vertical">
    <page-title 
      :back-to="{ name: 'episodes-list'}"
    >
      {{ $t('dataUpload.titles.dataUpload') }}
    </page-title>

    <data-upload-tabs />

    <alerts-panel />

    <div 
      ref="statusTap"
      class="l-vertical__scroll"
    >

      <v-container>

        <div class="mb-3 small">
          <span class="overline small">{{ $t('dataUpload.titles.folderStructure') }}:</span>
          {{ $t(nested ? 'dataUpload.model.folderStructure.nested.title' : 'dataUpload.model.folderStructure.flat.title') }}

          <template v-if="rootName">
            <span class="ml-4 overline small">Carpeta:</span>
            {{ rootName }}
          </template>
        </div>
        
        <div class="well mb-4">
          <div class="mb-3">{{ $t('dataUpload.titles.chooseFolder') }}:</div>

          <v-file-input
            v-model="files"       
            outlined
            prepend-icon="mdi-folder"
            webkitdirectory 
            multiple
            @change="onChange"
          />

          <v-progress-linear 
            v-if="total"
            color="primary" 
            height="4" 
            :value="progress"
          />

          <div 
            v-if="loadingFiles"
            class="mt-3"
          >
            <span class="overline">{{ $t('dataUpload.titles.verifyingFile') }}:</span> <strong>{{ Number(fileIndex) + 1 }}</strong> {{ $t('dataUpload.titles.uploadingFileOf') }} <strong>{{ total }}</strong>
          </div>

        </div>


        <v-row>
          <v-col
            cols="3"
          >
            <v-btn 
              ref="submit"
              class="primary"
              block
              :disabled="!valid || loadingFiles"
              @click="submit"
            >{{ $t('actions.next') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import AlertsPanel from '@/modules/dataUpload/components/AlertsPanel/AlertsPanel.vue';
import DataUploadTabs from '@/modules/dataUpload/components/DataUploadTabs';
import PageTitle from "@/components/layout/PageTitle.vue";
import statusTap from '@/mixins/statusTap.js';
import UploadParameters from '@/modules/files/model/UploadParameters.js';

export default {
  components: {
    AlertsPanel,
    DataUploadTabs,
    PageTitle,
  },
  mixins: [statusTap],
  data() {
    return {
      files: [],
      fileIndex: 0,
      folders: null,
      invaildExtensionsCount: {},
      loadingFiles: false,
      validFilesCount: 0,
      videosTooLongCount: 0,
    }
  },
  computed: {
    ...mapGetters({
      nested: 'dataUpload/nested',
      rootName: 'dataUpload/rootName',
    }),
    total() {
      return this.files.length;
    },
    progress() {
      return this.total ? ((this.fileIndex + 1) / this.total * 100) : 0;
    },
    valid() {
      return !!this.validFilesCount;
    }
  },
  created() {
    if (this.nested === null) {
      this.$router.push({ name: 'data-upload-step-1' });
    }
    this.clearAlerts();
    this.setRootName(null);
    this.setFolders(null);
  },
  methods: {
    ...mapMutations({
      addAlert: 'dataUpload/addAlert',
      addFlash: 'addFlash',
      clearAlerts: 'dataUpload/clearAlerts',
      setFolders: 'dataUpload/setFolders',
      setRootName: 'dataUpload/setRootName',
    }),
    ...mapActions({
      uploadFile: 'files/uploadFile',
    }),
    async onChange() {
      this.reset();

      if (!this.files.length) {
        console.log('no files');
        return;
      }

      this.loadingFiles = true;
      this.setRootName(this.files[0].webkitRelativePath.split('/')[0]);
      let filesCount = this.files.length;
      let rootName = this.rootName;
      let routeName = this.$route.name;

      for (let i in this.files) {
        if (
          (filesCount != this.files.length) 
          || (rootName != this.rootName) 
          || (routeName != this.$route.name)
        ) {
          this.reset();
          return;
        }

        this.fileIndex = i;
        let file = this.files[i];

        const filename = this.getFilename(file.webkitRelativePath);

        // skip hidden files
        if (filename.substr(0, 1) === '.') {
          continue;
        }

        // validate file extension
        if (UploadParameters.ACCEPTED_MIME_TYPES.indexOf(file.type) === -1) {
          const extension = filename.split('.').pop();
          if (this.invaildExtensionsCount[extension]) {
            this.invaildExtensionsCount[extension]++;
          }
          else {
            this.invaildExtensionsCount[extension] = 1;
          }
          continue;
        }

        let type = (file.type.indexOf('video') !== -1) ? 'video' : 'photo';

        // validate max video duration
        let duration = null;
        if (type == 'video') {
          duration = await this.$videoFunctions.getFileDuration(file).catch(error => {
            console.log(error, file.webkitRelativePath);
          });
          if (!duration) {
            this.addAlert({
              text: this.$t('dataUpload.messages.error.couldNotOpenFile', { filename: file.webkitRelativePath }),
              type: null
            });
            continue;
          }
          if (duration > UploadParameters.MAX_VIDEO_DURATION) {
            this.videosTooLongCount++;
            continue;
          }
        }

        const date = await this.getFileDate(file, type);

        this.addFileToFolders({
          file: file,
          date: date,
          id: null,
          type: type,
          duration: duration,
        });
        this.validFilesCount++;
      }

      // sort files by date
      for (let folder in this.folders) {
        this.folders[folder].files.sort((a, b) => {
          return a.date > b.date ? 1 : -1;
        });
      }

      this.setFolders(this.folders);

      this.showInvalidExtensionsAlert();
      this.showVideosTooLongAlert();
      this.loadingFiles = false;
    },
    reset() {
      this.loadingFiles = false;
      this.clearAlerts();
      this.setRootName(null);
      this.folders = {};
      this.validFilesCount = 0;
      this.fileIndex = 0;
      this.videosTooLongCount = 0;
    },
    getFilename(path) {
      // return path.split('\\').pop().split('/').pop();
      return path.split('/').pop();
    },
    /**
     * Get file date from EXIF data or file last modified date
     * @param {File} file
     * @returns {String} date in format YYYY-MM-DD HH:mm:ss
     */
    async getFileDate(file, type) {
      let date;
      if (type == 'photo') {
        date = await this.$imageFunctions.getExifDate(file).catch(error => {
          console.log('Error reading file date', error);
        });
      }
      if (date) {
        return date;
      }
      else {
        return this.$moment(file.lastModified).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    addFileToFolders(file) {
      let folder;
      if (this.nested) {
        let path = file.file.webkitRelativePath;
        let folders = path.split('/');
        // remove file name
        folders.pop();
        // remove root folder
        folders.shift();

        folder = folders.join('/');

        // console.log(path, folder);

        // skip files in root folder
        if (!folder) {
          return;
        }
      }
      else {
        folder = 'flat';
      }

      if (!this.folders[folder]) {
        this.folders[folder] = {
          path: folder,
          files: [],
        };
      }

      this.folders[folder].files.push(file);
    },
    showInvalidExtensionsAlert() {
      if (Object.keys(this.invaildExtensionsCount).length) {
        let text = this.$t('dataUpload.messages.error.invalidExtensionsTitle') + '<ul>';
        for (let extension in this.invaildExtensionsCount) {
          text += '<li>' + this.$t('dataUpload.messages.error.invalidExtensionsItem', { extension, count: this.invaildExtensionsCount[extension] }) + '</li>';
        }
        text += '</ul>';
        this.addAlert({
          text: text,
          type: null
        });
      }
    },
    showVideosTooLongAlert() {
      if (this.videosTooLongCount) {
        this.addAlert({
          text: this.$t('dataUpload.messages.error.videosTooLongCount', { count: this.videosTooLongCount }),
          type: null
        });
      }
    },
    submit() {
      if (!this.valid) {
        return;
      }

      this.$router.push({ name: 'data-upload-step-3' });
    },
  }
}
</script>

<style scoped>
</style>
